import React from 'react';
import cx from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as styles from './TourGallery.module.scss';
import { IFluidObject } from '../../interfaces';
import { FluidImage, BackgroundImage, Desktop, Mobile } from '../../components';
import { sliderLeft, sliderRight } from '../../icons';
import { windowSizes } from '../../constants';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';

interface IGalleryImage {
  id: string;
  title: string;
  fluid?: IFluidObject;
  src?: string;
}

interface IProps {
  images: IGalleryImage[];
}

interface IState {
  activeSlide: number;
  ready: boolean;
  isImageFullScreen: boolean;
}

const slideWidth = 270;

class TourGallery extends React.Component<IProps & IWindowSizeProps, IState> {
  readonly state = { activeSlide: 0, ready: false, isImageFullScreen: false };

  componentDidMount() {
    const center = Math.floor(this.props.images.length / 2);
    this.setState({ activeSlide: center, ready: true, isImageFullScreen: false });
  }

  render() {
    const { images, windowWidth } = this.props;
    //const { activeSlide } = this.state;
    //const activeImage = images[activeSlide];
    const useFillImages =
      images.length === 1 ||
      ((images.length === 2 || images.length === 3) &&
        windowWidth > windowSizes.tablet);

    if (useFillImages) {
      return this.renderFillImages();
    } else {
      return (
        <div className={styles.container} data-aos='fade-up'>
          <Mobile breakpoint='tablet'>{this.renderMobileSlider()}</Mobile>
          <Desktop breakpoint='tablet'>{this.renderSlider()}</Desktop>
          {/*           <div className={styles.imageTitle}>
            <p>{activeImage.title}</p>
          </div> */}
        </div>
      );
    }
  }

  renderFillImages() {
    return (
      <div className={cx('centered-content', styles.fillImages)}>
        <div className='columns'>
          {this.props.images.map((img) => (
            <div className='column' key={img.id}>
              <div className={styles.fillImage}>{this.renderImage(img)}</div>
              <div>
                <p className={styles.fillTitle}>{img.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderSlider() {
    const { images } = this.props;
    const sliderTransform = `translateX(-${
      this.state.activeSlide * slideWidth
    }px)`;

    if (images.length > 1) {
      return (
        <div className={styles.sliderContainer}>
          <div className={styles.slider} style={{ transform: sliderTransform }}>
            {images.map((image, index) => this.renderSlide(image, index))}
          </div>
          <div className={styles.arrows}>
            <div>
              <button className={styles.leftArrow} onClick={this.prevSlide}>
                <img src={sliderLeft} alt='Left arrow' />
              </button>
              <button onClick={this.nextSlide}>
                <img src={sliderRight} alt='Right arrow' />
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.sliderContainer}>
          {images.map((image, index) => this.renderSlide(image, index))}
        </div>
      );
    }
  }

  renderSlide(image: IGalleryImage, index: number) {
    const { activeSlide } = this.state;
    const isActive = activeSlide === index;
    const setActive = () => this.setState({ activeSlide: index });

    return (
      <div
        key={image.id}
        className={cx(styles.slide, { [styles.activeSlide]: isActive })}
        onClick={!isActive ? setActive : undefined}
      >
        {this.renderImage(image)}
      </div>
    );
  }

  renderMobileSlider() {
    const { images } = this.props;
    const { activeSlide, ready, isImageFullScreen } = this.state;
  
    // If initial slide hasn't been found
    if (!ready) {
      return null;
    }
  
    const toggleImageFullScreen = () => {
      this.setState((prevState) => ({
        isImageFullScreen: !prevState.isImageFullScreen,
      }));
    };
  
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      initialSlide: activeSlide,
    };
  
    return (
      <div>
        {isImageFullScreen && (
          <div
            className={styles.fullScreenImage}
            onClick={() => toggleImageFullScreen()}
          >
            {this.renderImage(images[activeSlide])}
          </div>
        )}
  
        {!isImageFullScreen && (
          <Slider {...settings} afterChange={this.updateActive}>
            {images.map((image) => (
              <div
                key={image.id}
                className={styles.mobileSlide}
                onClick={() => toggleImageFullScreen()}
              >
                {this.renderImage(image)}
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
  
  renderImage(image: IGalleryImage) {
    let displayImage = null;
    if (image.fluid) {
      displayImage = (
        <FluidImage
          fluid={image.fluid}
          alt={image.title}
          className={styles.image}
        />
      );
    } else if (image.src) {
      displayImage = (
        <BackgroundImage src={image.src} className={styles.image} />
      );
    }

    return displayImage;
  }

  nextSlide = () => {
    const { activeSlide } = this.state;
    const { images } = this.props;
    if (activeSlide < images.length - 1) {
      this.setState({ activeSlide: activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  };

  prevSlide = () => {
    const { activeSlide } = this.state;
    const { images } = this.props;
    if (activeSlide > 0) {
      this.setState({ activeSlide: activeSlide - 1 });
    } else {
      this.setState({ activeSlide: images.length - 1 });
    }
  };

  updateActive = (index: number) => {
    this.setState({ activeSlide: index });
  };
}

export default withWindowSize(TourGallery);
