import React from 'react';

export const Bin = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.125 2.5L12.4 14.275a1.25 1.25 0 0 1-1.225 1.225h-6.35A1.25 1.25 0 0 1 3.6 14.275L2.875 2.5M1.5 2.5h13-13zm4 0V1.475A.975.975 0 0 1 6.475.5h3.05a.975.975 0 0 1 .975.975V2.5h-5zM10.5 4.5l-.5 9m-2-9v9-9zm-2.5 0l.5 9-.5-9z'
      stroke='#676767'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default Bin;
