import React from 'react';

import * as styles from './Conditional.module.scss';
import { windowSizes } from '../../constants';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';

interface IProps {
  children: React.ReactNode;
  breakpoint?: 'header' | 'tablet';
}

const Mobile: React.FunctionComponent<IProps & IWindowSizeProps> = ({
  children,
  breakpoint,
  windowWidth,
}) => {
  const style = breakpoint === 'tablet' ? styles.mobileTablet : styles.mobile;
  const breakWidth =
    breakpoint === 'tablet' ? windowSizes.tablet : windowSizes.header;

  return (
    <div className={style}>
      {!windowWidth || windowWidth <= breakWidth ? children : null}
    </div>
  );
};

Mobile.defaultProps = {
  breakpoint: 'header',
};

export default withWindowSize(Mobile);
