import React, { useState, useEffect } from 'react';
import * as styles from './TourBooking.module.scss';
import { setCart } from '../../redux/actions/cartActions';
import { connect } from 'react-redux';
import {
  ICurrencyOption,
  IProduct,
  IPickupDropoff,
  ISelectValue,
  ISortedAvailability,
  IPersonCounter,
  IBookingCutoff,
  IRate,
  IBookableExtraState,
  DataType,
  ITranslationsBookingModal,
  IReducers,
  ITourDetails,
} from '../../interfaces';
import { Moment } from 'moment';
import { TourDatePicker } from '../components';
import TourBookingGuests from '../components/TourBookingGuests';
import { Loader } from '../../components';
import TourBookingTime from '../components/TourBookingTime';
import TourBookingPickup from '../components/TourBookingPickup';
import TourBookingDropoff from '../components/TourBookingDropoff';
import TourBookingMeetingPoints from '../components/TourBookingMeetingPoints';
import TourBookingExtras from '../components/TourBookingExtras';
import TBPriceBreakdown from '../components/TBPriceBreakdown';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  tour: ITourDetails;
  currency: ICurrencyOption;
  product: IProduct | null;
  pickupAndDropoffPlaces: IPickupDropoff | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  selectedDate: Moment | null;
  selectedMonth: Moment | null;
  focus: boolean;
  availability: ISortedAvailability | null;
  selectedTime: ISelectValue | null;
  validTime: boolean;
  validPickup: boolean;
  validDropoff: boolean;
  personCounter: IPersonCounter | null;
  addingToCart: boolean;
  selectPickupOpen: boolean;
  selectDropoffOpen: boolean;
  bookableExtraCounter: IBookableExtraState | null;
  locale: string;
  resetSelectedTimeAndObject(): void;
  onChangeDate(date: Moment | null): void;
  onFocusChange(focus: boolean | null): void;
  getAvailability(fromDate: Date, monthsToAdd: number): void;
  isDayBlocked(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  isDayDisabled(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  handleSelectedTime(event: { label: string; value: string }): void;
  getTotalPersonCount(): number;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
  getRateIndex(rates: IRate[]): number;
  getPersonCount(personType: string): number;
  changePickupDropoff(event: ISelectValue, pickupOrDropoff: pickOrDrop): void;
  changeSelectOpen(newState: boolean, pickupOrDropoff: pickOrDrop): void;
  addToCart(): void;
  getMinToBook(): number;
  toggleSelectExtra(name: string): void;
  changeBookableExtraCounter(
    addorRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ): void;
  changeAnswer(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}
type AddOrRemove = 'addOne' | 'removeOne';
type pickOrDrop = 'pickup' | 'dropoff';

const TourBookingMobile: React.FunctionComponent<IProps> = (props) => {
  const [dateOpen, triggerDate] = useState(false);
  const [availabilityIsLoading, setAvailabilityIsLoading] = useState(false);
  const {
    currency,
    product,
    availability,
    selectedDate,
    selectedMonth,
    selectedTime,
    pickupAndDropoffPlaces,
    selectedPickup,
    selectedDropoff,
    bookableExtraCounter,
    focus,
    validTime,
    validPickup,
    validDropoff,
    personCounter,
    addingToCart,
    onChangeDate,
    onFocusChange,
    getAvailability,
    isDayBlocked,
    isDayDisabled,
    resetSelectedTimeAndObject,
    handleSelectedTime,
    getTotalPersonCount,
    changePersonCount,
    changePickupDropoff,
    changeSelectOpen,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    getPersonCount,
    getRateIndex,
    addToCart,
    localeData,
    tour,
  } = props;

  useEffect(() => {
    setAvailabilityIsLoading(false);
  }, [availability]);

  function loadingAvailability(loading: boolean) {
    setAvailabilityIsLoading(loading);
  }
  const pickupDropoffText =
    (!!product && product.PickupSelection !== 'UNAVAILABLE'
      ? localeData.pickUpHeader
      : '') +
    (!!product && product.DropoffSelection !== 'UNAVAILABLE'
      ? localeData.dropOffHeader
      : '');

  return (
    <div className='columns is-gapless'>
      <div className={styles.container}>
        {product?.BookingType === 'PASS' ? null : (
          <div className='column'>
            <TourDatePicker
              product={product}
              availability={availability}
              selectedDate={selectedDate}
              defaultMonth={selectedMonth}
              selectedTime={selectedTime}
              focus={focus}
              bookingCutoff={product ? product.BookingCutoff : null}
              resetSelectedTimeAndObject={resetSelectedTimeAndObject}
              onChangeDate={onChangeDate}
              onFocusChange={onFocusChange}
              getAvailability={getAvailability}
              isDayBlocked={isDayBlocked}
              isDayDisabled={isDayDisabled}
              loadingAvailability={loadingAvailability}
            />
          </div>
        )}
        {product?.BookingType === 'PASS' ? null : (
          <div className='column'>
            <TourBookingTime
              product={product}
              availability={availability}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              handleSelectedTime={handleSelectedTime}
              isValid={validTime}
              header={localeData.timeSelectorHeader}
            />
            {availabilityIsLoading === true ? (
              <div className={styles.beatLoaderCenter}>
                <Loader />
              </div>
            ) : null}
          </div>
        )}
        <div className='column'>
          <TourBookingGuests
            personCounter={personCounter}
            getTotalPersonCount={getTotalPersonCount}
            changePersonCount={changePersonCount}
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            header={localeData.participantsSelectorHeader}
          />
        </div>
        {!!pickupAndDropoffPlaces &&
        Object.keys(pickupAndDropoffPlaces).length !== 0 ? (
          <div className='column'>
            <TourBookingPickup
              pickupDropoffText={pickupDropoffText}
              pickupAndDropoffPlaces={pickupAndDropoffPlaces}
              selectedPickup={selectedPickup}
              changePickupDropoff={changePickupDropoff}
              product={product}
              changeSelectOpen={changeSelectOpen}
              isValid={validPickup}
            />
          </div>
        ) : null}
        {!!pickupAndDropoffPlaces &&
        Object.keys(pickupAndDropoffPlaces).length !== 0 ? (
          <div className='column'>
            <TourBookingDropoff
              dropoff={pickupAndDropoffPlaces}
              selectedDropoff={selectedDropoff}
              isValid={validDropoff}
              dropoffSelection={product ? product.DropoffSelection : null}
              openDate={dateOpen}
              triggerDate={triggerDate}
              changeDropoff={changePickupDropoff}
              toggleSelect={changeSelectOpen}
            />
          </div>
        ) : null}
        {!!product && !!product.StartPlaces ? (
          <div className='column'>
            <TourBookingMeetingPoints
              pickupSelection={product ? product.PickupSelection : null}
              dropoffSelection={product ? product.DropoffSelection : null}
              meetingPoints={
                !!product && !!product.StartPlaces ? product.StartPlaces : null
              }
            />
          </div>
        ) : null}
        {bookableExtraCounter && (
          <div className='column'>
            <TourBookingExtras
              personCounter={personCounter}
              bookableExtraCounter={bookableExtraCounter}
              changeBookableExtraCounter={changeBookableExtraCounter}
              changeAnswer={changeAnswer}
              toggleSelectExtra={toggleSelectExtra}
              title={localeData.extrasHeader}
            />
          </div>
        )}
        <div className='column'>
          <TBPriceBreakdown
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            bookableExtraCounter={bookableExtraCounter}
            getRateIndex={getRateIndex}
            selectedPickup={selectedPickup}
            selectedDropoff={selectedDropoff}
            getPersonCount={getPersonCount}
            currency={currency}
            personCounter={personCounter}
            addToCart={addToCart}
            addingToCart={addingToCart}
            tour={tour}
            isMobile={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer, translationReducers } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale } = translationReducers;
  return { cartObject, currency, locale };
};

export default connect(mapStateToProps, {
  setCart,
})(
  ({
    currency,
    product,
    availability,
    selectedDate,
    selectedMonth,
    selectedTime,
    pickupAndDropoffPlaces,
    selectedPickup,
    selectedDropoff,
    bookableExtraCounter,
    focus,
    validTime,
    validPickup,
    validDropoff,
    personCounter,
    addingToCart,
    onChangeDate,
    onFocusChange,
    getAvailability,
    isDayBlocked,
    isDayDisabled,
    resetSelectedTimeAndObject,
    handleSelectedTime,
    getTotalPersonCount,
    changePersonCount,
    changePickupDropoff,
    changeSelectOpen,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    getPersonCount,
    getRateIndex,
    addToCart,
    selectPickupOpen,
    selectDropoffOpen,
    getMinToBook,
    locale,
    tour,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourBookingMobileQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                extrasHeader
                pickUpHeader
                dropOffHeader
                participantsSelectorHeader
                timeSelectorHeader
              }
            }
          }
        }
      `}
      render={(data) => (
        <TourBookingMobile
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          currency={currency}
          product={product}
          availability={availability}
          selectedDate={selectedDate}
          selectedMonth={selectedMonth}
          selectedTime={selectedTime}
          pickupAndDropoffPlaces={pickupAndDropoffPlaces}
          selectedPickup={selectedPickup}
          selectedDropoff={selectedDropoff}
          bookableExtraCounter={bookableExtraCounter}
          focus={focus}
          validTime={validTime}
          validPickup={validPickup}
          validDropoff={validDropoff}
          personCounter={personCounter}
          addingToCart={addingToCart}
          onChangeDate={onChangeDate}
          onFocusChange={onFocusChange}
          getAvailability={getAvailability}
          isDayBlocked={isDayBlocked}
          isDayDisabled={isDayDisabled}
          resetSelectedTimeAndObject={resetSelectedTimeAndObject}
          handleSelectedTime={handleSelectedTime}
          getTotalPersonCount={getTotalPersonCount}
          changePersonCount={changePersonCount}
          changePickupDropoff={changePickupDropoff}
          changeSelectOpen={changeSelectOpen}
          changeBookableExtraCounter={changeBookableExtraCounter}
          changeAnswer={changeAnswer}
          toggleSelectExtra={toggleSelectExtra}
          getPersonCount={getPersonCount}
          getRateIndex={getRateIndex}
          addToCart={addToCart}
          selectPickupOpen={selectPickupOpen}
          selectDropoffOpen={selectDropoffOpen}
          getMinToBook={getMinToBook}
          locale={locale}
          tour={tour}
        />
      )}
    />
  )
);
