import React from 'react';
import moment, { Moment } from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import Modal from 'react-modal';

import * as styles from './PrivateTourModal.module.scss';
import { TextInput, NumberInput, DateInput, Loader } from '../..';
import { validateEmail } from '../../../utils';
import { EmailApi } from '../../../api';
import {
  IInput,
  IPageEmailForm,
  ITextInput,
  //  ISelectInput
} from '../../../interfaces';
import TourBookingHeader from '../../../BookingEngine/components/TourBookingHeader';

const EMAIL_SUBJECT = 'Private tour enquiry for ';
const EMAIL_ADDRESS = 'sales@re.is';

interface IProps {
  form: IPageEmailForm;
  tourName: string;
  modalOpen: boolean;
  toggleModal(value: boolean): void;
  setEmailStatus(value: boolean): void;
  modalButton: string;
}

interface IState {
  fields: {
    [id: string]: string | number;
  };
  open: {
    [id: string]: boolean;
  };
  captcha: string | null;
  error: boolean;
  loading: boolean;
  replyEmailId: string;
  startDate: Moment | null;
  endDate: Moment | null;
}

const initialState = {
  fields: {},
  open: {},
  captcha: null,
  error: false,
  loading: false,
  replyEmailId: '',
  startDate: null,
  endDate: null,
};

class PrivateTourModal extends React.Component<IProps, IState> {
  readonly state: IState = initialState;
  emailApi = new EmailApi();

  render() {
    const { form, toggleModal, modalOpen, modalButton } = this.props;
    const { loading, captcha } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => toggleModal(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        closeTimeoutMS={200}
        className={styles.modal}
        bodyOpenClassName={styles.modalOpen}
        portalClassName={styles.modalPortal}
        ariaHideApp={false}
      >
        <TourBookingHeader
          toggleModal={toggleModal}
          tourTitle={form.emailTitle || form.title}
          tourSlug={''}
        />
        <div className='centered-content vertical-margin'>
          {/* <h3 className={styles.title}>{form.emailTitle}</h3> */}
          <form onSubmit={(e) => e.preventDefault()}>
            {form.inputFields.map((field) => {
              if (field.__typename === 'ContentfulTextInput') {
                return (
                  <div key={field.id} className={styles.formParts}>
                    {this.renderFieldTexts(field as ITextInput)}
                  </div>
                );
              } else {
                return null; // we dont handle selection input, maybe in the future
              } /* else if (field.__typename === 'ContentfulSelectInput') {
              this.renderFieldInputs(field as ISelectInput);
            } */
            })}
          </form>
          <div className={styles.captchaAndConfirm}>
            {!captcha ? (
              <div style={{ marginLeft: 'auto' }}>
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY as string}
                  onChange={(c) => this.setState({ captcha: c })}
                />
              </div>
            ) : (
              <button
                className='basic-button'
                onClick={this.sendEnquiry}
                style={{ marginLeft: 'auto' }}
              >
                {loading ? <Loader color='white' /> : modalButton}
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  renderFieldTexts = (field: ITextInput) => {
    const { fields, error, startDate, endDate, open } = this.state;
    switch (field.type) {
      case 'text':
        return (
          <TextInput
            title={field.title}
            value={(fields[field.id] as string) || ''}
            onChange={(e) => this.updateInput(e.target.value, field.id)}
            type={field.type}
            name={field.name}
            max={field.max}
            error={error && !this.validateField(field)}
            errorText={field.errorText}
          />
        );
      case 'textarea':
        return (
          <TextInput
            title={field.title}
            value={(fields[field.id] as string) || ''}
            onChange={(e) => this.updateInput(e.target.value, field.id)}
            type={field.type}
            name={field.name}
            max={field.max || (field.type === 'textarea' ? 500 : 100)}
            error={error && !this.validateField(field)}
            errorText={field.errorText}
          />
        );
      case 'date':
        return (
          <DateInput
            title={field.title}
            startDate={startDate}
            endDate={endDate}
            onChange={(e) => this.updateDateRange(e, field.id)}
            clearDateSelection={this.clearDateSelection}
            open={open[field.id]}
            toggleOpen={() => this.toggleOpen(field.id)}
            name='calendar'
            error={error && !this.validateTime()}
            errorText={field.errorText}
            dateClear={field.dateInputTexts.inputPlaceholder}
            dateText={field.dateInputTexts.altInputLabel}
          />
        );
      case 'email':
        return (
          <TextInput
            title={field.title}
            value={(fields[field.id] as string) || ''}
            onChange={(e) => this.updateInput(e.target.value, field.id)}
            type='email'
            name='email'
            error={error && !this.validateEmailForm(field.id)}
            errorText={field.errorText}
          />
        );
      case 'number':
        return (
          <NumberInput
            title={field.title}
            value={(fields[field.id] as number) || 1}
            onChange={(e) => this.updateInput(e, field.id)}
            name='passengers'
            max={field.maxNumber}
            min={field.minNumber}
            error={
              error &&
              !this.validatePassengers(
                field.minNumber,
                field.maxNumber,
                field.id
              )
            }
            errorText={field.errorText}
          />
        );
    }
  };

  /*   renderFieldInputs = (field: ISelectInput) => {
    /// TODO!!!!
  };
 */
  updateInput = (value: string | number, id: string) => {
    const { fields } = this.state;
    fields[id] = value;
    this.setState({ fields });
  };

  updateDateRange = (
    d: {
      startDate: Moment | null;
      endDate: Moment | null;
    },
    id: string
  ) => {
    const { startDate, endDate } = this.state;
    let end = d.endDate;
    if (startDate && endDate) {
      end = null;
    }
    this.setState({ startDate: d.startDate, endDate: end }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.toggleOpen(id);
      }
    });
  };

  clearDateSelection = () => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ startDate: null, endDate: null });
  };

  toggleOpen = (id: string) => {
    const { open } = this.state;
    open[id] = !open[id];
    this.setState({ open });
  };

  validateTime = () => {
    const { startDate } = this.state;
    return startDate !== null;
  };

  validateEmailForm = (id: string) => {
    const valid = validateEmail(this.state.fields[id] as string);
    if (valid) {
      this.setState({ replyEmailId: id });
    }
    return valid;
  };

  validatePassengers = (min: number, max: number, id: string) => {
    const { fields } = this.state;
    return fields[id] >= min && fields[id] <= max;
  };

  validateField = (field: IInput) => {
    const value = this.state.fields[field.id] as string;
    if (!field.required && (!value || value?.length === 0)) {
      return true;
    }
    if (field.__typename === 'ContentfulTextInput') {
      const textInput = field as ITextInput;
      if (textInput.type === 'email') {
        return validateEmail(value);
      }
    }
    if (!value) {
      return !field.required;
    }
    return value.length > 0;
  };

  validateAllFields = (fields: ITextInput[]) => {
    let valid = true;
    fields.map((field) => {
      // no need to check more if one failed
      if (!valid) {
        return;
      }
      if (field.type === 'number') {
        if (
          !this.validatePassengers(field.minNumber, field.maxNumber, field.id)
        ) {
          valid = false;
          return;
        }
      } else if (field.type === 'date') {
        if (!this.validateTime()) {
          valid = false;
          return;
        }
      } else {
        if (!this.validateField(field)) {
          valid = false;
          return;
        }
      }
      return;
    });
    return valid;
  };

  sendEnquiry = async () => {
    const { tourName, form, toggleModal, setEmailStatus } = this.props;
    const { fields, replyEmailId } = this.state;
    this.setState({ loading: true });
    if (!this.validateAllFields(form.inputFields as ITextInput[])) {
      this.setState({ error: true, loading: false });
    } else {
      const resp = await this.emailApi.sendEmail(
        EMAIL_SUBJECT + ' ' + tourName,
        EMAIL_ADDRESS,
        fields[replyEmailId] as string,
        this.getEmailBody()
      );
      if (resp) {
        // set seccess status in previos component
        // set modalshow false
        toggleModal(false);
        this.setState({ ...initialState, loading: false });
        this.clearInput();
        setEmailStatus(true);
      } else {
        this.setState({ loading: false });
      }
    }
  };

  getEmailBody = () => {
    const { startDate, endDate, fields } = this.state;

    const { form } = this.props;

    const dateFormat = 'DD/MM/YYYY';
    let when = startDate
      ? moment(startDate).format(dateFormat)
      : 'Not selected';
    when +=
      startDate && endDate && !startDate.isSame(endDate, 'day')
        ? ` - ${moment(endDate).format(dateFormat)}`
        : '';

    let emailBody = '';

    form.inputFields.forEach((field) => {
      const input = field as ITextInput;

      emailBody +=
        input.type === 'date'
          ? `<p><strong>When: </strong>${when}</p>`
          : `<p><strong>${field.title}: </strong>${fields[field.id]}</p>`;
    });
    return emailBody;
    /*     return `
      <p><strong>Name: </strong>${name}</p>
      <p><strong>Email: </strong>${email}</p>
      <p><strong>When: </strong>${when}</p>
      <p><strong>Passengers: </strong>${passengers}</p>
      <p><strong>Itinerary: </strong>${itinerary}</p>
      <p><strong>Group: </strong>${group}</p>
    `; */
  };

  clearInput = () => {
    const { fields } = this.state;
    const { inputFields } = this.props.form;
    inputFields.forEach((field) => {
      fields[field.id] = '';
      if (field.__typename === 'ContentfulTextInput') {
        const textField = field as ITextInput;
        if (textField.isReplyEmail) {
          this.setState({ replyEmailId: textField.id });
        }
      }
    });
    this.setState({ fields });
  };
}

export default PrivateTourModal;
