import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';

import * as styles from './AirportTourList.module.scss';
import { Image, Link } from '../index';
import {
  ITourItem,
  IReducers,
  ICurrencyOption,
  IAirportType,
  IPageTours,
} from '../../interfaces';
import { formatPrice, findDefaultPrice, getPageElement } from '../../utils';
import { ProductApi, TransportApi } from '../../api';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  flybus: ITourItem;
  types: IAirportType[];
  langPath: string;
  currency: ICurrencyOption;
  lang: string;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    loading: string;
    tourInfoPriceFrom: string;
  };
}

interface IState {
  prices: { [id: string]: number | null };
  loading: boolean;
}

class AirportTourList extends React.Component<IProps, IState> {
  readonly state: IState = { prices: {}, loading: false };

  productApi = new ProductApi();
  transportApi = new TransportApi();

  componentDidMount() {
    this.getDefaultPrices();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.currency !== this.props.currency) {
      this.getDefaultPrices();
    }
  }

  render() {
    return (
      <div className={cx('columns', 'is-multiline', styles.container)}>
        {this.renderTransport()}
        {this.props.types.map(this.renderType)}
      </div>
    );
  }

  renderTransport() {
    const { langPath, flybus } = this.props;
    const { id, pathPrefix, slug, title, heroImage } = flybus;
    const link = langPath + `${pathPrefix}/${slug}`;

    return (
      <div className={cx('column', 'is-12 is-6-desktop', styles.tourItem)}>
        <Link langPath={langPath} to={link}>
          <Image fluidImage={heroImage} className={styles.image} />
        </Link>
        <div className={styles.card}>
          <Link langPath={langPath} to={link}>
            <p className={styles.title}>{title}</p>
          </Link>
          {this.getPrice(id)}
        </div>
      </div>
    );
  }

  renderType = (type: IAirportType) => {
    const { langPath } = this.props;
    const { id, title, link, image } = type;

    return (
      <div
        key={id}
        className={cx('column', 'is-12 is-6-desktop', styles.tourItem)}
      >
        <Link langPath={langPath} to={link.slug}>
          <Image fluidImage={image} className={styles.image} />
        </Link>
        <div className={styles.card}>
          <Link langPath={langPath} to={link.slug}>
            <p className={styles.title}>{title}</p>
          </Link>
          {this.getPrice(id)}
        </div>
      </div>
    );
  };

  getPrice(id: string) {
    const { prices, loading } = this.state;
    const { currency, localeData } = this.props;
    const price = prices[id];

    if (loading) {
      return <p className={styles.price}>{localeData.loading}</p>;
    } else {
      return (
        price && (
          <p className={styles.price}>
            {localeData.tourInfoPriceFrom} {formatPrice(price, currency.value)}{' '}
            {currency.label}
          </p>
        )
      );
    }
  }

  getDefaultPrices = async () => {
    this.setState({ loading: true });
    const { prices } = this.state;
    const { currency, lang } = this.props;
    const productId = parseInt(this.props.flybus.productId, 10);
    const transport = await this.transportApi.getTransport(
      productId,
      currency.value,
      lang
    );
    if (transport && transport.PriceCategories) {
      transport.PriceCategories.forEach((price) => {
        if (price.Name === 'Adults' && price.Price) {
          prices[this.props.flybus.id] = price.Price.Amount;
          this.setState({ prices });
        }
      });
    }

    for (const type of this.props.types) {
      const pageElem = getPageElement(
        type.link.pageElements,
        'ContentfulPageTours'
      ) as IPageTours;
      if (pageElem.tours) {
        const defaultPrices = await this.productApi.getDefaultPrices(
          pageElem.tours.map((tour) => tour.productId),
          currency.value,
          lang
        );

        if (defaultPrices) {
          let minPrice: number | null = null;
          pageElem.tours.forEach((tour) => {
            const product = findDefaultPrice(defaultPrices, tour.productId);
            if (product && (!minPrice || minPrice > product.DefaultPrice)) {
              minPrice = product.DefaultPrice;
            }
          });
          prices[type.id] = minPrice;
        }
      }
    }
    this.setState({ loading: false, prices });
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang, locale } = reducers.translationReducers;
  return { currency, lang, locale };
};

export default connect(mapStateToProps)(
  ({ flybus, types, langPath, currency, lang, locale }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query AirportTourList {
          allContentfulTranslationsPage {
            edges {
              node {
                node_locale
                loading
                tourInfoPriceFrom
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <AirportTourList
            localeData={
              data.allContentfulTranslationsPage.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            flybus={flybus}
            types={types}
            langPath={langPath}
            currency={currency}
            lang={lang}
            locale={locale}
          />
        );
      }}
    />
  )
);
