import React from 'react';

export const Burger = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='13.8'>
    <g id='Group_260' data-name='Group 260' transform='translate(-320 -32)'>
      <rect
        id='Rectangle_120'
        width='18.902'
        height='1.8'
        fill='#6f7377'
        data-name='Rectangle 120'
        rx='.9'
        transform='translate(326 32)'
      />
      <rect
        id='Rectangle_124'
        width='18.902'
        height='1.8'
        fill='#6f7377'
        data-name='Rectangle 124'
        rx='.9'
        transform='translate(326 44)'
      />
      <rect
        id='Rectangle_121'
        width='30'
        height='1.8'
        fill='#6f7377'
        data-name='Rectangle 121'
        rx='.9'
        transform='translate(320 38)'
      />
    </g>
  </svg>
);
export default Burger;
