import React from 'react';
import * as styles from './index.module.scss';
import Icon from '../../../components/Icon';
import Title from '../../../components/Title';
import { flybus } from '../../../../src/icons';

interface IProps {
  tourTitle: string;
  toggleModal(value: boolean): void;
  tourSlug: string;
}

const TourBookingHeader: React.FunctionComponent<IProps> = ({
  toggleModal,
  tourTitle,
  tourSlug,
}) => {
  const isIcelandairUrl = tourSlug == 'icelandair' ? true : false;
  return (
    <div className={styles.modalTop}>
      {!isIcelandairUrl ? (
        <button onClick={() => toggleModal(false)} aria-label='Close modal'>
          <Icon name='CLOSE' />
        </button>
      ) : null}
      <Title tag='h1' theme={['sizeDesktopLarge']}>
        <div className={styles.titleImage}>
          {tourTitle}
          {isIcelandairUrl ? (
            <img
              src={flybus}
              className={styles.flybusLogo}
              alt='icelandair logo'
            />
          ) : null}
        </div>
      </Title>
    </div>
  );
};

export default TourBookingHeader;
