import React from 'react';
import { ISortedAvailability, ISelectValue } from '../../../interfaces';
import { Moment } from 'moment';
import { formatDate, formatHardcodedStringWithVariables } from '../../../utils';
import FilledBox from '../FilledBox';

interface IProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  getTotalPersonCount(): number;
  text: string;
}

const MinBookingText: React.FunctionComponent<IProps> = props => {
  const getMinBookingText = (receivedProps: IProps) => {
    const {
      availability,
      selectedDate,
      selectedTime,
      getTotalPersonCount,
      text
    } = receivedProps;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value] &&
      !!availability[formatDate(selectedDate)][selectedTime.value].MinToBook
    ) {
      const minToBook =
        availability[formatDate(selectedDate)][selectedTime.value].MinToBook;
      if (!!minToBook && minToBook > 1 && getTotalPersonCount() < minToBook) {
        const formatedString = formatHardcodedStringWithVariables(text, [
          minToBook.toString()
        ]);
        return (
          <div>
            <FilledBox />
            <p>{formatedString}</p>
          </div>
        );
      }
      return null;
    }
    return null;
  };

  return getMinBookingText(props);
};

export default MinBookingText;
