import React from 'react';
import cx from 'classnames';

import * as styles from './Image.module.scss';
import { IImage, IPhoto } from '../../interfaces';
import { Loader, FluidImage, BackgroundImage } from '../index';
import { getImageBySize } from '../../utils';
import { carbonNeutralTour } from '../../../src/icons';

interface IProps {
  fluidImage?: IImage | any;
  productPhoto?: IPhoto | null;
  loadingProduct?: boolean;
  productPhotoSize?: 'original' | 'large' | 'preview' | 'thumbnail';
  fadeIn?: boolean;
  align?: string;
  className?: string;
  loading?: `auto` | `lazy` | `eager`;
}

const Image: React.FunctionComponent<IProps> = ({
  fluidImage,
  productPhoto,
  loadingProduct,
  productPhotoSize,
  fadeIn,
  align,
  className,
  loading,
}) => {
  const style = cx(styles.container, className);

  if (fluidImage) {
    let fi = fluidImage.fluid;
    if (fluidImage.fluid === undefined) {
      fi = fluidImage.image.fluid;
    }
    return (
      <FluidImage
        fluid={fi}
        className={style}
        alt={fluidImage.title}
        fadeIn={fadeIn}
        align={align}
        loading={loading}
      />
    );
  }

  if (productPhoto) {
    return (
      <BackgroundImage
        src={getImageBySize(productPhoto, productPhotoSize || 'large')}
        className={style}
        alt={productPhoto.AlternateText}
        align={align}
      />
    );
  }

  if (loadingProduct) {
    return (
      <div className={style}>
        <Loader color='white' />
      </div>
    );
  }

  return <div className={style} />;
};

Image.defaultProps = {
  loadingProduct: false,
  fadeIn: true,
};

export default Image;
