import React from 'react';
import TransportSelect from '../TransportSelect';
import TransportDatePicker from '../TransportDatePicker';
import TransportTime from '../TransportTime';
import { Moment } from 'moment';
import {
  ISelectPickupValue,
  ITransportList,
  ITransport,
  ISelectTime,
  ITranslationsBookingModal,
} from '../../../interfaces';
import * as styles from './index.module.scss';

interface IProps {
  label: string;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndDate: Moment | null;
  selectedStartDate: Moment;
  isValid: boolean;
  transport: ITransport | null;
  selectedEndTime?: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  localeData: ITranslationsBookingModal;
  isLoadingEndTimes: boolean;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment, transportReturn: boolean): void;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
}

const TransportReturnTicket: React.FunctionComponent<IProps> = ({
  selectedEndPlace,
  selectedStartPlace,
  isValid,
  changeSelectedPlace,
  selectedEndDate,
  selectedStartDate,
  transport,
  changeSelectedDate,
  selectedEndTime,
  starTimeOptions,
  changeSelectedTime,
  endTimeOptions,
  localeData,
  isLoadingEndTimes,
}) => {
  return (
    <div className={styles.container}>
      <TransportSelect
        startPlaces={null}
        selectedStartPlace={selectedEndPlace}
        isValid={isValid}
        changeSelectedPlace={changeSelectedPlace}
        list='Start'
        ariaLabel='from'
        disabled={true}
        toFromLabel={localeData.from}
      />
      <TransportSelect
        startPlaces={null}
        selectedStartPlace={selectedStartPlace}
        isValid={isValid}
        changeSelectedPlace={changeSelectedPlace}
        ariaLabel='to'
        disabled={true}
        toFromLabel={localeData.to}
      />
      <TransportDatePicker
        selectedDate={selectedEndDate}
        startDate={selectedStartDate}
        nextAvailableDate={transport ? transport.NextAvailableDate : null}
        isReturn={true}
        changeDate={changeSelectedDate}
        transportKey='transportBooking return'
        transport={transport}
      />
      <TransportTime
        selectedEndTime={selectedEndTime}
        starTimeOptions={starTimeOptions}
        isValid={isValid}
        changeSelectedTime={changeSelectedTime}
        disabled={!selectedEndDate}
        isReturn={true}
        endTimeEndOptions={endTimeOptions}
        ariaLabel='Time'
        tabIndex='0'
        menuPlacement='top'
        timeSelectorHeader={localeData.timeSelectorHeader}
        isLoadingTimes={isLoadingEndTimes}
      />
    </div>
  );
};

export default TransportReturnTicket;
