import React, { FunctionComponent } from 'react';
import TextParser from '../TextParser/TextParser';
import * as styles from './index.module.scss';
import { IContentfulHeaderObject } from '../../interfaces';
import Icon from '../Icon';

interface IAnnouncementProps {
  announcement: IContentfulHeaderObject | undefined | null;
  mobile?: boolean;
  closeAnnouncement(close: boolean): void;
}

const Announcement: FunctionComponent<IAnnouncementProps> = ({
  announcement,
  mobile,
  closeAnnouncement,
}) => {
  const close = () => {
    closeAnnouncement(true);
  };
  if (announcement) {
    return (
      <div className={mobile ? styles.containerMobile : styles.container}>
        {!mobile ? (
          <div className={styles.icon}>
            <Icon name='ANNOUNCMENTWARNING' />
          </div>
        ) : null}
        <TextParser
          source={
            mobile
              ? announcement.announcementMobile.announcementMobile
              : announcement.announcement.announcement
          }
          className={styles.announcmentText}
          skipContainer={true}
        />
        <div className={styles.dismissButton} onClick={() => close()}>
          <Icon name='EXITMINI' />
        </div>
      </div>
    );
  }
  return null;
};

export default Announcement;
