import React from 'react';

export const NavUp = () => (
  <svg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.14189 5.5a.85545.85545 0 01-.60681-.25148L3.99167 2.77778 1.45456 5.24852a.85832.85832 0 01-1.20317-.01046.85827.85827 0 01-.01046-1.20317L3.99167.35025 7.7487 4.03489A.85829.85829 0 017.14189 5.5z'
      fill='#2C2C2C'
    />
  </svg>
);
export default NavUp;
