import React from 'react';
import cx from 'classnames';

import * as styles from './InputDropdown.module.scss';
import * as inputStyles from './Input.module.scss';

interface IProps {
  open: boolean;
  toggleOpen: () => void;
  selectedText: string;
  error?: boolean;
  darkMode?: boolean;
  children: React.ReactNode;
  title: string;
}

interface IState {
  optionsHeight: number;
  mouseDownOutside: boolean;
}

class InputDropdown extends React.Component<IProps, IState> {
  readonly state = { optionsHeight: 0, mouseDownOutside: false };

  options: HTMLDivElement | null = null;
  dropdown: HTMLDivElement | null = null;

  componentDidMount() {
    this.updateOptionsHeight();
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  componentDidUpdate() {
    this.updateOptionsHeight();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  render() {
    const { open, toggleOpen, selectedText, error, children, darkMode, title } =
      this.props;
    const { optionsHeight } = this.state;

    return (
      <div
        className={cx(styles.container, { [styles.open]: open })}
        ref={(elem) => (this.dropdown = elem)}
      >
        <button
          className={cx(
            darkMode ? inputStyles.darkInput : inputStyles.lightInput,
            styles.selected,
            { [inputStyles.error]: error }
          )}
          onClick={toggleOpen}
          id={title}
          aria-expanded={true}
        >
          <p>{selectedText}</p>
          <div className={styles.arrowContainer}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              id='baseline-keyboard_arrow_down-24px'
              viewBox='0 0 24 24'
              className={styles.arrow}
            >
              <path
                id='Path_272'
                d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'
                data-name='Path 272'
                className='cls-1'
              />
              <path
                id='Path_273'
                d='M0 0h24v24H0z'
                className='cls-2'
                data-name='Path 273'
                transform='translate(-30 -14)'
              />
            </svg>
          </div>
        </button>
        <div
          className={styles.options}
          style={{ height: open ? optionsHeight : 0 }}
        >
          <div ref={(elem) => (this.options = elem)}>{children}</div>
        </div>
      </div>
    );
  }

  handleMouseDown = (event: any) => {
    if (
      this.dropdown &&
      !this.dropdown.contains(event.target) &&
      this.props.open
    ) {
      this.setState({ mouseDownOutside: true });
    }
  };

  handleMouseUp = (event: any) => {
    if (
      this.dropdown &&
      !this.dropdown.contains(event.target) &&
      this.props.open &&
      this.state.mouseDownOutside
    ) {
      this.props.toggleOpen();
    }
    this.setState({ mouseDownOutside: false });
  };

  updateOptionsHeight = () => {
    if (this.options) {
      const height = this.options.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

export default InputDropdown;
