import React from 'react';
import * as styles from './index.module.scss';
import cx from 'classnames';
import FlagDropdown from '../../components/FlagDropdown/FlagDropdown';
import LanguageDropdown from '../../components/LanguageDropdown';
import TourCart from '../../BookingEngine/TourCart';
import {
  ICurrencyIcons,
  IPageContext,
  IHeaderList,
  IReducers,
} from '../../interfaces';
import SearchDropdown from '../SearchDropdown';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  searchValue: string;
  lightNav: boolean;
  currencies: ICurrencyIcons;
  pageContext: IPageContext;
  contentfulData: IHeaderList;
  locale: string;
  pathname: string;
  updateSearch(event: React.ChangeEvent<HTMLInputElement>): void;
  handleEnter(e: React.KeyboardEvent<HTMLInputElement>): void;
  onSearchClick(): void;
  isIcelandair: boolean;
  queryIcelandair: string;
}

interface IProps extends IExternalProps {
  localeData: {
    myBookingButton: string;
    headerSearch: {
      inputLabel: string;
      inputPlaceholder: string;
    };
    currencyChangeWarning: string;
  };
  langaugeSwithcerData: any;
}

const HeaderUpper: React.FunctionComponent<IProps> = ({
  searchValue,
  updateSearch,
  lightNav,
  handleEnter,
  onSearchClick,
  currencies,
  pageContext,
  contentfulData,
  localeData,
  langaugeSwithcerData,
  pathname,
  isIcelandair,
  queryIcelandair,
}) => {
  const stripLangPath = (text: string) => {
    let strippedText = text;
    // TODO this works only for is now, will add the others later on
    if (strippedText.split('/')[0] === 'is') {
      strippedText = strippedText.substr(2, strippedText.length - 1);
    }
    return strippedText;
  };

  const stripSlashes = (text: string) => {
    let strippedText = text;
    if (strippedText[0] === '/') {
      strippedText = strippedText.substr(1, strippedText.length);
    }
    if (strippedText && strippedText[strippedText.length - 1] === '/') {
      strippedText = strippedText.substr(0, strippedText.length - 1);
    }
    return strippedText;
  };

  const upper = () => {
    return (
      <React.Fragment>
        {isIcelandair ? null : (
          <SearchDropdown
            searchValue={searchValue}
            updateSearch={updateSearch}
            handleEnter={handleEnter}
            onSearchClick={onSearchClick}
            text={localeData.headerSearch}
          />
        )}
        <FlagDropdown
          currencyDropdown={currencies}
          currencyWarning={localeData.currencyChangeWarning}
          pageContext={pageContext}
          lightNav={lightNav}
        />
        <LanguageDropdown
          langaugeSwithcerData={langaugeSwithcerData}
          slug={stripLangPath(stripSlashes(pathname))}
          query={queryIcelandair}
        />
        <a
          href='https://booking.re.is/mybooking/'
          target='_blank'
          rel='noopener noreferrer'
          className={cx(
            styles.bookingButton,
            lightNav ? styles.bookingLight : styles.bookingDark
          )}
        >
          {localeData.myBookingButton}
        </a>
        <TourCart
          light={lightNav}
          langPath={pageContext.langPath}
          mobileOpen={false}
          mobile={false}
          contentfulData={contentfulData}
          isIcelandair={isIcelandair}
        />
      </React.Fragment>
    );
  };
  return upper();
};
// herna fa currency warning og svo senda inn sem props todo
const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    searchValue,
    updateSearch,
    lightNav,
    handleEnter,
    onSearchClick,
    currencies,
    pageContext,
    contentfulData,
    locale,
    pathname,
    isIcelandair,
    queryIcelandair,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query HeaderUpper {
          allContentfulHeader {
            edges {
              node {
                node_locale
                myBookingButton
                headerSearch {
                  inputLabel
                  inputPlaceholder
                }
                currencyChangeWarning
              }
            }
          }
          allContentfulTranslationsVarious {
            edges {
              node {
                headerlanguageSwitcher {
                  iconName
                  text
                  locale
                  lang
                  langPath
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <HeaderUpper
            localeData={
              data.allContentfulHeader.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            langaugeSwithcerData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { headerlanguageSwitcher: [] } }) => {
                  return node;
                }
              )[0].node
            }
            searchValue={searchValue}
            updateSearch={updateSearch}
            lightNav={lightNav}
            handleEnter={handleEnter}
            onSearchClick={onSearchClick}
            currencies={currencies}
            pageContext={pageContext}
            contentfulData={contentfulData}
            locale={locale}
            pathname={pathname}
            isIcelandair={isIcelandair}
            queryIcelandair={queryIcelandair}
          />
        );
      }}
    />
  )
);
