import React from 'react';
import * as styles from './index.module.scss';
import Modal from 'react-modal';
import TransportSelect from '../TransportSelect';
import TransportReturnTicket from '../TransportReturnTicket';
import TransportPriceBreakdown from '../TransportPriceBreakdown';
import {
  ITranslationsBookingModal,
  ITranslationsCheckout,
} from '../../../interfaces';
import cx from 'classnames';
import TourBookingHeader from '../TourBookingHeader';
import TransportDatePicker from '../TransportDatePicker';
import TransportTime from '../TransportTime';
import TransportReturnToggle from '../TransportReturnToggle';
import TransportGuests from '../TransportGuests';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import { Moment } from 'moment';
import {
  ITransportOption,
  ISelectPickupValue,
  ITransportList,
  ITransport,
  ISelectTime,
  ITransportPersonCounter,
  AddOrRemove,
  IReducers,
  ITourDetails,
} from '../../../interfaces';
import { icelandairlogo } from '../../../../src/icons';
import IcelandairCover from '../IcelandairCover';

interface IExternalProps {
  modalOpen: boolean;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  isValid: boolean;
  selectedStartDate: Moment;
  transport: ITransport | null;
  selectedStartTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  returnTicket: boolean;
  selectedEndDate: Moment | null;
  selectedEndTime?: ISelectTime | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  priceRef: React.MutableRefObject<HTMLDivElement | null>;
  addingToCart: boolean;
  locale: string;
  tour: ITourDetails;
  isLoadingStartTimes: boolean;
  isLoadingEndTimes: boolean;
  addToCart(): Promise<void>;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  toggleReturnTicket(ticket: boolean): void;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  toggleModal(value: boolean): void;
  changeSelectedDate(date: Moment, transportReturn: boolean): void;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
  localeData2: ITranslationsCheckout;
}

const TransportModal: React.FunctionComponent<IProps> = (props) => {
  const {
    modalOpen,
    toggleModal,
    startPlaces,
    selectedStartPlace,
    selectedEndPlace,
    isValid,
    changeSelectedPlace,
    endPlaces,
    selectedStartDate,
    transport,
    changeSelectedDate,
    selectedStartTime,
    starTimeOptions,
    changeSelectedTime,
    returnTicket,
    toggleReturnTicket,
    selectedEndDate,
    selectedEndTime,
    endTimeOptions,
    personCounter,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    priceRef,
    localeData,
    localeData2,
    addToCart,
    addingToCart,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
    locale,
  } = props;

  if (tour.slug.includes('icelandair')) {
    localeData.flybusHeader = locale === 'en-US' ? 'Book a  ' : 'Bókaðu ';
    return (
      <div>
        <IcelandairCover />
        {/* <div className={styles.staticPage}>
          <p>Inside container</p>
        </div> */}
        {/* <div className={styles.modal}> */}
        <div className={(cx('column is-mobile '), styles.icelandairContainer)}>
          <div className={cx('column', styles.left)}>
            <TourBookingHeader
              toggleModal={toggleModal}
              tourTitle={localeData.flybusHeader}
              tourSlug={tour.slug}
            />
            <TransportSelect
              startPlaces={startPlaces}
              selectedStartPlace={selectedStartPlace}
              isValid={isValid}
              changeSelectedPlace={changeSelectedPlace}
              list='Start'
              ariaLabel='from'
              disabled={false}
              toFromLabel={localeData.from}
            />
            <TransportSelect
              startPlaces={endPlaces}
              selectedStartPlace={selectedEndPlace}
              isValid={isValid}
              changeSelectedPlace={changeSelectedPlace}
              list='End'
              ariaLabel='to'
              disabled={false}
              toFromLabel={localeData.to}
            />
            <TransportDatePicker
              selectedDate={selectedStartDate}
              nextAvailableDate={transport ? transport.NextAvailableDate : null}
              isReturn={false}
              changeDate={changeSelectedDate}
              transportKey='transportModal'
              transport={transport}
              selectedStartPlace={selectedStartPlace}
              selectedEndPlace={selectedEndPlace}
            />
            {
              <TransportTime
                selectedStartTime={selectedStartTime}
                starTimeOptions={starTimeOptions}
                isValid={isValid}
                changeSelectedTime={changeSelectedTime}
                isReturn={false}
                ariaLabel='pick a time'
                tabIndex='0'
                timeSelectorHeader={localeData.timeSelectorHeader}
                isLoadingTimes={isLoadingStartTimes}
              />
            }
            <TransportReturnToggle
              returnTicket={returnTicket}
              toggleReturnTicket={toggleReturnTicket}
              returnButtonText={localeData.returnButtonText}
            />
            {returnTicket ? (
              <TransportReturnTicket
                label='From'
                selectedEndPlace={selectedEndPlace}
                selectedStartPlace={selectedStartPlace}
                isValid={isValid}
                changeSelectedPlace={changeSelectedPlace}
                transport={transport}
                selectedEndDate={selectedEndDate}
                selectedStartDate={selectedStartDate}
                changeSelectedDate={changeSelectedDate}
                selectedEndTime={selectedEndTime}
                starTimeOptions={starTimeOptions}
                endTimeOptions={endTimeOptions}
                changeSelectedTime={changeSelectedTime}
                localeData={localeData}
                isLoadingEndTimes={isLoadingEndTimes}
              />
            ) : null}
            <TransportGuests
              personCounter={personCounter}
              changePersonCounter={changePersonCounter}
              participantsSelectorHeader={localeData.participantsSelectorHeader}
            />
          </div>
          <div className='column'>
            <TransportPriceBreakdown
              personCounter={personCounter}
              getTotalPersonCount={getTotalPersonCount}
              getPersonCount={getPersonCount}
              withReturn={returnTicket}
              priceRef={priceRef}
              priceHeader={localeData.priceHeader}
              totalPriceString={localeData.totalPrice}
              noBookingFees={localeData.noBookingFees}
              freeCancellation={localeData.freeCancellation}
              smallPrint={localeData.smallPrint}
              addToCartButton={localeData.addToCartButton}
              addToCart={addToCart}
              addingToCart={addingToCart}
              tour={tour}
              isIcelandair={true}
              sagaTitle={localeData2.sagaTitle}
              sagaText={localeData2.sagaText}
              sagaInput={localeData2.sagaInput}
            />
          </div>
        </div>
        <div>
          <img
            src={icelandairlogo}
            className={styles.icelandairLogo}
            alt='icelandair logo'
          />
        </div>
      </div>
    );
  } else {
    return (
      <Modal
        isOpen={modalOpen}
        //onRequestClose={() => toggleModal(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        closeTimeoutMS={200}
        className={styles.modal}
        bodyOpenClassName={styles.modalOpen}
        portalClassName={styles.modalPortal}
        ariaHideApp={false}
      >
        <div className={styles.modal}>
          <TourBookingHeader
            toggleModal={toggleModal}
            tourTitle={localeData.flybusHeader}
            tourSlug={tour.slug}
          />
          <div className='columns is-mobile is-marginless'>
            <div className={cx('column', styles.left)}>
              <TransportSelect
                startPlaces={startPlaces}
                selectedStartPlace={selectedStartPlace}
                isValid={isValid}
                changeSelectedPlace={changeSelectedPlace}
                list='Start'
                ariaLabel='from'
                disabled={false}
                toFromLabel={localeData.from}
              />
              <TransportSelect
                startPlaces={endPlaces}
                selectedStartPlace={selectedEndPlace}
                isValid={isValid}
                changeSelectedPlace={changeSelectedPlace}
                list='End'
                ariaLabel='to'
                disabled={false}
                toFromLabel={localeData.to}
              />
              <TransportDatePicker
                selectedDate={selectedStartDate}
                nextAvailableDate={
                  transport ? transport.NextAvailableDate : null
                }
                isReturn={false}
                changeDate={changeSelectedDate}
                transportKey='transportModal'
                transport={transport}
                selectedStartPlace={selectedStartPlace}
                selectedEndPlace={selectedEndPlace}
              />
              {
                <TransportTime
                  selectedStartTime={selectedStartTime}
                  starTimeOptions={starTimeOptions}
                  isValid={isValid}
                  changeSelectedTime={changeSelectedTime}
                  isReturn={false}
                  ariaLabel='pick a time'
                  tabIndex='0'
                  timeSelectorHeader={localeData.timeSelectorHeader}
                  isLoadingTimes={isLoadingStartTimes}
                />
              }
              <TransportReturnToggle
                returnTicket={returnTicket}
                toggleReturnTicket={toggleReturnTicket}
                returnButtonText={localeData.returnButtonText}
              />
              {returnTicket ? (
                <TransportReturnTicket
                  label='From'
                  selectedEndPlace={selectedEndPlace}
                  selectedStartPlace={selectedStartPlace}
                  isValid={isValid}
                  changeSelectedPlace={changeSelectedPlace}
                  transport={transport}
                  selectedEndDate={selectedEndDate}
                  selectedStartDate={selectedStartDate}
                  changeSelectedDate={changeSelectedDate}
                  selectedEndTime={selectedEndTime}
                  starTimeOptions={starTimeOptions}
                  endTimeOptions={endTimeOptions}
                  changeSelectedTime={changeSelectedTime}
                  localeData={localeData}
                  isLoadingEndTimes={isLoadingEndTimes}
                />
              ) : null}
              <TransportGuests
                personCounter={personCounter}
                changePersonCounter={changePersonCounter}
                participantsSelectorHeader={
                  localeData.participantsSelectorHeader
                }
              />
            </div>
            <div className='column'>
              <TransportPriceBreakdown
                personCounter={personCounter}
                getTotalPersonCount={getTotalPersonCount}
                getPersonCount={getPersonCount}
                withReturn={returnTicket}
                priceRef={priceRef}
                priceHeader={localeData.priceHeader}
                totalPriceString={localeData.totalPrice}
                noBookingFees={localeData.noBookingFees}
                freeCancellation={localeData.freeCancellation}
                smallPrint={localeData.smallPrint}
                addToCartButton={localeData.addToCartButton}
                addToCart={addToCart}
                addingToCart={addingToCart}
                tour={tour}
                isIcelandair={false}
                sagaTitle={undefined}
                sagaText={undefined}
                sagaInput={undefined}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

const mapStateToProps = (reducers: IReducers) => {
  const { translationReducers } = reducers;
  const { locale } = translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    modalOpen,
    toggleModal,
    startPlaces,
    selectedStartPlace,
    isValid,
    changeSelectedPlace,
    endPlaces,
    selectedEndPlace,
    selectedStartDate,
    selectedEndDate,
    transport,
    changeSelectedDate,
    starTimeOptions,
    selectedStartTime,
    changeSelectedTime,
    returnTicket,
    toggleReturnTicket,
    selectedEndTime,
    endTimeOptions,
    personCounter,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    priceRef,
    addToCart,
    addingToCart,
    locale,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                extrasHeader
                pickUpHeader
                dropOffHeader
                participantsSelectorHeader
                timeSelectorHeader
                from {
                  inputLabel
                  inputPlaceholder
                }
                to {
                  inputLabel
                  inputPlaceholder
                }
                timeSelectorHeader
                returnButtonText
                priceHeader
                totalPrice
                noBookingFees
                freeCancellation
                smallPrint
                addToCartButton
                flybusHeader
              }
            }
          }
          allContentfulTranslationsCheckout {
            edges {
              node {
                ...TranslationsCheckout
                sagaTitle
                sagaText
                sagaInput
              }
            }
          }
        }
      `}
      render={(data) => (
        <TransportModal
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          localeData2={
            data.allContentfulTranslationsCheckout.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          startPlaces={startPlaces}
          selectedStartPlace={selectedStartPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          selectedEndPlace={selectedEndPlace}
          endPlaces={endPlaces}
          selectedStartDate={selectedStartDate}
          transport={transport}
          changeSelectedDate={changeSelectedDate}
          starTimeOptions={starTimeOptions}
          selectedStartTime={selectedStartTime}
          changeSelectedTime={changeSelectedTime}
          returnTicket={returnTicket}
          toggleReturnTicket={toggleReturnTicket}
          selectedEndDate={selectedEndDate}
          selectedEndTime={selectedEndTime}
          endTimeOptions={endTimeOptions}
          personCounter={personCounter}
          changePersonCounter={changePersonCounter}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          priceRef={priceRef}
          addToCart={addToCart}
          addingToCart={addingToCart}
          locale={locale}
          tour={tour}
          isLoadingStartTimes={isLoadingStartTimes}
          isLoadingEndTimes={isLoadingEndTimes}
        />
      )}
    />
  )
);
