import React from 'react';

export const AnnouncementWarning = () => (
  <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.25 12c0 5.6609-4.5891 10.25-10.25 10.25-5.66092 0-10.25-4.5891-10.25-10.25C1.75 6.33908 6.33908 1.75 12 1.75c5.6609 0 10.25 4.58908 10.25 10.25zM12 6.625v6'
      stroke='#2C2C2C'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 17.375c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75-.75.3358-.75.75.3358.75.75.75z'
      fill='#2C2C2C'
      stroke='#2C2C2C'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AnnouncementWarning;
