import React from 'react';
import * as styles from './index.module.scss';
import Modal from 'react-modal';
import { ITranslationsBookingModal } from '../../../interfaces';
import cx from 'classnames';
import TourBookingHeader from '../TourBookingHeader';
import TransportDatePicker from '../TransportDatePicker';
import TransportSelect from '../TransportSelect';
import TransportTime from '../TransportTime';
import TransportPriceBreakdown from '../TransportPriceBreakdown';
import BlueLagoonTime from '../BlueLagoonTime';
import TransportGuests from '../TransportGuests';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import { Moment } from 'moment';
import {
  IReducers,
  ITransportOption,
  ISelectPickupValue,
  ITransportList,
  ISelectTime,
  ITransport,
  ILagoonSelect,
  ITransportPersonCounter,
  AddOrRemove,
  ITourDetails,
} from '../../../interfaces';

interface IExternalProps {
  tourTitle: string;
  modalOpen: boolean;
  locale: string;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  isValid: boolean;
  selectedStartDate: Moment;
  selectedStartTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  transport: ITransport | null;
  selectedBluelagoonTime: ILagoonSelect | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  selectedEndTime: ISelectTime | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  priceRef: React.MutableRefObject<HTMLDivElement | null>;
  tour: ITourDetails;
  toggleModal(value: boolean): void;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment): void;
  changeSelectedTime(time: ISelectTime, isReturn: boolean): void;
  changeSelectedLagoonTime(time: ILagoonSelect): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  addToCart(): Promise<boolean> | Promise<void>;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getAvailabilityLeft(): number;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}

const BlueLagoonModal: React.FunctionComponent<IProps> = (props) => {
  const {
    tourTitle,
    modalOpen,
    toggleModal,
    startPlaces,
    selectedStartPlace,
    isValid,
    changeSelectedPlace,
    localeData,
    endPlaces,
    selectedEndPlace,
    selectedStartDate,
    changeSelectedDate,
    selectedStartTime,
    starTimeOptions,
    changeSelectedTime,
    transport,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    changeSelectedLagoonTime,
    selectedEndTime,
    endTimeOptions,
    personCounter,
    getTotalPersonCount,
    getPersonCount,
    addToCart,
    addingToCart,
    priceRef,
    changePersonCounter,
    getAvailabilityLeft,
    tour,
  } = props;
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => toggleModal(false)}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      closeTimeoutMS={200}
      className={styles.modal}
      bodyOpenClassName={styles.modalOpen}
      portalClassName={styles.modalPortal}
      ariaHideApp={false}
    >
      <TourBookingHeader toggleModal={toggleModal} tourTitle={tourTitle} />
      <div className='columns is-mobile is-marginless'>
        <div className={cx('column', styles.left)}>
          <TransportSelect
            startPlaces={startPlaces}
            selectedStartPlace={selectedStartPlace}
            isValid={isValid}
            changeSelectedPlace={changeSelectedPlace}
            list='Start'
            ariaLabel='from'
            disabled={false}
            toFromLabel={localeData.from}
          />
          <TransportSelect
            startPlaces={endPlaces}
            selectedStartPlace={selectedEndPlace}
            isValid={isValid}
            changeSelectedPlace={changeSelectedPlace}
            list='End'
            ariaLabel='to'
            disabled={false}
            toFromLabel={localeData.to}
          />
          <TransportDatePicker
            selectedDate={selectedStartDate}
            nextAvailableDate={transport ? transport.NextAvailableDate : null}
            isReturn={false}
            changeDate={changeSelectedDate}
            transportKey='BlueLagoonModal'
            transport={transport}
            selectedStartPlace={null}
            selectedEndPlace={null}
          />
          <TransportTime
            selectedStartTime={selectedStartTime}
            starTimeOptions={starTimeOptions}
            isValid={isValid}
            changeSelectedTime={changeSelectedTime}
            isReturn={false}
            ariaLabel={localeData.timeSelectorHeader}
            tabIndex='0'
            timeSelectorHeader={localeData.timeSelectorHeader}
          />
          <BlueLagoonTime
            selectedBluelagoonTime={selectedBluelagoonTime}
            blueLagoonTimeOptions={blueLagoonTimeOptions}
            selectedStartTime={selectedStartTime}
            selectedStartDate={selectedStartDate}
            isValid={isValid || !!selectedBluelagoonTime}
            ariaLabel={localeData.lagoonEntryTime}
            tabIndex='0'
            timeSelectorHeader={localeData.lagoonEntryTime}
            changeSelectedLagoonTime={changeSelectedLagoonTime}
          />
          <TransportTime
            isReturn={true}
            selectedEndTime={selectedEndTime}
            selectedBluelagoonTime={selectedBluelagoonTime}
            date={selectedStartDate}
            endTimeEndOptions={endTimeOptions}
            changeSelectedTime={changeSelectedTime}
            isValid={isValid || !!selectedEndTime}
            isLagoon={true}
            ariaLabel={localeData.returnTime}
            tabIndex='0'
            timeSelectorHeader={localeData.returnTime}
            menuPlacement='top'
          />
          <TransportGuests
            personCounter={personCounter}
            changePersonCounter={changePersonCounter}
            participantsSelectorHeader={localeData.participantsSelectorHeader}
            isLagoon={true}
            getTotalPersonCount={getTotalPersonCount}
            getAvailabilityLeft={getAvailabilityLeft}
          />
        </div>
        <div className='column'>
          <TransportPriceBreakdown
            personCounter={personCounter}
            getTotalPersonCount={getTotalPersonCount}
            getPersonCount={getPersonCount}
            withReturn={false}
            priceRef={priceRef}
            priceHeader={localeData.priceHeader}
            totalPriceString={localeData.totalPrice}
            noBookingFees={localeData.noBookingFees}
            freeCancellation={localeData.freeCancellation}
            smallPrint={localeData.smallPrint}
            addToCartButton={localeData.addToCartButton}
            addToCart={addToCart}
            addingToCart={addingToCart}
            tour={tour}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { translationReducers } = reducers;
  const { locale } = translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    tourTitle,
    modalOpen,
    toggleModal,
    locale,
    startPlaces,
    selectedStartPlace,
    isValid,
    changeSelectedPlace,
    endPlaces,
    selectedEndPlace,
    selectedStartDate,
    changeSelectedDate,
    selectedStartTime,
    starTimeOptions,
    changeSelectedTime,
    transport,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    changeSelectedLagoonTime,
    selectedEndTime,
    endTimeOptions,
    addingToCart,
    addToCart,
    personCounter,
    getTotalPersonCount,
    getPersonCount,
    priceRef,
    changePersonCounter,
    getAvailabilityLeft,
    tour,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query BlueLagoonModalQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                extrasHeader
                pickUpHeader
                dropOffHeader
                participantsSelectorHeader
                timeSelectorHeader
                from {
                  inputLabel
                  inputPlaceholder
                }
                to {
                  inputLabel
                  inputPlaceholder
                }
                timeSelectorHeader
                returnButtonText
                priceHeader
                totalPrice
                noBookingFees
                freeCancellation
                smallPrint
                addToCartButton
                lagoonEntryTime
                returnTime
              }
            }
          }
        }
      `}
      render={(data) => (
        <BlueLagoonModal
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          tourTitle={tourTitle}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          locale={locale}
          startPlaces={startPlaces}
          selectedStartPlace={selectedStartPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          endPlaces={endPlaces}
          selectedEndPlace={selectedEndPlace}
          selectedStartDate={selectedStartDate}
          changeSelectedDate={changeSelectedDate}
          selectedStartTime={selectedStartTime}
          starTimeOptions={starTimeOptions}
          changeSelectedTime={changeSelectedTime}
          transport={transport}
          selectedBluelagoonTime={selectedBluelagoonTime}
          blueLagoonTimeOptions={blueLagoonTimeOptions}
          changeSelectedLagoonTime={changeSelectedLagoonTime}
          selectedEndTime={selectedEndTime}
          endTimeOptions={endTimeOptions}
          addingToCart={addingToCart}
          addToCart={addToCart}
          personCounter={personCounter}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          priceRef={priceRef}
          changePersonCounter={changePersonCounter}
          getAvailabilityLeft={getAvailabilityLeft}
          tour={tour}
        />
      )}
    />
  )
);
