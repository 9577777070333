// extracted by mini-css-extract-plugin
export var container = "BlogCard-module--container--3IUmp";
export var blogImage = "BlogCard-module--blogImage--17N7Y";
export var date = "BlogCard-module--date--DITFc";
export var summary = "BlogCard-module--summary--3VWaN";
export var item = "BlogCard-module--item--1L-WN";
export var main = "BlogCard-module--main--3FNdt";
export var mainContent = "BlogCard-module--mainContent--1ZGTI";
export var tags = "BlogCard-module--tags--6EV8V";
export var tagsP = "BlogCard-module--tagsP--1B_vR";
export var buttonDiv = "BlogCard-module--buttonDiv--3G2_U";
export var readButton = "BlogCard-module--readButton--2vIlz";