import React from 'react';
import * as styles from './index.module.scss';
import { IProduct } from '../../interfaces';
import Icon from '../Icon';

interface IProps {
  product: IProduct | null;
  sreInfo: string;
}

const SreInfo: React.FunctionComponent<IProps> = ({ product, sreInfo }) => {
  if (product) {
    const { Vendor, Code } = product;
    const maskCode = () => {
      let subCode = '';
      if (Code) {
        subCode = Code.substring(0, 3);
      }
      return subCode;
    };
    if (Vendor === 'Reykjavik Excursions' && maskCode() === 'SRE') {
      return (
        <div className='columns'>
          <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
            <div className={styles.infoBox} style={{ padding: '15px 25px' }}>
              <div
                className={styles.infoLine}
                style={{ marginBottom: '5px' }}
              />
              <div style={{ display: 'flex', flexFlow: 'row' }}>
                <Icon name='THIRDPARTY' />
                <div
                  className={styles.thirdParty}
                  style={{ alignSelf: 'center' }}
                >
                  {sreInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
  return null;
};

export default SreInfo;
