import React from 'react';

// v
export const CheckMarkIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.3158 2l-8.84212 8.4211-3.78947-3.78952L0 8.31579l5.47368 5.47371L16 3.68421 14.3158 2z'
      fill='#FA7268'
    />
  </svg>
);
export default CheckMarkIcon;
