import React from 'react';
import Select from 'react-select';
import moment from 'moment';

import { ILagoonSelect, ISelectTime } from '../../interfaces';
import {
  customNoIndicator,
  errorStyle,
  noArrowStyle,
  mobileMenuNoIndicator
} from '../../styles/ReactSelect';
import { Moment } from 'moment';
import { createDateTime } from '../../utils';

interface IProps {
  selectedBluelagoonTime: ILagoonSelect | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  isValid: boolean;
  date: Moment;
  transportStartTime: ISelectTime | null;
  isMobile?: boolean;
  ariaLabel: string;
  tabIndex: string;
  changeSelectedLagoonTime(time: ILagoonSelect): void;
}

const BlueLagoonTimes: React.FunctionComponent<IProps> = props => {
  const {
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    transportStartTime,
    date
  } = props;

  function getStyles() {
    if (props.isValid) {
      if (props.isMobile) {
        return mobileMenuNoIndicator;
      } else {
        return customNoIndicator;
      }
    } else {
      return errorStyle;
    }
  }

  if (!!blueLagoonTimeOptions && !!transportStartTime) {
    const filteredOptions = blueLagoonTimeOptions.map(time => {
      if (
        moment(createDateTime(date, time.value)).isAfter(
          createDateTime(date, transportStartTime.label)
        )
      ) {
        return time;
      }
      return null;
    });
    // _.pickBy
    const newTimes = filteredOptions.filter(time => {
      return time !== null;
    }) as ILagoonSelect[];
    const options = Object.values(newTimes);

    return (
      <div style={{ marginTop: '10px' }}>
        <Select
          key={selectedBluelagoonTime + transportStartTime.label}
          menuPortalTarget={!props.isMobile ? document.body : null}
          options={options}
          value={selectedBluelagoonTime}
          onChange={(time: any) => props.changeSelectedLagoonTime(time)}
          styles={getStyles()}
          aria-label={props.ariaLabel}
          tabIndex={props.tabIndex}
        />
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: '10px' }}>
        <Select
          placeholder=''
          styles={noArrowStyle}
          isDisabled={true}
          aria-label={props.ariaLabel}
        />
      </div>
    );
  }
};

export default BlueLagoonTimes;
