import React from 'react';
import BlueLagoonTimes from '../BlueLagoonTimes';
import Title from '../../../components/Title';
import * as styles from './index.module.scss';
import { Moment } from 'moment';
import { ILagoonSelect, ISelectTime } from '../../../interfaces';

// type IMenuPlacement = 'auto' | 'bottom' | 'top';

interface IProps {
  selectedBluelagoonTime: ILagoonSelect | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  selectedStartTime: ISelectTime | null;
  selectedStartDate: Moment;
  isValid: boolean;
  ariaLabel: string;
  tabIndex: string;
  timeSelectorHeader: string;
  changeSelectedLagoonTime(time: ILagoonSelect): void;
}

const BlueLagoonTime: React.FunctionComponent<IProps> = ({
  selectedBluelagoonTime,
  blueLagoonTimeOptions,
  changeSelectedLagoonTime,
  selectedStartDate,
  selectedStartTime,
  isValid,
  timeSelectorHeader,
}) => (
  <div className={styles.container}>
    <div className={styles.labelText}>
      <Title tag='h3' theme={['sizeDesktopSmall']}>
        {timeSelectorHeader}
      </Title>
    </div>
    <div className={styles.labelPadding}>
      <BlueLagoonTimes
        selectedBluelagoonTime={selectedBluelagoonTime}
        blueLagoonTimeOptions={blueLagoonTimeOptions}
        changeSelectedLagoonTime={changeSelectedLagoonTime}
        date={selectedStartDate}
        transportStartTime={selectedStartTime}
        isValid={isValid || !!selectedBluelagoonTime}
        ariaLabel='Lagoon entry time'
        tabIndex='0'
      />
    </div>
  </div>
);

export default BlueLagoonTime;
