import React from 'react';

export const Iceland = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='13.8'>
    <g id='ICELAND_3_'>
      <defs>
        <path id='SVGID_1_ICE' d='M0 0h21.1v14.4H0z' />
      </defs>
      <clipPath id='SVGID_2_ICE'>
        <use xlinkHref='#SVGID_1_ICE' overflow='visible' />
      </clipPath>
      <path
        d='M3 14.4h15.2c1.6 0 3-1.3 3-3V3c0-1.6-1.3-3-3-3H3C1.3 0 0 1.3 0 3v8.5c0 1.6 1.3 2.9 3 2.9z'
        clipPath='url(#SVGID_2_ICE)'
        fill='#223693'
      />
      <path
        className='st1'
        d='M5.6 0h3.2v14.4H5.6z'
        clipPath='url(#SVGID_2_ICE)'
        fill='#fff'
      />
      <path
        className='st1'
        d='M0 5.6h21.1v3.2H0z'
        clipPath='url(#SVGID_2_ICE)'
        fill='#fff'
      />
      <path
        className='st2'
        d='M6.4 0H8v14.4H6.4z'
        clipPath='url(#SVGID_2_ICE)'
        fill='#c51d32'
      />
      <path
        className='st2'
        d='M0 6.4h21.1V8H0z'
        clipPath='url(#SVGID_2_ICE)'
        fill='#c51d32'
      />
    </g>
  </svg>
);
export default Iceland;
