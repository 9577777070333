// extracted by mini-css-extract-plugin
export var link = "TourCart-module--link--2muHs";
export var container = "TourCart-module--container--1E-C1";
export var options = "TourCart-module--options--33FCG";
export var desktopCheckouButton = "TourCart-module--desktopCheckouButton--LnAtI";
export var mobileStickyWhite = "TourCart-module--mobileStickyWhite--4yqXE";
export var dropdownArrow = "TourCart-module--dropdownArrow--1qmd1";
export var secondArrow = "TourCart-module--secondArrow--HCx_z";
export var cartIcon = "TourCart-module--cartIcon--3yGfh";
export var light = "TourCart-module--light--32G6f";
export var dark = "TourCart-module--dark--33ooT";
export var active = "TourCart-module--active--rtUge";
export var cartLabel = "TourCart-module--cartLabel--397VB";
export var emptyCart = "TourCart-module--emptyCart--1TX7e";
export var image = "TourCart-module--image--1JgNT";
export var cart = "TourCart-module--cart--2OtEu";
export var cartItem = "TourCart-module--cartItem--JAZXg";
export var dividerGray = "TourCart-module--dividerGray--ee_BI";
export var cartButton = "TourCart-module--cartButton--1qQU2";
export var cartRow = "TourCart-module--cartRow--3RFJo";
export var cartInfoList = "TourCart-module--cartInfoList--3OmOK";
export var bold = "TourCart-module--bold--3Ilgn";
export var deleteFromCart = "TourCart-module--deleteFromCart--3ScTS";
export var cartItemPrice = "TourCart-module--cartItemPrice--3amzr";
export var divider = "TourCart-module--divider--8nIDf";
export var totalPrice = "TourCart-module--totalPrice--13Wtx";
export var right = "TourCart-module--right--ibEEy";
export var notificationBubble = "TourCart-module--notificationBubble--1wM-T";
export var drawerContainer = "TourCart-module--drawerContainer--1F41G";
export var drawerContainerSearch = "TourCart-module--drawerContainerSearch--1kq19";
export var drawerBackground = "TourCart-module--drawerBackground--J1uel";
export var drawerBody = "TourCart-module--drawerBody--1HD6O";
export var cartHeader = "TourCart-module--cartHeader--3IT3t";
export var buttonExtra = "TourCart-module--buttonExtra--1iddu";
export var price = "TourCart-module--price--3swIK";
export var basicButton = "TourCart-module--basicButton--qu7Mq";
export var cartCloseButton = "TourCart-module--cartCloseButton--1Wslh";