import React from 'react';
import Select, { components } from 'react-select';
import {
  IPickupDropoff,
  IPickupSelection,
  ITranslationsOptionalRequired,
  IReducers,
} from '../../../interfaces';
import * as styles from './index.module.scss';
import { modalMenu, mobileMenu, errorStyle } from './TourPickup.reactSelect';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface ISelectPickupValue {
  label: string;
  value: string | null;
}
interface IExternalProps {
  pickup: IPickupDropoff | null;
  selectedPickup: { label: string; value: string } | null;
  isValid: boolean;
  pickupSelection: IPickupSelection | null;
  isMobile?: boolean;
  openDate?: boolean;
  triggerDate?: (date: boolean) => void;
  locale: string;
  changePickup(event: ISelectPickupValue, pickOrDrop: pickOrDrop): void;
  toggleSelect(newState: boolean, pickOrDrop: pickOrDrop): void;
}

interface IProps extends IExternalProps {
  localeData: {
    pickUpSubtext: ITranslationsOptionalRequired;
    pickUpSelectionPlaceholder: {
      version1: string;
      version2: string;
    };
    pudoNotAvailable: string;
    pudoDropdownSections: {
      puDontNeedHeader: string;
      puDontNeedLabel: string;
      puPickUpPlaces: string;
    };
  };
}

type pickOrDrop = 'pickup' | 'dropoff';

const TourPickup: React.FunctionComponent<IProps> = (props) => {
  const {
    pickup,
    selectedPickup,
    changePickup,
    pickupSelection,
    isMobile,
    localeData,
  } = props;
  const notAvailable = localeData.pudoNotAvailable;
  const options =
    !!pickup && !!pickup.PickupPlaces && pickup.PickupPlaces.length > 0
      ? pickup.PickupPlaces.map((address) => {
          const { Name, Id } = address;
          return { label: Name, value: Id };
        })
      : [{ value: notAvailable, label: notAvailable }];
  const getStyles = () => {
    if (props.pickupSelection === 'PRESELECTED' && !props.isValid) {
      return errorStyle;
    }
    if (isMobile) {
      return mobileMenu;
    }
    return modalMenu;
  };
  if (pickupSelection) {
    if (pickupSelection === 'OPTIONAL') {
      const groupOptions = [
        {
          label: localeData.pudoDropdownSections.puDontNeedHeader,
          options: [
            {
              label: localeData.pudoDropdownSections.puDontNeedLabel,
              value: null,
            },
          ],
        },
        {
          label: localeData.pudoDropdownSections.puPickUpPlaces,
          options,
        },
      ];

      return (
        <div style={{ marginBottom: 10 }}>
          <label className={styles.label}>
            {localeData.pickUpSubtext.optional}
          </label>
          <Select
            placeholder={
              isMobile
                ? localeData.pickUpSelectionPlaceholder.version1
                : localeData.pickUpSelectionPlaceholder.version2
            }
            options={groupOptions}
            value={selectedPickup ? selectedPickup : null}
            isMulti={false}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={!isMobile}
            onChange={(event: any) => changePickup(event, 'pickup')}
            components={{ Group: components.Group }}
            styles={getStyles()}
            onMenuOpen={() => {
              props.toggleSelect(true, 'pickup');
              if (props.triggerDate) {
                props.triggerDate(!props.openDate);
              }
            }}
            onMenuClose={() =>
              setTimeout(() => props.toggleSelect(false, 'pickup'), 500)
            }
          />
        </div>
      );
    }
    if (pickupSelection === 'PRESELECTED') {
      return (
        <div style={{ marginBottom: 10 }}>
          <label className={styles.label}>
            {localeData.pickUpSubtext.required}
          </label>
          <Select
            placeholder={
              isMobile
                ? localeData.pickUpSelectionPlaceholder.version1
                : localeData.pickUpSelectionPlaceholder.version2
            }
            options={options}
            value={selectedPickup ? selectedPickup : null}
            isMulti={false}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={!isMobile}
            onChange={(event: any) => changePickup(event, 'pickup')}
            components={{ SingleValue: components.SingleValue }}
            styles={getStyles()}
            onMenuOpen={() => {
              props.toggleSelect(true, 'pickup');
              if (props.triggerDate) {
                props.triggerDate(!props.openDate);
              }
            }}
            onMenuClose={() =>
              setTimeout(() => props.toggleSelect(false, 'pickup'), 500)
            }
          />
        </div>
      );
    }
    return null;
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    pickup,
    selectedPickup,
    changePickup,
    pickupSelection,
    isMobile,
    isValid,
    openDate,
    triggerDate,
    toggleSelect,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourPickup {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                pickUpSubtext {
                  optional
                  required
                }
                pickUpSelectionPlaceholder {
                  version1
                  version2
                }
                pudoNotAvailable
                pudoDropdownSections {
                  puDontNeedHeader
                  puDontNeedLabel
                  puPickUpPlaces
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TourPickup
            localeData={
              data.allContentfulTranslationsBookingModal.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            pickup={pickup}
            selectedPickup={selectedPickup}
            changePickup={changePickup}
            pickupSelection={pickupSelection}
            isMobile={isMobile}
            isValid={isValid}
            openDate={openDate}
            triggerDate={triggerDate}
            toggleSelect={toggleSelect}
            locale={locale}
          />
        );
      }}
    />
  )
);
