import React from 'react';

export const England = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='30'
    height='13.8'
    enableBackground='new 0 0 21.11 14.43'
    xmlSpace='preserve'
  >
    <defs>
      <path
        id='SVGID_1_ENG'
        d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
      />
    </defs>
    <clipPath id='SVGID_2_ENG'>
      <use xlinkHref='#SVGID_1_ENG' overflow='visible' />
    </clipPath>
    <path
      clipPath='url(#SVGID_2_ENG)'
      fill='#FFFFFF'
      d='M-2.2-.14h26.15v15.25H-2.2z'
    />
    <defs>
      <path
        id='SVGID_3_ENG'
        d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
      />
    </defs>
    <clipPath id='SVGID_4_ENG'>
      <use xlinkHref='#SVGID_3_ENG' overflow='visible' />
    </clipPath>
    <path
      clipPath='url(#SVGID_4_ENG)'
      fill='#BD1329'
      d='M15.31 5.37L23.95.53v-.67h-.6L13.5 5.37z'
    />
    <defs>
      <path
        id='SVGID_5_ENG'
        d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
      />
    </defs>
    <clipPath id='SVGID_6_ENG'>
      <use xlinkHref='#SVGID_5_ENG' overflow='visible' />
    </clipPath>
    <path
      clipPath='url(#SVGID_6_ENG)'
      fill='#BD1329'
      d='M14.9 9.9l9.05 5.06v-1.01L16.71 9.9z'
    />
    <defs>
      <path
        id='SVGID_7_ENG'
        d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
      />
    </defs>
    <clipPath id='SVGID_8_ENG'>
      <use xlinkHref='#SVGID_7_ENG' overflow='visible' />
    </clipPath>
    <path
      clipPath='url(#SVGID_8_ENG)'
      fill='#BD1329'
      d='M-2.2 1.02l7.74 4.35h1.81L-2.2.01z'
    />
    <defs>
      <path
        id='SVGID_9_ENG'
        d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
      />
    </defs>
    <clipPath id='SVGID_10_ENG'>
      <use xlinkHref='#SVGID_9_ENG' overflow='visible' />
    </clipPath>
    <path
      clipPath='url(#SVGID_10_ENG)'
      fill='#BD1329'
      d='M6.94 9.9l-9.14 5.12v.09h1.65l9.3-5.21z'
    />
    <g>
      <defs>
        <path
          id='SVGID_11_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_12_ENG'>
        <use xlinkHref='#SVGID_11_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_12_ENG)'
        fill='#2A276C'
        d='M22.16-.14h-9.15v5.12z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_13_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_14_ENG'>
        <use xlinkHref='#SVGID_13_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_14_ENG)'
        fill='#2A276C'
        d='M8.96-.14H-.16l9.12 5.12z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_15_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_16_ENG'>
        <use xlinkHref='#SVGID_15_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_16_ENG)'
        fill='#2A276C'
        d='M23.95 5.37v-3.5l-6.21 3.5z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_17_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_18_ENG'>
        <use xlinkHref='#SVGID_17_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_18_ENG)'
        fill='#2A276C'
        d='M23.95 13.36V9.9h-6.21z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_19_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_20_ENG'>
        <use xlinkHref='#SVGID_19_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_20_ENG)'
        fill='#2A276C'
        d='M.36 15.11h8.6v-4.82z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_21_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_22_ENG'>
        <use xlinkHref='#SVGID_21_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_22_ENG)'
        fill='#2A276C'
        d='M13 15.11h8.63L13 10.29z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_23_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_24_ENG'>
        <use xlinkHref='#SVGID_23_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_24_ENG)'
        fill='#2A276C'
        d='M-2.2 9.9v3.62L4.23 9.9z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_25_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_26_ENG'>
        <use xlinkHref='#SVGID_25_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_26_ENG)'
        fill='#2A276C'
        d='M-2.2 5.37h6.43L-2.2 1.75z'
      />
    </g>
    <g>
      <defs>
        <path
          id='SVGID_27_ENG'
          d='M3.02 0C1.35 0 0 1.35 0 3.02v8.4c0 1.67 1.35 3.02 3.02 3.02H18.1c1.67 0 3.02-1.35 3.02-3.02v-8.4C21.12 1.35 19.77 0 18.1 0H3.02z'
        />
      </defs>
      <clipPath id='SVGID_28_ENG'>
        <use xlinkHref='#SVGID_27_ENG' overflow='visible' />
      </clipPath>
      <path
        clipPath='url(#SVGID_28_ENG)'
        fill='#BD1329'
        d='M9.77-.14v6.42H-2.2V9H9.77v6.11h2.43V9h11.75V6.28H12.2V-.14z'
      />
    </g>
  </svg>
);
export default England;
