import React from 'react';
import * as styles from './index.module.scss';
import {
  IProduct,
  ISortedAvailability,
  ISelectValue,
} from '../../../interfaces';
import TourTimeAvailability from '../TourTimeAvailability/index';
import { Moment } from 'moment';
import Title from '../../../components/Title';

interface IProps {
  product: IProduct | null;
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  isValid: boolean;
  header: string;
  handleSelectedTime(event: { label: string; value: string }): void;
}

const TourBookingTime: React.FunctionComponent<IProps> = (props) => {
  const {
    product,
    availability,
    selectedDate,
    selectedTime,
    handleSelectedTime,
    isValid,
    header,
  } = props;
  return (
    <div className={styles.timePadding}>
      {!!product && product.BookingType !== 'DATE' ? (
        <React.Fragment>
          <Title tag='h3' theme={['sizeDesktopSmall']}>
            {header}
          </Title>
          <div className={styles.timeContainer}>
            <TourTimeAvailability
              availability={availability}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              changeSelectedTime={handleSelectedTime}
              isValid={isValid || !!selectedTime}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default TourBookingTime;
