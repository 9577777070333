import React from 'react';
import ls from 'local-storage';
import { connect } from 'react-redux';
import cx from 'classnames';
import Drawer from 'rc-drawer';

import { CartApi } from '../api';
import {
  ICart,
  ICurrencyOption,
  IPhoto,
  IReducers,
  IItem,
  ITranslationsCart,
  ICartCheckout,
} from '../interfaces';

import TranslationConsumer from '../context/translationContext';
import { formatPrice, formatDate } from '../utils';
import {
  Link,
  BackgroundImage,
  Desktop,
  Mobile,
  Title,
  Icon,
  Button,
} from '../components';
import { setCart } from '../redux/actions/cartActions';
import * as styles from './TourCart.module.scss';
import EmptyCart from './Cart/EmptyCart';
import moment from 'moment-timezone';
import { StaticQuery, graphql } from 'gatsby';

const XSTART = 'X -';

interface IState {
  open: boolean;
  optionsHeight: number;
  finalYayPrice: number;
}

interface IExternalProps {
  currency: ICurrencyOption;
  light: boolean;
  langPath: string;
  cartObject: ICart | null;
  mobileOpen: boolean;
  mobile: boolean;
  searchOpen?: boolean;
  lang: string;
  cartChange?: (open: boolean) => void;
  contentfulData: any; // todo was any change to something else
  locale: string;
  setCart(cart: ICart): void;
  isIcelandair: boolean;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsCart;
  categoryInfo: any;
}

class TourCart extends React.Component<IProps, IState> {
  readonly state: IState = {
    open: false,
    optionsHeight: 0,
    finalYayPrice: 0,
  };
  cartDropdown: HTMLDivElement | null = null;
  cartIconSVG: SVGSVGElement | null = null;
  cartApi = new CartApi();

  isPrivateTour = this.props.categoryInfo.slug == 'private-tours';

  async componentDidMount() {
    const cartId: string = ls.get('cartId');
    const { currency, lang } = this.props;
    if (window.location.pathname.includes('icelandair')) ls.remove('cartId');

    if (!this.isPrivateTour) {
      if (!this.props.cartObject) {
        this.setNewCart(this.props.currency);
      }
      const cartCheckout = await this.cartApi.getCartByCheckout(
        cartId,
        currency.value,
        lang
      );
      if (cartCheckout) {
        this.updateYayPrice(cartCheckout);
      }

      this.updateOptionsHeight();
      document.addEventListener('click', this.handleClickOutside);
    }
  }
  async componentDidUpdate(prevProps: IProps) {
    const cartId: string = ls.get('cartId');
    const { currency, lang } = this.props;

    if (!this.isPrivateTour) {
      this.updateOptionsHeight();

      if (prevProps.currency !== this.props.currency) {
        this.setNewCart(this.props.currency);
      }

      if (prevProps.mobileOpen !== this.props.mobileOpen) {
        if (!this.props.mobileOpen) {
          this.setState({ open: false });
        }
      }

      if (this.props.cartObject !== prevProps.cartObject) {
        if (cartId) {
          const cartCheckout = await this.cartApi.getCartByCheckout(
            cartId,
            currency.value,
            lang
          );
          if (cartCheckout) {
            this.setState({ finalYayPrice: cartCheckout.options[0].amount });
          }
        }
      }

      if (
        (!!this.props.cartObject &&
          !!prevProps.cartObject &&
          ((!!prevProps.cartObject.Items &&
            !!this.props.cartObject.Items &&
            this.props.cartObject.Items.length >
              prevProps.cartObject.Items.length) ||
            (!!this.props.cartObject.Items && !prevProps.cartObject.Items))) ||
        (!!this.props.cartObject &&
          this.props.cartObject.Items &&
          !prevProps.cartObject)
      ) {
        this.setState({ open: true });
        if (this.props.cartChange) {
          this.props.cartChange(true);
        }
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const { open } = this.state;
    const { localeData } = this.props;
    const numberOfItemsInCart =
      this.props.cartObject &&
      this.props.cartObject.Items &&
      this.props.cartObject.Items.length;

    return (
      <div className={styles.container}>
        {this.cartIcon()}
        <Mobile>
          <Drawer
            height='100%'
            width='100%'
            open={open}
            placement='right'
            handler={false}
            level={null}
            className={
              this.props.searchOpen
                ? styles.drawerContainerSearch
                : styles.drawerContainer
            }
          >
            <div className={styles.drawerBackground}>
              <div className={styles.drawerBody}>
                <div className={styles.cartHeader}>
                  <Title tag='h2' theme={['sizeMobileMedium']}>
                    {localeData.cartHeader}
                    {numberOfItemsInCart &&
                      `, ${numberOfItemsInCart} ${
                        numberOfItemsInCart > 1
                          ? localeData.cartHeaderItemCount.plural
                          : localeData.cartHeaderItemCount.singular
                      } `}
                  </Title>
                  <button
                    aria-label='Close cart'
                    onClick={() => {
                      this.setState({ open: false });
                    }}
                  >
                    <Icon name='CLOSE' />
                  </button>
                </div>
                {this.renderCart('mobile')}
              </div>
            </div>
          </Drawer>
        </Mobile>
        <Desktop>
          <div>
            <div
              className={open ? styles.options : null}
              style={{
                height: open ? 'auto' : 0,
                width: open ? '310px' : 0,
                overflow: 'hidden',
                minWidth: open ? 320 : 0,
              }}
            >
              <div
                ref={(elem) => (this.cartDropdown = elem)}
                style={
                  numberOfItemsInCart && numberOfItemsInCart > 1
                    ? { overflow: 'auto' }
                    : {}
                }
              >
                <div className={styles.cartHeader}>
                  <Title tag='h2' theme={['sizeDesktopMedium']}>
                    {localeData.cartHeader}
                    {numberOfItemsInCart &&
                      `, ${numberOfItemsInCart} ${
                        numberOfItemsInCart > 1
                          ? localeData.cartHeaderItemCount.plural
                          : localeData.cartHeaderItemCount.singular
                      } `}
                  </Title>
                  <button
                    aria-label='Close cart'
                    onClick={() => {
                      this.setState({ open: false });
                    }}
                  >
                    <Icon name='CLOSE' />
                  </button>
                </div>
                <div
                  style={
                    numberOfItemsInCart && numberOfItemsInCart >= 1
                      ? { overflowY: 'auto', height: 'calc(100vh - 400px)' }
                      : {}
                  }
                >
                  {this.renderCart('desktop')}
                </div>
              </div>
              {!!this.props.cartObject &&
              !!this.props.cartObject.Items &&
              open ? (
                <div className={styles.desktopCheckouButton}>
                  <div>
                    <hr className={styles.divider} />

                    <div className={styles.price}>{this.renderPrice()}</div>
                    <Link
                      to='checkout'
                      langPath={this.props.langPath}
                      className={styles.link}
                    >
                      <Button
                        data-cy='Go-To-Checkout-Button'
                        onClick={() => {
                          this.setState({ open: false });
                          if (this.props.cartChange) {
                            this.props.cartChange(false);
                          }
                        }}
                      >
                        {localeData.cartCheckoutButton}
                      </Button>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Desktop>
      </div>
    );
  }

  cartIcon = () => {
    const { cartObject, cartChange } = this.props;
    const active =
      cartObject && cartObject.Items && cartObject.Items.length > 0;

    return (
      <React.Fragment>
        {!!this.props.cartObject && !!this.props.cartObject.Items ? (
          <div
            data-cy='Notification-Bubble-Message'
            className={styles.notificationBubble}
          >
            <p>{this.props.cartObject.Items.length}</p>
          </div>
        ) : null}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 24 24'
          data-cy='Notification-Bubble-Icon'
          onClick={() => {
            if (this.state.open) {
              this.setState({ open: false });
              if (cartChange) {
                cartChange(false);
              }
            } else {
              this.setState({ open: true });
              if (cartChange) {
                cartChange(true);
              }
            }
          }}
          ref={(elem) => (this.cartIconSVG = elem)}
        >
          <g id='ic_shopping_cart_48px' transform='translate(-2 -4)'>
            <path
              id='Path_17'
              d='M9.2 23.2a2.4 2.4 0 1 0 2.4 2.4 2.389 2.389 0 0 0-2.4-2.4zM2 4v2.4h2.4l4.314 9.1-1.62 2.94A2.44 2.44 0 0 0 6.8 19.6 2.4 2.4 0 0 0 9.2 22h14.4v-2.4H9.71a.3.3 0 0 1-.3-.3.285.285 0 0 1 .036-.144L10.52 17.2h8.94a2.408 2.408 0 0 0 2.1-1.236l4.29-7.788A1.16 1.16 0 0 0 26 7.6a1.2 1.2 0 0 0-1.2-1.2H7.058L5.918 4zm19.2 19.2a2.4 2.4 0 1 0 2.4 2.4 2.389 2.389 0 0 0-2.4-2.4z'
              className={cx(
                styles.cartIcon,
                this.props.light ? styles.light : styles.dark,
                {
                  [styles.active]: active,
                }
              )}
              data-name='Path 17'
            />
          </g>
        </svg>
      </React.Fragment>
    );
  };

  async setNewCart(currency: ICurrencyOption) {
    const cartId = ls.get('cartId');
    if (cartId) {
      const cart = await this.cartApi.getCart(
        cartId,
        currency.value,
        this.props.lang
      );
      if (cart) {
        this.props.setCart(cart);
      }
    }
  }

  // TODO Create a static query that fetches the required slugs instead of getting them all
  getSlug(cartItems: any, product: any) {
    let result = 'tour/';
    let productId = '';
    cartItems.Items.forEach((item: any) => {
      if (item.Product.Id === product.Product.Id) {
        productId = item.Product.Id;
      }
    });
    for (const edge of this.props.contentfulData.allContentfulTour.edges) {
      if (!!edge.node.productId && !!productId) {
        if (
          edge.node.productId.toString() === productId.toString() &&
          !!edge.node.productId
        ) {
          result += edge.node.slug;
          break;
        }
      }
    }
    return result;
  }

  renderCart(mobileOrDesktop: string) {
    const { cartObject, cartChange, localeData } = this.props;
    const { currency } = this.props;
    if (!!cartObject && !!cartObject.Items) {
      return (
        <div>
          <ul className={styles.cart}>
            {cartObject.Items.map((item) => {
              const image = this.getPreviewImage(item.Product.KeyPhoto);
              return (
                <React.Fragment key={item.CartItemId}>
                  <li>
                    {image ? (
                      item.Product.KeyPhoto.OtherVersions !== undefined ? (
                        <div className={styles.cartItem}>
                          <BackgroundImage
                            className={styles.image}
                            src={image ? image : ''}
                          />
                        </div>
                      ) : null
                    ) : null}
                    <TranslationConsumer>
                      {() => (
                        <div className={styles.cartItem}>
                          <Link
                            to={this.getSlug(cartObject, item)}
                            langPath={this.props.langPath}
                            className={styles.link}
                          >
                            <Title tag='h3' theme={['sizeMobileSmall']}>
                              {this.getCartItemName(item)}
                            </Title>
                          </Link>
                          <hr className={styles.dividerGray} />

                          <dl className={styles.cartInfoList}>
                            <dt>{localeData.cartPartySize}</dt>
                            <dd>{item.Guests.length}</dd>
                            <dt>{localeData.cartDate}</dt>
                            <dd>{formatDate(moment(item.Date))}</dd>
                            <dt>{localeData.cartTime}</dt>
                            <dd>{item.StartTime}</dd>
                            <dt className={styles.bold}>
                              {localeData.cartPrice}
                            </dt>
                            <dd>
                              {formatPrice(item.TotalPrice, currency.value) +
                                ' ' +
                                this.props.currency.label}
                            </dd>
                          </dl>
                        </div>
                      )}
                    </TranslationConsumer>

                    <div className={styles.deleteFromCart}>
                      <button
                        onClick={() =>
                          this.removeItem(item.CartItemId, item.BookingType)
                        }
                      >
                        <Icon name='BIN' />
                        {localeData.cartRemove}
                      </button>
                    </div>
                  </li>
                  <div className={styles.divider} />
                </React.Fragment>
              );
            })}
          </ul>
          {mobileOrDesktop === 'mobile' ? (
            <>
              <hr className={styles.divider} />
              <div>{this.renderPrice()}</div>
            </>
          ) : null}
          {mobileOrDesktop === 'mobile' ? (
            <React.Fragment>
              <div
                className={cx(
                  styles.buttonExtra,

                  styles.mobileStickyWhite
                )}
              >
                <Link
                  to='checkout'
                  langPath={this.props.langPath}
                  className={styles.link}
                >
                  <Button
                    onClick={() => {
                      this.setState({ open: false });
                      if (cartChange) {
                        cartChange(false);
                      }
                    }}
                  >
                    {localeData.cartCheckoutButton}
                  </Button>
                </Link>
                <div style={{ paddingTop: 10 }}>
                  <Link
                    to='tours-activities'
                    langPath={this.props.langPath}
                    className={styles.link}
                  >
                    {this.props.isIcelandair ? null : (
                      <Button
                        tag='secondary'
                        onClick={() => {
                          this.setState({ open: false });
                        }}
                      >
                        {/* Note this might seem strange but mobiel has a button that is the same as the empty cart button on desktop this might need to change in the future */}
                        {localeData.cartEmptyButton}
                      </Button>
                    )}
                  </Link>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      );
    } else if ((!!cartObject && !cartObject.Items) || !cartObject) {
      return (
        <EmptyCart
          langPath={this.props.langPath}
          hardcodedStrings={localeData}
        />
      );
    }
    return null;
  }

  getCartItemName(item: IItem) {
    // check if the name starts with x -
    // note this happens because of how product manager handled combo tours in bokun
    let name = item.Product.Name;
    if (name.startsWith(XSTART)) {
      name = name.split('- ')[1];
    }

    if (item.ReturnPickupAddress && !item.BlueLagoonEntryTime) {
      return name + ' - Return';
    }
    return name;
  }

  getPreviewImage(KeyPhoto: IPhoto) {
    if (KeyPhoto) {
      const photoIndex = KeyPhoto.OtherVersions.findIndex(
        (photo) => photo.Version === 'preview'
      );
      if (photoIndex) {
        return KeyPhoto.OtherVersions[photoIndex].Url;
      }
    }
    return undefined;
  }

  removeItem = async (cartItemId: number, bookingType: string | null) => {
    const { currency, lang } = this.props;
    const id = ls.get('cartId');

    if (!!id && !!cartItemId) {
      const cart = await this.cartApi.removeFromCart(
        id,
        cartItemId,
        currency.value,
        bookingType,
        lang
      );
      if (cart) {
        // reducer set cart
        this.props.setCart(cart);
      }
    }
  };

  renderPrice() {
    const { currency, localeData } = this.props;
    const { cartObject } = this.props;
    let finalPrice = 0;
    if (cartObject) {
      if (cartObject.TotalPriceWithDiscount) {
        if (
          this.state.finalYayPrice !== 0 &&
          this.state.finalYayPrice < cartObject.TotalPriceWithDiscount
        ) {
          finalPrice = this.state.finalYayPrice;
        } else {
          finalPrice = cartObject.TotalPriceWithDiscount;
        }
      } else {
        if (
          this.state.finalYayPrice !== 0 &&
          this.state.finalYayPrice < cartObject.TotalPrice
        ) {
          finalPrice = this.state.finalYayPrice;
        } else {
          finalPrice = cartObject.TotalPrice;
        }
      }
      return (
        <React.Fragment>
          <TranslationConsumer>
            {() => (
              <div className={styles.totalPrice}>
                <strong>{localeData.cartTotalPrice}</strong>
                <strong
                  data-cy='Total-Price-And-Currency'
                  className={styles.right}
                >
                  {formatPrice(finalPrice, currency.value)}{' '}
                  {!!cartObject.TotalPrice ||
                  !!cartObject.TotalPriceWithDiscount
                    ? currency.value
                    : null}
                </strong>
              </div>
            )}
          </TranslationConsumer>
        </React.Fragment>
      );
    }
    return null;
  }

  handleClickOutside = (event: any) => {
    if (
      this.cartDropdown &&
      !this.cartDropdown.contains(event.target) &&
      this.cartIconSVG &&
      !this.cartIconSVG.contains(event.target)
    ) {
      this.setState({ open: false });
      if (this.props.cartChange) {
        this.props.cartChange(false);
      }
    }
  };

  updateYayPrice = (cartCheckout: ICartCheckout) => {
    if (this.cartDropdown) {
      this.setState({ finalYayPrice: cartCheckout.options[0].amount });
    }
  };

  updateOptionsHeight = () => {
    if (this.cartDropdown) {
      const height = this.cartDropdown.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { cartObject } = reducers.cartReducers;
  const { currency } = reducers.currencyReducer;
  const { lang, locale } = reducers.translationReducers;
  return { cartObject, currency, lang, locale };
};

export default connect(mapStateToProps, { setCart })(
  ({
    currency,
    light,
    langPath,
    cartObject,
    mobileOpen,
    mobile,
    searchOpen,
    lang,
    cartChange,
    contentfulData,
    setCart,
    locale,
    isIcelandair,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourCart {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                cartHeader
                cartHeaderItemCount {
                  singular
                  plural
                }
                cartPartySize
                cartDate
                cartTime
                cartPrice
                cartRemove
                cartTotalPrice
                cartCheckoutButton
                cartEmptyTitle
                cartEmptySubtitle
                cartEmptyButton
              }
            }
          }
          contentfulTourCategory {
            slug
          }
        }
      `}
      render={(data) => {
        return (
          <TourCart
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            categoryInfo={data.contentfulTourCategory}
            currency={currency}
            light={light}
            langPath={langPath}
            cartObject={cartObject}
            mobileOpen={mobileOpen}
            mobile={mobile}
            searchOpen={searchOpen}
            lang={lang}
            cartChange={cartChange}
            contentfulData={contentfulData}
            setCart={setCart}
            locale={locale}
            isIcelandair={isIcelandair}
          />
        );
      }}
    />
  )
);
