import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as styles from './index.module.scss';
import { Link } from '../../components';
import {
  changeAnnouncementVisible,
  changeDismiss,
} from '../../redux/actions/layoutActions';
import {
  IHeaderList,
  IPageContext,
  IContentfulHeaderObject,
} from '../../interfaces';
import HeaderLinks from '../HeaderLinks';
import HeaderUpper from '../HeaderUpper';
import Announcement from '../Annoncement';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cx from 'classnames';
import { currencies } from '../../constants';

import { IReducers } from '../../interfaces';
import { logoSvg } from '../../../src/icons';

interface IProps {
  langPath: string;
  contentfulHeader: IHeaderList;
  contentfulData: any;
  pageContext: IPageContext;
  pathname: string;
  open: boolean;
  searchValue: string;
  announcement: IContentfulHeaderObject | undefined | null;
  setOpen(open: boolean): void;
  updateSearch(event: React.ChangeEvent<HTMLInputElement>): void;
  handleEnter(e: React.KeyboardEvent<HTMLInputElement>): void;
  onSearchClick(): void;
  changeAnnouncementVisible(parameter: boolean): void;
  changeDismiss(parameter: boolean): void;
  dismiss: boolean;
  isIcelandairUrl: boolean;
  isIcelandair: boolean;
  queryIcelandair: string;
  expiredAnnouncement(): boolean;
}

const StickyHeader: React.FunctionComponent<IProps> = ({
  langPath,
  contentfulHeader,
  pageContext,
  pathname,
  open,
  setOpen,
  searchValue,
  contentfulData,
  updateSearch,
  handleEnter,
  onSearchClick,
  announcement,
  changeAnnouncementVisible,
  changeDismiss,
  dismiss,
  isIcelandairUrl,
  isIcelandair,
  queryIcelandair,
  expiredAnnouncement,
}) => {
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const { imageOverlay, navColor } = pageContext;
  const lightNav = navColor === 'light';
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== showAnnouncement) {
        if (!dismiss) {
          setShowAnnouncement(isShow);
          changeAnnouncementVisible(isShow);
        }
      }
      if (currPos.y > prevPos.y && currPos.y !== 0) {
        if (!dismiss) {
          setShowAnnouncement(false);
          changeAnnouncementVisible(false);
        }
      }
      if (currPos.y === 0) {
        if (!dismiss) {
          setShowAnnouncement(true);
          changeAnnouncementVisible(true);
        }
      }
    },
    [showAnnouncement]
  );

  useEffect(() => {
    if (dismiss) {
      setShowAnnouncement(false);
    }
  }, [dismiss]);

  const initializeVisible = useCallback(() => {
    if (announcement) {
      if (announcement.announcement.announcement) {
        changeAnnouncementVisible(true);
      }
    }
  }, [changeAnnouncementVisible, announcement]);

  useEffect(() => {
    initializeVisible();
  }, [initializeVisible]);

  const componentDidUnmount = useCallback(() => {
    return changeAnnouncementVisible(false);
  }, [changeAnnouncementVisible]);

  useEffect(() => {
    return () => {
      componentDidUnmount();
    };
  }, [componentDidUnmount]);

  const close = (parameter: boolean) => {
    changeDismiss(parameter);
  };

  function getHideAnnouncement() {
    if (isIcelandairUrl || isIcelandair) announcement = null;
    return announcement;
  }

  return (
    <div>
      {announcement && !expiredAnnouncement() && (
        <Announcement announcement={announcement} closeAnnouncement={close} />
      )}
      <header
        className={
          announcement &&
          !expiredAnnouncement() &&
          showAnnouncement &&
          getHideAnnouncement != null
            ? styles.headerWithAnnouncement
            : styles.header
          //styles.header
        }
      >
        {imageOverlay && (
          <div
            className={cx(
              styles.gradient,
              lightNav ? styles.darkGradient : styles.lightGradient
            )}
          />
        )}
        {/* {isIcelandair?
        <div className={styles.sticky} style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>: */}
        <div className={styles.sticky}>
          {/* <div className={styles.sticky}> */}
          <div
            className={cx('centered-content', styles.logoHeight)}
            //className='centered-content'
          >
            {!!isIcelandair || !!isIcelandairUrl ? (
              <>
                <div className={styles.stickyLogo}>
                  <img src={logoSvg} />
                </div>
                <div className={styles.stickyLogoMobile}>
                  <img src={logoSvg} />
                </div>
              </>
            ) : (
              <Link langPath={langPath} to={''}>
                <div className={styles.stickyLogo}>
                  <img src={logoSvg} />
                </div>
                <div className={styles.stickyLogoMobile}>
                  <img src={logoSvg} />
                </div>
              </Link>
            )}
            <div className={styles.stickyLinks}>
              <HeaderLinks
                langPath={langPath}
                contentfulHeader={contentfulHeader}
                pageContext={pageContext}
                pathname={pathname}
                open={open}
                light={false}
                mobile={false}
                setOpen={setOpen}
                isIcelandairUrl={isIcelandairUrl}
                isIcelandair={isIcelandair}
              />
            </div>
            <div className={styles.stickyRight}>
              <HeaderUpper
                searchValue={searchValue}
                lightNav={false}
                currencies={currencies}
                pageContext={pageContext}
                contentfulData={contentfulData}
                updateSearch={updateSearch}
                handleEnter={handleEnter}
                onSearchClick={onSearchClick}
                pathname={pathname}
                isIcelandair={isIcelandair}
                queryIcelandair={queryIcelandair}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { announcementVisible, dismiss, isIcelandair } = reducers.layoutReducer;
  return { announcementVisible, dismiss, isIcelandair };
};

export default connect(mapStateToProps, {
  changeAnnouncementVisible,
  changeDismiss,
})(StickyHeader);
