import React from 'react';
import * as styles from './index.module.scss';
import Modal from 'react-modal';
import {
  IProduct,
  ISortedAvailability,
  ISelectValue,
  IBookingCutoff,
  IPersonCounter,
  IPickupDropoff,
  IBookableExtraState,
  DataType,
  IRate,
  ICurrencyOption,
  ITranslationsBookingModal,
  IReducers,
  ITourDetails,
} from '../../../interfaces';
import cx from 'classnames';
import { Moment } from 'moment';
import { TourDatePicker } from '..';
import TourBookingHeader from '../TourBookingHeader';
import TBPriceBreakdown from '../TBPriceBreakdown';
import TourBookingTime from '../TourBookingTime';
import TourBookingPickup from '../TourBookingPickup';
import TourBookingMeetingPoints from '../TourBookingMeetingPoints';
import TourBookingDropoff from '../TourBookingDropoff';
import TourBookingGuests from '../TourBookingGuests';
import TourBookingExtras from '../TourBookingExtras';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';

type AddOrRemove = 'addOne' | 'removeOne';
type pickOrDrop = 'pickup' | 'dropoff';

interface IExternalProps {
  tour: ITourDetails;
  product: IProduct | null;
  tourTitle: string;
  availabilityIsLoading: boolean;
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedMonth: Moment | null;
  selectedTime: ISelectValue | null;
  focus: boolean;
  isValid: boolean;
  personCounter: IPersonCounter | null;
  pickupAndDropoffPlaces: IPickupDropoff | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  bookableExtraCounter: IBookableExtraState | null;
  currency: ICurrencyOption;
  addingToCart: boolean;
  modalOpen: boolean;
  locale: string;
  resetSelectedTimeAndObject(): void;
  onChangeDate(date: Moment | null): void;
  onFocusChange(focus: boolean | null): void;
  getAvailability(fromDate: Date, monthsToAdd: number): void;
  isDayBlocked(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  isDayDisabled(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  loadingAvailability(loading: boolean): void;
  handleSelectedTime(event: { label: string; value: string }): void;
  getMaxToBook(): number | null;
  getTotalPersonCount(): number;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
  changePickupDropoff(event: ISelectValue, pickupOrDropoff: pickOrDrop): void;
  changeSelectOpen(newState: boolean, pickupOrDropoff: pickOrDrop): void;
  changeBookableExtraCounter(
    addorRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ): void;
  changeAnswer(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ): void;
  toggleSelectExtra(name: string): void;
  getRateIndex(rates: IRate[]): number;
  getPersonCount(personType: string): number;
  addToCart(): void;
  toggleModal(value: boolean): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}

const TourModal: React.FunctionComponent<IProps> = (props) => {
  const {
    product,
    tourTitle,
    availability,
    selectedDate,
    selectedMonth,
    selectedTime,
    focus,
    resetSelectedTimeAndObject,
    onChangeDate,
    onFocusChange,
    getAvailability,
    isDayBlocked,
    isDayDisabled,
    loadingAvailability,
    handleSelectedTime,
    isValid,
    personCounter,
    getTotalPersonCount,
    changePersonCount,
    pickupAndDropoffPlaces,
    selectedPickup,
    changePickupDropoff,
    changeSelectOpen,
    selectedDropoff,
    bookableExtraCounter,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    getRateIndex,
    getPersonCount,
    currency,
    addToCart,
    addingToCart,
    modalOpen,
    toggleModal,
    localeData,
    tour,
  } = props;

  function renderModal() {
    const pickupDropoffText =
      (!!product && product.PickupSelection !== 'UNAVAILABLE'
        ? localeData.pickUpHeader
        : '') +
      (!!product && product.DropoffSelection !== 'UNAVAILABLE'
        ? ' / ' + localeData.dropOffHeader
        : '');
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => toggleModal(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        closeTimeoutMS={200}
        className={styles.modal}
        bodyOpenClassName={styles.modalOpen}
        portalClassName={styles.modalPortal}
        ariaHideApp={false}
      >
        <TourBookingHeader
          toggleModal={toggleModal}
          tourTitle={tourTitle}
          tourSlug={tour.slug}
        />
        <div className='columns is-mobile is-marginless'>
          <div className={cx('column', styles.left)}>
            {product?.BookingType === 'PASS' ? null : (
              <div>
                <TourDatePicker
                  product={product}
                  availability={availability}
                  selectedDate={selectedDate}
                  defaultMonth={selectedMonth}
                  selectedTime={selectedTime}
                  focus={focus}
                  bookingCutoff={product ? product.BookingCutoff : null}
                  resetSelectedTimeAndObject={resetSelectedTimeAndObject}
                  onChangeDate={onChangeDate}
                  onFocusChange={onFocusChange}
                  getAvailability={getAvailability}
                  isDayBlocked={isDayBlocked}
                  isDayDisabled={isDayDisabled}
                  loadingAvailability={loadingAvailability}
                />
                <TourBookingTime
                  product={product}
                  availability={availability}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  handleSelectedTime={handleSelectedTime}
                  isValid={isValid}
                  header={localeData.timeSelectorHeader}
                />
              </div>
            )}
            <TourBookingGuests
              personCounter={personCounter}
              getTotalPersonCount={getTotalPersonCount}
              changePersonCount={changePersonCount}
              availability={availability}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              header={localeData.participantsSelectorHeader}
            />
            <TourBookingPickup
              pickupDropoffText={pickupDropoffText}
              pickupAndDropoffPlaces={pickupAndDropoffPlaces}
              selectedPickup={selectedPickup}
              changePickupDropoff={changePickupDropoff}
              product={product}
              changeSelectOpen={changeSelectOpen}
              isValid={isValid}
            />
            <TourBookingDropoff
              dropoff={pickupAndDropoffPlaces}
              selectedDropoff={selectedDropoff}
              changeDropoff={props.changePickupDropoff}
              dropoffSelection={product ? product.DropoffSelection : null}
              isValid={isValid || !!selectedDropoff}
              toggleSelect={props.changeSelectOpen}
            />
            <TourBookingMeetingPoints
              pickupSelection={product ? product.PickupSelection : null}
              dropoffSelection={product ? product.DropoffSelection : null}
              meetingPoints={
                !!product && !!product.StartPlaces ? product.StartPlaces : null
              }
            />
            {bookableExtraCounter && (
              <TourBookingExtras
                personCounter={personCounter}
                bookableExtraCounter={bookableExtraCounter}
                changeBookableExtraCounter={changeBookableExtraCounter}
                changeAnswer={changeAnswer}
                toggleSelectExtra={toggleSelectExtra}
                title={localeData.extrasHeader}
              />
            )}
          </div>
          <div className='column'>
            <TBPriceBreakdown
              availability={availability}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              bookableExtraCounter={bookableExtraCounter}
              getRateIndex={getRateIndex}
              selectedPickup={selectedPickup}
              selectedDropoff={selectedDropoff}
              getPersonCount={getPersonCount}
              currency={currency}
              personCounter={personCounter}
              addToCart={addToCart}
              addingToCart={addingToCart}
              tour={tour}
            />
          </div>
        </div>
      </Modal>
    );
  }

  return renderModal();
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    product,
    tourTitle,
    availability,
    selectedDate,
    selectedMonth,
    selectedTime,
    focus,
    resetSelectedTimeAndObject,
    onChangeDate,
    onFocusChange,
    getAvailability,
    isDayBlocked,
    isDayDisabled,
    loadingAvailability,
    handleSelectedTime,
    isValid,
    personCounter,
    getTotalPersonCount,
    changePersonCount,
    pickupAndDropoffPlaces,
    selectedPickup,
    changePickupDropoff,
    changeSelectOpen,
    selectedDropoff,
    bookableExtraCounter,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    getRateIndex,
    getPersonCount,
    currency,
    addToCart,
    addingToCart,
    modalOpen,
    toggleModal,
    availabilityIsLoading,
    getMaxToBook,
    locale,
    tour,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourModalQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                extrasHeader
                pickUpHeader
                dropOffHeader
                participantsSelectorHeader
                timeSelectorHeader
              }
            }
          }
        }
      `}
      render={(data) => (
        <TourModal
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          product={product}
          tourTitle={tourTitle}
          availability={availability}
          selectedDate={selectedDate}
          selectedMonth={selectedMonth}
          selectedTime={selectedTime}
          focus={focus}
          resetSelectedTimeAndObject={resetSelectedTimeAndObject}
          onChangeDate={onChangeDate}
          onFocusChange={onFocusChange}
          getAvailability={getAvailability}
          isDayBlocked={isDayBlocked}
          isDayDisabled={isDayDisabled}
          loadingAvailability={loadingAvailability}
          handleSelectedTime={handleSelectedTime}
          isValid={isValid}
          personCounter={personCounter}
          getTotalPersonCount={getTotalPersonCount}
          changePersonCount={changePersonCount}
          pickupAndDropoffPlaces={pickupAndDropoffPlaces}
          selectedPickup={selectedPickup}
          changePickupDropoff={changePickupDropoff}
          changeSelectOpen={changeSelectOpen}
          selectedDropoff={selectedDropoff}
          bookableExtraCounter={bookableExtraCounter}
          changeBookableExtraCounter={changeBookableExtraCounter}
          changeAnswer={changeAnswer}
          toggleSelectExtra={toggleSelectExtra}
          getRateIndex={getRateIndex}
          getPersonCount={getPersonCount}
          currency={currency}
          addToCart={addToCart}
          addingToCart={addingToCart}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          availabilityIsLoading={availabilityIsLoading}
          getMaxToBook={getMaxToBook}
          locale={locale}
          tour={tour}
        />
      )}
    />
  )
);
