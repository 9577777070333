import React from 'react';
import cx from 'classnames';

import * as styles from './OverflowText.module.scss';
import { TextParser } from '../../components';
import { IReducers } from '../../interfaces';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  text: string;
  height: number;
  className?: string;
  textClassName?: string;
  gradientClassName?: string;
  locale: string;
}
interface IProps extends IExternalProps {
  localeData: {
    overflowText: IOverflowText;
  };
}

interface IOverflowText {
  buttonTextBeforeClick: string;
  buttonTextAfterClick: string;
}

interface IState {
  textExpanded: boolean;
  textOverflowing: boolean;
}

class OverflowText extends React.Component<IProps, IState> {
  readonly state: IState = { textExpanded: false, textOverflowing: false };

  textElement: HTMLDivElement | null = null;

  componentDidMount() {
    this.checkOverflow();
    window.addEventListener('resize', this.checkOverflow);
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.text !== prevProps.text ||
      this.props.height !== prevProps.height
    ) {
      this.checkOverflow();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkOverflow);
  }

  render() {
    const {
      text,
      height,
      className,
      textClassName,
      gradientClassName,
      localeData,
    } = this.props;
    const { textExpanded, textOverflowing } = this.state;
    const gradient = !textExpanded && textOverflowing;

    return (
      <div data-aos='fade-in' className={className}>
        <div
          ref={(elem) => {
            this.textElement = elem;
          }}
          className={cx(styles.text, {
            [gradientClassName || styles.gradient]: gradient,
          })}
          style={textExpanded ? {} : { maxHeight: height }}
        >
          <TextParser source={text} className={textClassName} />
        </div>
        {textOverflowing || textExpanded ? (
          <button onClick={this.toggleText} className={styles.readMore}>
            <p>
              {textExpanded
                ? localeData.overflowText.buttonTextAfterClick
                : localeData.overflowText.buttonTextBeforeClick}
            </p>
          </button>
        ) : null}
      </div>
    );
  }

  toggleText = () => {
    this.setState((state) => ({ textExpanded: !state.textExpanded }));
  };

  checkOverflow = () => {
    if (this.textElement) {
      const isOverflowing = this.textElement.clientHeight >= this.props.height;
      this.setState({ textOverflowing: isOverflowing });
    }
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    text,
    height,
    className,
    textClassName,
    gradientClassName,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query OverflowText {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                overflowText {
                  buttonTextBeforeClick
                  buttonTextAfterClick
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <OverflowText
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            text={text}
            height={height}
            className={className}
            textClassName={textClassName}
            gradientClassName={gradientClassName}
            locale={locale}
          />
        );
      }}
    />
  )
);
