import React from 'react';

export const HeartCircle = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='55.202'
    height='55.208'
    viewBox='0 0 55.202 55.208'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2.5px'
  >
    <g
      id='Love_Favorite'
      data-name='Love / Favorite'
      transform='translate(-1010.67 -650.752)'
    >
      <path
        id='Path_48'
        d='M1038.272 704.71a26.354 26.354 0 1 1 26.35-26.354 26.263 26.263 0 0 1-26.35 26.354z'
        stroke='#afafaf'
        data-name='Path 48'
      />
      <path
        id='Path_49'
        d='M1041.793 664.874a10.578 10.578 0 0 1 3.608-.6 8.313 8.313 0 0 1 8.313 8.314c0 8.169-16.608 20.684-16.608 20.684s-16.643-12.514-16.643-20.684a8.313 8.313 0 1 1 16.626 0'
        stroke='#2d8377'
        data-name='Path 49'
        transform='translate(1.166 1.675)'
      />
    </g>
  </svg>
);
export default HeartCircle;
