import React from 'react';

import * as styles from './BlogList.module.scss';
import { IBlogItem } from '../../interfaces';
import { FluidImage, Link, MarkdownWrapper } from '../index';

interface IProps {
  blogs: IBlogItem[];
  langPath: string;
}

function renderBlog(blog: IBlogItem, langPath: string) {
  return (
    <div key={blog.id}>
      <Link langPath={langPath} to={`${blog.pathPrefix}/${blog.slug}`}>
        <p>{blog.title}</p>
      </Link>
      {blog.mainImage ? (
        <FluidImage
          fluid={blog.mainImage.fluid}
          className={styles.blogImage}
          alt={blog.title}
        />
      ) : null}
      <p>{blog.subtitle}</p>
      {blog.summary ? <MarkdownWrapper text={blog.summary.summary} /> : null}
    </div>
  );
}

const BlogList = ({ blogs, langPath }: IProps) => {
  return (
    <React.Fragment>
      {blogs.map((blog) => renderBlog(blog, langPath))}
    </React.Fragment>
  );
};

export default BlogList;
