import React from 'react';

import * as styles from './FilterSelect.module.scss';
import { filterCheck } from '../../icons';
import { IFilterSelectItem } from '../../interfaces';

interface IProps {
  items: IFilterSelectItem[];
  select: (index: number) => void;
}

const FilterSelect: React.FunctionComponent<IProps> = ({ items, select }) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <button
          className={styles.item}
          key={index}
          onClick={() => select(index)}
        >
          <div className={styles.checkBox} />
          <img
            src={filterCheck}
            alt=''
            style={{ visibility: item.selected ? 'visible' : 'hidden' }}
          />
          <p>{item.title}</p>
        </button>
      ))}
    </div>
  );
};

export default FilterSelect;
