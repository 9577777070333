import React from 'react';

const ArrowsUpDown = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9034 8.75L1.25 19.2403c.42614.7553 1.02273 1.5106 1.70454 2.182.68182.6713 1.44887 1.2588 2.21592 1.6784l3.92044-3.8604v12.924c.85227.2518 1.7898.3357 2.7273.3357s1.875-.0839 2.7272-.3357V19.1564l2.8978 2.8533.0852.0839 3.9204-3.8604-.0852-.0839L11.9034 8.75zm0 1.9302l7.6704 7.553-1.9602 1.9302-1.9602-1.9302-2.3864-2.3498v15.106c-.4261.0839-.9375.0839-1.3636.0839-.4261 0-.9375 0-1.3636-.0839v-15.106l-2.38639 2.3498-3.15342 3.1051c-.34091-.2517-.68181-.5874-1.02272-.9231-.34091-.3357-.59658-.6714-.93749-1.0071l8.86362-8.7279z'
      fill='#FA7268'
    />
    <path
      d='M37.0454 19.8277c-.6818-.6713-1.4489-1.2588-2.2159-1.6784l-3.9205 3.8604V9.08569c-.8522-.25177-1.7897-.33569-2.7272-.33569-.9375 0-1.875.08392-2.7273.33569V22.0936l-2.8977-2.8533-.0853-.0839-3.9204 3.8604.0852.0839L28.1818 32.5l10.5681-10.4903c-.4261-.7553-1.0227-1.5106-1.7045-2.182z'
      fill='#FA7268'
    />
  </svg>
);

export default ArrowsUpDown;
