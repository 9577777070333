import axios, { AxiosInstance } from 'axios';
import {
  ITransportStartTimes,
  ITransport,
  ITransportDefaultPrice,
  IPriceCategory
} from '../interfaces';
import { formatAvailabilityDate, handleAxiosError } from '../utils';
import { Moment } from 'moment';

interface IGetStartTimes {
  productId: number;
  fromId: number;
  toId: number;
  date: string;
  currency: string;
  returnDate?: string | null;
  lang: string;
}

interface ITransportApi {
  getStartTimes(
    productId: number,
    fromId: number,
    toId: number,
    date: Moment,
    currency: string,
    isLagoon: boolean,
    lang: string
  ): Promise<ITransportStartTimes | null>;

  getTransport(
    productId: number,
    currency: string,
    lang: string
  ): Promise<ITransport | null>;
  getPrices(
    productId: number,
    fromId: number,
    toId: number,
    date: Moment,
    StartTime: string,
    currency: string,
    lang: string
  ): Promise<IPriceCategory[] | null>;
}
class TransportApi implements ITransportApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async getStartTimes(
    productId: number,
    fromId: number,
    toId: number,
    date: Moment,
    currency: string,
    isLagoon: boolean,
    lang: string
  ): Promise<ITransportStartTimes | null> {
    try {
      const newDate = formatAvailabilityDate(date);
      const basicParams: IGetStartTimes = {
        productId,
        fromId,
        toId,
        date: newDate,
        currency,
        lang
      };

      basicParams.returnDate = isLagoon ? newDate : null;

      const result = await this.httpClient.get('/api/Transport/GetStartTimes', {
        params: basicParams
      });

      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getTransport(
    productId: number,
    currency: string,
    lang: string
  ): Promise<ITransport | null> {
    try {
      const result = await this.httpClient.get('/api/Transport/GetTransport', {
        params: { productId, currency, lang }
      });
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getPrices(
    productId: number,
    fromId: number,
    toId: number,
    date: Moment,
    StartTime: string,
    currency: string,
    lang: string
  ): Promise<IPriceCategory[] | null> {
    try {
      const newDate = formatAvailabilityDate(date);

      const result = await this.httpClient.get('/api/Transport/GetPrices', {
        params: {
          productId,
          fromId,
          toId,
          date: newDate,
          StartTime,
          currency,
          lang
        }
      });
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getDefaultPrices(
    tourIds: string[],
    currency: string,
    lang: string
  ): Promise<ITransportDefaultPrice[] | null> {
    try {
      const idList = tourIds.map((id: string) => parseInt(id, 10));
      const result = await this.httpClient.post(
        '/api/Transport/GetDefaultPrices',
        idList,
        { params: { currency, lang } }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default TransportApi;
