import React from 'react';

export const TimerClock = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M32.262 11.5265c.6023 0 1.0905-.4882 1.0905-1.0905 0-.60225-.4882-1.09048-1.0905-1.09048-.6022 0-1.0904.48823-1.0904 1.09048 0 .6023.4882 1.0905 1.0904 1.0905zM19.6482 36.6621C11.5103 36.6621 5 30.043 5 22.0133c0-8.1382 6.6188-14.64883 14.6482-14.64883 8.0293 0 14.6481 6.61913 14.6481 14.64883.1085 8.0297-6.5103 14.6488-14.6481 14.6488zm0-26.69339c-6.6188 0-11.93558 5.42549-11.93558 11.93609S13.1379 33.8409 19.6482 33.8409c6.5102 0 11.9355-5.4255 11.9355-11.9361 0-6.5106-5.3168-11.93609-11.9355-11.93609zM15.0171 6.12859c-.5164 0-1.0329-.30198-1.2395-.90594-.2066-.70462.2066-1.40923.8264-1.61055 3.4086-1.0066 6.9206-1.0066 10.226 0 .7231.20132 1.0329.90593.8264 1.61055-.2066.70462-.9297 1.0066-1.6527.80528-2.8923-.80528-5.8878-.80528-8.78 0 .1033 0-.1033.10066-.2066.10066z'
      fill='#FA7268'
    />
    <path
      d='M19.5035 23.8489c-.3272 0-.6543-.1091-.9815-.4362-.5452-.5452-.5452-1.4176 0-1.9629l5.6705-5.6705c.5453-.5452 1.4177-.5452 1.9629 0 .5452.5453.5452 1.4176 0 1.9629l-5.6705 5.6705c-.3271.3271-.6543.4362-.9814.4362z'
      fill='#FA7268'
    />
  </svg>
);
export default TimerClock;
