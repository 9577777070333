import React from 'react';
import * as styles from './index.module.scss';
import { TourPrice } from '../TourPrices';
import TourTotalPrice from '../TourTotalPrice';
import {
  ISortedAvailability,
  ISelectValue,
  IBookableExtraState,
  IRate,
  ICurrencyOption,
  IPersonCounter,
  ITranslationsBookingModal,
  ITranslationsCancellationPolicy,
  IReducers,
  ITranslationsPriceBreakdown,
  ITourDetails,
  ITranslationsSagaPoints,
} from '../../../interfaces';
import { Moment } from 'moment';
import Loader from '../../../components/Loader';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import TextBox from '../../components/TextBox';
import CancellationPolicy from '../CancellationPolicy';
import { graphql, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  bookableExtraCounter: IBookableExtraState | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  currency: ICurrencyOption;
  personCounter: IPersonCounter | null;
  addingToCart: boolean;
  locale: string;
  tour: ITourDetails;
  isMobile?: boolean;
  getRateIndex(rates: IRate[]): number;
  getPersonCount(personType: string): number;
  addToCart(): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}
const TBPriceBreakdown: React.FunctionComponent<IProps> = (props) => {
  const {
    availability,
    selectedDate,
    selectedTime,
    bookableExtraCounter,
    getRateIndex,
    selectedPickup,
    selectedDropoff,
    getPersonCount,
    currency,
    personCounter,
    addToCart,
    addingToCart,
    localeData,
    tour,
    isMobile,
  } = props;
  //
  return (
    <div className={styles.modalPrice}>
      <Title tag='h3' theme={['sizeDesktopSmall']}>
        {localeData.priceHeader}
      </Title>
      <TourPrice
        availability={availability}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        bookableExtraCounter={bookableExtraCounter}
        getRateIndex={getRateIndex}
        selectedPickup={selectedPickup}
        selectedDropoff={selectedDropoff}
        getPersonCount={getPersonCount}
        localeData={localeData as ITranslationsPriceBreakdown}
      />
      <TourTotalPrice
        bookableExtraCounter={bookableExtraCounter}
        currency={currency}
        availability={availability}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        getRateIndex={getRateIndex}
        personCounter={personCounter}
        selectedPickup={selectedPickup}
        selectedDropoff={selectedDropoff}
        title={localeData.totalPrice}
      />
      <CancellationPolicy
        hardcodedStrings={
          {
            freeCancellation: localeData.freeCancellation,
            noBookingFees: localeData.noBookingFees,
            smallPrint: localeData.smallPrint,
          } as ITranslationsCancellationPolicy
        }
      />{' '}
      {isMobile ? <TextBox tour={tour} /> : null}
      <div className={styles.addToCartButton}>
        <Button onClick={addToCart}>
          {addingToCart === true ? (
            <Loader size='medium' color='white' />
          ) : (
            localeData.addToCartButton
          )}
        </Button>
        {!isMobile ? <TextBox tour={tour} /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    availability,
    selectedDate,
    selectedTime,
    bookableExtraCounter,
    getRateIndex,
    selectedPickup,
    selectedDropoff,
    getPersonCount,
    currency,
    personCounter,
    addToCart,
    addingToCart,
    locale,
    tour,
    isMobile,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query PriceBreakdownQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                addToCartButton
                priceHeader
                totalPrice
                freeCancellation
                noBookingFees
                smallPrint
                notAvailable
                noGuests
                pickUp
                dropOff
                extras
              }
            }
          }
        }
      `}
      render={(data) => (
        <TBPriceBreakdown
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          availability={availability}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          bookableExtraCounter={bookableExtraCounter}
          getRateIndex={getRateIndex}
          selectedPickup={selectedPickup}
          selectedDropoff={selectedDropoff}
          getPersonCount={getPersonCount}
          currency={currency}
          personCounter={personCounter}
          addToCart={addToCart}
          addingToCart={addingToCart}
          locale={locale}
          tour={tour}
          isMobile={isMobile}
        />
      )}
    />
  )
);
