import React from 'react';
import moment, { Moment } from 'moment';
import { IFlybusSchedule } from '../../interfaces';
import * as styles from './FlybusScheduleTable.module.scss';
import Title from '../Title';
import Icon from '../Icon';
import TextParser from '../TextParser/TextParser';

const parseTime = (time: string) => {
  return moment(time, 'HH:mm');
};

const formatTime = (time: Moment) => {
  return time.format('HH:mm');
};

// OBS This Component is looking funny beacuse of Covid-19 situation.
const FlybusScheduleTable: React.FunctionComponent<IFlybusSchedule> = ({
  departures,
  bsiColumn,
  hotelColumn,
  kefColumn,
  scheduleImageText,
}) => {
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div className={styles.thContainer}>
                <Icon name='FLYBUSPLUS' />
                <span>{hotelColumn}</span>
              </div>
            </th>
            <th>
              <div className={styles.thContainer}>
                <Icon name='FLYBUS' />
                <span>{bsiColumn}</span>
              </div>
            </th>
            <th>
              <div className={styles.thContainer}>
                <Icon name='FLYBUS' />
                <span>{kefColumn}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {departures
            ? departures.map((departure) => (
                <tr key={departure}>
                  <td>
                    {formatTime(parseTime(departure).subtract(30, 'minutes'))}
                  </td>
                  <td>{formatTime(parseTime(departure))}</td>
                  <td>{formatTime(parseTime(departure).add(45, 'minutes'))}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <div className={styles.subtitle}>{scheduleImageText}</div>
    </>
  );
};
export default FlybusScheduleTable;
