import React from 'react';

export const TripAdvisorBannerIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 33.97 27.05'
    width='55.202'
    height='55.208'
  >
    <style />
    <g id='Layer_1'>
      <path
        fill='none'
        d='M11.92 12.71c-2.31 0-4.2 1.88-4.2 4.2a4.195 4.195 0 104.2-4.2z'
      />
      <path
        fill='none'
        d='M20.57 12c.55-.2 1.1-.3 1.65-.32-1.5-.67-3.21-1.01-5.09-1.01-1.87 0-3.6.35-5.11.99 2.72.05 4.94 2.18 5.13 4.87.05-.61.2-1.22.46-1.8.6-1.28 1.65-2.25 2.96-2.73z'
      />
      <path
        fill='none'
        d='M20.94 12.99a4.178 4.178 0 00-2.36 2.18 4.158 4.158 0 00-.12 3.21 4.204 4.204 0 005.39 2.49 4.2 4.2 0 002.48-5.39 4.206 4.206 0 00-5.39-2.49z'
      />
      <path d='M27.33 15.11c-.18-.48-.42-.92-.71-1.31.25-1.05 1.03-2.1 1.03-2.1h-3.51c-1.97-1.27-4.36-1.95-7.01-1.95-2.75 0-5.23.69-7.18 1.97H6.66s.78 1.04 1.03 2.08c-.64.87-1.03 1.94-1.03 3.11 0 2.9 2.36 5.26 5.26 5.26 1.66 0 3.13-.77 4.1-1.97l1.12 1.68 1.13-1.69c.51.65 1.16 1.17 1.93 1.52 1.28.59 2.71.65 4.02.16a5.276 5.276 0 003.11-6.76zM11.92 21.1c-2.31 0-4.2-1.88-4.2-4.19 0-2.31 1.88-4.2 4.2-4.2 2.31 0 4.19 1.88 4.19 4.2.01 2.31-1.88 4.19-4.19 4.19zm5.24-4.57c-.2-2.69-2.41-4.82-5.13-4.87 1.51-.64 3.23-.99 5.11-.99 1.89 0 3.59.34 5.09 1.01-.55.02-1.11.12-1.65.32a5.21 5.21 0 00-2.95 2.73c-.27.58-.42 1.18-.47 1.8zm6.69 4.34a4.16 4.16 0 01-3.21-.13 4.178 4.178 0 01-2.18-2.36c-.39-1.05-.34-2.19.12-3.21a4.2 4.2 0 017.75.3c.8 2.18-.31 4.59-2.48 5.4z' />
      <path
        fill='#F8C40F'
        d='M20.57 12c.55-.2 1.1-.3 1.65-.32-1.5-.67-3.21-1.01-5.09-1.01-1.87 0-3.6.35-5.11.99 2.72.05 4.94 2.18 5.13 4.87.05-.61.2-1.22.46-1.8.6-1.28 1.65-2.25 2.96-2.73z'
      />
      <path
        fill='#FFF'
        d='M20.94 12.99a4.178 4.178 0 00-2.36 2.18 4.158 4.158 0 00-.12 3.21 4.204 4.204 0 005.39 2.49 4.2 4.2 0 002.48-5.39 4.206 4.206 0 00-5.39-2.49zm1.45 6.47c-1.43 0-2.6-1.16-2.6-2.6 0-1.43 1.17-2.6 2.6-2.6 1.43 0 2.59 1.16 2.59 2.6 0 1.44-1.16 2.6-2.59 2.6z'
      />
      <path
        fill='#FFF'
        d='M22.39 15.16c-.94 0-1.7.76-1.7 1.7s.76 1.7 1.7 1.7 1.7-.76 1.7-1.7c0-.93-.77-1.7-1.7-1.7zm0 2.58a.87.87 0 11.001-1.739.87.87 0 01-.001 1.739zM11.86 15.16c-.94 0-1.7.76-1.7 1.7s.77 1.7 1.7 1.7c.94 0 1.7-.76 1.7-1.7-.01-.93-.77-1.7-1.7-1.7zm-.01 2.58a.87.87 0 11.87-.87c.01.48-.39.87-.87.87z'
      />
      <path
        fill='#FFF'
        d='M11.92 12.71c-2.31 0-4.2 1.88-4.2 4.2a4.195 4.195 0 104.2-4.2zm-.06 6.75a2.601 2.601 0 010-5.2c1.43 0 2.59 1.16 2.59 2.6 0 1.44-1.16 2.6-2.59 2.6z'
      />
      <path
        fill='#EF6945'
        d='M11.85 15.99a.87.87 0 10.87.87.864.864 0 00-.87-.87z'
      />
      <path
        fill='#00A680'
        d='M22.39 15.99a.87.87 0 10.001 1.739.87.87 0 00-.001-1.739z'
      />
      <path
        fill='none'
        d='M20.57 12c.55-.2 1.1-.3 1.65-.32-1.5-.67-3.21-1.01-5.09-1.01-1.87 0-3.6.35-5.11.99 2.72.05 4.94 2.18 5.13 4.87.05-.61.2-1.22.46-1.8.6-1.28 1.65-2.25 2.96-2.73z'
      />
      <path
        fill='none'
        d='M11.92 12.71c-2.31 0-4.2 1.88-4.2 4.2a4.195 4.195 0 104.2-4.2zM20.94 12.99a4.178 4.178 0 00-2.36 2.18 4.158 4.158 0 00-.12 3.21 4.204 4.204 0 005.39 2.49 4.2 4.2 0 002.48-5.39 4.206 4.206 0 00-5.39-2.49z'
      />
      <path d='M11.86 14.27a2.601 2.601 0 000 5.2c1.43 0 2.59-1.16 2.59-2.6 0-1.44-1.16-2.6-2.59-2.6zm0 4.3c-.94 0-1.7-.76-1.7-1.7s.77-1.7 1.7-1.7c.94 0 1.7.76 1.7 1.7-.01.93-.77 1.7-1.7 1.7zM22.39 14.27c-1.43 0-2.6 1.16-2.6 2.6 0 1.43 1.17 2.6 2.6 2.6 1.43 0 2.59-1.16 2.59-2.6 0-1.44-1.16-2.6-2.59-2.6zm0 4.3c-.94 0-1.7-.76-1.7-1.7s.76-1.7 1.7-1.7 1.7.76 1.7 1.7c0 .93-.77 1.7-1.7 1.7zM29.09 12.06h.07c.08 0 .12-.03.12-.09s-.04-.09-.13-.09h-.06v.18zm.09-.29c.19 0 .29.07.29.19 0 .09-.05.15-.14.18l.15.3h-.2l-.13-.27h-.05v.27h-.18v-.67h.26zm-.36-.01a.508.508 0 00-.01.71c.09.1.22.15.35.15a.5.5 0 00.35-.14c.1-.09.15-.22.15-.36a.491.491 0 00-.5-.5c-.13 0-.25.04-.34.14m.79-.07c.11.11.17.26.17.43 0 .17-.06.32-.18.44-.12.11-.27.18-.43.18-.16 0-.32-.06-.43-.19a.641.641 0 01-.18-.43c0-.16.07-.32.19-.44.12-.11.26-.17.42-.17.17-.01.32.06.44.18' />
      <path
        fill='#00A680'
        d='M.69 16.98C.69 8 8 .69 16.99.69S33.28 8 33.28 16.98c0 3.66-1.25 7.19-3.51 10.06h.87c2.14-2.91 3.32-6.44 3.32-10.06C33.97 7.62 26.35 0 16.99 0S0 7.62 0 16.98c0 3.62 1.18 7.15 3.33 10.06h.87C1.94 24.18.69 20.64.69 16.98z'
      />
      <path
        fill='#00A680'
        d='M16.99 1.05C8.25 1.05 1.14 8.16 1.14 16.9c0 3.72 1.32 7.3 3.71 10.15h.54A15.378 15.378 0 011.56 16.9c0-8.51 6.92-15.43 15.43-15.43S32.42 8.39 32.42 16.9c0 3.75-1.37 7.34-3.83 10.15h.54c2.39-2.85 3.71-6.43 3.71-10.15-.01-8.74-7.12-15.85-15.85-15.85z'
      />
    </g>
  </svg>
);
export default TripAdvisorBannerIcon;
