import React from 'react';

// arrow right
export const ArrowRightWhite = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20pt'
    height='20pt'
    viewBox='0 0 20 20'
  >
    <g fill='#fff'>
      <path d='M15.960938 9.777344l-4.984376 4.984375-1.199218-1.195313 3.789062-3.789062-3.789062-3.785156 1.199218-1.195313zm0 0' />
      <path d='M9.464844 10.597656H4.039062V8.894531h10.535157v1.703125zm0 0' />
    </g>
  </svg>
);
export default ArrowRightWhite;
