import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';

import * as styles from './BlogHero.module.scss';
import { IReducers, IBlogDetails, IPageContext } from '../../interfaces';
import { facebook, instagram, youtube } from '../../icons';
import { Image, Desktop, Mobile } from '../index';
import Title from '../Title';

interface IProps {
  data: {
    contentfulBlog: IBlogDetails;
  };
  pageContext: IPageContext;
}

interface IState {
  blurRight1: number | null;
  blurRight2: number | null;
  blurLeft1: number | null;
  blurLeft2: number | null;
  facebookLink: string | null;
}

const iconHeight = 20;

class BlogHero extends React.Component<IProps, IState> {
  readonly state = {
    blurRight1: null,
    blurRight2: null,
    blurLeft1: null,
    blurLeft2: null,
    facebookLink: null,
  };

  componentDidMount() {
    const url = window.location;
    const facebookUrl = url.host + url.pathname;

    this.setState({ facebookLink: facebookUrl });
  }

  render() {
    const { title, subtitle } = this.props.data.contentfulBlog;
    return (
      <React.Fragment>
        <Desktop>
          <div className={styles.hero}>
            {this.renderImage()}
            <div className={styles.right}>
              <div className={styles.darker} />
            </div>

            <div className={cx(styles.content, 'centered-content')}>
              <div className={styles.titleContainer}>
                <Title tag='h1' theme={['sizeDesktopLarge']}>
                  {title}
                </Title>
                <Title tag='h3' theme={['sizeMediumLarge']}>
                  {subtitle}
                </Title>
                <div className={styles.line} />
                {this.renderDate()}
                {/* this.renderInfo() */}
              </div>

              <div className={styles.infoContainer} />
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div>
            {this.renderImage()}
            <div className={styles.content}>
              <div
                className={cx(styles.titleContainerMobile, 'centered-content')}
              >
                <Title tag='h1' theme={['sizeMobileLarge']}>
                  {title}
                </Title>
                <Title tag='h3' theme={['sizeMobileSmall']}>
                  {subtitle}
                </Title>
                <div className={styles.line} />
                {this.renderDate()}
              </div>
            </div>
          </div>
        </Mobile>
      </React.Fragment>
    );
  }

  renderImage() {
    const { mainImage } = this.props.data.contentfulBlog;
    if (mainImage) {
      return (
        <Image
          fluidImage={{
            ...mainImage.fluid,
            fluid: {
              ...(mainImage ? mainImage.fluid : null),
              sizes: '60vw',
              alt: mainImage.title,
            },
          }}
          className={styles.image}
        />
      );
    }
    return null;
  }

  renderDate() {
    const { date } = this.props.data.contentfulBlog;

    return <p className={styles.date}>{moment(date).format('LL')}</p>;
  }

  renderInfo() {
    return (
      <div
        className={cx(
          'columns',
          'is-variable',
          'is-8',
          'is-mobile',
          'is-multiline',
          styles.iconDiv
        )}
      >
        <div className={'column'}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.facebookLink}`}
          >
            <div className={styles.ringDiv}>
              <img src={facebook} style={{ height: iconHeight }} />
            </div>
          </a>
        </div>

        <div className={'column'}>
          <div className={styles.ringDiv}>
            <img src={instagram} style={{ height: iconHeight }} />
          </div>
        </div>
        <div className={'column'}>
          <div className={styles.ringDiv}>
            <img src={youtube} style={{ height: iconHeight }} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  return { currency };
};

export default connect(mapStateToProps)(BlogHero);
