import axios, { AxiosInstance } from 'axios';
import { ICurrency } from '../interfaces/index';
import { handleAxiosError } from '../utils';

interface ICurrencyApi {
  getAllCurrencyExchange(): Promise<ICurrency[] | null>;
  getCurrencyExchange(currencyCode: string): Promise<ICurrency[] | null>;
}

class CurrencyApi implements ICurrencyApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async getAllCurrencyExchange(): Promise<ICurrency[] | null> {
    try {
      const result = await this.httpClient.get(
        '/api/CurrencyExchange/GetCurrencyExchange'
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getCurrencyExchange(currencyCode: string): Promise<ICurrency[] | null> {
    try {
      const result = await this.httpClient.get(
        '/api/CurrencyExchange/GetCurrencyExchange',
        {
          params: {
            currencyCode
          }
        }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default CurrencyApi;
