import React from 'react';

export const FileError = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path opacity='.1' d='M21 9h-8V1H1v27h14.6L21 17.9V9z' fill='#294B98' />
    <path d='M21 9l-8-8v8h8z' fill='#fff' />
    <path opacity='.15' d='M11 1v10.5h10V9h-8V1h-2z' fill='#000064' />
    <path d='M14 31l8-15 8 15H14z' fill='#FDBF45' />
    <path
      opacity='.15'
      d='M20.25 19.3L19 17l-5.85 11h2.45l4.65-8.7z'
      fill='#000064'
    />
    <path
      d='M15.6 28H1V1h12l8 8v8.9'
      stroke='#2C2C2C'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22 22v3M13 1v8h8l-8-8zm1 30l8-15 8 15H14z'
      stroke='#2C2C2C'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22 28a.5.5 0 100-1 .5.5 0 000 1z'
      stroke='#2C2C2C'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default FileError;
