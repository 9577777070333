// extracted by mini-css-extract-plugin
export var container = "BookableExtraCollapse-module--container--1E17q";
export var upper = "BookableExtraCollapse-module--upper--1r--6";
export var checkBox = "BookableExtraCollapse-module--checkBox--3959a";
export var text = "BookableExtraCollapse-module--text--1Eyqr";
export var arrow = "BookableExtraCollapse-module--arrow--mLL4l";
export var open = "BookableExtraCollapse-module--open--2GbwI";
export var contentContainer = "BookableExtraCollapse-module--contentContainer--P3T6d";
export var closed = "BookableExtraCollapse-module--closed--Mniq3";
export var content = "BookableExtraCollapse-module--content--15Xw2";
export var error = "BookableExtraCollapse-module--error--FOAoS";