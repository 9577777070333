import React from 'react';
import cx from 'classnames';

import * as styles from './FaqList.module.scss';
import { IFaqItem } from '../../interfaces';
import { colors } from '../../constants';
import { TextParser } from '../index';

interface IProps {
  faq: IFaqItem[];
}

interface IState {
  open: { [id: string]: boolean };
  heights: { [id: string]: number };
}

class FaqList extends React.Component<IProps, IState> {
  readonly state: IState = { open: {}, heights: {} };

  answers: { [id: string]: HTMLDivElement | null } = {};

  componentDidMount() {
    const open: { [id: string]: boolean } = {};
    this.props.faq.forEach((faq) => {
      open[faq.id] = false;
    });
    this.setState({ open });

    this.updateAnswersHeight();
    window.addEventListener('resize', this.updateAnswersHeight);
  }

  componentDidUpdate() {
    this.updateAnswersHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateAnswersHeight);
  }

  render() {
    return (
      <React.Fragment>{this.props.faq.map(this.renderFaqItem)}</React.Fragment>
    );
  }

  renderFaqItem = (faq: IFaqItem) => {
    const open = this.state.open[faq.id];
    const height = this.state.heights[faq.id];
    return (
      <div className={cx(styles.item, { [styles.open]: open })} key={faq.id}>
        <button
          className={styles.question}
          onClick={() => this.toggleItem(faq.id)}
        >
          <strong style={{ color: open ? colors.coralRed : colors.darkBlue }}>
            {faq.question}
          </strong>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            id='ic_arrow_drop_down_48px'
            width='15'
            height='7.5'
            className={styles.arrow}
            viewBox='0 0 15 7.5'
          >
            <path
              id='Path_20'
              d='M14 20l7.5 7.5L29 20z'
              className='cls-1'
              data-name='Path 20'
              transform='translate(-14 -20)'
            />
          </svg>
        </button>
        <div
          className={styles.answer}
          style={{ height: open && height ? height : 0 }}
        >
          <div ref={(elem) => (this.answers[faq.id] = elem)}>
            <TextParser source={faq.answer.answer} />
          </div>
        </div>
      </div>
    );
  };

  toggleItem = (id: string) => {
    const { open } = this.state;
    open[id] = !open[id];
    this.setState({ open });
  };

  updateAnswersHeight = () => {
    Object.keys(this.answers).forEach((id) => {
      const answer = this.answers[id];
      if (answer) {
        const height = answer.clientHeight;
        const { heights } = this.state;
        if (heights[id] !== height) {
          heights[id] = height;
          this.setState({ heights });
        }
      }
    });
  };
}

export default FaqList;
