import axios, { AxiosInstance } from 'axios';
import {
  ICart,
  ICartCheckout,
  ICartObject,
  IQuestions,
  IBooking,
  ICardInfo,
  IConfirmBooking,
  ITransportCart,
  IBookingError,
  IThreeDSecureData,
  IPromoError
} from '../interfaces/index';
import { handleAxiosError } from '../utils';

interface ICartApi {
  addToCart(
    currency: string,
    cartId: string | null,
    cartObject: ICartObject | ITransportCart,
    lang: string
  ): Promise<ICart | null>;

  getCart(
    currency: string,
    cartId: string,
    lang: string
  ): Promise<ICart | null>;

  getCartByCheckout(
    currency: string,
    cartId: string,
    lang: string
  ): Promise<ICartCheckout | null>;

  getCheckoutQuestions(cartId: string): Promise<IQuestions | null>;
  removeFromCart(
    cartId: string,
    cartItemId: number,
    currency: string,
    bookingType: string | null,
    lang: string
  ): Promise<ICart | null>;
  checkoutCart(
    booking: IConfirmBooking,
    currency: string,
    lang: string
  ): Promise<IBooking | null>;
  confirmCheckout(
    bookingNumber: string,
    signUpForEmails: boolean,
    currency: string,
    cardInfo: ICardInfo | null,
    lang: string
  ): Promise<IBooking | IBookingError | null>;

  bookCart(
    booking: IConfirmBooking,
    currency: string,
    lang: string
  ): Promise<IBooking | IBookingError | null>;

  applyCouponCode(
    cartId: string,
    promoCode: string,
    currency: string,
    lang: string
  ): Promise<[ICart | null, IPromoError | null]>;

  applyGiftCard(
    cartId: string,
    giftCardCode: string,
    currency: string,
    lang: string
  ): Promise<[ICart | null, IPromoError | null]>;

  getLocalizationData(): Promise<ICart | null>;
}

class CartApi implements ICartApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async addToCart(
    currency: string,
    cartId: string | null,
    cartObject: ICartObject | ITransportCart,
    lang: string
  ): Promise<ICart | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/AddToCart',
        JSON.stringify(cartObject),
        {
          params: {
            cartId,
            currency,
            lang
          }
        }
      );

      return result.data;
    } catch (e:any) {
      handleAxiosError(e);
      return null;
    }
  }

  async getCart(
    cartId: string,
    currency: string,
    lang: string
  ): Promise<ICart | null> {
    try {
      const result = await this.httpClient.get('/api/Cart/GetCart', {
        params: {
          cartId,
          currency,
          lang
        }
      });
      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }

  async getCartByCheckout(
    cartId: string,
    currency: string,
    lang: string
  ): Promise<ICartCheckout | null> {
    try {
      const result = await this.httpClient.get('/api/Cart/GetCartByCheckout', {
        params: {
          cartId,
          currency,
          lang
        }
      });
      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }

  async removeFromCart(
    cartId: string,
    cartItemId: number,
    currency: string,
    bookingType: string | null,
    lang: string
  ): Promise<ICart | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/RemoveFromCart',
        {},
        {
          params: {
            cartId,
            cartItemId,
            currency,
            bookingType,
            lang
          }
        }
      );

      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }

  async getCheckoutQuestions(cartId: string): Promise<IQuestions | null> {
    try {
      const result = await this.httpClient.get(
        '/api/Cart/GetCheckoutQuestions',
        { params: { cartId } }
      );
      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }

  async checkoutCart(
    booking: IConfirmBooking,
    currency: string,
    lang: string
  ): Promise<IBooking | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/CheckoutCart3ds',
        booking,
        {
          params: {
            currency,
            lang
          }
        }
      );
      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }
  // temp solution to error translation
  async confirmCheckout(
    bookingNumber: string,
    signUpForEmails: boolean,
    currency: string,
    cardInfo: ICardInfo | null,
    lang: string
  ): Promise<IBooking | IBookingError | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/ConfirmCheckout3ds',
        cardInfo,
        {
          params: {
            bookingNumber,
            signUpForEmails,
            currency,
            lang
          }
        }
      );
      return result.data;
    } catch (e: any) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e: any) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }

  async get3dSecureData(
    booking: IConfirmBooking,
    bookingNumber: string,
    currency: string,
    externalPartnerNumber: string
  ): Promise<IThreeDSecureData | null> {
    try {
      const result = await this.httpClient.post('/api/Cart/Get3DSecureData', booking, { params: { bookingNumber, currency, externalPartnerNumber } });
      return result.data;
    } catch (e) {
      return null;
    }
  }

  async shouldUse3dSecure(): Promise<boolean> {
    try {
      const result = await this.httpClient.get('/api/Cart/ShouldUse3dSecure');
      return result.data;
    } catch (e: any) {
      return e;
    }
  }

  async moveBackToCart(
    cartId: string,
    bookingNumber: string,
    currency: string
  ): Promise<ICart | null> {
    const body = { cartId, bookingNumber, currency };
    try {
      const result = await this.httpClient.post(
        '/api/Cart/MoveBackToCart',
        body,
        {
          params: { cartId, bookingNumber, currency }
        }
      );
      return result.data;
    } catch (e: any) {
      return e;
    }
  }
  async bookCart(
    booking: IConfirmBooking,
    currency: string,
    lang: string
  ): Promise<IBooking | IBookingError | null> {
    try {
      const result = await this.httpClient.post('/api/Cart/BookCart', booking, {
        params: { currency, lang }
      });
      return result.data;
    } catch (e: any) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e: any) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }

  async applyCouponCode(
    cartId: string,
    promoCode: string,
    currency: string,
    lang: string
  ): Promise<[ICart | null, IPromoError | null]> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/ApplyPromoCode',
        {},
        { params: { cartId, promoCode, currency, lang } }
      );
      return [result.data, null];
    } catch (e: any) {
      return [null, e.response.data];
    }
  }

  async applyGiftCard(
    cartId: string,
    giftCardCode: string,
    currency: string,
    lang: string
  ): Promise<[ICart | null, IPromoError | null]> {
    try {
      const result = await this.httpClient.post(
        '/api/Cart/ApplyGiftCard',
        {},
        { params: { cartId, giftCardCode, currency, lang } }
      );
      return [result.data, null];
    } catch (e: any) {
      return [null, e.response.data];
    }
  }

  async getLocalizationData(): Promise<any | null> {
    try {
      const result = await this.httpClient.get('https://ipapi.co/json');
      return result.data;
    } catch (e: any) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default CartApi;
