import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
  IReducers,
  ITranslationsExtras,
} from '../../interfaces';
import { BookingDropdown } from '../../BookingEngine/components';
import { Calendar } from '../../icons';
import { formatDate } from '../../utils';
import moment from 'moment';
import {
  DayPickerSingleDateController,
  isInclusivelyBeforeDay,
} from 'react-dates';
import * as styles from './Questions.module.scss';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  locale: string;
  changeVal(val: string): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsExtras;
}

function stableDummy() {
  return undefined;
}

const DateQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion, localeData, changeVal } = props;
  if (bookingQuestion) {
    return (
      <div>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}
        <p
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </p>
        <BookingDropdown
          Icon={{ icon: Calendar, alt: 'Calendar' }}
          placeHolder={
            !!bookingQuestion && !!bookingQuestion.value
              ? formatDate(moment(bookingQuestion.value))
              : localeData.extrasDateQuestionPlaceholder
          }
          isValid={bookingQuestion.isValid}
          backgroundColor={true}
        >
          <DayPickerSingleDateController
            initialVisibleMonth={null}
            key={bookingQuestion.Id}
            date={
              !!bookingQuestion && !!bookingQuestion.value
                ? moment(bookingQuestion.value)
                : null
            }
            focused={true}
            onFocusChange={stableDummy}
            onDateChange={(e) =>
              !!bookingQuestion && !!bookingQuestion.Id && !!e
                ? changeVal(e.format('YYYY-MM-DD'))
                : null
            }
            isOutsideRange={(day) =>
              isInclusivelyBeforeDay(day, moment(new Date()).subtract(1, 'day'))
            }
            hideKeyboardShortcutsPanel={true}
          />
        </BookingDropdown>
      </div>
    );
  } else if (contactQuestion) {
    return (
      <div>
        <p
          className={cx(styles.label, {
            [styles.required]: contactQuestion.Required,
          })}
        >
          {contactQuestion.Label}
        </p>
        <BookingDropdown
          Icon={{ icon: Calendar, alt: 'Calendar' }}
          placeHolder={
            !!bookingQuestion && !!contactQuestion.value
              ? formatDate(moment(contactQuestion.value))
              : localeData.extrasDateQuestionPlaceholder
          }
          isValid={contactQuestion.isValid}
        >
          <DayPickerSingleDateController
            initialVisibleMonth={null}
            key={contactQuestion.Id}
            date={
              !!contactQuestion && !!contactQuestion.value
                ? moment(contactQuestion.value)
                : null
            }
            focused={true}
            onFocusChange={stableDummy}
            onDateChange={(e) =>
              !!contactQuestion && !!contactQuestion.Id && !!e
                ? changeVal(e.format('YYYY-MM-DD'))
                : null
            }
            isOutsideRange={(day) =>
              isInclusivelyBeforeDay(day, moment(new Date()).subtract(1, 'day'))
            }
            hideKeyboardShortcutsPanel={true}
          />
        </BookingDropdown>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({ bookingQuestion, contactQuestion, locale, changeVal }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query DateQuestion {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                extrasDateQuestionPlaceholder
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <DateQuestion
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            bookingQuestion={bookingQuestion}
            contactQuestion={contactQuestion}
            locale={locale}
            changeVal={changeVal}
          />
        );
      }}
    />
  )
);
