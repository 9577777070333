import { SET_CART, RESET_CART } from '../actions/types';
import { ICart } from '../../interfaces';

interface IState {
  cartObject: ICart | null;
  sagaNumber: number | null
}

interface IAction {
  type: string;
  payload: ICart | null;
  sagaNumberPayload: number | null;
}

const initialState: IState = {
  cartObject: null,
  sagaNumber: null
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_CART:
      return { cartObject: action.payload };
    case RESET_CART:
      return { cartObject: null };
    default:
      return state;
  }
};
