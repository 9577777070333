import React from 'react';

export const FilterSearch = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26.766' height='26.766'>
    <path
      fill='#fa7268'
      id='FilterSearch'
      d='M18.9,17.2l-4.8-4.8c2.6-3.5,1.8-8.4-1.7-11S4-0.3,1.4,3.2s-1.8,8.4,1.7,11c2.8,2,6.5,2,9.3,0
		l4.8,4.8L18.9,17.2z M1.2,7.8c0-3.6,3-6.6,6.6-6.6s6.6,3,6.6,6.6s-3,6.6-6.6,6.6C4.2,14.4,1.2,11.5,1.2,7.8z'
    />
  </svg>
);

export default FilterSearch;
