import React, { useState, useRef } from 'react';
import PrivateTourModal from '../PrivateTourModal/PrivateTourModal';
import { ITourDetails } from '../../../interfaces/tours';
import * as styles from './PrivateEngine.module.scss';

interface IProps {
  tour: ITourDetails;
}

const PrivateEngine: React.FunctionComponent<IProps> = (props) => {
  const [modalOpen, toggleModal] = useState(false);
  const [emailSent, toggleEmailSent] = useState(false);
  const { tour } = props;

  function setToggleModal(value: boolean) {
    toggleModal(value);
  }

  function setToggleEmailSent(value: boolean) {
    toggleEmailSent(value);
  }

  const button = useRef<HTMLButtonElement | null>(null);

  return (
    <React.Fragment>
      <div>
        <div className={styles.bookingText}>
          <p>
            {emailSent
              ? tour.specialTourBookingBoxText.after
              : tour.specialTourBookingBoxText.before}
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          setToggleModal(!modalOpen);
          setToggleEmailSent(false);
        }}
        className={styles.bookingButton}
        ref={button}
        aria-label='Open Modal'
      >
        {tour.specialTourButtons.specialTourBookingBoxButton}
      </button>
      <PrivateTourModal // TODO 'a eftir ad breyta nafn!! thetta er modal!
        form={tour.specialTourForm}
        tourName={tour.title}
        modalOpen={modalOpen}
        toggleModal={setToggleModal}
        setEmailStatus={setToggleEmailSent}
        modalButton={tour.specialTourButtons.specialTourModalConfirmButton}
      />
    </React.Fragment>
  );
};

export default PrivateEngine;
