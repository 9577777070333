import { CHANGE_CURRENCY } from './types';
import { ICurrencyOption } from '../../interfaces';

export const changeCurrency = (
  newCurrency: ICurrencyOption,
  cartCount: number
) => {
  return {
    type: CHANGE_CURRENCY,
    payload: { currencyPayload: newCurrency, cartCount }
  };
};
