import React from 'react';
import * as styles from './index.module.scss';
import cx from 'classnames';
import { Moment } from 'moment';
import {
  ISelectValue,
  IProduct,
  ITranslationsBookingBox,
  IReducers,
} from '../../../interfaces';
import TourBookingDateDropdown from '../TourBookingDateDropdown';
import TourBookingGuestDropdown from '../TourBookingGuestDropDown';
import TourBookingButton from '../TourBookingButton';
import TourBookingFreeNoFees from '../TourBookingFreeNoFees';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  modalOpen: boolean;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  product: IProduct | null;
  isValid: boolean;
  addingToCart: boolean;
  locale: string;

  toggleModal(value: boolean): void;
  getTotalPersonCount(): number;
  getMinToBook(): number;
  triggerValid(value: boolean): void;
  addToCart(): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingBox; // TODO
}

const TourBookingBox: React.FunctionComponent<IProps> = (props) => {
  const {
    modalOpen,
    toggleModal,
    selectedDate,
    selectedTime,
    product,
    isValid,
    getTotalPersonCount,
    getMinToBook,
    triggerValid,
    addToCart,
    addingToCart,
    localeData,
  } = props;

  return (
    <div className={cx('column level', styles.container)}>
      {product?.BookingType === 'PASS' ? null : (
        <div className='column'>
          <TourBookingDateDropdown
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            product={product}
            isValid={isValid}
            text={localeData}
          />
        </div>
      )}
      <div className='column'>
        <TourBookingGuestDropdown
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          getTotalPersonCount={getTotalPersonCount}
          getMinToBook={getMinToBook}
          isValid={isValid}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          triggerValid={triggerValid}
          text={
            localeData
            /* {
              tourBookingBoxNumberOfGuests:
                localeData.tourBookingBoxNumberOfGuests,
              tourBookingBoxNumberOfGuestsPlaceholders:
                localeData.tourBookingBoxNumberOfGuestsPlaceholders
            } as ITranslationsTourBokingGuestDropDown */
          }
        />
      </div>
      <TourBookingFreeNoFees text={localeData} />
      <div>
        <TourBookingButton
          modalOpen={modalOpen}
          getTotalPersonCount={getTotalPersonCount}
          addToCart={addToCart}
          addingToCart={addingToCart}
          toggleModal={toggleModal}
          text={localeData.tourBookingBoxMainButton}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    modalOpen,
    toggleModal,
    selectedDate,
    selectedTime,
    product,
    isValid,
    getTotalPersonCount,
    getMinToBook,
    triggerValid,
    addToCart,
    addingToCart,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourBookingBox {
          allContentfulTranslationsPage {
            edges {
              node {
                ...TranslationPageBookingBox
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TourBookingBox
            localeData={
              data.allContentfulTranslationsPage.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            product={product}
            isValid={isValid}
            getTotalPersonCount={getTotalPersonCount}
            getMinToBook={getMinToBook}
            triggerValid={triggerValid}
            addToCart={addToCart}
            addingToCart={addingToCart}
            locale={locale}
          />
        );
      }}
    />
  )
);
