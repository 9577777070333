import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
} from '../../interfaces';
import { CheckBox } from '../';
import * as styles from './Questions.module.scss';

interface IProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  changeVal(val: string | null): void;
}

const BooleanQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion } = props;
  if (bookingQuestion) {
    return (
      <div>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}

        <p
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </p>
        <CheckBox
          isError={!bookingQuestion.isValid}
          checked={
            !!bookingQuestion &&
            !!bookingQuestion.value &&
            bookingQuestion.value === 'yes'
          }
          toggleCheckbox={(e) =>
            !!e && !!bookingQuestion && !!bookingQuestion.Id
              ? props.changeVal(e ? 'yes' : null)
              : null
          }
        >
          Yes
        </CheckBox>

        <CheckBox
          isError={!bookingQuestion.isValid}
          checked={
            !!bookingQuestion &&
            !!bookingQuestion.value &&
            bookingQuestion.value === 'no'
          }
          toggleCheckbox={(e) =>
            !!e && !!bookingQuestion && !!bookingQuestion.Id
              ? props.changeVal(e ? 'no' : null)
              : null
          }
        >
          No
        </CheckBox>
      </div>
    );
  } else if (contactQuestion) {
    return (
      <div>
        <p
          className={cx(styles.label, {
            [styles.required]: contactQuestion.Required,
          })}
        >
          {contactQuestion.Label}
        </p>
        <CheckBox
          isError={!contactQuestion.isValid}
          checked={
            !!contactQuestion &&
            !!contactQuestion.value &&
            contactQuestion.value === 'yes'
          }
          toggleCheckbox={(e) =>
            !!e && !!contactQuestion && !!contactQuestion.Id
              ? props.changeVal(e ? 'yes' : null)
              : null
          }
        >
          No
        </CheckBox>
        <CheckBox
          isError={!contactQuestion.isValid}
          checked={
            !!contactQuestion &&
            !!contactQuestion.value &&
            contactQuestion.value === 'no'
          }
          toggleCheckbox={(e) =>
            !!e && !!contactQuestion && !!contactQuestion.Id
              ? props.changeVal(e ? 'no' : null)
              : null
          }
        >
          No
        </CheckBox>
      </div>
    );
  }
  return null;
};

export default BooleanQuestion;
