import React from 'react';

import { ISupportCard } from '../../interfaces';
import Icon from '../Icon';
import PhosphorIcon from '../PhosphorIcon';
import * as styles from './index.module.scss';

interface IProps {
  cards: ISupportCard[];
}

const SupportCards = ({ cards }: IProps) => {
  function getLink(card: ISupportCard) {
    let link = '';
    if (card.page) {
      if ('externalLink' in card.page) {
        if (card.page.externalLink) {
          link = card.page.externalLink;
        }
      }
      if ('slug' in card.page) {
        if (card.page.slug) {
          link = '/' + card.page.slug;
        }
      }
    } else {
      link = '#';
    }
    return link;
  }
  return (
    <>
      {cards.map((card, i) => (
        <div className={styles.item} key={i + '-' + card.iconName}>
          <div className={styles.container}>
            <a href={getLink(card)} className={styles.card}>
              <div className={styles.inner}>
                <div className={styles.icon_container}>
                  {card.iconName ? (
                    <Icon
                      name={
                        card.iconName ? card.iconName : 'ANNOUNCMENTWARNING'
                      }
                    />
                  ) : (
                    <>
                      <PhosphorIcon
                        name={card.lucideIcon ? card.lucideIcon : 'Bus'}
                        weight='thin'
                      />
                    </>
                  )}
                </div>
                <div className={styles.content}>
                  <h3>{card.text}</h3>
                  {card.description && <p>{card.description}</p>}
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default SupportCards;
