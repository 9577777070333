import React from 'react';

export const Wifi = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 28c-1.8846 0-3.5 1.575-3.5 3.5S18.0256 35 20 35s3.5-1.575 3.5-3.5c0-2.0125-1.6154-3.5-3.5-3.5zM19.9999 20.1961c-3.2092 0-6.1175 1.5006-8.2235 4.073-.1003.2144-.1003.4288 0 .6431l1.6046 1.8222c.2005.2144.5014.2144.6017 0 1.5043-1.9293 3.6103-3.0012 5.9169-3.0012s4.4126 1.0719 5.9169 3.0012c.2006.2144.5014.2144.6017 0l1.6046-1.8222c.2006-.2143.2006-.4287 0-.6431-1.9054-2.5724-4.914-4.073-8.0229-4.073z'
      fill='#FA7268'
    />
    <path
      d='M30.9616 20.8515c-.1955.198-.4886.198-.5864 0-2.7361-3.0687-6.4495-4.7515-10.456-4.7515-4.0066 0-7.8176 1.6828-10.45609 4.7515-.19544.198-.39088.198-.58632 0l-1.56352-1.6828c-.19544-.198-.19544-.396 0-.5939 3.32253-3.6627 7.91533-5.7415 12.70363-5.7415 4.7883 0 9.3811 2.0788 12.7036 5.7415.1955.1979.0977.3959 0 .5939l-1.7589 1.6828z'
      fill='#FA7268'
    />
    <path
      d='M35.745 16.1782c-.2006.2069-.4012.2069-.6017 0C31.2321 11.7299 25.7163 9.24714 20 9.24714S8.86819 11.7299 4.85673 16.1782c-.20057.2069-.40114.2069-.60172 0l-1.60458-1.7586c-.20057-.2069-.20057-.4138 0-.6207C7.16332 8.7299 13.4814 5.83334 20 5.83334c6.5186 0 12.8367 2.89656 17.3496 7.96556.2006.2069.2006.4138 0 .6207l-1.6046 1.7586z'
      fill='#FA7268'
    />
  </svg>
);

export default Wifi;
