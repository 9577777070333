import { CHANGE_LOCALE, GET_LOCALE, GET_LANG } from './types';

export const changeLocale = (locale: string, lang: string) => {
  //const lang = newLocale === 'is' ? 'IS' : 'EN';
  //const locale = newLocale == 'is' ? 'is-IS' : 'en-US';

  return {
    type: CHANGE_LOCALE,
    payload: { locale: locale, lang: lang }
  };
};

export const getLocale = () => {
  return {
    type: GET_LOCALE
    //paylaod: loc
  };
};

export const getLangString = () => {
  return {
    type: GET_LANG
    // paylaod: { translationPayload: null } // TODO
  };
};
