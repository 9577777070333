import React from 'react';
import { IPersonCounter } from '../../../interfaces';
import Icon from '../../../components/Icon';
import { formatHardcodedStringWithVariables } from '../../../utils';

interface IProps {
  personCounter: IPersonCounter;
  text: string;
}

interface ICount {
  maxPersons: number;
  personType: string;
  master: string;
}

/**
 * for contentful
 * index one (0) shoudl be maxPersons
 * index two (1) should be personType
 * index three (2) should be master
 * send in the string array with the words in this order
 *
 */

const MaxPerMasterText: React.FunctionComponent<IProps> = ({
  personCounter,
  text
}) => {
  const getMaxPerMasterText = (personType: string) => {
    if (personCounter) {
      const newPersonCounter = personCounter;
      let counter = 0;
      const realCounter: Array<{
        master: string;
        maxPersons: number;
        personType: string;
      }> = [];
      Object.keys(personCounter).map(key => {
        if (
          personCounter[key].Id ===
          newPersonCounter[personType].masterCategoryId
        ) {
          counter =
            personCounter[key].count *
            newPersonCounter[personType].maxPerMaster;
          if (counter > 0) {
            const maxPersons = personCounter[personType].maxPerMaster;
            realCounter.push({
              master: key,
              maxPersons,
              personType
            });
          }
        }
      });

      return (
        <ul key={personType}>
          {realCounter.map((count: ICount) => {
            const formattedString = formatHardcodedStringWithVariables(text, [
              count.maxPersons.toString(),
              count.personType,
              count.master
            ]);
            return (
              <li key={personType}>
                <Icon name='SQUARE' />{' '}
                {
                  /*'Max ' +
                  count.maxPersons +
                  ' ' +
                  count.personType +
                  ' per ' +
                  count.master */ formattedString
                }
              </li>
            );
          })}
        </ul>
      );
    }
    return null;
  };

  return (
    <div>
      {Object.keys(personCounter).map(personType => {
        return getMaxPerMasterText(personType);
      })}
    </div>
  );
};

export default MaxPerMasterText;
