import React from 'react';
import 'react-dates/initialize';
import './styles//datePicker.css';
import moment, { Moment } from 'moment';

import {
  ISortedAvailability,
  IBookingCutoff,
  ISelectValue,
  IProduct // added as temp fix
} from '../../interfaces';
import { DayPickerSingleDateController } from 'react-dates';

interface IProps {
  product: IProduct | null; // added as temp fix
  availability: ISortedAvailability | null;
  selectedTime: ISelectValue | null;
  selectedDate: Moment | null;
  focus: boolean;
  bookingCutoff: IBookingCutoff | null;
  defaultMonth: Moment | null;
  loadingAvailability(loading: boolean): void;
  resetSelectedTimeAndObject(): void;
  onChangeDate(date: Moment | null): void;
  onFocusChange(focus: boolean | null): void;
  getAvailability(fromDate: Date, monthsToAdd: number): void;
  isDayBlocked(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  isDayDisabled(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
}

const TourDatePicker: React.FunctionComponent<IProps> = props => {
  const {
    availability,
    selectedTime,
    selectedDate,
    focus,
    resetSelectedTimeAndObject,
    onChangeDate,
    getAvailability,
    bookingCutoff,
    isDayBlocked,
    isDayDisabled,
    defaultMonth,
    loadingAvailability
  } = props;

  const onNextMonthClicked = (date: any) => {
    loadingAvailability(true);
    getAvailability(moment(date).toDate(), 1);
  };

  const onPrevMonthClicked = (date: any) => {
    loadingAvailability(true);
    getAvailability(moment(date).toDate(), 0);
  };

  return (
    <DayPickerSingleDateController
      key={
        defaultMonth
          ? moment(defaultMonth)
              .format()
              .toString()
          : ''
      }
      // eslint-disable-next-line no-empty-pattern
      onFocusChange={({}) => {
        //
      }}
      date={selectedDate}
      onDateChange={date => {
        if (
          !!availability &&
          !!selectedTime &&
          !!date &&
          !availability[moment(date).format('LL')][selectedTime.value]
        ) {
          resetSelectedTimeAndObject();
        }
        onChangeDate(date);
      }}
      onNextMonthClick={date => onNextMonthClicked(date)}
      onPrevMonthClick={date => onPrevMonthClicked(date)}
      focused={focus}
      numberOfMonths={1}
      isDayBlocked={day => isDayBlocked(day, availability, bookingCutoff)}
      isOutsideRange={day => isDayDisabled(day, availability, bookingCutoff)}
      hideKeyboardShortcutsPanel={true}
      initialVisibleMonth={
        () => (selectedDate ? selectedDate : moment(new Date()))
        /*!!defaultMonth ? defaultMonth : moment(new Date())*/
      }
    />
  );
};

export default TourDatePicker;
