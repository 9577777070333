// extracted by mini-css-extract-plugin
export var headerMobile = "index-module--headerMobile--27fkC";
export var headerMobileWithAnnouncement = "index-module--headerMobileWithAnnouncement--3jrXS";
export var upperMobile = "index-module--upperMobile--2IGpp";
export var searchContainer = "index-module--searchContainer--27pVl";
export var search = "index-module--search--1U_qV";
export var exit = "index-module--exit--1KR_n";
export var burger = "index-module--burger--1pQh3";
export var lowerMobile = "index-module--lowerMobile--2YzC3";
export var logoMobile = "index-module--logoMobile--2mJim";
export var searchInput = "index-module--searchInput--2TF1L";