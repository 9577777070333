import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Title, ProductCard } from '../../components';
import {
  ITourItem,
  IPageContext,
  IReducers,
  ICurrencyOption,
} from '../../interfaces';
import * as styles from './index.module.scss';
import { ProductApi, TransportApi } from '../../api';

interface IDefaultPrices {
  defaultPrice: string | null;
  discountPrice: string | null;
}

interface IProps {
  recommendedTours?: ITourItem[];
  localeData: {
    node_locale: string;
    recommendedToursTitle: string;
    seeMoreButton: string;
  };
  pageContext: IPageContext;
  locale: string;
  currency: ICurrencyOption;
}

interface ITourState {
  id: string;
  defaultPrice: string | null;
  discountPrice: string | null;
}

const RecommendedTours: React.FunctionComponent<IProps> = ({
  recommendedTours,
  localeData,
  pageContext,
  locale,
  currency,
}) => {
  const [tourPrice, setTourPrice] = useState<ITourState[]>([]);
  const [transportPrice, setTransportPrice] = useState<ITourState[]>([]);

  const getDefaultPrice = (productId: string) => {
    let ret: IDefaultPrices | null = null;
    if (tourPrice) {
      tourPrice.forEach((item) => {
        if (item.id === productId.toString()) {
          ret = {
            defaultPrice: item.defaultPrice,
            discountPrice: item.discountPrice,
          };
        }
      });
    }
    if (transportPrice) {
      transportPrice.forEach((item) => {
        if (item.id === productId.toString()) {
          ret = {
            defaultPrice: item.defaultPrice,
            discountPrice: item.discountPrice,
          };
        }
      });
    }
    return ret;
  };

  useEffect(() => {
    async function fetchTourData() {
      const productApi = new ProductApi();
      if (recommendedTours) {
        const pIds: string[] = [];
        recommendedTours.forEach((item) => {
          pIds.push(item.productId.toString());
        });
        const response = await productApi.getDefaultPrices(
          pIds,
          currency.value,
          locale
        );
        if (response) {
          const transportPriceObj: ITourState[] = [];
          response.forEach((item) => {
            transportPriceObj.push({
              id: item.Id.toString(),
              defaultPrice: item.DefaultPrice.toString(),
              discountPrice: item.DiscountPrice
                ? item.DiscountPrice.toString()
                : null,
            });
          });
          setTourPrice(transportPriceObj);
        }
      }
    }
    async function fetchTransportData() {
      const transportApi = new TransportApi();
      if (recommendedTours) {
        const pIds: string[] = [];
        recommendedTours.forEach((item) => {
          pIds.push(item.productId.toString());
        });
        const response = await transportApi.getDefaultPrices(
          pIds,
          currency.value,
          locale
        );
        if (response) {
          const tourPriceObj: ITourState[] = [];
          response.forEach((item) => {
            tourPriceObj.push({
              id: item.Id.toString(),
              defaultPrice: item.DefaultPrice
                ? item.DefaultPrice.toString()
                : null,
              discountPrice: item.DiscountPrice
                ? item.DiscountPrice.toString()
                : null,
            });
          });
          setTransportPrice(tourPriceObj);
        }
      }
    }
    if (recommendedTours && locale && currency) {
      fetchTourData();
      fetchTransportData();
    }
  }, [recommendedTours, locale, currency]);

  if (recommendedTours) {
    return (
      <div className='column is-6-desktop is-offset-1'>
        <Title tag='h2' theme={['sizeMobileLarge', 'sizeDesktopLarge']}>
          {localeData.recommendedToursTitle}
        </Title>
        <div className={cx('columns is-multiline', styles.productList)}>
          {recommendedTours.slice(0, 4).map((tour) => (
            <div key={tour.id} className='column is-12-tablet is-half-desktop'>
              <ProductCard
                tour={tour}
                defaultPrices={getDefaultPrice(tour.productId)}
                loadingPrices={false}
                currency='ISK'
                langPath={pageContext.langPath}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  const { currency } = reducers.currencyReducer;
  return { locale, currency };
};

export default connect(mapStateToProps)(RecommendedTours);
