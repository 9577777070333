import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Drawer from 'rc-drawer';

import * as styles from './TourHeroPrivate.module.scss';
import {
  ITourDetails,
  IProduct,
  IReducers,
  ICurrencyOption,
  ICart,
  ITourCategory,
  ITranslationsTransportTourInfo,
} from '../../interfaces';
import {
  star,
  tourInfoDuration,
  tourInfoLanguage,
  tourInfoSeason,
  tourTag,
  exitButton,
  dropdownArrow,
  dropdownArrowUp,
  tourInfoAge,
} from '../../icons';
import { Image, Desktop, Mobile, Title } from '../index';
import { formatPrice } from '../../utils';
import 'rc-drawer/assets/index.css';
import TourEngine from '../../BookingEngine/TourEngine';
import TourBookNav from '../TourBookNav/TourBookNav';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';
import { StaticQuery, graphql } from 'gatsby';
import TourBookingButtonPrivate from '../../../src/BookingEngine/components/TourBookingButtonPrivate';

interface IInfoItem {
  title: string;
  value: string | undefined;
  icon: string;
  iconHeight: number;
  mobile: boolean;
  loading?: boolean;
  defaultVal?: string;
  languageMobileItem?: boolean;
  overflow?: boolean | false;
}

interface IExternalProps {
  tour: ITourDetails;
  productId: string;
  product: IProduct | null;
  productLoaded: boolean;
  currency: ICurrencyOption;
  cartObject: ICart | null;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsTransportTourInfo;
  translationButtonText: string;
}

interface IState {
  open: boolean;
  iconsMobileShow: boolean;
  buttonPosition: string;
  buttonPositionBottom: string;
}

class TourHeroPrivate extends React.Component<
  IProps & IWindowSizeProps,
  IState
> {
  readonly state = {
    open: false,
    iconsMobileShow: true,
    buttonPosition: 'relative',
    buttonPositionBottom: '0px',
  };
  constructor(props: Readonly<IProps & IWindowSizeProps>) {
    super(props);

    //this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    //window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    //window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      !!prevProps.cartObject &&
      !!prevProps.cartObject.Items &&
      !!this.props.cartObject &&
      !!this.props.cartObject.Items &&
      prevProps.cartObject.Items.length < this.props.cartObject.Items.length
    ) {
      this.setState({ open: false });
    }

    if (
      !!prevProps.cartObject &&
      !prevProps.cartObject.Items &&
      !!this.props.cartObject &&
      !!this.props.cartObject.Items &&
      this.props.cartObject.Items.length === 1
    ) {
      this.setState({ open: false });
    }
  }

  handleScroll() {
    const footer = document.getElementsByTagName('footer');
    // NOTE footer is undefined if tour is missing or sold out which causes this to break.
    if (footer && footer.length > 0) {
      const footerStarts = footer[0].offsetTop - window.innerHeight;
      // If button is in the same position as the footer we stick it above the footer
      if (window.scrollY >= footerStarts) {
        if (this.state.buttonPosition !== 'absolute') {
          this.setState({ buttonPosition: 'absolute' });
          this.setState({ buttonPositionBottom: '-60px' });
        }
      } else {
        if (this.state.buttonPosition !== 'fixed') {
          this.setState({ buttonPosition: 'fixed' });
          this.setState({ buttonPositionBottom: '24px' });
        }
      }
    }
  }

  renderDrawer = () => {
    const { productId, product, tour } = this.props;

    return (
      <Drawer
        height='100%'
        width='100%'
        open={this.state.open}
        placement='right'
        handler={false}
      >
        <div>
          <img
            src={exitButton}
            alt='exit'
            className={styles.exit}
            onClick={() => this.setState({ open: false })}
          />
          <div
            className={cx('centered-content', styles.titleContainerAddToCart)}
          >
            <h1 style={{ marginBottom: 5 }}>{tour.title}</h1>
          </div>

          <div>
            <TourEngine
              productId={productId}
              tourTitle={tour.title}
              product={product}
              isMobile={true}
              tour={tour}
            />
          </div>
        </div>
      </Drawer>
    );
  };

  render() {
    const {
      productId,
      product,
      productLoaded,
      tour,
      localeData,
      translationButtonText,
    } = this.props;

    return (
      <div>
        <Desktop>
          <div className={cx(styles.hero, { [styles.hideNav]: true })}>
            {tour.heroImage && (
              // The image is not cropped before fetching,that's why we set a bigger vw
              // to get the right quality
              <Image
                fluidImage={{
                  ...tour.heroImage.fluid,
                  fluid: {
                    ...(tour.heroImage ? tour.heroImage.fluid : null),
                    sizes: '60vw',
                    alt: tour.heroImage.title,
                  },
                }}
                // fluidImage={tour.heroImage}
                productPhoto={product && product.KeyPhoto}
                loadingProduct={!productLoaded}
                productPhotoSize='original'
                className={styles.image}
              />
            )}
            <div className={styles.content}>
              <div className={styles.titleContainer}>
                <p className={styles.offerBanner}>
                  {tour.offerBanner && tour.offerBanner.title}
                </p>
                <Title tag='h1' theme={['sizeDesktopLarge']}>
                  {tour.title}
                </Title>
                {tour.productCode
                  ? localeData.tourInfoTourId + ' ' + tour.productCode
                  : null}{' '}
                <div className={styles.line} />
              </div>

              <div className={styles.infoContainer}>
                {this.renderInfo(false)}
              </div>
            </div>
            <div
              style={{
                top: '525px',
                position: 'absolute',
                width: '100%',
              }}
            >
              <div className={styles.priceContainer}>{this.renderPrice()}</div>
            </div>

            <div className={styles.bookNav}>
              <TourBookNav
                tourTitle={tour.title}
                product={product}
                productId={productId}
                productLoaded={productLoaded}
                tour={tour}
              />
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div style={{ overflowY: 'hidden' }}>
            <Image
              fluidImage={tour.heroImage}
              productPhoto={product && product.KeyPhoto}
              loadingProduct={!productLoaded}
              productPhotoSize='large'
              className={styles.imageMobile}
            />
            {tour.offerBanner ? (
              <div className={styles.banner}>{tour.offerBanner.title}</div>
            ) : null}
            <div
              className={cx('centered-content', styles.titleContainerMobile)}
            >
              <Title tag='h1' theme={['sizeMobileMedium']}>
                {tour.title}
              </Title>
              {this.renderPrice()}
            </div>

            {this.renderInfo(true)}

            <div
              className={styles.addToCartButton}
              style={
                {
                  position: this.state.buttonPosition,
                  bottom: this.state.buttonPositionBottom,
                } as React.CSSProperties
              }
            >
              <TourBookingButtonPrivate
                privateUrl={tour.privateTourUrl}
                text={translationButtonText}
              />
            </div>
          </div>
          {this.renderDrawer()}
        </Mobile>
      </div>
    );
  }

  renderPrice() {
    const { currency, localeData, tour } = this.props;

    if (tour.priceFrom && currency) {
      return (
        <strong className={styles.price}>
          <span>{localeData.tourInfoPriceFrom}</span>{' '}
          {formatPrice(tour.priceFrom, currency.value)} ISK
        </strong>
      );
    }
    return null;
  }

  renderInfo(mobile: boolean) {
    const { tour, productLoaded, localeData } = this.props;
    const loading = !productLoaded;

    let duration = tour.duration;
    let difficulty = tour.difficulty;
    let language = this.parseLanguages(tour.language);
    let categories;
    const season = this.parseSeasons(tour.season);
    let minimumAge = this.formatMinAge(tour.minimumAge);

    if (tour) {
      if (!categories && tour.category) {
        categories = this.parseLanguages(this.parseCategories(tour.category));
      }
    }

    const toogleIcons = () => {
      this.setState({ iconsMobileShow: !this.state.iconsMobileShow });
    };

    const infoItems = (
      <React.Fragment>
        <div className={styles.row}>
          {this.renderInfoItem({
            title: localeData.tourInfoDuration,
            value: duration,
            icon: tourInfoDuration,
            iconHeight: mobile ? 21 : 23,
            mobile,
            loading,
          })}
          {this.renderInfoItem({
            title: localeData.tourInfoLanguage,
            value: language,
            icon: tourInfoLanguage,
            iconHeight: mobile ? 26 : 30,
            mobile,
            loading,
            defaultVal: 'English',
            languageMobileItem: mobile,
          })}
        </div>
        <div className={styles.row}>
          {this.renderInfoItem({
            title: localeData.tourInfoSeason,
            value: season,
            icon: tourInfoSeason,
            iconHeight: 22,
            mobile,
          })}

          {this.renderInfoItem({
            title: localeData.tourInfoCategory,
            value: categories,
            icon: tourTag,
            iconHeight: mobile ? 24 : 26,
            mobile,
            loading,
            defaultVal: 'None',
          })}
        </div>
        <div className={styles.row}>
          {this.renderInfoItem({
            title: localeData.tourInfoMinimumAge,
            value: minimumAge,
            icon: tourInfoAge,
            iconHeight: mobile ? 24 : 26,
            mobile,
            loading,
            defaultVal: 'None',
          })}
        </div>
        <div className={styles.row}>
          {this.renderReview()}
          <div />
        </div>
      </React.Fragment>
    );

    const infoItemsMobile = (
      <React.Fragment>
        <div className={styles.row}>
          {this.renderInfoItem({
            title: localeData.tourInfoDuration,
            value: duration,
            icon: tourInfoDuration,
            iconHeight: mobile ? 21 : 23,
            mobile,
            loading,
          })}

          {this.renderInfoItem({
            title: localeData.tourInfoSeason,
            value: season,
            icon: tourInfoSeason,
            iconHeight: 22,
            mobile,
          })}
          <img
            src={this.state.iconsMobileShow ? dropdownArrowUp : dropdownArrow}
            alt='hide and show icons' // TODO hardcoded ?
            className={styles.arrow}
            onClick={toogleIcons}
          />
        </div>
        {this.state.iconsMobileShow ? (
          <React.Fragment>
            <div className={styles.row}>
              {this.renderInfoItem({
                title: localeData.tourInfoLanguage,
                value: language,
                icon: tourInfoLanguage,
                iconHeight: mobile ? 26 : 30,
                mobile,
                loading,
                defaultVal: 'English', // TODO hardcoded ?
              })}
              {this.renderInfoItem({
                title: localeData.tourInfoCategory,
                value: categories,
                icon: tourTag,
                iconHeight: mobile ? 24 : 26,
                mobile,
                loading,
                defaultVal: 'None',
              })}
              <div className={styles.ghostMobile} />
            </div>
            <div className={styles.row}>
              {this.renderInfoItem({
                title: localeData.tourInfoMinimumAge,
                value: minimumAge,
                icon: tourInfoAge,
                iconHeight: mobile ? 24 : 26,
                mobile,
                loading,
                defaultVal: 'None',
              })}
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );

    return !mobile ? (
      <div className={styles.info}>{infoItems}</div>
    ) : (
      <div
        className={cx(
          'columns',
          'is-multiline',
          'is-variable',
          'is-2',
          'is-mobile',
          'centered-content',
          styles.itemContainer
        )}
      >
        {infoItemsMobile}
      </div>
    );
  }

  renderInfoItem = ({
    title,
    value,
    icon,
    iconHeight,
    mobile,
    loading,
    defaultVal,
    languageMobileItem,
  }: IInfoItem) => {
    const { localeData } = this.props;
    let display = value;
    let isLoading = false;
    let hide = false;

    if (!display) {
      if (loading) {
        display = localeData.tourDescriptionLoading; // TODO this might have to be a seperate content value
        isLoading = true;
      } else {
        if (defaultVal) {
          display = defaultVal;
        } else {
          hide = true;
        }
      }
    }

    if (hide) {
      return null;
    }

    return !mobile ? (
      <div className={styles.infoItem}>
        <img src={icon} alt={title} style={{ height: iconHeight }} />
        <div>
          <p>{title}</p>
          <strong className={cx({ [styles.loading]: isLoading })}>
            {display}
          </strong>
        </div>
      </div>
    ) : (
      <div
        className={cx(
          'column',
          'is-half ',
          languageMobileItem ? styles.languageMobileItem : styles.mobileItem
        )}
      >
        <img src={icon} alt={title} style={{ height: iconHeight }} />
        <div>
          <p>{title}</p>
          <strong className={cx({ [styles.loading]: isLoading })}>
            {display}
          </strong>
        </div>
      </div>
    );
  };

  renderReview() {
    const { reviewsTop } = this.props.tour;

    if (reviewsTop && reviewsTop.length > 0) {
      const review = reviewsTop[0];
      const stars = [];

      for (let i = 0; i < review.stars; i++) {
        stars.push(<img src={star} alt='Star' key={i} />);
      }

      return (
        <div className={styles.ratingInfoWrapper}>
          <div className={styles.ratingInfo}>
            <div>{stars}</div>
            <p>{`"${review.title}"`}</p>
          </div>
        </div>
      );
    }

    return <div className={styles.rating} />;
  }

  parseCategories = (categories: ITourCategory[] | undefined) => {
    const categoryArray: string[] = [];
    if (categories) {
      categories.forEach((category) => {
        categoryArray.push(category.title);
      });
    }
    return categoryArray;
  };

  parseLanguages = (languages: string[] | undefined) => {
    let language;
    if (languages && languages.length > 0) {
      let langStr = '';
      for (const l of languages) {
        langStr += `${l}, `;
      }
      language = langStr.substr(0, langStr.length - 2);
    }
    return language;
  };

  parseSeasons = (seasons: string[] | undefined) => {
    const { localeData } = this.props;
    let season;
    if (seasons && seasons.length > 0) {
      if (seasons.length >= 4) {
        season = localeData.tourInfoAllYear;
      } else {
        let seasonStr = '';
        for (const s of seasons) {
          seasonStr += `${s}, `;
        }
        season = seasonStr.substr(0, seasonStr.length - 2);
      }
    }
    return season;
  };

  formatMinAge = (minAge: number | undefined) => {
    return minAge && minAge > 0 ? `${minAge}` : undefined;
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { cartObject } = reducers.cartReducers;
  const { locale } = reducers.translationReducers;
  return { currency, cartObject, locale };
};

export default connect(mapStateToProps)(
  withWindowSize(
    ({
      tour,
      productId,
      product,
      productLoaded,
      currency,
      cartObject,
      windowWidth,
      locale,
      windowHeight,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query TourHeroPrivate {
            allContentfulTranslationsPage {
              edges {
                node {
                  ...TranslationsTourInfo
                  tourDescriptionLoading
                }
              }
            }
            contentfulTranslationsPage {
              tourBookingBoxMainButton {
                buttonTextBeforeClickPrivateTour
              }
            }
          }
        `}
        render={(data) => {
          return (
            <TourHeroPrivate
              localeData={
                data.allContentfulTranslationsPage.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              translationButtonText={
                data.contentfulTranslationsPage.tourBookingBoxMainButton
                  .buttonTextBeforeClickPrivateTour
              }
              tour={tour}
              productId={productId}
              product={product}
              productLoaded={productLoaded}
              currency={currency}
              cartObject={cartObject}
              locale={locale}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          );
        }}
      />
    )
  )
);
