import React, { useState } from 'react';
import {
  IPersonCounter,
  ISortedAvailability,
  ISelectValue,
  IReducers
} from '../../../interfaces';

import { Moment } from 'moment';
import TourCountPerson from '../TourCountPerson';
import MinBookingText from '../MinBookingText';
import MaxBookingText from '../MaxBookingText';
import AvailabilityCount from '../AvailabilityCount';
import MaxPerMasterText from '../MaxPerMasterText';
import TourBookingError from '../TourBookingError';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  personCounter: IPersonCounter | null;
  availability: ISortedAvailability | null;
  selectedTime: ISelectValue | null;
  selectedDate: Moment | null;
  locale: string;

  getTotalPersonCount(): number;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
}

interface ITranslationsTourCountPersons {
  maxPerBooking: string;
  minPerBooking: string;
  noAvailabilityLeftCountdown: {
    singular: string;
    plural: string;
  };
  maxPerMaster: string;
  dateAndTimeErrorMessage: {
    version1: string;
    version2: string;
  };
}

interface IProps extends IExternalProps {
  localeData: ITranslationsTourCountPersons;
}

type AddOrRemove = 'addOne' | 'removeOne';

const TourCountPersons: React.FunctionComponent<IProps> = props => {
  const {
    personCounter,
    changePersonCount,
    availability,
    selectedDate,
    selectedTime,
    getTotalPersonCount,
    localeData
  } = props;
  const [errorDate, setErrorDate] = useState(false);
  const [errorTime, setErrorTime] = useState(false);

  if (personCounter) {
    return (
      <React.Fragment>
        {Object.keys(personCounter).map(key => {
          return (
            <TourCountPerson
              personCounter={personCounter}
              personKey={key}
              changePersonCount={changePersonCount}
              key={key}
              selectedDate={selectedDate}
              setErrorDate={setErrorDate}
              selectedTime={selectedTime}
              setErrorTime={setErrorTime}
            />
          );
        })}
        <div>
          <MaxPerMasterText
            personCounter={personCounter}
            text={localeData.maxPerMaster}
          />
          <MinBookingText
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            getTotalPersonCount={getTotalPersonCount}
            text={localeData.minPerBooking}
          />
          <MaxBookingText
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            text={localeData.maxPerBooking}
          />
          <AvailabilityCount
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            getTotalPersonCount={getTotalPersonCount}
            text={localeData.noAvailabilityLeftCountdown}
          />
          {errorDate ? (
            <TourBookingError
              errorMessage={localeData.dateAndTimeErrorMessage.version1}
            />
          ) : null}
          {errorTime ? (
            <TourBookingError
              errorMessage={localeData.dateAndTimeErrorMessage.version2}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    personCounter,
    changePersonCount,
    availability,
    selectedDate,
    selectedTime,
    getTotalPersonCount,
    locale
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourCountPersons {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                maxPerBooking
                maxPerMaster
                minPerBooking
                noAvailabilityLeftCountdown {
                  singular
                  plural
                }
                dateAndTimeErrorMessage {
                  version1
                  version2
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <TourCountPersons
            localeData={
              data.allContentfulTranslationsBookingModal.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            personCounter={personCounter}
            changePersonCount={changePersonCount}
            availability={availability}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            getTotalPersonCount={getTotalPersonCount}
            locale={locale}
          />
        );
      }}
    />
  )
);
