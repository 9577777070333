export const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: '#676767',
    textDecoration: 'none',
    fontFamily: 'DINNextLT, sans-serif',
    fontSize: 15
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textDecoration: 'none',
    color: '#2c2c2c',
    fontFamily: 'DINNextLT, sans-serif',
    fontSize: 15
  }),
  option: (provided: any) => ({
    ...provided,
    textDecoration: 'none',
    fontFamily: 'DINNextLT, sans-serif',
    fontSize: 15
  }),
  control: (provided: any) => {
    const boxShadow = null;
    const borderStyle = 'solid';
    const borderColor = '#DDDDDD';
    const borderWidth = '1px';
    const borderRadius = '0px';
    const backgroundColor = '#FFFFFF';
    const textDecoration = 'none';
    const fontFamily = 'DINNextLT, sans-serif';
    const fontSize = 16;
    return {
      ...provided,
      boxShadow,
      borderColor,
      borderStyle,
      borderWidth,
      borderRadius,
      backgroundColor,
      textDecoration,
      fontFamily,
      fontSize,
      '&:hover': {
        borderColor: null
      }
    };
  },
  dropdownIndicator: (provided: any, { isFocused }: any) => {
    const color = isFocused ? '#37559b' : '#676767';
    const transform = isFocused ? 'rotate(180deg)' : null;
    return { ...provided, color, transform };
  },
  menuList: (provided: any) => {
    return {
      ...provided,
      backgroundColor: 'white',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
      transition: 'all 0.2s ease'
    };
  },
  container: (provided: any, { isFocused }: any) => {
    const borderBottom = isFocused
      ? '2px solid #294b98'
      : '2px solid transparent';
    return { ...provided, borderBottom };
  }
};

export const customStylesCountryPicker = {
  ...customStyles,
  option: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '47px',
    borderStyle: 'hidden',
  }),
  menuList: (provided: any) => ({
    ...provided,
    width: '300px',
  }),
  container: (provided: any, { isFocused }: any) => ({
    ...provided,
    borderBottom: isFocused ? '1px solid #294b98' : '1px solid transparent',
  })
};

const noIndicator = {
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
    paddingRight: 5
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};

const noIndicatorCountryPicker = {
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
    paddingRight: 5
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999, width: '300px' })
};

const noArrow = {
  dropdownIndicator: (provided: any) => ({ ...provided, display: 'none' })
};

const error = {
  control: (provided: any) => ({ ...provided, border: '1px solid #fa7268' })
};
const lowMenu = {
  menuList: (provided: any) => ({ ...provided, maxHeight: 160 })
};
const lowMenuCountryPicker = {
  menuList: (provided: any) => ({ ...provided, maxHeight: 300 })
};
const lowMenuModal = {
  menuList: (provided: any) => ({ ...provided, maxHeight: 200 })
};

const overideSelectStyles = {
  container: (provided: any) => ({ ...provided, width: '100%' }),
  control: (provided: any, { isFocused }: any) => {
    const borderBottom = isFocused ? '2px solid #294b98' : null;
    const boxShadow = null;
    const borderColor = '#f8f8f8';
    const backgroundColor = '#f8f8f8';
    const maxHeight = 50;

    return {
      ...provided,
      borderBottom,
      boxShadow,
      borderColor,
      maxHeight,
      backgroundColor,
      '&:hover': {
        borderColor: null
      }
    };
  }
};
export const customNoIndicator = { ...customStyles, ...noIndicator };
export const customNoIndicatorCountryPicker = { ...customStylesCountryPicker, ...noIndicatorCountryPicker };
export const noArrowStyle = { ...customStyles, ...noIndicator, ...noArrow };
export const errorStyle = { ...customStyles, ...noIndicator, ...error };
export const mobileMenu = {
  ...customStyles,
  ...lowMenu,
  ...overideSelectStyles
};
export const mobileMenuCountryPicker = {
  ...customStylesCountryPicker,
  ...lowMenuCountryPicker
};
export const mobileMenuNoIndicator = {
  ...customStyles,
  ...noIndicator,
  ...lowMenu,
  ...overideSelectStyles
};
export const modalMenu = {
  ...customStyles,
  ...lowMenuModal
};
