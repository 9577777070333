import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import moment from 'moment';

import * as styles from './Footer.module.scss';
import {
  facebook,
  instagram,
  youtube,
  tripAdvisorBlack,
  globalFooter,
  inspiredFooter,
  safFooter,
  travelFooter,
  vakinnFooter,
  dropdownArrow,
  ensemble,
  ustoa,
  etoa,
  iso,
  cleanSafe,
  vakinnGoldFooter,
} from '../../icons';
import {
  IPageContext,
  IFooterList,
  IFooter,
  INavigation,
} from '../../interfaces';
import { Link, Desktop, Mobile, MarkdownWrapper } from '../../components';

interface IExternalProps {
  pageContext: IPageContext;
  icelandairUrl: boolean;
  isIcelandair: boolean;
}

interface IProps extends IExternalProps {
  data: {
    allContentfulFooter: IFooterList;
  };
}

interface IState {
  infoOpen: boolean;
  compOpen: boolean;
  contactOpen: boolean;
  popularOpen: boolean;
}

class Footer extends React.Component<IProps, IState> {
  readonly state = {
    infoOpen: false,
    compOpen: false,
    contactOpen: false,
    popularOpen: false,
  };

  render() {
    const { data, pageContext } = this.props;
    const { infoOpen, compOpen, contactOpen, popularOpen } = this.state;
    const { locale, langPath } = pageContext;
    let footerData: IFooter | null = null;

    for (const edge of data.allContentfulFooter.edges) {
      if (edge.node.node_locale === locale) {
        footerData = edge.node;
      }
    }

    if (!footerData) {
      return null;
    }

    const {
      companyLinks,
      infoLinks,
      contactUs,
      bottomLinks,
      //getInTouch,
      contact,
      popularToursLinks,
    } = footerData;

    return (
      <div>
        <Desktop>
          <footer className={styles.footer}>
            <div className='centered-content'>
              <div className={styles.lineMargin}>
                <div className={styles.line} />
              </div>
              <div
                className={cx('columns', 'is-multiline')}
                style={
                  this.showIcelandairFooter()
                    ? { textAlign: 'center' }
                    : undefined
                }
              >
                {this.showIcelandairFooter()
                  ? this.renderLinksIcelandair(companyLinks, infoLinks)
                  : this.renderAllLinks(
                      companyLinks,
                      popularToursLinks,
                      infoLinks
                    )}
                {this.showIcelandairFooter() ? null : (
                  <>
                    <div
                      className={cx(
                        'column',
                        'is-6 is-3-desktop',
                        styles.linkList
                      )}
                    >
                      <h2 className={styles.footerTitle}>{contact.title}</h2>

                      <MarkdownWrapper text={contact.text.text} />
                      <ul>
                        <li>
                          <Link langPath={langPath} to={contactUs.slug}>
                            {contactUs.title}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={cx(
                        'column',
                        'is-6 is-3-desktop',
                        styles.agentLoginArea
                      )}
                    >
                      <a
                        className={styles.agentButton}
                        href='https://agent.re.is/login'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Agent login
                      </a>
                      {/*<img src={road} alt='Road' />*/}
                    </div>
                  </>
                )}
              </div>
              {this.showIcelandairFooter() ? null : (
                <>
                  <div className={styles.social}>{this.renderSocial()}</div>
                  <div className={styles.line} />
                  <div className={styles.infoContainer}>
                    <div className={styles.info}>
                      <p>
                        {'Reykjavík Excursions'} {moment().year()}
                      </p>
                      {bottomLinks.links.map((link) => (
                        <Link
                          key={link.id}
                          langPath={langPath}
                          to={link.page.slug}
                        >
                          {link.title || link.page.title}
                        </Link>
                      ))}
                    </div>

                    {this.renderIcons()}
                  </div>
                </>
              )}
            </div>
          </footer>
        </Desktop>
        <Mobile>
          <footer className={styles.footer}>
            <div className='centered-content'>
              <div className={styles.lineMargin}>
                <div className={styles.line} />
              </div>
              {this.showIcelandairFooter()
                ? this.renderLinksIcelandair(companyLinks, infoLinks)
                : this.renderAllLinksMobile(
                    companyLinks,
                    popularToursLinks,
                    infoLinks,
                    langPath,
                    compOpen,
                    infoOpen,
                    popularOpen
                  )}
              {/* {this.renderMobileLinks(companyLinks, langPath, compOpen)}
              {this.renderMobileLinks(infoLinks, langPath, infoOpen)}
              {this.renderMobileLinks(popularToursLinks, langPath, popularOpen)} */}
              {this.showIcelandairFooter() ? null : (
                <>
                  <div className={styles.item} key={'Get in touch'}>
                    <button
                      className={styles.question}
                      onClick={() =>
                        this.setState({ contactOpen: !contactOpen })
                      }
                    >
                      <h2 className={styles.footerTitle}>{contact.title}</h2>

                      <img
                        src={dropdownArrow}
                        alt='Arrow'
                        className={styles.arrow}
                      />
                    </button>
                    <div
                      className={styles.links}
                      style={{ height: contactOpen ? 190 : 0 }}
                    >
                      <ul>
                        <MarkdownWrapper text={contact.text.text} />
                        <li>
                          <Link langPath={langPath} to={contactUs.slug}>
                            {contactUs.title}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={cx(
                      'column',
                      'is-6 is-3-desktop',
                      styles.agentLoginArea
                    )}
                  >
                    <a
                      className={styles.agentButton}
                      href='https://agent.re.is/login'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Agent login
                    </a>
                    {/*<img src={road} alt='Road' />*/}
                  </div>
                </>
              )}

              {/*                 <div
                  className={cx(
                    'column',
                    'is-6 is-3-desktop',
                    styles.agentLoginArea
                  )}
                /> */}

              {/* this.renderIcons()  viljum ekki icons i mobile lengurs*/}
              {this.showIcelandairFooter() ? null : (
                <>
                  <div className={cx(styles.line)} />
                  <div className={cx(styles.mobileInfo)}>
                    <div>
                      <p>
                        {'Reykjavík Excursions'} {moment().year()}
                      </p>
                    </div>

                    {bottomLinks.links.map((link) => (
                      <div key={link.id}>
                        <Link langPath={langPath} to={link.page.slug}>
                          {link.title || link.page.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div className={styles.mobileSocial}>
                    {this.renderSocial()}
                  </div>
                </>
              )}
            </div>
          </footer>
        </Mobile>
      </div>
    );
  }

  showIcelandairFooter() {
    const { icelandairUrl, isIcelandair } = this.props;
    return icelandairUrl || isIcelandair ? true : false;
  }
  toggleItem = (name: string | undefined) => {
    const { infoOpen, compOpen, popularOpen } = this.state;

    if (name === 'Reykjavik Excursions') {
      this.setState({ compOpen: !compOpen });
    } else if (name === 'Need More Info?') {
      this.setState({ infoOpen: !infoOpen });
    } else {
      this.setState({ popularOpen: !popularOpen });
    }
  };

  renderMobileLinks = (nav: INavigation, langPath: string, open: boolean) => {
    return (
      <div className={styles.item} key={nav.title}>
        <button
          className={styles.question}
          onClick={() => this.toggleItem(nav.title)}
        >
          <h2 className={styles.footerTitle}>{nav.title}</h2>
          <img src={dropdownArrow} alt='Arrow' className={styles.arrow} />
        </button>
        <div
          className={styles.links}
          style={{ height: open ? 30 * nav.links.length : 0 }}
        >
          <ul>
            {nav.links.map((link) => (
              <li key={link.id}>
                {link.externalLink ? (
                  <a
                    href={link.externalLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.title || link.page.title}
                  </a>
                ) : (
                  <Link langPath={langPath} to={link.page.slug}>
                    {link.title || link.page.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  renderIcons() {
    return (
      <React.Fragment>
        <div className={styles.icons}>
          <img src={cleanSafe} alt='Clean and safe' />
          <a
            href='https://www.iso.org/iso-14001-environmental-management.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={iso} alt='ISO 14001' />
          </a>
          <a
            href='http://www.ensembletravel.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={ensemble} alt='Ensemble Travel Group' />
          </a>
          <a
            href='https://ustoa.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={ustoa} alt='United States Tour Operators Association' />
          </a>
          <a
            href='https://www.etoa.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={etoa} alt='European Tourism Association' />
          </a>
          <a
            href='https://www.inspiredbyiceland.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={inspiredFooter} alt='Inspired by Iceland' />
          </a>
          <a
            href='https://www.ferdamalastofa.is/en/licences-legislation/travel-agency'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={travelFooter} alt='Iceland Travel Board' />
          </a>
          <a
            href='http://www.saf.is/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={safFooter} alt='SAF' />
          </a>
          <a
            href='https://www.re.is/vakinn'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={vakinnFooter} alt='Vakinn' />
          </a>
          <a
            href='https://www.re.is/vakinn'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={vakinnGoldFooter} alt='VakinnGold' />
          </a>
          <a
            href='http://www.gpn.travel/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={globalFooter} alt='Global Passenger Network' />
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderSocial() {
    return (
      <React.Fragment>
        <a
          href='https://www.facebook.com/reykjavikexcursions'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={facebook} alt='Facebook' />
        </a>
        <a
          href='https://www.instagram.com/reykjavikexcursions'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={instagram} alt='Instagram' />
        </a>
        <a
          href='https://www.youtube.com/channel/UCYoNHifkvPC0uPhfkg-hkSQ'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={youtube} alt='Youtube' />
        </a>
        <a
          href='https://www.tripadvisor.com/Attraction_Review-g189970-d1556635-Reviews-Reykjavik_Excursions-Reykjavik_Capital_Region.html'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={tripAdvisorBlack} alt='Trip Advisor' />
        </a>
      </React.Fragment>
    );
  }
  renderAllLinks(
    companyLinks: INavigation,
    popularToursLinks: INavigation,
    infoLinks: INavigation
  ) {
    return (
      <React.Fragment>
        {this.renderLinks(companyLinks)}
        {this.renderLinks(popularToursLinks)}
        {this.renderLinks(infoLinks)}
      </React.Fragment>
    );
  }
  renderAllLinksMobile(
    companyLinks: INavigation,
    popularToursLinks: INavigation,
    infoLinks: INavigation,
    langPath: string,
    compOpen: boolean,
    infoOpen: boolean,
    popularOpen: boolean
  ) {
    return (
      <React.Fragment>
        {this.renderMobileLinks(companyLinks, langPath, compOpen)}
        {this.renderMobileLinks(infoLinks, langPath, infoOpen)}
        {this.renderMobileLinks(popularToursLinks, langPath, popularOpen)}
      </React.Fragment>
    );
  }
  renderLinksIcelandair(companyLinks: INavigation, infoLinks: INavigation) {
    const filterCompanyLinks = companyLinks.links.filter(
      (e) => e.title === 'Environmental Policy' || e.title === 'Umhverfisstefna'
    );
    return (
      <>
        <div className={cx('column', 'is-6 is-4-desktop', styles.linkList)}>
          <ul>
            {filterCompanyLinks.map((link) => (
              <li key={link.id}>
                {link.externalLink ? (
                  <a
                    href={link.externalLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.title || link.page.title}
                  </a>
                ) : (
                  <Link
                    langPath={this.props.pageContext.langPath}
                    to={link.page.slug}
                  >
                    {link.title || link.page.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={cx('column', 'is-6 is-4-desktop', styles.linkList)}>
          <ul>
            {infoLinks.links.map((link) => (
              <li key={link.id}>
                {link.externalLink ? (
                  <a
                    href={link.externalLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.title || link.page.title}
                  </a>
                ) : link.title === 'Tour Cancellation' ||
                  link.title === 'Ferðum aflýst' ? (
                  <Link
                    langPath={this.props.pageContext.langPath}
                    to={link.page.slug}
                  >
                    {link.title || link.page.title}
                  </Link>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
        <div className={cx('column', 'is-6 is-4-desktop', styles.linkList)}>
          <ul>
            {infoLinks.links.map((link) => (
              <li key={link.id}>
                {link.externalLink ? (
                  <a
                    href={link.externalLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {link.title || link.page.title}
                  </a>
                ) : link.title === 'FAQ' ||
                  link.title === 'Algengar spurningar' ? (
                  <Link
                    langPath={this.props.pageContext.langPath}
                    to={link.page.slug}
                  >
                    {link.title || link.page.title}
                  </Link>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
  renderLinks(nav: INavigation) {
    return (
      <div className={cx('column', 'is-6 is-3-desktop', styles.linkList)}>
        <h2 className={styles.footerTitle}>{nav.title}</h2>
        <ul>
          {nav.links.map((link) => (
            <li key={link.id}>
              {link.externalLink ? (
                <a
                  href={link.externalLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {link.title || link.page.title}
                </a>
              ) : (
                <Link
                  langPath={this.props.pageContext.langPath}
                  to={link.page.slug}
                >
                  {link.title || link.page.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

// eslint-disable-next-line react/display-name
export default ({
  pageContext,
  icelandairUrl,
  isIcelandair,
}: IExternalProps) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulFooter {
          ...FooterList
        }
      }
    `}
    render={(data) => (
      <Footer
        data={data}
        pageContext={pageContext}
        icelandairUrl={icelandairUrl}
        isIcelandair={isIcelandair}
      />
    )}
  />
);
