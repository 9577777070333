import React from 'react';

export const Exit = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20.366' height='20.365'>
    <g id='Group_339' data-name='Group 339' transform='translate(-317.6 -40)'>
      <rect
        id='Rectangle_585'
        width='27'
        height='1.8'
        fill='#6f7377'
        data-name='Rectangle 585'
        rx='.9'
        transform='rotate(-45 230.131 -353.834)'
      />
      <rect
        id='Rectangle_586'
        width='27'
        height='1.8'
        fill='#6f7377'
        data-name='Rectangle 586'
        rx='.9'
        transform='rotate(45 111.152 404.914)'
      />
    </g>
  </svg>
);
export default Exit;
