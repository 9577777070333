import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
} from '../../interfaces';
import * as styles from './Questions.module.scss';
import Select, { components } from 'react-select';
import { customStyles, errorStyle } from '../../styles/ReactSelect';

interface IProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  changeVal(val: any): void;
}

const SelectQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion, changeVal } = props;
  if (!!bookingQuestion && !!bookingQuestion.AnswerOptions) {
    return (
      <div className={styles.padding} key={bookingQuestion.Id}>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}
        <p
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </p>
        <Select
          menuPortalTarget={
            !!document && !!document.body ? document.body : null
          }
          placeholder={
            bookingQuestion.Placeholder ? bookingQuestion.Placeholder : ''
          }
          // value={a.selectValue}
          options={bookingQuestion.AnswerOptions}
          isMulti={
            bookingQuestion.SelectMultiple
              ? bookingQuestion.SelectMultiple
              : false
          }
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          components={{ SingleValue: components.SingleValue }}
          styles={
            !!bookingQuestion && !!bookingQuestion.isValid
              ? customStyles
              : errorStyle
          }
          onChange={(e: any) =>
            e ? changeVal(bookingQuestion.SelectMultiple ? e : [e]) : null
          }
        />
      </div>
    );
  } else if (!!contactQuestion && !!contactQuestion.AnswerOptions) {
    return (
      <div className={styles.padding} key={contactQuestion.Id}>
        <label
          className={cx(styles.label, {
            [styles.required]: contactQuestion.Required,
          })}
        >
          {contactQuestion.Label}
        </label>
        <Select
          menuPortalTarget={
            !!document && !!document.body ? document.body : null
          }
          placeholder={
            contactQuestion.Placeholder ? contactQuestion.Placeholder : ''
          }
          // value={a.selectValue}
          options={contactQuestion.AnswerOptions}
          isMulti={
            contactQuestion.SelectMultiple
              ? contactQuestion.SelectMultiple
              : false
          }
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          components={{ SingleValue: components.SingleValue }}
          styles={
            !!contactQuestion && !!contactQuestion.isValid
              ? customStyles
              : errorStyle
          }
          onChange={(e: any) =>
            e ? changeVal(contactQuestion.SelectMultiple ? e : [e]) : null
          }
        />
      </div>
    );
  }
  return null;
};

export default SelectQuestion;
