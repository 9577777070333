import React, { useState, useRef, useEffect } from 'react';
import {
  ICurrencyOption,
  IReducers,
  ICurrencyIcons,
  IPageContext,
  ICart,
} from '../../interfaces';
import { connect } from 'react-redux';
import { changeCurrency } from '../../redux/actions/currencyActions';
import BorderDropdown from '../BorderDropdown/BorderDropdown';
import * as styles from './FlagDropdown.module.scss';
import { getCurrencyConstant } from '../../utils';
import Icon from '../Icon';

interface IProps {
  currencyDropdown: ICurrencyIcons;
  currency: ICurrencyOption;
  cartObject: ICart | null;
  pageContext: IPageContext;
  lightNav?: boolean;
  currencyWarning: string;
  changeCurrency(newCurrency: ICurrencyOption, cartCount: number): void;
}

interface IOption {
  label: string;
  code: string;
  value: string;
  icon: string;
  lightIcon: string;
}

const FlagDropdown: React.FunctionComponent<IProps> = (props) => {
  const { currency, currencyDropdown, cartObject } = props;
  const [openCurrency, setOpenCurrency] = useState(false);
  const [toggle, setToggle] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const options: IOption[] = [];
  Object.keys(currencyDropdown).map((key) => {
    const localCurrency = getCurrencyConstant(key);
    options.push(localCurrency);
  });

  const handleClickOutsideSearch = (event: MouseEvent) => {
    const divConstRef: any = divRef.current;
    if (divConstRef) {
      if (!divConstRef.contains(event.target) && toggle) {
        setToggle(!toggle);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearch);
  }, [toggle]);
  // TODO HARDCODED EMPTY CART
  const visible = (
    <div
      className={styles.visibleStyle}
      data-cy='Currency-Select-Dropdown-Options'
      onClick={() => setToggle(!toggle)}
      ref={divRef}
    >
      <p className={styles.text}>{currency.label}</p>
      <div className={styles.arrow}>
        <Icon name={toggle ? 'NAVUP' : 'NAVDOWN'} />
      </div>
    </div>
  );
  if (!!cartObject && !!cartObject.Items ? cartObject.Items.length : 0) {
    return (
      <BorderDropdown visible={visible} component='flagDropdown'>
        <div style={{ padding: 10, width: 180 }}>
          <p className={styles.message}>{props.currencyWarning}</p>
        </div>
      </BorderDropdown>
    );
  } else {
    return (
      <BorderDropdown
        visible={visible}
        openCurrency={openCurrency}
        component='flagDropdown'
      >
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => {
              props.changeCurrency(
                option as any,
                !!cartObject && !!cartObject.Items ? cartObject.Items.length : 0
              );
              setOpenCurrency(!openCurrency);
            }}
            className={styles.currencyButton}
            data-cy={option.value}
          >
            <div className={styles.labelContainer}>
              <p className={styles.label}>{option.label}</p>
            </div>
          </button>
        ))}
      </BorderDropdown>
    );
  }
};

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { cartObject } = reducers.cartReducers;
  return { currency, cartObject };
};

export default connect(mapStateToProps, { changeCurrency })(FlagDropdown);
