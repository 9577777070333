import axios, { AxiosInstance } from 'axios';
import { handleAxiosError } from '../utils';

interface IEmailApi {
  sendEmail(
    subject: string,
    to: string,
    from: string,
    emailBody: string
  ): Promise<any>;
}

class EmailApi implements IEmailApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }

  async sendEmail(
    subject: string,
    to: string,
    from: string,
    emailBody: string
  ): Promise<any> {
    try {
      await this.httpClient.post(
        '/api/Email/SendEmail',
        JSON.stringify(emailBody),
        {
          params: { subject, to, from }
        }
      );
      return true;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default EmailApi;
