import React from 'react';

import { Image, Link } from '../../components/index';
import { ITourItem, ITranslationProductCard } from '../../interfaces';
import { formatPrice } from '../../utils';
import * as styles from './index.module.scss';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { IReducers } from '../../interfaces';

interface IDefaultPrices {
  defaultPrice: number | null;
  discountPrice: number | null | undefined;
}

interface IExternalProps {
  tour: ITourItem;
  defaultPrices: IDefaultPrices | null | undefined;
  loadingPrices: boolean;
  currency: string;
  langPath: string;
  locale: string;
  privateTours?: boolean;
}

interface IProps extends IExternalProps {
  localeData: ITranslationProductCard[];
}

const ProductCard = ({
  tour,
  defaultPrices,
  loadingPrices,
  currency,
  langPath,
  localeData,
  privateTours,
}: IProps) => {
  if (privateTours) currency = 'ISK';

  const defaultPrice =
    defaultPrices &&
    defaultPrices.defaultPrice &&
    `${formatPrice(defaultPrices.defaultPrice, currency)} ${currency}`;

  const discountPrice =
    defaultPrices &&
    defaultPrices.discountPrice &&
    `${formatPrice(defaultPrices.discountPrice, currency)} ${currency}`;

  const originalPrice = !loadingPrices && discountPrice && defaultPrice;

  let finalPrice = loadingPrices
    ? localeData[0].node.loading
    : discountPrice
    ? discountPrice
    : defaultPrice;

  finalPrice = finalPrice
    ? finalPrice
    : formatPrice(tour.priceFrom || 0, currency) + ' ' + currency;
  return (
    <div className={styles.productCard}>
      <Link
        langPath={langPath || ''}
        to={`${tour.pathPrefix}/${tour.slug}`}
        className={styles.productCardLink}
      >
        <div className={styles.imageContainer}>
          <Image
            fluidImage={tour.heroImage}
            loadingProduct={false}
            productPhotoSize='large'
            className={styles.image}
          />
          {tour.offerBanner ? (
            <div className={styles.specialOfferBanner}>
              <strong>{tour.offerBanner.title}</strong>
            </div>
          ) : null}
        </div>
        <div className={styles.card}>
          {tour.category && tour.category.length > 0 && (
            <p className={styles.category}>
              {tour.category[0].title.toUpperCase()}
            </p>
          )}
          <h3 className={styles.title}>{tour.title}</h3>
          {/* Prices */}
          <div className={styles.priceContainer}>
            {originalPrice && (
              <p className={styles.originalPrice}>{originalPrice}</p>
            )}
            <p
              className={`${
                originalPrice ? styles.discountPrice : styles.finalPrice
              }`}
            >
              {finalPrice}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    tour,
    defaultPrices,
    loadingPrices,
    currency,
    langPath,
    locale,
    privateTours,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query ProductCardQuery {
          allContentfulTranslationsVarious {
            edges {
              node {
                loading
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ProductCard
            localeData={data.allContentfulTranslationsVarious.edges.filter(
              (node: { node: { loading: string; node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )}
            tour={tour}
            defaultPrices={defaultPrices}
            loadingPrices={loadingPrices}
            currency={currency}
            langPath={langPath}
            locale={locale}
            privateTours={privateTours}
          />
        );
      }}
    />
  )
);
