// extracted by mini-css-extract-plugin
export var container = "TourGallery-module--container--26Clh";
export var sliderContainer = "TourGallery-module--sliderContainer--1Tkr4";
export var slider = "TourGallery-module--slider--3OaYl";
export var slide = "TourGallery-module--slide--1yzq7";
export var activeSlide = "TourGallery-module--activeSlide--1o7UK";
export var image = "TourGallery-module--image--1RMyD";
export var arrows = "TourGallery-module--arrows--VStmv";
export var leftArrow = "TourGallery-module--leftArrow--3TXD7";
export var imageTitle = "TourGallery-module--imageTitle--1jSyk";
export var mobileSlide = "TourGallery-module--mobileSlide--1iYWA";
export var fillImages = "TourGallery-module--fillImages--2xZKy";
export var fillImage = "TourGallery-module--fillImage--3U4bV";
export var fillTitle = "TourGallery-module--fillTitle--2XPgJ";
export var fullScreenImage = "TourGallery-module--fullScreenImage--1f9yS";