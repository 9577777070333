// extracted by mini-css-extract-plugin
export var extraContainer = "TourBookableExtras-module--extraContainer--1df6l";
export var extraQuestions = "TourBookableExtras-module--extraQuestions--3Hov-";
export var container = "TourBookableExtras-module--container--2r9vw";
export var textContainer = "TourBookableExtras-module--textContainer--24EgB";
export var buttonContainer = "TourBookableExtras-module--buttonContainer--3Yn3x";
export var counter = "TourBookableExtras-module--counter--1eEWH";
export var mainText = "TourBookableExtras-module--mainText--3WkKJ";
export var secondaryText = "TourBookableExtras-module--secondaryText--2Sjam";
export var maxText = "TourBookableExtras-module--maxText--1qokJ";
export var paddingDiv = "TourBookableExtras-module--paddingDiv--J7SAl";
export var label = "TourBookableExtras-module--label--1AJ0d";
export var textInput = "TourBookableExtras-module--textInput--3lNbU";
export var area = "TourBookableExtras-module--area--34mJ1";
export var errorField = "TourBookableExtras-module--errorField--31KRg";