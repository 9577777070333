import React from 'react';
import { IFlybusSchedule } from '../../interfaces';
import * as styles from './FlybusSchedule.module.scss';
import Title from '../Title';
import TextParser from '../TextParser/TextParser';

// OBS This Component is looking funny beacuse of Covid-19 situation.
const FlybusSchedule: React.FunctionComponent<IFlybusSchedule> = ({
  title,
  subtitle,
  scheduleImageText,
}) => {
  return (
    <>
      <Title tag='h2' theme={['sizeDesktopLarge', 'sizeMobileLarge']}>
        {title}
      </Title>
      <TextParser source={subtitle.subtitle} className={styles.subtitle} />

      <div className={styles.subtitle}>{scheduleImageText}</div>
    </>
  );
};
export default FlybusSchedule;
