import React from 'react';
import moment, { Moment } from 'moment';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import * as styles from './PrivateForm.module.scss';
import { TextInput, NumberInput, DateInput, Loader } from '../../components';
import { validateEmail } from '../../utils';
import { EmailApi } from '../../api';
import { graphql, StaticQuery } from 'gatsby';
import { ITranslationsPrivateForm } from '../../interfaces/translations';
import { connect } from 'react-redux';
import { IReducers } from '../../interfaces/various';

interface IFormState {
  name: string;
  email: string;
  itinerary: string;
  group: string;
}

interface IExternalProps {
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsPrivateForm;
}

interface IState extends IFormState {
  dropdownOpen: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
  passengers: number;
  captcha: string | null;
  error: boolean;
  loading: boolean;
  success: boolean;
  failure: boolean;
}

const initialState: IState = {
  name: '',
  email: '',
  itinerary: '',
  group: '',
  dropdownOpen: false,
  startDate: null,
  endDate: null,
  passengers: 1,
  captcha: null,
  error: false,
  loading: false,
  success: false,
  failure: false,
};

const EMAIL_SUBJECT = 'Private tour enquiry';
const EMAIL_ADDRESS = 'sales@re.is';

class PrivateForm extends React.Component<IProps, IState> {
  readonly state: IState = initialState;

  emailApi = new EmailApi();

  render() {
    const {
      name,
      email,
      itinerary,
      group,
      dropdownOpen,
      startDate,
      endDate,
      passengers,
      error,
      success,
      failure,
      loading,
      captcha,
    } = this.state;
    const { localeData } = this.props;
    const {
      privateFormAbout,
      privateFormEmail,
      privateFormHeader,
      privateFormIntinerary,
      privateFormName,
      privateFormNumberOfPassengers,
      privateFormSendButton,
      privateFormSuccessFailure,
      privateFormWhen,
      privateFormDateInput,
    } = localeData;

    return (
      <div className='centered-content vertical-margin'>
        <h3 className={styles.title}>{privateFormHeader}</h3>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={cx('columns is-multiline', styles.form)}>
            <div className='column is-6'>
              <TextInput
                title={privateFormName.label}
                value={name}
                onChange={this.updateInput}
                name='name'
                max={100}
                error={error && !this.validateName()}
                errorText={privateFormName.labelErrorMessage}
                darkMode={true}
              />
            </div>
            <div className='column is-6'>
              <TextInput
                title={privateFormEmail.label}
                value={email}
                onChange={this.updateInput}
                type='email'
                name='email'
                error={error && !this.validateEmail()}
                errorText={privateFormEmail.labelErrorMessage}
                darkMode={true}
              />
            </div>
            <div className='column is-6'>
              <DateInput
                title={privateFormWhen.label}
                startDate={startDate}
                endDate={endDate}
                onChange={this.updateDateRange}
                clearDateSelection={this.clearDateSelection}
                open={dropdownOpen}
                toggleOpen={this.toggleDropdown}
                name='calendar'
                error={error && !this.validateTime()}
                errorText={privateFormWhen.labelErrorMessage}
                darkMode={true}
                dateClear={privateFormDateInput.inputPlaceholder}
                dateText={privateFormDateInput.altInputLabel}
              />
            </div>
            <div className='column is-6'>
              <NumberInput
                title={privateFormNumberOfPassengers.label}
                value={passengers}
                onChange={this.updatePassengers}
                name='passengers'
                max={50}
                min={1}
                error={error && !this.validatePassengers()}
                errorText={privateFormNumberOfPassengers.labelErrorMessage}
                darkMode={true}
              />
            </div>
            <div className='column is-6'>
              <TextInput
                title={privateFormIntinerary.label}
                value={itinerary}
                onChange={this.updateInput}
                type='textarea'
                name='itinerary'
                error={error && !this.validateItinerary()}
                errorText={privateFormIntinerary.labelErrorMessage}
                darkMode={true}
              />
            </div>
            <div className='column is-6'>
              <TextInput
                title={privateFormAbout.label}
                value={group}
                onChange={this.updateInput}
                type='textarea'
                name='group'
                error={error && !this.validateGroup()}
                errorText={privateFormAbout.labelErrorMessage}
                darkMode={true}
              />
            </div>
          </div>
          <div className={styles.submitContainer}>
            {success || failure ? (
              <strong className={styles.success}>
                {success
                  ? privateFormSuccessFailure.success
                  : privateFormSuccessFailure.failure}
              </strong>
            ) : null}
            {!captcha ? (
              <div style={{ marginLeft: 'auto' }}>
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY as string}
                  onChange={(c) => this.setState({ captcha: c })}
                />
              </div>
            ) : (
              <button
                className={cx('basic-button', styles.submit)}
                onClick={this.sendEnquiry}
              >
                {loading ? <Loader color='white' /> : privateFormSendButton}
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }

  updateInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<IState, keyof IFormState>);
  };

  updatePassengers = (value: number) => {
    this.setState({ passengers: value });
  };

  updateDateRange = (d: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const { startDate, endDate } = this.state;
    let end = d.endDate;
    if (startDate && endDate) {
      end = null;
    }
    this.setState({ startDate: d.startDate, endDate: end }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.toggleDropdown();
      }
    });
  };

  toggleDropdown = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
    }
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  };

  clearDateSelection = () => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ startDate: null, endDate: null });
  };

  validateName = () => {
    return this.state.name.length > 0;
  };

  validateEmail = () => {
    return validateEmail(this.state.email);
  };

  validateTime = () => {
    const { startDate } = this.state;
    return startDate !== null;
  };

  validatePassengers = () => {
    const { passengers } = this.state;
    return passengers >= 1 && passengers <= 50;
  };

  validateItinerary = () => {
    return this.state.itinerary.length > 0;
  };

  validateGroup = () => {
    return this.state.group.length > 0;
  };

  sendEnquiry = async () => {
    this.setState({ loading: true, success: false, failure: false });
    if (
      !this.validateName() ||
      !this.validateEmail() ||
      !this.validateTime() ||
      !this.validatePassengers() ||
      !this.validateItinerary() ||
      !this.validateGroup()
    ) {
      this.setState({ error: true, loading: false });
    } else {
      const resp = await this.emailApi.sendEmail(
        EMAIL_SUBJECT,
        EMAIL_ADDRESS,
        this.state.email,
        this.getEmailBody()
      );
      if (resp) {
        this.setState({ ...initialState, success: true, loading: false });
      } else {
        this.setState({ failure: true, loading: false });
      }
    }
  };

  getEmailBody = () => {
    const { name, email, itinerary, group, startDate, endDate, passengers } =
      this.state;

    const dateFormat = 'DD/MM/YYYY';
    let when = startDate
      ? moment(startDate).format(dateFormat)
      : 'Not selected';
    when +=
      startDate && endDate && !startDate.isSame(endDate, 'day')
        ? ` - ${moment(endDate).format(dateFormat)}`
        : '';

    return `
      <p><strong>Name: </strong>${name}</p>
      <p><strong>Email: </strong>${email}</p>
      <p><strong>When: </strong>${when}</p>
      <p><strong>Passengers: </strong>${passengers}</p>
      <p><strong>Itinerary: </strong>${itinerary}</p>
      <p><strong>Group: </strong>${group}</p>
    `;
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(({ locale }: IExternalProps) => (
  <StaticQuery
    query={graphql`
      query PrivateFormQuery {
        allContentfulTranslationsPage {
          edges {
            node {
              node_locale
              ...TranslationsPrivateForm
            }
          }
        }
      }
    `}
    render={(data) => (
      <PrivateForm
        localeData={
          data.allContentfulTranslationsPage.edges.filter(
            (node: { node: { node_locale: string } }) => {
              return node.node.node_locale === locale;
            }
          )[0].node
        }
        locale={locale}
      />
    )}
  />
));
