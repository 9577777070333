import axios, { AxiosInstance } from 'axios';
import { IBlueLagoon } from '../interfaces';
import { handleAxiosError } from '../utils';

interface IBlueLagoonApi {
  GetAvailability(date: Date, lang: string): Promise<IBlueLagoon[] | null>;
}

class BlueLagoonApi implements IBlueLagoonApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }

  async GetAvailability(
    date: Date,
    lang: string
  ): Promise<IBlueLagoon[] | null> {
    try {
      const result = await this.httpClient.get(
        '/api/BlueLagoon/GetAvailability',
        {
          params: { date, lang }
        }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default BlueLagoonApi;
