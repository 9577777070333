import React, { useState } from 'react';
import * as styles from './index.module.scss';
import Icon from '../Icon';
import { ILink } from '../../interfaces';
import { Link } from '../../components';

interface IProps {
  nameOfLink: string | undefined;
  links: ILink[] | undefined;
  langPath: string;
  setOpen(value: boolean): void;
}

const ToursToggleMobile: React.FunctionComponent<IProps> = ({
  nameOfLink,
  links,
  langPath,
  setOpen,
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <React.Fragment>
      <div className={styles.container}>
        <p className={styles.nameOfLink} onClick={() => setToggle(!toggle)}>
          {nameOfLink}
        </p>
        <div className={styles.icon} onClick={() => setToggle(!toggle)}>
          <Icon name={toggle ? 'NAVUP' : 'NAVDOWN'} />
        </div>
      </div>
      <div className={styles.linksContainer}>
        <ul>
          {toggle
            ? links
              ? links.map((link, index) => (
                  <li
                    key={index.toString()}
                    style={{
                      paddingBottom:
                        index === links.length - 1 ? '0px' : '20px',
                      paddingLeft: '20px',
                    }}
                    onClick={() => setOpen(false)}
                  >
                    <Link
                      className={styles.linkDark}
                      to={link.page.slug}
                      langPath={langPath}
                      key={index.toString()}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))
              : null
            : null}
        </ul>
      </div>
      {toggle ? <div className={styles.ghost} /> : null}
    </React.Fragment>
  );
};
export default ToursToggleMobile;
