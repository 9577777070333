// extracted by mini-css-extract-plugin
export var hero = "BlogHero-module--hero--1UI9M";
export var image = "BlogHero-module--image--RnTCj";
export var right = "BlogHero-module--right--2hbU9";
export var darker = "BlogHero-module--darker--Xx7QF";
export var content = "BlogHero-module--content--A4ZpA";
export var titleContainer = "BlogHero-module--titleContainer--MtPfd";
export var line = "BlogHero-module--line--2tv2h";
export var date = "BlogHero-module--date--1fUhX";
export var titleContainerMobile = "BlogHero-module--titleContainerMobile--k6YK7";
export var infoContainer = "BlogHero-module--infoContainer--3FDVC";
export var rating = "BlogHero-module--rating--20-9v";
export var info = "BlogHero-module--info--y7SX7";
export var infoItem = "BlogHero-module--infoItem--3pJIZ";
export var loading = "BlogHero-module--loading--E0jK_";
export var bookNav = "BlogHero-module--bookNav--1iPO7";
export var bookingContainer = "BlogHero-module--bookingContainer--1mWcJ";
export var booking = "BlogHero-module--booking--lQM02";
export var bookingButton = "BlogHero-module--bookingButton--2Sc9j";
export var navBanner = "BlogHero-module--navBanner--39Ixp";
export var iconDiv = "BlogHero-module--iconDiv--1nI4U";
export var ringDiv = "BlogHero-module--ringDiv--3dSpI";