import React from 'react';
import cx from 'classnames';

import * as styles from './TourBookNav.module.scss';
import TourEngine from '../../BookingEngine/TourEngine';
import TransportEngine from '../../BookingEngine/TransportEngine';
import { IProduct, ITourDetails } from '../../interfaces';
import BlueLagoonEngine from '../../BookingEngine/BlueLagoonEngine';
import PrivateEngine from '../PrivateBookineEngine/PrivateEngine/PrivateEngine';

interface IProps {
  tour: ITourDetails;
  tourTitle: string;
  productId: string;
  product: IProduct | null;
  productLoaded: boolean;
  transport?: boolean;
  blueLagoon?: boolean;
  offerBanner?: {
    title: string;
  };
}

interface IState {
  displayStickyNav: boolean;
}

class TourNav extends React.Component<IProps, IState> {
  readonly state = { displayStickyNav: false };

  top: HTMLDivElement | null = null;

  render() {
    const { tour, transport, offerBanner, blueLagoon } = this.props;
    const isPrivateTour = tour.category?.[0].slug == 'private-tours';
    if (tour.slug == 'icelandair') {
      return (
        <React.Fragment>
          <div>{this.getBookingEngine()}</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={cx(
              transport
                ? styles.transportBookingContainer
                : blueLagoon
                ? styles.blueLagoonBookingContainer
                : styles.bookingContainer,
              {
                [styles.flybusContaier]: transport,
                [styles.centerBooking]:
                  !transport && !blueLagoon && !isPrivateTour,
                [styles.privateContainer]: isPrivateTour,
              }
            )}
          >
            {offerBanner ? (
              <div className={styles.offerBanner}>{offerBanner.title}</div>
            ) : null}
            <div className={`level ${styles.booking}`}>
              {this.getBookingEngine()}
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  getBookingEngine() {
    const { transport, blueLagoon, productId, product, tourTitle, tour } =
      this.props;

    if (tour.isSpecialTour) {
      return <PrivateEngine tour={tour} />;
    }
    if (transport) {
      return (
        <TransportEngine productId={productId} product={product} tour={tour} />
      );
    } else if (blueLagoon) {
      return (
        <BlueLagoonEngine
          productId={productId}
          product={product}
          tourTitle={tourTitle}
          tour={tour}
        />
      );
    } else {
      return (
        <TourEngine
          productId={productId}
          product={product}
          tourTitle={tourTitle}
          tour={tour}
        />
      );
    }
  }
}

export default TourNav;
