import React from 'react';

export const Close = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20.5' height='20.5'>
    <path
      d='M12.425 10.25L19 3.68l1.355-1.355a.513.513 0 000-.725L18.905.15a.513.513 0 00-.725 0l-7.925 7.925L2.325.15a.513.513 0 00-.725 0L.15 1.6a.513.513 0 000 .725l7.926 7.926L.15 18.176a.513.513 0 000 .725l1.45 1.45a.513.513 0 00.725 0l7.926-7.925 6.57 6.57 1.355 1.355a.513.513 0 00.725 0l1.45-1.45a.513.513 0 000-.725z'
      fill='#303030'
    />
  </svg>
);
export default Close;
