import React, { FC, Suspense, lazy } from 'react';
import { IconProps as PhosphorProps } from '@phosphor-icons/react';
import dynamicIconImports from './dynamicIconImports';

interface IPhosphorIconProps extends Omit<PhosphorProps, 'icon'> {
  name: string;
}

const fallback = (
  <div style={{ background: '#ddd', width: 24, height: 24 }} /> // Adjust size if necessary
);

const PhosphorIcon: FC<IPhosphorIconProps> = ({ name, ...props }) => {
  const IconComponent = dynamicIconImports(name);

  if (typeof window === 'undefined') {
    return fallback; // For SSR, avoid using Suspense and directly return the fallback or null
  } else {
    return (
      <Suspense fallback={fallback}>
        <IconComponent {...props} />
      </Suspense>
    );
  }
};

export default PhosphorIcon;
