import React from 'react';
import { connect } from 'react-redux';
import ls from 'local-storage';

import { setCart } from '../redux/actions/cartActions';
import {
  ICurrencyOption,
  IProduct,
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ITransportRouteStation,
  ITransportStartTimes,
  ISelectTime,
  IPriceCategory,
  ITransportPersonCounter,
  AddOrRemove,
  ITransportCart,
  ICart,
  ITransportLeg,
  IReducers,
  ITourDetails,
  IFlybus,
  ITravelers,
} from '../interfaces';
import { Tire } from '../icons';
import { Loader } from '../components';
import TransportBooking from './TransportBooking/TransportBooking';
import TransportBookingMobile from './TransportBooking/TransportBookingMobile';
import { TransportApi, CartApi } from '../api';
import moment, { Moment } from 'moment';
import { formatAvailabilityDate } from '../utils';

interface IProps {
  tour: ITourDetails;
  currency: ICurrencyOption;
  productId: string;
  product: IProduct | null;
  cartObject: ICart | null;
  isMobile?: boolean;
  lang: string;
  setCart(newCart: ICart): void;
}

interface IState {
  transport: ITransport | null;
  startTimes: ITransportStartTimes | null;
  endTimes: ITransportStartTimes | null;
  sortedTransportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  isLoading: boolean;
  isLoadingStartTimes: boolean;
  isLoadingEndTimes: boolean;
  previousEndPlace: ISelectPickupValue | null;
  transportRoutes: ITransportOption | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartDate: Moment;
  selectedEndDate: Moment | null;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  returnTicket: boolean;
  timeError: boolean;
}

class TransportEngine extends React.Component<IProps, IState> {
  transportApi = new TransportApi();
  cartApi = new CartApi();
  readonly state: IState = {
    transport: null,
    startTimes: null,
    endTimes: null,
    sortedTransportRoutes: null,
    isLoading: true,
    isLoadingStartTimes: true,
    isLoadingEndTimes: true,
    transportRoutes: null,
    startPlaces: null,
    endPlaces: null,
    previousEndPlace: null,
    selectedStartPlace: null,
    selectedEndPlace: null,
    selectedStartDate: moment(new Date()),
    selectedEndDate: null, // moment(new Date()).add(1, 'week'),
    selectedStartTime: null,
    selectedEndTime: null,
    starTimeOptions: null,
    endTimeOptions: null,
    personCounter: null,
    addingToCart: false,
    returnTicket: false,
    timeError: false,
  };

  async componentDidMount() {
    const { currency } = this.props;
    await this.getTransportAvailability(currency.value);
    if (!!this.state.transport && !!this.state.transport.RouteStations) {
      const sortedStations = this.sortTransportRoutes(
        this.state.transport.RouteStations,
        this.state.transport.Legs
      );
      this.setState({ sortedTransportRoutes: sortedStations });
    } else {
      this.setState({ isLoading: false });
    }

    if (!!this.state.transport && !!this.state.transport.PriceCategories) {
      this.setPersonCounter(this.state.transport.PriceCategories);
    }

    if (this.state.sortedTransportRoutes) {
      const options = this.getPlacesOptions(this.state.sortedTransportRoutes);
      this.setState({ startPlaces: options }, () => {
        if (
          !!this.state.transport &&
          !!this.state.transport.DefaultFromStationId
        ) {
          this.setDefaultPlaces();
        }
      });
    }
    this.setTimeOptions('Start');
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.currency !== this.props.currency) {
      this.updateCurrency();
    }
    if (
      this.state.selectedStartPlace !== prevState.selectedStartPlace &&
      !!this.state.selectedStartPlace
    ) {
      const stations = this.getEndPlaceIds(
        this.state.selectedStartPlace.routeId
      );
      if (this.state.transport && this.state.transport.Legs) {
        this.setEndPlaces(stations);
      }
    }

    if (
      !!this.state.selectedStartPlace &&
      !!this.state.selectedEndPlace &&
      !!this.state.selectedStartDate &&
      this.state.selectedStartDate !== prevState.selectedStartDate
    ) {
      this.setTimeOptions('Start');
    }
    if (
      !!this.state.selectedStartPlace &&
      !!this.state.selectedEndPlace &&
      !!this.state.selectedEndDate &&
      this.state.selectedEndDate !== prevState.selectedEndDate
    ) {
      this.setTimeOptions('End');
    }
  }
  render() {
    const {
      isLoading,
      transport,
      sortedTransportRoutes,
      startPlaces,
      endPlaces,
      personCounter,
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate,
      selectedEndDate,
      starTimeOptions,
      endTimeOptions,
      selectedStartTime,
      selectedEndTime,
      addingToCart,
      returnTicket,
    } = this.state;
    const { productId, isMobile, tour } = this.props;

    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            margin: 20,
          }}
        >
          <Loader />
        </div>
      );
    } else if (
      (!!transport && !transport.NextAvailableDate && !isLoading) ||
      !transport
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <img src={Tire} alt='error' style={{ height: 35 }} />
          {/*TODO Þýðing*/}
          <p style={{ padding: 10 }}>
            This transport seems to be missing or is sold out!
          </p>
        </div>
      );
    } else {
      if (isMobile) {
        return (
          <TransportBookingMobile
            productId={productId}
            transport={transport}
            transportRoutes={sortedTransportRoutes}
            personCounter={personCounter}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            addingToCart={addingToCart}
            changeSelectedPlace={this.changeSelectedPlace}
            changeSelectedDate={this.changeSelectedDate}
            changeSelectedTime={this.changeSelectedTime}
            changePersonCounter={this.changePersonCounter}
            getTotalPersonCount={this.getTotalPersonCount}
            getTimeError={() => {
              return this.state.timeError;
            }} // TODO
            getPersonCount={this.getPersonCount}
            toggleReturnTicket={this.toggleReturnTicket}
            addToCart={this.addToCart}
            returnTicket={returnTicket}
            tour={tour}
            isLoadingStartTimes={this.state.isLoadingStartTimes}
            isLoadingEndTimes={this.state.isLoadingEndTimes}
          />
        );
      } else {
        return (
          <TransportBooking
            productId={productId}
            transport={transport}
            transportRoutes={sortedTransportRoutes}
            personCounter={personCounter}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            addingToCart={addingToCart}
            changeSelectedPlace={this.changeSelectedPlace}
            changeSelectedDate={this.changeSelectedDate}
            changeSelectedTime={this.changeSelectedTime}
            changePersonCounter={this.changePersonCounter}
            getTotalPersonCount={this.getTotalPersonCount}
            getPersonCount={this.getPersonCount}
            toggleReturnTicket={this.toggleReturnTicket}
            addToCart={this.addToCart}
            returnTicket={returnTicket}
            tour={tour}
            isLoadingStartTimes={this.state.isLoadingStartTimes}
            isLoadingEndTimes={this.state.isLoadingEndTimes}
          />
        );
      }
    }
  }
  setDefaultPlaces = () => {
    const { transport, startPlaces } = this.state;
    if (!!transport && !!transport.DefaultFromStationId && !!startPlaces) {
      startPlaces.map((place) => {
        if (!!place && !!place.options) {
          place.options.map((option) => {
            if (
              !!option &&
              !!option.routeId &&
              option.routeId === transport.DefaultFromStationId
            ) {
              this.setState({ selectedStartPlace: option });
            }
            if (
              !!option &&
              !!option.routeId &&
              option.routeId === transport.DefaultToStationId
            ) {
              this.setState({ selectedEndPlace: option });
            }
          });
        }
      });
    }
  };

  toggleReturnTicket = (ticket: boolean) => {
    this.setState({ returnTicket: ticket });
  };

  setEndPlaces = async (stations: ITransportRouteStation[] | null) => {
    const { currency } = this.props;
    const { returnTicket } = this.state;
    const sortedEndStations = this.sortEndRoutes(stations);
    if (sortedEndStations) {
      const endPlaces = this.getPlacesOptions(sortedEndStations);
      if (endPlaces) {
        let total = 0;
        let prevIsValid = false;
        // check if prevendPLace is valid and the total number of endPlaces
        endPlaces.forEach((routeStation) => {
          total += routeStation.options.length;
          // if prevIsValid is false then check in the options if it exists there
          // if we have found one that exists then we dont need to search other options
          prevIsValid = !prevIsValid
            ? (prevIsValid = routeStation.options.some((endPlace) => {
                return endPlace === this.state.previousEndPlace;
              }))
            : prevIsValid;
        });
        // if total endplace options is 1 then pick it right away
        // if previous endplace is in the current endplace options then select it
        // else leave it empty and user has to select a new endPlace
        if (total === 1) {
          this.setState({ selectedEndPlace: endPlaces[0].options[0] });
          await this.getTransportStartTimes(
            'Start',
            currency.value,
            endPlaces[0].options[0]
          );
          if (returnTicket) {
            await this.getTransportStartTimes('End', currency.value);
          }
        } else if (prevIsValid) {
          this.setState({ selectedEndPlace: this.state.previousEndPlace });
          await this.getTransportStartTimes(
            'Start',
            currency.value,
            this.state.previousEndPlace || undefined
          );
          if (returnTicket) {
            await this.getTransportStartTimes(
              'End',
              currency.value,
              this.state.previousEndPlace || undefined
            );
          }
        }

        // finally set the new endPlace list
        this.setState({ endPlaces });
      }
    }
  };

  updateCurrency = async () => {
    const { currency, productId, lang } = this.props;
    const { returnTicket } = this.state;
    this.setState({ isLoading: true });
    const id = parseInt(productId, 10);
    const availability = await this.transportApi.getTransport(
      id,
      currency.value,
      lang
    );
    await this.getTransportStartTimes('Start', currency.value);
    if (returnTicket) {
      await this.getTransportStartTimes('End', currency.value);
    }
    await this.getTransportPrices();
    if (availability) {
      this.setState({
        transport: availability,
        isLoading: false,
      });
    }
  };

  getTransportAvailability = async (currency: string) => {
    const { productId, lang } = this.props;
    const id = parseInt(productId, 10);
    const availability = await this.transportApi.getTransport(
      id,
      currency,
      lang
    );
    if (availability) {
      if (availability.NextAvailableDate) {
        this.changeSelectedDate(moment(availability.NextAvailableDate), false);
      }
      this.setState({
        transport: availability,
        isLoading: false,
      });
    }
  };

  async setTimeOptions(startOrEnd: ITransportList) {
    const { currency } = this.props;
    await this.getTransportStartTimes(startOrEnd, currency.value);
    await this.getTransportPrices();
  }

  async getTransportStartTimes(
    startOrEnd: ITransportList,
    currency: string,
    newEndPlace?: ISelectPickupValue
  ) {
    const {
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate,
      selectedEndDate,
      returnTicket,
    } = this.state;
    const { lang } = this.props;
    const endPlace = selectedEndPlace ? selectedEndPlace : newEndPlace;
    if (!!selectedStartPlace && !!endPlace) {
      const productId = parseInt(this.props.productId, 10);
      const fromId = selectedStartPlace.routeId;
      const toId = endPlace.routeId;

      if (startOrEnd === 'Start') {
        this.setState({ isLoadingStartTimes: true });
        const startTimes = await this.transportApi.getStartTimes(
          productId,
          fromId,
          toId,
          selectedStartDate,
          currency,
          false,
          lang
        );
        this.setState({ isLoadingStartTimes: false });
        if (startTimes) {
          if (startTimes.Prices) {
            this.changePersonCounterPrice(startTimes.Prices);
          }
          this.setState({ startTimes });

          this.getStartTimeOptions(startTimes, 'Start');
        }
      } else if (startOrEnd === 'End') {
        this.setState({ isLoadingEndTimes: true });
        let endTimes;
        if (
          !!selectedEndDate &&
          moment(selectedEndDate).isValid() &&
          returnTicket
        ) {
          endTimes = await this.transportApi.getStartTimes(
            productId,
            toId,
            fromId,
            selectedEndDate,
            currency,
            false,
            lang
          );
        }
        this.setState({ isLoadingEndTimes: false });
        if (endTimes) {
          this.setState({ endTimes });
          this.getStartTimeOptions(endTimes, 'End');
        }
      }

      /* if (!!startTimes && !startTimes.StartTimes) {
        this.changeSelectedDate(moment(selectedStartDate).add(1, 'day'), false);
        startTimes = await this.transportApi.getStartTimes(
          productId,
          fromId,
          toId,
          moment(selectedStartDate).add(1, 'day'),
          currency,
          false
        );
      } */
    }
  }

  async getTransportPrices() {
    const { currency, lang } = this.props;
    const {
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate,
      selectedStartTime,
    } = this.state;
    if (!!selectedStartPlace && !!selectedEndPlace && !!selectedStartTime) {
      const productId = parseInt(this.props.productId, 10);
      const fromId = selectedStartPlace.routeId;
      const toId = selectedEndPlace.routeId;
      const prices = await this.transportApi.getPrices(
        productId,
        fromId,
        toId,
        selectedStartDate,
        selectedStartTime.label.split(' ')[0],
        currency.label,
        lang
      );
      if (prices) {
        this.changePersonCounterPrice(prices);
      }
    }
  }
  sortTransportRoutes = (
    stations: ITransportRouteStation[] | null,
    legs: ITransportLeg[] | null
  ) => {
    if (stations) {
      const array: { [key: string]: any } = [];
      if (legs) {
        const fromStationIds = legs.map((leg) => leg.FromStationId);
        stations.map((route) => {
          if (!!route && !!route.Places && fromStationIds.includes(route.Id)) {
            if (!array[route.Type]) {
              array[route.Type] = [{ id: route.Id, places: route.Places }];
            } else {
              array[route.Type].push({ id: route.Id, places: route.Places });
            }
          }
        });
      }
      return array as ISortedTransport;
    }
    return null;
  };
  sortEndRoutes = (stations: ITransportRouteStation[] | null) => {
    if (stations) {
      const array: { [key: string]: any } = [];
      stations.map((route) => {
        if (!!route && !!route.Places) {
          if (!array[route.Type]) {
            array[route.Type] = [{ id: route.Id, places: route.Places }];
          } else {
            array[route.Type].push({ id: route.Id, places: route.Places });
          }
        }
      });
      return array as ISortedTransport;
    }

    return null;
  };

  getPlacesOptions = (placeList: ISortedTransport) => {
    if (placeList) {
      const options = Object.keys(placeList).map((key) => {
        const array: ISelectPickupValue[] = [];
        placeList[key].map((type) => {
          if (type.places) {
            type.places.map((place) => {
              array.push({
                label: place.Name,
                value: place.Id + '-' + type.id,
                routeId: type.id,
              });
            });
          }
        });
        return { label: key, options: array };
      });
      return options;
    }
    return null;
  };

  changeSelectedPlace = async (
    place: ISelectPickupValue,
    list: ITransportList
  ) => {
    if (list === 'Start') {
      this.setState({
        selectedStartPlace: place,
        selectedEndPlace: null,
        previousEndPlace: this.state.selectedEndPlace,
        startTimes: null,
        endTimes: null,
        starTimeOptions: null,
        endTimeOptions: null,
        selectedStartTime: null,
        selectedEndTime: null,
      });
    } else if (list === 'End') {
      this.setState(
        {
          selectedEndPlace: place,
          startTimes: null,
          endTimes: null,
          starTimeOptions: null,
          endTimeOptions: null,
          selectedStartTime: null,
          selectedEndTime: null,
        },
        () => {
          this.setTimeOptions('Start');
          if (this.state.returnTicket) {
            this.setTimeOptions('End');
          }
        }
      );
    }
  };

  getEndPlaceIds(routeId: number) {
    const { transport } = this.state;
    if (!!transport && !!transport.Legs) {
      const legIds = transport.Legs.map((leg) => {
        if (leg.FromStationId === routeId) {
          return leg.ToStationId;
        }
        return null;
      }).filter((elem) => !!elem);
      const routes = transport.RouteStations.map((route) => {
        if (legIds.includes(route.Id)) {
          return route;
        }
        return null;
      }).filter((elem) => !!elem);
      return routes.filter((elem) => !!elem) as ITransportRouteStation[];
    }
    return [] as ITransportRouteStation[];
  }

  /*   getStartTimeOptions(times: ITransportStartTimes | null) {
    if (!!times && !!times.StartTimes && !!times.ReturnStartTimes) {
      const startTimes = [] as ISelectTime[];
      const endTimes = [] as ISelectTime[];
      times.StartTimes.map(time => {
        startTimes.push({ label: time.StartTime, value: time.Id });
      });
      times.ReturnStartTimes.map(time => {
        endTimes.push({ label: time.StartTime, value: time.Id });
      });

      this.setState({ starTimeOptions: startTimes, endTimeOptions: endTimes });
    }
  } */
  getStartTimeOptions(
    times: ITransportStartTimes | null,
    startOrEnd: ITransportList
  ) {
    if (!!times && startOrEnd === 'Start' && !!times.StartTimes) {
      const array = [] as ISelectTime[];
      times.StartTimes.map((time) => {
        array.push({
          label: time.PeakTime
            ? time.StartTime + ' - lower price'
            : time.StartTime,
          value: time.Id,
        });
      });
      // if there is only one time then select it right away
      if (array.length === 1) {
        this.setState({
          starTimeOptions: array,
          selectedStartTime: array[0],
        });
      } else {
        this.setState({ starTimeOptions: array });
      }
    } else if (!!times && startOrEnd === 'End' && !!times.StartTimes) {
      const array = [] as ISelectTime[];
      times.StartTimes.map((time) => {
        if (
          (!!time &&
            !!time.Available &&
            this.getTotalPersonCount() <= time.Available) ||
          (!!time && !!time.UnlimitedAvailability)
        ) {
          array.push({
            label: time.PeakTime
              ? time.StartTime + ' - lower price'
              : time.StartTime,
            value: time.Id,
          });
        }
      });
      this.setState({ endTimeOptions: array });
    }
  }
  changeSelectedDate = (date: Moment, transportReturn: boolean) => {
    if (!transportReturn) {
      // this.clearPersonCounter();
      this.setState(
        {
          selectedStartDate: date,
          selectedStartTime: null,
          starTimeOptions: null,
        },
        () => {
          if (
            !!this.state.selectedEndDate &&
            date.isAfter(this.state.selectedEndDate)
          ) {
            this.setState({
              selectedEndDate: null,
              selectedEndTime: null,
              endTimes: null,
              endTimeOptions: null,
            });
          }
        }
      );
    } else {
      this.setState({
        selectedEndDate: date,
        selectedEndTime: null,
        endTimeOptions: null,
        endTimes: null,
      });
    }
  };

  changeSelectedTime = (time: ISelectTime, transportReturn: boolean) => {
    const { startTimes } = this.state;
    if (!transportReturn) {
      if (!!startTimes && !!startTimes.StartTimes) {
        const availability = startTimes.StartTimes.find(
          (s) => s.Id === time.value
        );
        if (
          !!availability &&
          !!availability.Available &&
          availability.Available < this.getTotalPersonCount()
        ) {
          this.clearPersonCounter();
        }
      }
      this.setState({ selectedStartTime: time }, () =>
        this.getTransportPrices()
      );
    } else {
      this.setState({ selectedEndTime: time });
    }
  };

  changePersonCounter = (addOrRemove: AddOrRemove, personType: string) => {
    const { personCounter, selectedStartTime } = this.state;
    if (!selectedStartTime) {
      this.setState({ timeError: true });
      return;
    }
    this.setState({ timeError: false });
    if (addOrRemove === 'addOne') {
      if (!!personCounter && !!personCounter[personType]) {
        const isAvailable =
          this.getAvailabilityLeft() > this.getTotalPersonCount();
        const maxPerMaster = this.checkMaxPerMaster(personType);
        if (
          isAvailable &&
          (personCounter[personType].MaxPerMaster === 0 ||
            maxPerMaster > personCounter[personType].Count)
        ) {
          const newPersonCounter = { ...personCounter };
          newPersonCounter[personType].Count =
            newPersonCounter[personType].Count + 1;
          this.setState({ personCounter: newPersonCounter });
        }
      }
    } else if (addOrRemove === 'removeOne') {
      if (personCounter) {
        const newPersonCounter = { ...personCounter };
        const newPersonCounterItem = newPersonCounter[personType];
        if (
          !!newPersonCounter &&
          !!newPersonCounter[personType] &&
          newPersonCounterItem.Count > 0
        ) {
          newPersonCounter[personType].Count =
            newPersonCounter[personType].Count - 1;
          this.checkCounter();

          this.setState({ personCounter: newPersonCounter });
        }
      }
    }
  };

  clearPersonCounter() {
    const { personCounter } = this.state;
    if (personCounter) {
      const newCounter = { ...personCounter };
      Object.keys(newCounter).map((key) => {
        if (!!newCounter[key] && !!newCounter[key].Count) {
          newCounter[key].Count = 0;
        }
      });
      this.setState({ personCounter: newCounter });
    }
  }

  getAvailabilityLeft = () => {
    const {
      selectedStartTime,
      startTimes /* selectedEndTime, endTimes, returnTicket */,
    } = this.state;
    if (!!selectedStartTime && !!startTimes && !!startTimes.StartTimes) {
      const availability = startTimes.StartTimes.find(
        (b) => b.Id === selectedStartTime.value
      );
      /*       if(returnTicket && !!endTimes && !!selectedEndTime){
        const endTimeAvailability = find(
          endTimes.StartTimes,
          b => b.Id === selectedEndTime.value
        );
          if(!!availability && !!endTimeAvailability && !!availability.Available && !!endTimeAvailability.Available && availability.Available < ){

          }
      } */
      if (!!availability && availability.UnlimitedAvailability) {
        return 1000;
      } else if (!!availability && !!availability.Available) {
        return availability.Available;
      }
    }
    return 0;
  };

  checkCounter() {
    const { personCounter } = this.state;
    if (personCounter) {
      const newPersonCounter = { ...personCounter };
      Object.keys(personCounter).map((personType) => {
        const maxPerMasterNumber = this.checkMaxPerMaster(personType);
        if (
          (maxPerMasterNumber !== 0 ||
            personCounter[personType].MaxPerMaster !== 0) &&
          maxPerMasterNumber < personCounter[personType].Count
        ) {
          newPersonCounter[personType].Count = maxPerMasterNumber;
        }
      });
      this.setState({ personCounter: newPersonCounter });
    }
  }
  checkMaxPerMaster(personType: string) {
    const { personCounter } = this.state;
    if (personCounter) {
      const newPersonCounter = { ...personCounter };
      let counter = 0;
      Object.keys(personCounter).map((key) => {
        const personCounterItem = personCounter[key];
        const newPersonCounterItem = newPersonCounter[personType];
        if (
          !!newPersonCounterItem &&
          !!newPersonCounterItem.MaxPerMaster &&
          personCounterItem.Id === newPersonCounterItem.MasterCategoryId
        ) {
          counter = personCounterItem.Count * newPersonCounterItem.MaxPerMaster;
        }
      });
      return counter;
    }
    return 0;
  }
  resetDefaultPersonCounter(
    personCounter: ITransportPersonCounter,
    priceCategories: IPriceCategory[],
    userFlybus: ITravelers[] | null
  ) {
    let personCounterReset: ITransportPersonCounter = personCounter;
    if (userFlybus) {
      personCounterReset = priceCategories.reduce((acc, val) => {
        const key = val.Name;
        const personFlybus = userFlybus.find(
          (t) =>
            t.Type.charAt(0).toUpperCase() + t.Type.slice(1) ==
            (key == 'Fullorðnir' ? 'Adults' : key)
        );
        if (!!key && !acc[key]) {
          acc[key] = {
            Id: val.Id,
            Name: val.Name,
            Dependent: val.Dependent,
            MasterCategoryId: val.MasterCategoryId,
            MaxPerMaster: val.MaxPerMaster ? val.MaxPerMaster : 0,
            Count: !!personFlybus ? personFlybus?.Number : 0,
            MinCount: val.MinCount,
            MinAge: val.MinAge,
            MaxAge: val.MaxAge,
            Price: val.Price,
            ReturnPrice: val.ReturnPrice,
            Default: val.Default,
            Occupancy: val.Occupancy,
          };
        }
        return acc;
      }, {} as ITransportPersonCounter);
      //personCounter.Adults.Count = 0;
    }
    // else {
    //   personCounter.Fullorðnir.Count = 0;
    // }
    //personCounter.Youth.Count = 0;
    //personCounter.Children.Count = 0;
    //this.setState({ personCounter: personCounterReset });
    return personCounterReset;
  }
  setFlybusPersonCounter(
    personCounter: ITransportPersonCounter | null,
    priceCategories: IPriceCategory[]
  ) {
    const storageUserFlybus = localStorage.getItem('flybusData');
    const userFlybusData: IFlybus | null = storageUserFlybus
      ? JSON.parse(storageUserFlybus)
      : null;
    if (userFlybusData && personCounter) {
      personCounter = this.resetDefaultPersonCounter(
        personCounter,
        priceCategories,
        userFlybusData?.Travelers
      );
    }
    return personCounter;
  }
  setPersonCounter(priceCategories: IPriceCategory[]) {
    const personCounter = priceCategories.reduce((acc, val) => {
      const key = val.Name;
      if (!!key && !acc[key]) {
        acc[key] = {
          Id: val.Id,
          Name: val.Name,
          Dependent: val.Dependent,
          MasterCategoryId: val.MasterCategoryId,
          MaxPerMaster: val.MaxPerMaster ? val.MaxPerMaster : 0,
          Count: val.Default ? 1 : 0,
          MinCount: val.MinCount,
          MinAge: val.MinAge,
          MaxAge: val.MaxAge,
          Price: val.Price,
          ReturnPrice: val.ReturnPrice,
          Default: val.Default,
          Occupancy: val.Occupancy,
        };
      }
      return acc;
    }, {} as ITransportPersonCounter);
    const storageUserFlybus = localStorage.getItem('flybusData');
    const userFlybusData: string | null = storageUserFlybus
      ? JSON.parse(storageUserFlybus)
      : null;
    if (userFlybusData) {
      const personCounterFlybus: ITransportPersonCounter | null =
        this.setFlybusPersonCounter(personCounter, priceCategories);
      this.setState({ personCounter: personCounterFlybus });
    } else {
      this.setState({ personCounter });
    }
  }

  changePersonCounterPrice(priceCategories: IPriceCategory[]) {
    const { personCounter } = this.state;
    if (personCounter) {
      const newCounter = { ...personCounter };
      Object.keys(personCounter).map((key) => {
        priceCategories.map((category) => {
          if (category.Name === key) {
            newCounter[key].Price = category.Price;
            newCounter[key].ReturnPrice = category.ReturnPrice;
          }
        });
      });
      this.setState({ personCounter: newCounter });
    }
  }

  getTotalPersonCount = () => {
    const { personCounter } = this.state;
    let total = 0;
    if (personCounter) {
      Object.keys(personCounter).map((personType) => {
        if (personCounter[personType]) {
          total += personCounter[personType].Count;
        }
      });
      return total;
    }
    return 0;
  };

  getPersonCount = (name: string) => {
    const { personCounter } = this.state;
    if (!!personCounter && !!personCounter[name]) {
      return personCounter[name].Count;
    }
    return 0;
  };

  getLegId(startOrEnd: ITransportList): ITransportLeg | null {
    const { transport, selectedStartPlace, selectedEndPlace } = this.state;
    if (
      !!transport &&
      !!transport.Legs &&
      !!selectedStartPlace &&
      !!selectedEndPlace
    ) {
      let startLeg;
      let endLeg;
      if (startOrEnd === 'Start') {
        startLeg = transport.Legs.find((leg) => {
          if (
            leg.FromStationId === selectedStartPlace.routeId &&
            leg.ToStationId === selectedEndPlace.routeId
          ) {
            return true;
          }
          return false;
        });
      } else {
        endLeg = transport.Legs.find((leg) => {
          if (
            leg.ToStationId === selectedStartPlace.routeId &&
            leg.FromStationId === selectedEndPlace.routeId
          ) {
            return true;
          }
          return false;
        });
      }
      if (startLeg) {
        return startLeg;
      } else if (endLeg) {
        return endLeg;
      }
    }
    return null;
  }

  createCartObject() {
    const {
      personCounter,
      selectedStartDate,
      selectedStartPlace,
      selectedStartTime,
      selectedEndPlace,
      selectedEndDate,
      selectedEndTime,
      transport,
      returnTicket,
    } = this.state;
    const guests: Array<{ Id: string }> = [];
    if (personCounter) {
      Object.keys(personCounter).map((key) => {
        const Id = personCounter[key].Id.toString();
        const personCounterItem = personCounter[key];
        const person = { ...personCounter };
        if (
          !!personCounterItem &&
          !!personCounterItem.MinCount &&
          personCounterItem.MinCount > person[key].Count
        ) {
          return;
        }
        for (let i = 0; i < person[key].Count; i++) {
          guests.push({
            Id,
          });
        }
      });
    }
    const product = {
      Id: parseInt(this.props.productId, 10),
    };

    const startLegId = this.getLegId('Start');
    const endLegId = this.getLegId('End');
    if (
      !transport ||
      !selectedStartDate ||
      !selectedStartPlace ||
      !selectedStartTime ||
      !selectedEndPlace ||
      !personCounter ||
      !startLegId
    ) {
      return;
    }
    const object: ITransportCart = {
      BookingType: 'TRANSPORT',
      Guests: guests,
      Product: product,
      Date: formatAvailabilityDate(selectedStartDate),
      StartLegId: startLegId.Id,
      StartTimeId: selectedStartTime.value,
      PickupAddress: { Id: parseInt(selectedStartPlace.value, 10) },
      DropoffAddress: { Id: parseInt(selectedEndPlace.value, 10) },
    };

    if (returnTicket && !!selectedEndDate && !!endLegId && !!selectedEndTime) {
      const withReturn = {
        ...object,
        ReturnDate: formatAvailabilityDate(selectedEndDate),
        ReturnLegId: endLegId.Id,
        ReturnStartTimeId: selectedEndTime.value,
        ReturnPickupAddress: { Id: parseInt(selectedEndPlace.value, 10) },
        ReturnDropoffAddress: { Id: parseInt(selectedStartPlace.value, 10) },
      };

      return withReturn;
    }

    return object;
  }

  addToCart = async () => {
    const { currency, cartObject, lang } = this.props;
    let cartId: string;
    if (!cartObject) {
      cartId = ls.get('cartId');
    } else {
      cartId = cartObject.Id;
    }
    const newCartObject = this.createCartObject();

    if (newCartObject) {
      this.setState({ addingToCart: true });
      const newCart = await this.cartApi.addToCart(
        currency.value,
        cartId,
        newCartObject,
        lang
      );
      let success = false;
      if (newCart) {
        ls.set('cartId', newCart.Id);
        this.props.setCart(newCart);
        success = true;
      }
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({ addingToCart: false });
      return success;
    }
    this.setState({ addingToCart: false });
    return false;
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer, translationReducers } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { lang } = translationReducers;
  return { cartObject, currency, lang };
};

export default connect(mapStateToProps, {
  setCart,
})(TransportEngine);
