import React from 'react';

export const CartEmpty = () => (
  <svg
    width='52'
    height='47'
    viewBox='0 0 52 47'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.11111 1.125h7L15.9861 35.25h28l7-21H11.14M16.8611 45.75c1.933 0 3.5-1.567 3.5-3.5s-1.567-3.5-3.5-3.5-3.5 1.567-3.5 3.5 1.567 3.5 3.5 3.5zM39.6111 45.75c1.933 0 3.5-1.567 3.5-3.5s-1.567-3.5-3.5-3.5-3.5 1.567-3.5 3.5 1.567 3.5 3.5 3.5z'
      stroke='#25A493'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default CartEmpty;
