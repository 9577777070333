import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
  IReducers,
  ITranslationsExtras,
} from '../../interfaces';
import { BookingDropdown } from '../../BookingEngine/components';
import { Calendar } from '../../icons';
import { formatDate } from '../../utils';
import moment from 'moment';
import {
  DayPickerSingleDateController,
  isInclusivelyBeforeDay,
} from 'react-dates';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import * as styles from './Questions.module.scss';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  locale: string;
  changeVal(date: string | null, time: string | null): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsExtras;
}

function stableDummy() {
  return undefined;
}

const DateTimeQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion, localeData, changeVal } = props;
  if (bookingQuestion) {
    return (
      <div>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}

        <p
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </p>
        <BookingDropdown
          Icon={{ icon: Calendar, alt: 'Calendar' }}
          placeHolder={
            !!bookingQuestion && !!bookingQuestion.value
              ? bookingQuestion.time
                ? formatDate(moment(bookingQuestion.value)) +
                  ' ' +
                  bookingQuestion.time
                : formatDate(moment(bookingQuestion.value))
              : localeData.extrasDateAndTimeQuestionPlaceholder
          }
          isValid={bookingQuestion ? bookingQuestion.isValid : undefined}
          backgroundColor={true}
        >
          <div className='columns'>
            <div className='column'>
              <DayPickerSingleDateController
                initialVisibleMonth={null}
                key={bookingQuestion.Id}
                date={
                  !!bookingQuestion && !!bookingQuestion.value
                    ? moment(bookingQuestion.value)
                    : null
                }
                focused={true}
                onFocusChange={stableDummy}
                onDateChange={(e) =>
                  !!bookingQuestion && !!bookingQuestion.Id && !!e
                    ? changeVal(e.format('YYYY-MM-DD'), null)
                    : null
                }
                isOutsideRange={(day) =>
                  isInclusivelyBeforeDay(
                    day,
                    moment(new Date()).subtract(1, 'day')
                  )
                }
                hideKeyboardShortcutsPanel={true}
              />
            </div>
            <div
              className='column'
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                width: 200,
              }}
            >
              <TimePicker
                showSecond={false}
                value={
                  !!bookingQuestion && !!bookingQuestion.time
                    ? moment(bookingQuestion.time, 'HH:mm')
                    : moment(new Date())
                }
                onChange={(e) =>
                  !!bookingQuestion &&
                  !!bookingQuestion.Id &&
                  !!e &&
                  !!moment(e)
                    ? changeVal(null, e.format('HH:mm').toString())
                    : null
                }
                format={'HH:mm'}
                use12Hours={false}
                inputReadOnly={true}
                clearIcon={<div />}
              />
            </div>
          </div>
        </BookingDropdown>
      </div>
    );
  } else if (contactQuestion) {
    return (
      <div>
        <p
          className={cx(styles.label, {
            [styles.required]: contactQuestion.Required,
          })}
        >
          {contactQuestion.Label}
        </p>
        <BookingDropdown
          Icon={{ icon: Calendar, alt: 'Calendar' }}
          placeHolder={
            !!contactQuestion && !!contactQuestion.value
              ? contactQuestion.time
                ? formatDate(moment(contactQuestion.value)) +
                  ' ' +
                  contactQuestion.time
                : formatDate(moment(contactQuestion.value))
              : localeData.extrasDateAndTimeQuestionPlaceholder
          }
          isValid={contactQuestion ? contactQuestion.isValid : undefined}
        >
          <div className='columns'>
            <div className='column'>
              <DayPickerSingleDateController
                initialVisibleMonth={null}
                key={contactQuestion.Id}
                date={
                  !!contactQuestion && !!contactQuestion.value
                    ? moment(contactQuestion.value)
                    : null
                }
                focused={true}
                onFocusChange={stableDummy}
                onDateChange={(e) =>
                  !!contactQuestion && !!contactQuestion.Id && !!e
                    ? changeVal(e.format('YYYY-MM-DD'), null)
                    : null
                }
                isOutsideRange={(day) =>
                  isInclusivelyBeforeDay(
                    day,
                    moment(new Date()).subtract(1, 'day')
                  )
                }
                hideKeyboardShortcutsPanel={true}
              />
            </div>
            <div
              className='column'
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                width: 200,
              }}
            >
              <TimePicker
                showSecond={false}
                value={
                  !!contactQuestion && !!contactQuestion.time
                    ? moment(contactQuestion.time, 'HH:mm')
                    : moment(new Date())
                }
                onChange={(e) =>
                  !!contactQuestion &&
                  !!contactQuestion.Id &&
                  !!e &&
                  !!moment(e)
                    ? changeVal(null, e.format('HH:mm').toString())
                    : null
                }
                format={'HH:mm'}
                use12Hours={false}
                inputReadOnly={true}
                clearIcon={<div />}
              />
            </div>
          </div>
        </BookingDropdown>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({ bookingQuestion, contactQuestion, locale, changeVal }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query DateTimeQuestion {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                extrasDateAndTimeQuestionPlaceholder
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <DateTimeQuestion
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            bookingQuestion={bookingQuestion}
            contactQuestion={contactQuestion}
            locale={locale}
            changeVal={changeVal}
          />
        );
      }}
    />
  )
);
