import axios, { AxiosInstance } from 'axios';
import { Moment } from 'moment';

import { IAvailability, ISearchAvailability } from '../interfaces';
import { formatAvailabilityDate, handleAxiosError } from '../utils';

interface IAvailabilityApi {
  getAvailability(
    productId: string,
    currency: string,
    fromDate: string,
    toDate: string,
    lang: string
  ): Promise<IAvailability[] | null>;

  getNextAvailableDay(productId: string): Promise<Date | null>;
  searchForAvailability(
    fromDate: Moment,
    toDate: Moment,
    productIds: string[],
    lang: string
  ): Promise<ISearchAvailability[] | null>;
}

class AvailabilityApi implements IAvailabilityApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }

  async getAvailability(
    productId: string,
    currency: string,
    fromDate: string,
    toDate: string,
    lang: string
  ): Promise<IAvailability[] | null> {
    try {
      const result = await this.httpClient.get(
        '/api/Availability/GetAvailability',
        {
          params: {
            productId,
            currency,
            fromDate,
            toDate,
            lang
          }
        }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getNextAvailableDay(productId: string): Promise<Date | null> {
    try {
      const result = await this.httpClient.get(
        '/api/Availability/GetNextAvailableDate',
        { params: { productId } }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async searchForAvailability(
    fromDate: Moment,
    toDate: Moment,
    ids: string[],
    lang: string
  ): Promise<ISearchAvailability[] | null> {
    try {
      const from = formatAvailabilityDate(fromDate);
      const to = formatAvailabilityDate(toDate);

      const productIds = ids.map(id => parseInt(id, 10));
      const result = await this.httpClient.post(
        '/api/Availability/SearchForAvailability',
        productIds,
        {
          params: {
            fromDate: from,
            toDate: to,
            lang
          }
        }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default AvailabilityApi;
