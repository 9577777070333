import React from 'react';
import * as styles from './index.module.scss';
import Loader from '../../../components/Loader';

interface IProps {
  dropdownOpen: boolean;
  button: React.MutableRefObject<HTMLButtonElement | null>;
  addingToCart: boolean;
  flybus: boolean;
  addToCart(): void;
  openBookingDropdown(): void;
  text: {
    buttonTextBeforeClick: string;
    buttonTextBeforeClickFlybus: string;
    buttonTextAfterClick: string;
  };
}

const TransportButton: React.FunctionComponent<IProps> = ({
  dropdownOpen,
  addToCart,
  button,
  addingToCart,
  openBookingDropdown,
  flybus,
  text,
}) => {
  if (dropdownOpen) {
    return (
      <div className={styles.container}>
        <button
          className={styles.bookingButton}
          ref={button}
          onClick={() => addToCart()}
        >
          {addingToCart === true ? (
            <Loader color='white' />
          ) : (
            text.buttonTextAfterClick
          )}
        </button>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <button
          onClick={() => openBookingDropdown()}
          className={styles.bookingButton}
          data-cy='Book-Button'
        >
          {flybus
            ? text.buttonTextBeforeClickFlybus
            : text.buttonTextBeforeClick}
        </button>
      </div>
    );
  }
};

export default TransportButton;
