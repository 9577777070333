import React, { FunctionComponent } from 'react';
import * as styles from './index.module.scss';

const SkipNavigation: FunctionComponent = ({ children }) => (
  <div>
    <div className='centered-content'>
      <a href='#main' className={styles.skipNav}>
        {children}
      </a>
    </div>
  </div>
);

export default SkipNavigation;
