import React from 'react';
import { BookingDropdown } from '../../components';
import { Calendar } from '../../../icons';
import { getCalendarDate } from '../../../utils';
import {
  ISelectValue,
  IProduct,
  ITranslationsTourBookingDateDropdown,
} from '../../../interfaces';
import { Moment } from 'moment';
import * as styles from './index.module.scss';

interface IProps {
  modalOpen: boolean;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  product: IProduct | null;
  isValid: boolean;
  toggleModal(value: boolean): void;
  text: ITranslationsTourBookingDateDropdown;
}

const TourBookingDateDropdown: React.FunctionComponent<IProps> = (props) => {
  const {
    modalOpen,
    toggleModal,
    selectedDate,
    selectedTime,
    product,
    isValid,
    text,
  } = props;
  return (
    <div onClick={modalOpen ? undefined : () => toggleModal(true)}>
      <label className={styles.bookingLabel}>
        {text.tourBookingBoxWhen.inputLabel}
      </label>
      <BookingDropdown
        Icon={{ icon: Calendar, alt: 'Calendar' }}
        placeHolder={
          selectedDate
            ? getCalendarDate(selectedDate) +
              (!!selectedTime && !!product && product.BookingType !== 'DATE'
                ? ' ' + selectedTime.label
                : '')
            : text.tourBookingBoxWhen.inputPlaceholder
        }
        isValid={isValid || (!!selectedDate && !!selectedTime)}
        errorText={
          !isValid && !selectedDate
            ? text.tourBookingBoxWhenErrorTexts.version1
            : !isValid && !selectedTime
            ? text.tourBookingBoxWhenErrorTexts.version2
            : ''
        }
      >
        {null}
      </BookingDropdown>
    </div>
  );
};

export default TourBookingDateDropdown;
