// extracted by mini-css-extract-plugin
export var container = "TourList-module--container--1DrPu";
export var tourItem = "TourList-module--tourItem--Qp36t";
export var extraMargin = "TourList-module--extraMargin--ywz4J";
export var imageContainer = "TourList-module--imageContainer--2tsUq";
export var image = "TourList-module--image--2G9Yy";
export var specialOfferBanner = "TourList-module--specialOfferBanner--1WHsG";
export var mobileImageContainer = "TourList-module--mobileImageContainer--RN6LD";
export var imageMobile = "TourList-module--imageMobile--1Rpvg";
export var searchResultTitle = "TourList-module--searchResultTitle--nfQhL";
export var card = "TourList-module--card--1IgL7";
export var tourItemLink = "TourList-module--tourItemLink--rTfQ1";
export var cardSliderMobile = "TourList-module--cardSliderMobile--1orfg";
export var buttonBack = "TourList-module--buttonBack--24gH9";
export var buttonNext = "TourList-module--buttonNext--2J6Bs";
export var containerCarousel = "TourList-module--containerCarousel--1WR8j";
export var category = "TourList-module--category--gRkMS";
export var title = "TourList-module--title--1wY29";
export var price = "TourList-module--price--1yJqg";
export var priceContainer = "TourList-module--priceContainer--Duprb";
export var originalPrice = "TourList-module--originalPrice--2M6Bf";
export var offerPrice = "TourList-module--offerPrice--fv_Ui";
export var sliderMobile = "TourList-module--sliderMobile--2oy6f";