import React from 'react';
import { TransportPickupDropoff } from '../../components';
import Title from '../../../components/Title';
import * as styles from './index.module.scss';

import {
  ITransportOption,
  ISelectPickupValue,
  ITransportList,
  IToFromLabel,
} from '../../../interfaces';

interface IProps {
  startPlaces: ITransportOption[] | null;
  selectedStartPlace: ISelectPickupValue | null;
  isValid: boolean;
  list?: ITransportList;
  ariaLabel: string;
  disabled: boolean;
  toFromLabel: IToFromLabel;
  isMobile?: boolean;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
}

const TransportSelect: React.FunctionComponent<IProps> = ({
  startPlaces,
  selectedStartPlace,
  changeSelectedPlace,
  isValid,
  list,
  ariaLabel,
  disabled,
  toFromLabel,
  isMobile,
}) => {
  return (
    <div className={!isMobile ? styles.container : ''}>
      <div className={styles.labelText}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {toFromLabel.inputLabel}
        </Title>
      </div>
      <div className={styles.labelPadding}>
        <TransportPickupDropoff
          placeList={startPlaces}
          list={list}
          selected={selectedStartPlace}
          changeSelectedPlace={changeSelectedPlace}
          disabled={disabled}
          canContinue={isValid}
          ariaLabel={ariaLabel}
          tabIndex='0'
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default TransportSelect;
