// currency
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

// Cart

export const SET_CART = 'SET_CART';
export const RESET_CART = 'RESET_CART';

// Translations

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const GET_LOCALE = 'GET_LOCALE';
export const GET_LANG = 'GET_LANG';

// Layout
export const SET_ANNOUNCMENTVISSIBLE = 'SET_ANNOUNCMENTVISSIBLE';
export const SET_ANNOUNCMENTVISSIBLE_MOBILE = 'SET_ANNOUNCMENTVISSIBLE_MOBILE';
export const SET_DISMISS = 'SET_DISMISS';

// Icelandair
export const SET_ICELANDAIR = 'SET_ICELANDAIR';
