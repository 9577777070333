import React from 'react';
import { icelandairBanner } from '../../../../src/icons';
import * as styles from './index.module.scss';

const IcelandairCover = () => {
  return (
    <div className={styles.container}>
      <div className={styles.flybusLogo}>
        <img src={icelandairBanner} className={styles.flybusLogo} alt='' />
      </div>
    </div>
  );
};

export default IcelandairCover;
