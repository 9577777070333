import { CHANGE_LOCALE, GET_LOCALE, GET_LANG } from '../actions/types';
import ls from 'local-storage';

interface IState {
  locale: string;
  lang: string;
}

interface ITranslationPayload {
  locale: string;
  lang: string;
}

let initialState: IState = {
  locale: 'en-US',
  lang: 'EN'
};

if (typeof window !== 'undefined'){
  initialState = {
    locale:
      window?.location.pathname.substring(0, 4) === '/is/' ? 'is-IS' : 'en-US',
    lang: window?.location.pathname.substring(0, 4) === '/is/' ? 'IS' : 'EN'
  };
}
// Initial local storage for locale and lang
ls.set('lang', initialState.lang);
/* const initialState: IState = {
  locale:
    window?.location.pathname.substring(0, 4) === '/is/' ? 'is-IS' : 'en-US',
  lang: window?.location.pathname.substring(0, 4) === '/is/' ? 'IS' : 'EN'
}; */

export default (
  state = initialState,
  action: { type: string; payload: ITranslationPayload }
) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      state = action.payload;
      ls.set('lang', action.payload.lang);
      return {
        ...state,
        locale: action.payload.locale,
        lang: action.payload.lang
      };
    case GET_LOCALE:
      return {locale: state.locale, lang: state.lang}; 
    case GET_LANG:
      return state;

    default:
      return state;
  }
};

