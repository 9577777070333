import React from 'react';

export const TimerClock = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' enableBackground='new 0 0 50 50' xmlSpace='preserve'>
    <style>{'.st5_icon{fill:#e58170}'}</style>
    <g id='Layer_1'>
      <path
        d='M46.6 48.5H3.4c-1 0-1.9-.8-1.9-1.9V3.4c0-1 .8-1.9 1.9-1.9h43.3c1 0 1.9.8 1.9 1.9v43.3c-.1 1-.9 1.8-2 1.8z'
        style={{
          fill: 'none',
          stroke: '#e58170',
          strokeWidth: 1.6626,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className='st5_icon'
        d='M24.9 11.8c.6.2.8.7.7 1.3v.5c0 .2 0 .2.2.2 4.5.6 7.7 2.9 9.6 7.1.8 1.7 1 3.5.9 5.3-.1 1.7-.6 3.3-1.4 4.9-.2.4-.6.7-1.1.6-.4-.1-.7-.4-.8-.8-.1-.3 0-.5.2-.7.5-.9.8-1.8 1-2.8.4-2 .1-3.9-.7-5.8-.9-2-2.3-3.5-4.2-4.5-1.1-.6-2.2-1-3.4-1.1-2.1-.3-4 .1-5.8 1.1-1.8 1-3.2 2.4-4.1 4.2-.8 1.5-1.1 3.1-1 4.7.2 3.6 1.9 6.4 5.1 8.2 1.7 1 3.6 1.3 5.6 1.1 1.7-.2 3.2-.7 4.6-1.7.3-.2.6-.4 1-.3.4.1.7.4.7.8.1.4-.1.8-.4 1-.8.6-1.8 1.1-2.7 1.5-1.6.6-3.2.8-4.8.8-5.4-.3-9.7-4.2-10.8-9.1-.8-3.7 0-7.1 2.3-10 1.7-2.2 4-3.5 6.7-4.1.4-.1.8-.1 1.2-.2.2 0 .2-.1.2-.2v-.6c-.1-.6.2-1 .7-1.3.2-.1.4-.1.5-.1z'
      />
      <path
        className='st5_icon'
        d='M24.7 27.5c-1.1 0-2-.9-2-2s.9-2 2-2c.3 0 .4-.1.6-.3.9-1.2 1.9-2.4 2.8-3.5.3-.3.6-.5 1-.4.4.1.7.4.8.8.1.3 0 .6-.2.9-1 1.2-2 2.4-2.9 3.6-.1.1-.1.2-.1.4.3 1.2-.7 2.5-2 2.5zM13.6 15.7c0-.4.2-.7.5-.9.8-.7 1.6-1.3 2.4-2 .2-.2.4-.3.6-.4.5-.1.9.1 1.1.5.2.4.1.9-.3 1.2-.5.4-1 .9-1.5 1.3-.4.3-.7.6-1.1.9-.3.3-.7.3-1.1.1-.4 0-.6-.3-.6-.7zM35.8 15.8c0 .3-.2.6-.6.8-.4.2-.8.1-1.1-.2-.5-.4-1-.9-1.5-1.3-.4-.3-.8-.6-1.1-.9-.3-.3-.4-.6-.3-1.1.1-.4.4-.6.8-.7.3 0 .5 0 .8.2.9.7 1.8 1.5 2.7 2.2.1.3.3.6.3 1z'
      />
    </g>
  </svg>
);
export default TimerClock;
