import React, { ComponentPropsWithRef } from 'react';
import cx from 'classnames';
import { colors } from '../../constants';
import * as styles from './styles/BookingDropdown.module.scss';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { IReducers } from '../../interfaces';

interface IExternalProps {
  children: React.ReactNode;
  Icon?: {
    icon: string;
    alt: string;
  } | null;
  placeHolder: string | null;
  disabled?: boolean;
  isValid?: boolean;
  errorText?: string;
  open?: boolean;
  backgroundColor?: boolean;
  locale: string;
  checkForError?(): void;
  dropdownHeight?: string;
  maxWidth?: string;
}

interface IProps extends IExternalProps {
  localeData: {
    bookingDropdownComponentErrorMessage: string;
  };
}

interface IState {
  open: boolean;
  optionsHeight: number;
  displayError: boolean;
}

class BookingDropdown extends React.Component<
  IProps & ComponentPropsWithRef<'div'>,
  IState
> {
  readonly state = { open: false, optionsHeight: 0, displayError: false };

  options: HTMLDivElement | null = null;
  dropdown: HTMLDivElement | null = null;

  componentDidMount() {
    this.updateOptionsHeight();

    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: IProps) {
    this.updateOptionsHeight();
    if (prevProps.open !== this.props.open) {
      this.setState({ open: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const {
      children,
      Icon,
      placeHolder,
      disabled,
      isValid,
      errorText,
      checkForError,
      backgroundColor,
      localeData,
      dropdownHeight,
      maxWidth,
    } = this.props;
    const { bookingDropdownComponentErrorMessage } = localeData;
    const { open, displayError } = this.state;

    return (
      <React.Fragment>
        <div
          className={cx(
            backgroundColor ? styles.container : styles.containerRegular,
            { [styles.open]: open },
            isValid !== undefined && !disabled
              ? { [styles.invalid]: !isValid }
              : null
          )}
          ref={(elem) => (this.dropdown = elem)}
        >
          <button
            className={
              backgroundColor ? styles.selectedRegular : styles.selected
            }
            onClick={
              disabled !== false && disabled !== undefined && !!checkForError
                ? checkForError
                : this.toggleOpen
            }
          >
            {Icon ? (
              <div
                className={
                  backgroundColor
                    ? styles.iconContainer
                    : styles.iconContainerRegular
                }
              >
                <img src={Icon.icon} alt={Icon.alt} />
              </div>
            ) : null}
            {disabled ? null : (
              <div
                className={
                  backgroundColor
                    ? styles.arrowContainer
                    : styles.arrowContainerRegular
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  id='baseline-keyboard_arrow_down-24px'
                  viewBox='0 0 24 24'
                  className={styles.arrow}
                >
                  <path
                    id='Path_272'
                    d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'
                    data-name='Path 272'
                    className='cls-1'
                  />
                  <path
                    id='Path_273'
                    d='M0 0h24v24H0z'
                    className='cls-2'
                    data-name='Path 273'
                    transform='translate(-30 -14)'
                  />
                </svg>
              </div>
            )}
            {placeHolder ? (
              <p
                className={cx(styles.placeHolder, {
                  [styles.disabled]: disabled,
                })}
              >
                {placeHolder}
              </p>
            ) : null}
          </button>
          {!!errorText && !isValid && !disabled ? (
            <p
              style={{
                display: 'flex',
                color: colors.coralRed,
                padding: '10px 0px',
                fontSize: 12,
              }}
            >
              {errorText}
            </p>
          ) : null}
          <div
            className={dropdownHeight ? styles.optionsScroll : styles.options}
            style={{
              height: open ? (dropdownHeight ? dropdownHeight : 'auto') : 0,
              maxWidth: maxWidth ? maxWidth : 'auto',
            }}
          >
            {open ? (
              <div ref={(elem) => (this.options = elem)}>{children}</div>
            ) : null}
          </div>
        </div>
        {displayError && disabled ? (
          <div className={styles.errorMessage}>
            {bookingDropdownComponentErrorMessage}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  toggleOpen = () => {
    if (!this.props.disabled) {
      this.setState((state) => ({ open: !state.open, displayError: false }));
    } else {
      this.setState({ displayError: true });
    }
  };

  handleClickOutside = (event: any) => {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  updateOptionsHeight = () => {
    if (this.options) {
      const height = this.options.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    children,
    Icon,
    placeHolder,
    disabled,
    isValid,
    errorText,
    open,
    backgroundColor,
    locale,
    dropdownHeight,
    maxWidth,
    checkForError,
  }: IExternalProps & ComponentPropsWithRef<'div'>) => (
    <StaticQuery
      query={graphql`
        query BookingDropdown {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                bookingDropdownComponentErrorMessage
                extrasDateAndTimeQuestionPlaceholder
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <BookingDropdown
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            // eslint-disable-next-line react/no-children-prop
            children={children}
            Icon={Icon}
            placeHolder={placeHolder}
            disabled={disabled}
            isValid={isValid}
            errorText={errorText}
            open={open}
            backgroundColor={backgroundColor}
            checkForError={checkForError}
            locale={locale}
            dropdownHeight={dropdownHeight}
            maxWidth={maxWidth}
          />
        );
      }}
    />
  )
);
