import React from 'react';
import Select from 'react-select';
import { ISelectTime, ILagoonSelect } from '../../interfaces';
import {
  customNoIndicator,
  errorStyle,
  noArrowStyle
} from '../../styles/ReactSelect';
import moment, { Moment } from 'moment';
import { createDateTime } from '../../utils';

type IMenuPlacement = 'auto' | 'bottom' | 'top';

interface IProps {
  isReturn: boolean;
  startTimeOptions?: ISelectTime[] | null;
  endTimeEndOptions?: ISelectTime[] | null;
  selectedStartTime?: ISelectTime | null;
  selectedEndTime?: ISelectTime | null;
  isValid?: boolean;
  selectedBluelagoonTime?: ILagoonSelect | null;
  date?: Moment;
  isMobile?: boolean;
  isLagoon?: boolean;
  disabled?: boolean;
  ariaLabel: string;
  tabIndex: string;
  menuPlacement?: IMenuPlacement;
  isLoadingTimes?: boolean;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
}

const TransportStartTimes: React.FunctionComponent<IProps> = props => {
  function getEndTimes() {
    if (!!props.endTimeEndOptions && props.isLagoon) {
      const filteredOptions = props.endTimeEndOptions.map(time => {
        if (
          !!props.date &&
          !!props.selectedBluelagoonTime &&
          moment(createDateTime(props.date, time.label)).isAfter(
            createDateTime(props.date, props.selectedBluelagoonTime.value, 1)
          )
        ) {
          return time;
        }
        return null;
      });
      const newTimes = filteredOptions.filter(time => {
        return time !== null;
      }) as ISelectTime[];
      return Object.values(newTimes);
    } else if (props.endTimeEndOptions) {
      return props.endTimeEndOptions;
    }
    return [] as ISelectTime[];
  }

  function getStyles() {
    if (props.isMobile && props.isValid) {
      return customNoIndicator;
    } else if (!props.isValid) {
      return errorStyle;
    } else if (
      !!props.isLagoon &&
      props.isLagoon &&
      !props.selectedBluelagoonTime
    ) {
      return noArrowStyle;
    }
    return customNoIndicator;
  }

  if (!!props.startTimeOptions && !props.isReturn) {
    return (
      <div style={{ margin: '10px 0px 10px' }}>
        <Select
          key={props.selectedStartTime + ''}
          menuPortalTarget={!props.isMobile ? document.body : null}
          options={props.startTimeOptions}
          value={props.selectedStartTime}
          onChange={(time: any) =>
            props.changeSelectedTime(time, props.isReturn)
          }
          styles={getStyles()}
          isSearchable={!props.isMobile}
          aria-label={props.ariaLabel}
          tabIndex={props.tabIndex}
        />
      </div>
    );
  } else if (!!props.endTimeEndOptions && props.isReturn) {
    // todo hardcoded placeholder
    return (
      <div style={{ margin: '10px 0px 10px' }}>
        <Select
          key={props.selectedEndTime + ''}
          menuPortalTarget={!props.isMobile ? document.body : null}
          options={getEndTimes()}
          value={props.selectedEndTime}
          onChange={(time: any) =>
            props.changeSelectedTime(time, props.isReturn)
          }
          styles={getStyles()}
          isSearchable={!props.isMobile}
          isDisabled={
            (props.isLagoon && !props.selectedBluelagoonTime) || props.disabled
          }
          aria-label={props.ariaLabel}
          tabIndex={props.tabIndex}
          menuPlacement={props.menuPlacement ? props.menuPlacement : 'bottom'}
          isLoading={props.isLoadingTimes}
        />
      </div>
    );
  }
  return (
    <Select
      placeholder={''}
      styles={getStyles()}
      isSearchable={!props.isMobile}
      aria-label={props.ariaLabel}
      tabIndex={props.tabIndex}
      isLoading={props.isLoadingTimes}
    />
  );
};

export default TransportStartTimes;
