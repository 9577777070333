import React from 'react';
import { Link } from '..';
import * as styles from './index.module.scss';
import { filterCheck } from '../../icons';
import Icon from '../Icon';
import { IFilterSelectItem } from '../../interfaces';

interface IProps {
  items: IFilterSelectItem[];
  langPath: string;
  slug: string;
  select: (index: number) => void;
  queryIcelandair: string;
}

const FilterLangSelect: React.FunctionComponent<IProps> = ({
  items,
  langPath,
  slug,
  select,
  queryIcelandair,
}) => {
  const newLangPath = langPath === '' ? 'is/' : '';
  let slugIcelandair = slug;
  if (queryIcelandair) {
    slugIcelandair = slugIcelandair + queryIcelandair;
  }
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <Link
          langPath={newLangPath}
          to={slugIcelandair ? slugIcelandair : slug}
          style={{ marginLeft: '15px' }}
          key={item.title}
        >
          <button
            className={styles.item}
            key={index}
            onClick={() => select(index)}
          >
            <div className={styles.checkBox} />
            <img
              src={filterCheck}
              alt=''
              style={{ visibility: item.selected ? 'visible' : 'hidden' }}
            />
            <p>{item.title}</p>
            <Icon name={item.iconName ? item.iconName : ''} />
          </button>
        </Link>
      ))}
    </div>
  );
};

export default FilterLangSelect;
