import React from 'react';
import Select, { components } from 'react-select';
import moment from 'moment';
import cx from 'classnames';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import * as styles from './styles/TourBookableExtras.module.scss';
import {
  IBookableExtraState,
  IBookableExtraCounter,
  IBookableExtraPriceCounter,
  IQuestion,
  IAnswerState,
  DataType,
  ISelectValue,
  IExtraQuestion,
  IPersonCounter,
  IReducers,
  ITranslationsExtras,
} from '../../interfaces';
import { Calendar } from '../../icons';
import { isPriceCounter, formatDate } from '../../utils';
import { customStyles, errorStyle } from '../../styles/ReactSelect';
import {
  isInclusivelyBeforeDay,
  DayPickerSingleDateController,
} from 'react-dates';
import { BookingDropdown, BookableExtraCollapse, NumberInput } from './index';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  personCounter: IPersonCounter | null;
  bookableExtras: IBookableExtraState | null;
  locale: string;
  changeBookableExtraCounter(
    addOrRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ): void;

  changeAnswer(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ): void;
  toggleSelectExtra(name: string): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsExtras;
}

interface IState {
  answerForm: IExtraQuestion[] | null;
  open: number | null;
}

type AddOrRemove = 'addOne' | 'removeOne';

class TourBookableExtras extends React.Component<IProps, IState> {
  readonly state: IState = { answerForm: null, open: null };
  // why was this debounced?
  debouncedChangeAnswer = this.props.changeAnswer;

  componentDidMount() {
    this.setAnswerForm();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.bookableExtras !== this.props.bookableExtras) {
      this.setAnswerForm();
    }
  }

  setAnswerForm() {
    const { bookableExtras } = this.props;
    const extrasForm: IExtraQuestion[] = [];
    if (bookableExtras) {
      Object.keys(bookableExtras).map((key) => {
        if (bookableExtras[key]) {
          const answerObj = bookableExtras[key].answers;
          if (!!answerObj && !!answerObj.Questions) {
            answerObj.Questions.map((q) => extrasForm.push(q));
          }
        }
      });
    }
    this.setState({ answerForm: extrasForm });
  }
  render() {
    const { bookableExtras, localeData } = this.props;
    let index = 0;
    let preselected = false;
    if (bookableExtras) {
      return (
        <div>
          {Object.keys(bookableExtras)
            .filter((key) => !bookableExtras[key].Included)
            .map((key) => this.renderExtra(key, index++, bookableExtras))}
          {Object.keys(bookableExtras).filter((key) => {
            if (!!bookableExtras[key].Included && bookableExtras[key].Free) {
              preselected = true;
            } else if (
              !!bookableExtras[key].Included &&
              !bookableExtras[key].Free
            ) {
              preselected = false;
            }
          }).length > 0 && preselected ? (
            <p style={{ marginTop: 30, marginBottom: 15 }}>
              <strong>{localeData.extrasIncluded}</strong>
            </p>
          ) : length > 0 && preselected ? (
            <p style={{ marginTop: 30, marginBottom: 15 }}>
              <strong>{localeData.extrasPreselected}</strong>
            </p>
          ) : null}
          {Object.keys(bookableExtras)
            .filter((key) => !!bookableExtras[key].Included)
            .map((key) => this.renderExtra(key, index++, bookableExtras))}
        </div>
      );
    }
    return null;
  }

  changeAnswerForm(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ) {
    this.debouncedChangeAnswer(
      type,
      key,
      answerIndex,
      questionId,
      categoryId,
      value,
      selectValue,
      time
    );
    const { answerForm } = this.state;
    if (answerForm) {
      const index: number = answerForm.findIndex(
        (a) =>
          a.CategoryId === categoryId &&
          a.Index === answerIndex &&
          a.Id === questionId
      );
      const newForm = [...answerForm];
      if (!!newForm && !!newForm[index]) {
        switch (type) {
          case 'LONG_TEXT':
            newForm[index].Answer = value;
            this.setState({ answerForm: newForm });
            break;

          case 'SHORT_TEXT':
            newForm[index].Answer = value;
            this.setState({ answerForm: newForm });
            break;

          case 'DATE_AND_TIME':
            if (time) {
              newForm[index].time = time;
              this.setState({ answerForm: newForm });
            } else if (value) {
              newForm[index].Answer = value;
              this.setState({ answerForm: newForm });
            }
            break;

          case 'OPTIONS':
            if (
              !!newForm &&
              !!index &&
              !!newForm[index] &&
              !!newForm[index].selectValue &&
              !!selectValue
            ) {
              newForm[index].selectValue = selectValue;
              this.setState({ answerForm: newForm });
            }
            break;

          case 'INT':
            newForm[index].Answer = value;
            this.setState({ answerForm: newForm });

            break;
          case 'DOUBLE':
            newForm[index].Answer = value;
            this.setState({ answerForm: newForm });
            break;
          case 'BOOLEAN':
            newForm[index].Answer = value;
            this.setState({ answerForm: newForm });
            break;
        }
      }
    }
  }

  renderItem = (Item: IBookableExtraCounter) => {
    const { changeBookableExtraCounter, personCounter } = this.props;
    if (
      !Item.Free &&
      Item.selected &&
      Item.PricedPerPerson &&
      Item.PricingType === 'PRICED_SEPARATELY' &&
      !!Item.preselected
    ) {
      if (typeof Item.counter === 'object') {
        Object.entries(Item.counter).forEach(([key, value]) => {
          if (personCounter) {
            if (Item.preselected === true) {
              value.count = personCounter[key].count;
            }
          }
        });
      } else if (typeof Item.counter === 'number') {
        // To do, if counter is number and Item.preselected is true
        // vantar dæmi til að geta útfært þetta
      }
    }
    if (!!Item && !!Item.Id && !!Item.Title) {
      if (
        (Item.PricingType === 'INCLUDED_IN_PRICE' ||
          !Item.PricingType ||
          (Item.PricingType === 'PRICED_SEPARATELY' &&
            !Item.PricedPerPerson)) &&
        typeof Item.counter === 'number'
      ) {
        return (
          <div className={styles.extraContainer}>
            <NumberInput
              value={Item.counter}
              add={() => changeBookableExtraCounter('addOne', Item.Title)}
              subtract={() =>
                changeBookableExtraCounter('removeOne', Item.Title)
              }
            />
            <div className={styles.extraQuestions}>
              <div className='columns is-multiline'>
                {this.renderQuestion(Item.Questions, Item.answers, 0)}
              </div>
            </div>
          </div>
        );
      }
      if (
        !!Item.counter &&
        !!personCounter &&
        Item.PricedPerPerson &&
        isPriceCounter(Item.counter)
      ) {
        return (
          <React.Fragment>
            {Object.keys(Item.counter).map((key) => {
              const counter = Item.counter as IBookableExtraPriceCounter;
              if (
                !!personCounter[key] &&
                !!personCounter[key].count &&
                personCounter[key].count > 0
              ) {
                return (
                  <div key={key} className={styles.extraContainer}>
                    <div>
                      <strong>{key}</strong>
                      <NumberInput
                        value={counter[key].count}
                        add={() =>
                          changeBookableExtraCounter('addOne', Item.Title, key)
                        }
                        subtract={() =>
                          changeBookableExtraCounter(
                            'removeOne',
                            Item.Title,
                            key
                          )
                        }
                      />
                    </div>
                    <div className={styles.extraQuestions}>
                      <div className='columns is-multiline'>
                        {this.renderQuestion(
                          Item.Questions,
                          Item.answers,
                          counter[key].PriceCategoryId
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </React.Fragment>
        );
      }

      if (Item.counter && isPriceCounter(Item.counter) && Item.answers) {
        return (
          <React.Fragment>
            {Object.keys(Item.counter).map((key) => {
              const counter = Item.counter as IBookableExtraPriceCounter;
              return (
                <div key={key} className={styles.extraContainer}>
                  <NumberInput
                    value={counter[key].count}
                    add={() =>
                      changeBookableExtraCounter('addOne', Item.Title, key)
                    }
                    subtract={() =>
                      changeBookableExtraCounter('removeOne', Item.Title, key)
                    }
                  />
                  <div className={styles.extraQuestions}>
                    {this.renderQuestion(
                      Item.Questions,
                      Item.answers,
                      counter[key].PriceCategoryId
                    )}
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        );
      }
    }
    return null;
  };

  renderQuestion = (
    questions: IQuestion[] | null,
    answers: IAnswerState | null,
    categoryId: number
  ) => {
    if (!!questions && !!answers) {
      return questions.map((q) => {
        const question = (() => {
          switch (q.DataType) {
            case 'LONG_TEXT':
              return this.renderTextQuestion(q, answers, categoryId);
            case 'SHORT_TEXT':
              return this.renderTextQuestion(q, answers, categoryId);
            case 'DOUBLE':
              return this.renderTextQuestion(q, answers, categoryId);
            case 'INT':
              return this.renderTextQuestion(q, answers, categoryId);
            case 'OPTIONS':
              return this.renderOptions(q, answers, categoryId);
            case 'DATE_AND_TIME':
              return this.renderDateTimeQuestion(q, answers, categoryId);
            case 'DATE':
              return this.renderDateQuestion(q, answers, categoryId);
            case 'BOOLEAN':
              return this.renderBooleanQuestion(q, answers, categoryId);
          }
          return null;
        })();

        return !!question &&
          !question.props.children.every((childQuestion: IQuestion | null) => {
            return childQuestion === null;
          }) ? (
          <div className='column is-half-widescreen is-full'>
            <div style={{ height: 'auto' }}>{question}</div>
          </div>
        ) : null;
      });
    }
    return null;
  };

  renderTextQuestion(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number
  ) {
    const { answerForm } = this.state;

    if (!!answers && !!answers.Questions) {
      return (
        <React.Fragment>
          {answers.Questions.map((a) => {
            if (
              question.Id &&
              a.Id === question.Id &&
              a.CategoryId === categoryId
            ) {
              const thisAnswer = answerForm
                ? answerForm.find((t) => t.Index === a.Index)
                : null;
              if (thisAnswer) {
                return (
                  <div style={{ padding: '10px 0px', flex: 2 }}>
                    <div style={{ flex: 1 }}>
                      <p className={styles.label}>{question.Label}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                      {this.renderInputs(
                        question,
                        answers,
                        categoryId,
                        a,
                        thisAnswer
                      )}
                    </div>
                  </div>
                );
              }
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    return;
  }

  renderInputs(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number,
    answer: IExtraQuestion,
    thisAnswer: IExtraQuestion
  ) {
    if (question.DataType === 'LONG_TEXT') {
      return (
        <textarea
          rows={5}
          className={cx(
            styles.textInput,
            styles.area,
            !answer.valid ? styles.errorField : null
          )}
          value={!!thisAnswer && !!thisAnswer.Answer ? thisAnswer.Answer : ''}
          placeholder={question.Placeholder ? question.Placeholder : ''}
          onChange={(event) =>
            !!question && !!question.Id
              ? this.changeAnswerForm(
                  question.DataType,
                  answers.Title,
                  answer.Index,
                  question.Id,
                  categoryId,
                  event.target.value,
                  null,
                  null
                )
              : null
          }
        />
      );
    } else if (question.DataType === 'SHORT_TEXT') {
      return (
        <input
          type='text'
          className={cx(
            styles.textInput,
            !answer.valid ? styles.errorField : null
          )}
          value={!!thisAnswer && !!thisAnswer.Answer ? thisAnswer.Answer : ''}
          placeholder={question.Placeholder ? question.Placeholder : ''}
          onChange={(event) =>
            !!question && !!question.Id
              ? this.changeAnswerForm(
                  question.DataType,
                  answers.Title,
                  answer.Index,
                  question.Id,
                  categoryId,
                  event.target.value,
                  null,
                  null
                )
              : null
          }
        />
      );
    } else if (question.DataType === 'INT') {
      const re = new RegExp(/^[0-9]*$/);
      return (
        <input
          type='text'
          className={cx(
            styles.textInput,
            !answer.valid ? styles.errorField : null
          )}
          value={!!thisAnswer && !!thisAnswer.Answer ? thisAnswer.Answer : ''}
          placeholder={question.Placeholder ? question.Placeholder : ''}
          onChange={(event) =>
            !!question && !!question.Id && re.test(event.target.value)
              ? this.changeAnswerForm(
                  question.DataType,
                  answers.Title,
                  answer.Index,
                  question.Id,
                  categoryId,
                  event.target.value,
                  null,
                  null
                )
              : null
          }
        />
      );
    } else if (question.DataType === 'DOUBLE') {
      const re = new RegExp(/^\d*\.?\d*$/);

      return (
        <input
          type='text'
          className={cx(
            styles.textInput,
            !answer.valid ? styles.errorField : null
          )}
          value={!!thisAnswer && !!thisAnswer.Answer ? thisAnswer.Answer : ''}
          placeholder={question.Placeholder ? question.Placeholder : ''}
          onChange={(event) =>
            !!question && !!question.Id && re.test(event.target.value)
              ? this.changeAnswerForm(
                  question.DataType,
                  answers.Title,
                  answer.Index,
                  question.Id,
                  categoryId,
                  event.target.value,
                  null,
                  null
                )
              : null
          }
        />
      );
    } else {
      return null;
    }
  }

  renderOptions(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number
  ) {
    if (!!answers && !!answers.Questions) {
      return (
        <React.Fragment>
          {answers.Questions.map((a) => {
            if (
              question.Id &&
              a.Id === question.Id &&
              a.CategoryId === categoryId &&
              question.AnswerOptions
            ) {
              return (
                <div style={{ padding: '10px 0px' }}>
                  <p className={styles.label}>{question.Label}</p>
                  <Select
                    key={a.Index}
                    /* menuPortalTarget={
                      !!document && !!document.body ? document.body : null
                    } */
                    placeholder={
                      question.Placeholder ? question.Placeholder : ''
                    }
                    // value={a.selectValue}
                    options={question.AnswerOptions}
                    isMulti={
                      question.SelectMultiple ? question.SelectMultiple : false
                    }
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    components={{ SingleValue: components.SingleValue }}
                    styles={a.valid ? customStyles : errorStyle}
                    onChange={(event: any) =>
                      !!question && !!question.Id
                        ? this.changeAnswerForm(
                            question.DataType,
                            answers.Title,
                            a.Index,
                            question.Id,
                            categoryId,
                            null,
                            question.SelectMultiple ? event : [event],
                            null
                          )
                        : null
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    return null;
  }

  renderDateTimeQuestion(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number
  ) {
    const { answerForm } = this.state;
    const { localeData } = this.props;
    if (!!answers && !!answers.Questions) {
      return (
        <React.Fragment>
          {answers.Questions.map((a) => {
            if (
              question.Id &&
              a.Id === question.Id &&
              a.CategoryId === categoryId
            ) {
              const thisAnswer = answerForm
                ? answerForm.find((t) => t.Index === a.Index)
                : null;
              return (
                <div style={{ padding: '10px 0px' }}>
                  <p className={styles.label}>{question.Label}</p>
                  <BookingDropdown
                    Icon={{ icon: Calendar, alt: 'Calendar' }}
                    placeHolder={
                      !!thisAnswer && !!thisAnswer.Answer
                        ? thisAnswer.time
                          ? formatDate(moment(thisAnswer.Answer)) +
                            ' ' +
                            thisAnswer.time
                          : formatDate(moment(thisAnswer.Answer))
                        : localeData.extrasDateAndTimeQuestionPlaceholder
                    }
                    isValid={thisAnswer ? thisAnswer.valid : undefined}
                  >
                    <div className='columns'>
                      <div className='column'>
                        <DayPickerSingleDateController
                          initialVisibleMonth={null}
                          key={a.Index}
                          date={
                            !!thisAnswer && !!thisAnswer.Answer
                              ? moment(thisAnswer.Answer)
                              : null
                          }
                          focused={true}
                          onFocusChange={() => {
                            //
                          }}
                          onDateChange={(event) =>
                            !!question && !!question.Id && !!event
                              ? this.changeAnswerForm(
                                  question.DataType,
                                  answers.Title,
                                  a.Index,
                                  question.Id,
                                  categoryId,
                                  event.format('YYYY-MM-DD'),
                                  null,
                                  null
                                )
                              : null
                          }
                          isOutsideRange={(day) =>
                            isInclusivelyBeforeDay(
                              day,
                              moment(new Date()).subtract(1, 'day')
                            )
                          }
                          hideKeyboardShortcutsPanel={true}
                        />
                      </div>
                      <div
                        className='column'
                        style={{
                          justifyContent: 'center',
                          alignSelf: 'center',
                          width: 200,
                        }}
                      >
                        <TimePicker
                          showSecond={false}
                          value={
                            !!thisAnswer && !!thisAnswer.time
                              ? moment(thisAnswer.time, 'HH:mm')
                              : moment(new Date())
                          }
                          onChange={(event) =>
                            !!question && !!question.Id && !!event
                              ? this.changeAnswerForm(
                                  question.DataType,
                                  answers.Title,
                                  a.Index,
                                  question.Id,
                                  categoryId,
                                  null,
                                  null,
                                  event.format('HH:mm').toString()
                                )
                              : null
                          }
                          format={'HH:mm'}
                          use12Hours={false}
                          inputReadOnly={true}
                          clearIcon={<div />}
                        />
                      </div>
                    </div>
                  </BookingDropdown>
                </div>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    return null;
  }
  renderDateQuestion(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number
  ) {
    const { answerForm } = this.state;
    const { localeData } = this.props;
    if (!!answers && !!answers.Questions && !!answerForm) {
      return (
        <React.Fragment>
          {answers.Questions.map((a) => {
            if (
              question.Id &&
              a.Id === question.Id &&
              a.CategoryId === categoryId
            ) {
              const thisAnswer = answerForm.find((t) => t.Index === a.Index);

              return (
                <div style={{ padding: '10px 0px' }}>
                  <p className={styles.label}>{question.Label}</p>
                  <BookingDropdown
                    Icon={{ icon: Calendar, alt: 'Calendar' }}
                    placeHolder={
                      !!thisAnswer && !!thisAnswer.Answer
                        ? formatDate(moment(thisAnswer.Answer))
                        : localeData.extrasDateQuestionPlaceholder
                    }
                    isValid={true}
                  >
                    <DayPickerSingleDateController
                      initialVisibleMonth={null}
                      key={a.Index}
                      date={
                        !!thisAnswer && !!thisAnswer.Answer
                          ? moment(thisAnswer.Answer)
                          : null
                      }
                      focused={true}
                      onFocusChange={() => {
                        //
                      }}
                      onDateChange={(event) =>
                        !!question && !!question.Id && !!event
                          ? this.changeAnswerForm(
                              question.DataType,
                              answers.Title,
                              a.Index,
                              question.Id,
                              categoryId,
                              event.format('YYYY-MM-DD'),
                              null,
                              null
                            )
                          : null
                      }
                      isOutsideRange={(day) =>
                        isInclusivelyBeforeDay(
                          day,
                          moment(new Date()).subtract(1, 'day')
                        )
                      }
                      hideKeyboardShortcutsPanel={true}
                    />
                  </BookingDropdown>
                </div>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    return null;
  }

  renderBooleanQuestion(
    question: IQuestion,
    answers: IAnswerState,
    categoryId: number
  ) {
    const { answerForm } = this.state;
    const { localeData } = this.props;
    if (!!answers && !!answers.Questions && !!answerForm) {
      return (
        <React.Fragment>
          {answers.Questions.map((a) => {
            if (
              question.Id &&
              a.Id === question.Id &&
              a.CategoryId === categoryId
            ) {
              const thisAnswer = answerForm.find((t) => t.Index === a.Index);
              return (
                <div style={{ padding: '10px 0px' }}>
                  <p>{question.Label}</p>
                  <label>{localeData.extrasBooleanQuestion.yes}</label>
                  <input
                    type='checkbox'
                    checked={
                      !!thisAnswer &&
                      !!thisAnswer.Answer &&
                      thisAnswer.Answer === 'yes' // might ave to change this to the translated verison as well, not sure
                    }
                    onChange={(event) =>
                      !!question && !!question.Id
                        ? this.changeAnswerForm(
                            question.DataType,
                            answers.Title,
                            a.Index,
                            question.Id,
                            categoryId,
                            event.target.value === 'on' ? 'yes' : null,
                            null,
                            null
                          )
                        : null
                    }
                  />
                  <label>{localeData.extrasBooleanQuestion.no}</label>
                  <input
                    type='checkbox'
                    checked={
                      !!thisAnswer &&
                      !!thisAnswer.Answer &&
                      thisAnswer.Answer === 'no'
                    }
                    onChange={(event) =>
                      !!question && !!question.Id
                        ? this.changeAnswerForm(
                            question.DataType,
                            answers.Title,
                            a.Index,
                            question.Id,
                            categoryId,
                            event.target.value === 'on' ? 'no' : null,
                            null,
                            null
                          )
                        : null
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    return null;
  }

  renderExtra = (
    extra: string,
    index: number,
    bookableExtras: IBookableExtraState
  ) => {
    const { open } = this.state;
    const item = bookableExtras[extra];
    if (!!item && !!item.Id && !!item.Title) {
      return (
        <div key={item.Id}>
          <BookableExtraCollapse
            title={item.Title}
            selected={item.selected}
            toggleSelect={() => this.toggleSelect(item, index)}
            open={open === index}
            toggleOpen={() => this.toggleOpen(item, index)}
            included={!!item.Included}
            error={this.extraIsValid(item)}
          >
            {this.renderItem(item)}
          </BookableExtraCollapse>
        </div>
      );
    }
    return null;
  };

  extraIsValid = (item: IBookableExtraCounter) => {
    if (item.answers && item.answers.Questions) {
      for (const question of item.answers.Questions) {
        if (!question.valid) {
          return true;
        }
      }
    }
    return false;
  };

  toggleOpen = (item: IBookableExtraCounter, index: number) => {
    const { open } = this.state;
    if (item.Title && open !== index && !item.selected) {
      this.props.toggleSelectExtra(item.Title);
    }
    this.setState({ open: open === index ? null : index });
  };

  toggleSelect = (item: IBookableExtraCounter, index: number) => {
    if (item.Title) {
      if (!item.selected) {
        this.setState({ open: index });
      } else if (this.state.open === index) {
        this.setState({ open: null });
      }
      this.props.toggleSelectExtra(item.Title);
    }
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    personCounter,
    bookableExtras,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourBookableExtras {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                extrasBooleanQuestion {
                  yes
                  no
                }
                bookingDropdownComponentErrorMessage
                extrasIncluded
                extrasPreselected
                extrasDateQuestionPlaceholder
                extrasDateAndTimeQuestionPlaceholder
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TourBookableExtras
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            personCounter={personCounter}
            bookableExtras={bookableExtras}
            changeBookableExtraCounter={changeBookableExtraCounter}
            changeAnswer={changeAnswer}
            toggleSelectExtra={toggleSelectExtra}
            locale={locale}
          />
        );
      }}
    />
  )
);
