import React from 'react';
import TransportCounter from '../TransportCounter';
import * as styles from './index.module.scss';
import Title from '../../../components/Title';
import { ITransportPersonCounter, AddOrRemove } from '../../../interfaces';

interface IProps {
  personCounter: ITransportPersonCounter | null;
  participantsSelectorHeader: string;
  isLagoon?: boolean;
  getTotalPersonCount?(): number;
  getAvailabilityLeft?(): number;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
}

const TransportGuests: React.FunctionComponent<IProps> = ({
  personCounter,
  participantsSelectorHeader,
  changePersonCounter,
  isLagoon,
  getTotalPersonCount,
  getAvailabilityLeft,
}) => (
  <div className={styles.guestsPadding}>
    <Title tag='h3' theme={['sizeDesktopSmall']}>
      {participantsSelectorHeader}
    </Title>
    <TransportCounter
      personCounter={personCounter}
      changePersonCounter={changePersonCounter}
      isLagoon={isLagoon}
      getTotalPersonCount={getTotalPersonCount}
      getAvailabilityLeft={getAvailabilityLeft}
    />
  </div>
);

export default TransportGuests;
