import React from 'react';
import cx from 'classnames';
import Drawer from 'rc-drawer';

import * as styles from './TransportHero.module.scss';
import {
  ITourDetails,
  ITransport,
  ITranslationsTransportTourInfo,
} from '../../interfaces';
import { IReducers, ICart } from '../../interfaces';
import { Image, Mobile, Desktop } from '../../components';
import TourBookNav from '../TourBookNav/TourBookNav';
import Title from '../Title';
import { formatPrice, showCarbonNeutralTransfer } from '../../utils';
// import { heroHeight, blurBoxSize, tourInfoHeight } from '../../constants';
import {
  carbonNeutralTour,
  carbonNeutralTransfer,
  exitButton,
} from '../../icons';
import TransportEngine from '../../BookingEngine/TransportEngine';
import { connect } from 'react-redux';
import BlueLagoonEngine from '../../BookingEngine/BlueLagoonEngine';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';
import { StaticQuery, graphql } from 'gatsby';
import Button from '../Button';

interface IExternalProps {
  tour: ITourDetails;
  transport: ITransport | null;
  loadingPrice: boolean;
  currency: string;
  cartObject: ICart | null;
  locale: string;
  announcementVisible: boolean;
  announcementVisibleMobile: boolean;
  dismiss: boolean;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsTransportTourInfo;
}

interface IState {
  open: boolean;
}

// const imgHeight = heroHeight;

class TransportHero extends React.Component<IProps & IWindowSizeProps, IState> {
  readonly state = {
    open: false,
  };

  componentDidUpdate(prevProps: IProps) {
    if (
      !!prevProps.cartObject &&
      !!prevProps.cartObject.Items &&
      !!this.props.cartObject &&
      !!this.props.cartObject.Items &&
      prevProps.cartObject.Items.length < this.props.cartObject.Items.length
    ) {
      this.setState({ open: false });
    }

    if (
      !!prevProps.cartObject &&
      !prevProps.cartObject.Items &&
      !!this.props.cartObject &&
      !!this.props.cartObject.Items &&
      this.props.cartObject.Items.length === 1
    ) {
      this.setState({ open: false });
    }
  }

  renderDrawer = () => {
    const { tour } = this.props;
    const { productId, title } = tour;
    return (
      <Drawer
        height='100%'
        width='100%'
        open={tour.slug == 'icelandair' ? true : this.state.open}
        placement='right'
        handler={false}
      >
        <div>
          <img
            src={exitButton}
            alt='exit'
            className={styles.exit}
            onClick={() => this.setState({ open: false })}
          />
          <div
            className={cx('centered-content', styles.titleContainerAddToCart)}
          >
            <div className={styles.headerPadding}>
              <Title tag='h1' theme={['sizeMobileSmall']}>
                {tour.title}
              </Title>
            </div>
          </div>

          <div>
            {tour.type === 'transport' ? (
              <TransportEngine
                productId={productId}
                product={null}
                isMobile={true}
                tour={tour}
              />
            ) : (
              <BlueLagoonEngine
                productId={productId}
                product={null}
                isMobile={true}
                tourTitle={title}
                tour={tour}
              />
            )}
          </div>
        </div>
      </Drawer>
    );
  };

  render() {
    const {
      tour,
      currency,
      transport,
      localeData,
      dismiss,
      announcementVisible,
      announcementVisibleMobile,
    } = this.props;
    const { title, heroImage, productId, type, navColor } = tour;

    const price = this.getPrice();

    if (tour.slug === 'icelandair') {
      return (
        <div className={styles.container} style={{ height: '100%' }}>
          {/* <Image
            fluidImage={heroImage}
            productPhoto={transport && transport.KeyPhoto}
            className={styles.image}
            productPhotoSize='original'
          /> */}
          <div className={cx(styles.content, styles.contentIcelandair)}>
            <div className='centered-content'>
              <div
                className={cx(
                  styles.titleContainer,
                  navColor && navColor === 'dark'
                    ? styles.titleContainerDark
                    : styles.titleContainerLight
                )}
              >
                {/* <h1>{title}</h1> */}
                {/* <div className={styles.line} /> */}
                {/* {price && (
                  <strong>
                    {localeData.tourInfoTransportPriceFrom}{' '}
                    {formatPrice(price, currency)} {currency}
                  </strong>
                )} */}
              </div>
            </div>
            <div>
              <Desktop>
                <TourBookNav
                  tourTitle={title}
                  productId={productId}
                  productLoaded={true}
                  transport={type === 'transport'}
                  blueLagoon={type === 'blue-lagoon'}
                  offerBanner={tour.offerBanner}
                  product={null}
                  tour={tour}
                />
              </Desktop>
              <Mobile>
                <div className={styles.addToCartButtonIcelandair}>
                  {/* <Button
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    {localeData.tourInfoMobileBookButton}
                  </Button> */}
                </div>
                {/* {() => this.setState({ open: !this.state.open })} */}
                <div>
                  {tour.type === 'transport' ? (
                    <TransportEngine
                      productId={productId}
                      product={null}
                      isMobile={true}
                      tour={tour}
                    />
                  ) : (
                    <BlueLagoonEngine
                      productId={productId}
                      product={null}
                      isMobile={true}
                      tourTitle={title}
                      tour={tour}
                    />
                  )}
                </div>
                {/* {this.renderDrawer()} */}
              </Mobile>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <Image
            fluidImage={heroImage}
            productPhoto={transport && transport.KeyPhoto}
            className={styles.image}
            productPhotoSize='original'
          />
          <div className={styles.content}>
            <div className='centered-content'>
              <div
                className={cx(
                  styles.titleContainer,
                  navColor && navColor === 'dark'
                    ? styles.titleContainerDark
                    : styles.titleContainerLight
                )}
              >
                <h1>{title}</h1>
                <div className={styles.line} />
                {price && (
                  <strong>
                    {localeData.tourInfoTransportPriceFrom}{' '}
                    {formatPrice(price, currency)} {currency}
                  </strong>
                )}
              </div>
            </div>
            <div>
              <Desktop>
                <TourBookNav
                  tourTitle={title}
                  productId={productId}
                  productLoaded={true}
                  transport={type === 'transport'}
                  blueLagoon={type === 'blue-lagoon'}
                  offerBanner={tour.offerBanner}
                  product={null}
                  tour={tour}
                />
                {showCarbonNeutralTransfer(tour) ? (
                  <img
                    src={carbonNeutralTransfer}
                    alt='carbonNeutralTransfer'
                    className={
                      announcementVisible && dismiss == false
                        ? styles.carbonLogoAnnouncement
                        : styles.carbonLogo
                    }
                  />
                ) : null}
              </Desktop>
              <Mobile>
                <div className={styles.addToCartButton}>
                  <Button
                    onClick={() => this.setState({ open: !this.state.open })}
                  >
                    {localeData.tourInfoMobileBookButton}
                  </Button>
                </div>
                {this.renderDrawer()}
                {showCarbonNeutralTransfer(tour) ? (
                  <div className={styles.addToCartButton}>
                    <img
                      src={carbonNeutralTransfer}
                      alt='carbonNeutralTransfer'
                      className={
                        announcementVisibleMobile
                          ? styles.carbonLogoMobileAnnouncement
                          : styles.carbonLogoMobile
                      }
                    />
                  </div>
                ) : null}
              </Mobile>
            </div>
          </div>
        </div>
      );
    }
  }

  getPrice = () => {
    const { transport } = this.props;
    if (transport && transport.PriceCategories) {
      for (const p of transport.PriceCategories) {
        if (p.Name === 'Adults' && p.Price) {
          return p.Price.Amount;
        }
      }
    }
    return null;
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { cartObject } = reducers.cartReducers;
  const { locale } = reducers.translationReducers;
  const { announcementVisible, announcementVisibleMobile, dismiss } =
    reducers.layoutReducer;
  return {
    cartObject,
    locale,
    announcementVisible,
    announcementVisibleMobile,
    dismiss,
  };
};
export default connect(mapStateToProps)(
  withWindowSize(
    ({
      tour,
      transport,
      loadingPrice,
      currency,
      cartObject,
      windowWidth,
      windowHeight,
      locale,
      announcementVisible,
      announcementVisibleMobile,
      dismiss,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query TransportHero {
            allContentfulTranslationsPage {
              edges {
                node {
                  node_locale
                  tourInfoTransportPriceFrom
                  tourInfoMobileBookButton
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <TransportHero
              localeData={
                data.allContentfulTranslationsPage.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              tour={tour}
              transport={transport}
              loadingPrice={loadingPrice}
              currency={currency}
              cartObject={cartObject}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              locale={locale}
              announcementVisible={announcementVisible}
              announcementVisibleMobile={announcementVisibleMobile}
              dismiss={dismiss}
            />
          );
        }}
      />
    )
  )
);
