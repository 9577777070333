import React from 'react';
import * as styles from './index.module.scss';
import { formatPrice } from '../../../utils';

import { ICurrencyOption } from '../../../interfaces';

interface IProps {
  totalPrice: number;
  currency: ICurrencyOption;
  totalPriceString: string;
}

const TransportTotalPrice: React.FunctionComponent<IProps> = ({
  totalPrice,
  currency,
  totalPriceString,
}) => {
  return (
    <div className={styles.totalPrice}>
      <p>{totalPriceString}:</p>
      <p data-cy='Total-Price-And-Currency'>
        {formatPrice(totalPrice, currency.value)} {currency.label}
      </p>
    </div>
  );
};

export default TransportTotalPrice;
