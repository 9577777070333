import { CHANGE_CURRENCY } from '../actions/types';
import { ICurrencyOption } from '../../interfaces';
/* import { Krona, KronaLight } from '../../icons';
 */ import { getCurrencyConstant } from '../../utils';
import ls from 'local-storage';

interface IState {
  currency: ICurrencyOption;
}

interface ICurrencyPayload {
  currencyPayload: ICurrencyOption;
  cartCount: number;
}

const localCurrency = ls.get('currency');
let initialState: IState = {
  currency: getCurrencyConstant('is')
};

if (localCurrency) {
  initialState = { currency: getCurrencyConstant(localCurrency) };
}

export default (
  state = initialState,
  action: { type: string; payload: ICurrencyPayload }
) => {
  switch (action.type) {
    case CHANGE_CURRENCY:
      if (action.payload.cartCount > 0) {
        return state;
      } else {
        ls.set('currency', action.payload.currencyPayload.code);
        return { currency: action.payload.currencyPayload };
      }

    default:
      return state;
  }
};
