import {
  SET_ANNOUNCMENTVISSIBLE,
  SET_ANNOUNCMENTVISSIBLE_MOBILE,
  SET_DISMISS,
  SET_ICELANDAIR
} from './types';

export const changeAnnouncementVisible = (parameter: boolean) => {
  return {
    type: SET_ANNOUNCMENTVISSIBLE,
    payload: { layoutPayload: parameter }
  };
};

export const changeAnnouncementVisibleMobile = (parameter: boolean) => {
  return {
    type: SET_ANNOUNCMENTVISSIBLE_MOBILE,
    payload: { layoutPayload: parameter }
  };
};

export const changeDismiss = (parameter: boolean) => {
  return {
    type: SET_DISMISS,
    payload: { dissmissPayload: parameter }
  };
};

export const changeIcelandair = (parameter: boolean) => {
  return {
    type: SET_ICELANDAIR,
    payload: { icelandairPayload: parameter }
  };
};
