export const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    color: '#676767',
    textDecoration: 'none',
    fontFamily: 'DINNextLTProRegular, sans-serif',
    fontSize: 16
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textDecoration: 'none',
    color: '#2c2c2c',
    fontFamily: 'DINNextLTProRegular, sans-serif',
    fontSize: 16
  }),
  option: (provided: any) => ({
    ...provided,
    textDecoration: 'none',
    fontFamily: 'DINNextLTProRegular, sans-serif',
    fontSize: 16
  }),
  control: (provided: any) => {
    const boxShadow = null;
    const borderStyle = null;
    const borderColor = null;
    const borderWidth = null;
    const backgroundColor = 'white';
    const textDecoration = 'none';
    const fontFamily = 'DINNextLTProRegular, sans-serif';
    const fontSize = 16;
    const border = '1px solid #DDDDDD';
    const borderRadius = '0px';
    const maxWidth = '270px';
    const minWidth = '270px';
    return {
      ...provided,
      boxShadow,
      borderColor,
      borderStyle,
      borderWidth,
      backgroundColor,
      textDecoration,
      fontFamily,
      fontSize,
      border,
      borderRadius,
      maxWidth,
      minWidth,
      '&:hover': {
        borderColor: null
      }
    };
  },
  dropdownIndicator: (provided: any, { isFocused }: any) => {
    const color = isFocused ? '#37559b' : '#676767';
    const transform = isFocused ? 'rotate(180deg)' : null;
    return { ...provided, color, transform };
  },
  menuList: (provided: any) => {
    return {
      ...provided,
      backgroundColor: 'white',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
      transition: 'all 0.2s ease'
    };
  },
  container: (provided: any, { isFocused }: any) => {
    const borderBottom = isFocused
      ? '2px solid #294b98'
      : '2px solid transparent';
    return { ...provided, borderBottom };
  }
};

const noIndicator = {
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
    paddingRight: 5
  })
};

const noArrow = {
  dropdownIndicator: (provided: any) => ({ ...provided, display: 'none' })
};

const error = {
  control: (provided: any) => ({ ...provided, border: '1px solid #fa7268' })
};
const lowMenu = {
  menuList: (provided: any) => ({ ...provided, maxHeight: 160 })
};
const lowMenuModal = {
  menuList: (provided: any) => ({ ...provided, maxHeight: 200 })
};

const overideSelectStyles = {
  container: (provided: any) => ({ ...provided, width: '100%' }),
  control: (provided: any, { isFocused }: any) => {
    const borderBottom = isFocused ? '2px solid #294b98' : null;
    const boxShadow = null;
    const borderColor = '#f8f8f8';
    const backgroundColor = 'white';
    const maxHeight = 50;

    return {
      ...provided,
      borderBottom,
      boxShadow,
      borderColor,
      maxHeight,
      backgroundColor,
      '&:hover': {
        borderColor: null
      }
    };
  }
};
export const customNoIndicator = { ...customStyles, ...noIndicator };
export const noArrowStyle = { ...customStyles, ...noIndicator, ...noArrow };
export const errorStyle = { ...customStyles, ...noIndicator, ...error };
export const mobileMenu = {
  ...customStyles,
  ...lowMenu,
  ...overideSelectStyles
};
export const mobileMenuNoIndicator = {
  ...customStyles,
  ...noIndicator,
  ...lowMenu,
  ...overideSelectStyles
};
export const modalMenu = {
  ...customStyles,
  ...lowMenuModal
};
