import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import ls from 'local-storage';
import { currencies, filterParams } from '../constants';
import {
  DataFormat,
  IBookableExtraPriceCounter,
  IBooking,
  IBookingError,
  ICurrencyOption,
  IDefaultPrice,
  IDuration,
  IExtraQuestion,
  IFilterSelectItem,
  IGuidance,
  IItem,
  IPageElement,
  IPhoto,
  ITourItem,
  ITourListItem,
  ITransportDefaultPrice,
  IPersonCounter,
  ICountryOption,
  IProduct,
  ITourDetails,
} from '../interfaces';
import '@formatjs/intl-numberformat/polyfill';

import {
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  bq,
  br,
  bs,
  bt,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cefta,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cw,
  cx_flag,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  do_flag,
  dz,
  ec,
  ee,
  eg,
  eh,
  er,
  es,
  et,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  im,
  in_flag,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls_flag,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pc,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  tc,
  td,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  ye,
  yt,
  za,
  zm,
  zw,
} from '../../src/icons/flags';
import {
  gb1x1,
  de1x1,
  es1x1,
  fr1x1,
  is1x1,
  jp1x1,
} from '../../src/icons/flags1x1';

// try catch to prevent IE from crashing
// "There is nothing more permanent than a temporary solution"
try {
  if (typeof (Intl.NumberFormat as any).__addLocaleData === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('@formatjs/intl-numberformat/locale-data/en-IE.js'); // locale-data for zh
    require('@formatjs/intl-numberformat/locale-data/en.js'); // locale-data for en
    require('@formatjs/intl-getcanonicallocales/polyfill');
  }
} catch {
  null;
}

export const formatPrice = (price: number, currency: string) => {
  try {
    const locale = currency === 'USD' ? 'en-US' : 'en-DK'; // en_DK is for english speakers in europe

    const formatter = new Intl.NumberFormat(locale, {
      currency,
    });
    return formatter.format(price);
  } catch {
    return price;
  }
};

export const formatDate = (date: Moment) => {
  const lang = ls.get('lang');
  return moment(date).locale(lang).format('LL');
};

export const formatDateEnglishLocale = (date: Moment) => {
  return moment(date).locale('EN').format('LL');
};

export const showCarbonNeutralTour = (
  product: IProduct | null,
  tour: ITourDetails
) => {
  return (
    product &&
    product.Vendor &&
    (product.Vendor.toLocaleUpperCase().includes('REYKJAVIK EXCURSIONS') || product.Vendor.toLocaleUpperCase().includes('ICELANDIA')) &&
    tour.includeCarbonNeutral === 'Carbon Neutral Tour'
  );
};

export const showCarbonNeutralTransfer = (tour: ITourDetails) => {
  return tour.includeCarbonNeutral === 'Carbon Neutral Transfer';
};

export const formatHardcodedStringWithVariables = (
  mainString: string,
  replacementWords: string[]
) => {
  // start by splitting the string equal to the numbers in replacmentWords on {}
  let finalString = '';
  let originalString = mainString;
  replacementWords.forEach((word, index) => {
    const num = index + 1; // we start at 1 in contentful for the content creators
    const split = '{' + num + '}'; // contentful will return {'number'} to indicate where the variables should appear
    const splitString = originalString.split(split);
    finalString = finalString + splitString[0] + word;
    originalString = splitString[1]; // if there are more words then we should split the second part again
  });
  // the originalString contains the end of the sentence so we have to add that
  return finalString + originalString;
};

export const getImageBySize = (
  image: IPhoto,
  size: 'original' | 'large' | 'preview' | 'thumbnail'
) => {
  let src = image.Url;
  if (image.OtherVersions) {
    for (const img of image.OtherVersions) {
      if (img.Version === size) {
        src = img.Url;
        break;
      }
    }
  }
  return src;
};

export const getQueryVariable = (variable: string) => {
  const vars = window.location.search.substring(1).split('&');
  for (const v of vars) {
    const pair = v.split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }

  return false;
};

export const getQueryVariables = (variable: string) => {
  const vars = window.location.search.substring(1).split('&');
  const variables = [];
  for (const v of vars) {
    const pair = v.split('=');
    if (pair[0] === variable) {
      variables.push(pair[1]);
    }
  }
  return variables;
};

export const findDefaultPrice = (
  defaultPrices: IDefaultPrice[],
  productId: string
) => {
  const defaultPrice = defaultPrices.find(
    (price: IDefaultPrice) => price.Id.toString() === productId
  );
  return defaultPrice || null;
};

export const findDefaultTransportPrice = (
  defaultPrices: ITransportDefaultPrice[],
  productId: string
) => {
  const defaultPrice = defaultPrices.find(
    (price: ITransportDefaultPrice) => price.Id.toString() === productId
  );
  return defaultPrice || null;
};

export const formatAvailabilityDate = (date: Moment) => {
  return date.format('YYYY-MM-DD');
};

export const getMinutesFromDuration = (duration: IDuration) => {
  const hour = 60;
  const day = hour * 24;
  const week = day * 7;

  return (
    duration.Minutes +
    duration.Hours * hour +
    duration.Days * day +
    duration.Weeks * week
  );
};

export const getLangByType = (guidance: IGuidance[], type = 'GUIDED') => {
  for (const g of guidance) {
    if (g.Type === type) {
      return g.Languages;
    }
  }
  return [];
};

export const setQueryParameterNoRefresh = (key: string, value: string) => {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    let initCount = 0;
    searchParams.forEach(() => initCount++);
    value === '' ? searchParams.delete(key) : searchParams.set(key, value);
    let count = 0;
    searchParams.forEach(() => count++);

    if (initCount !== 0 || count !== 0) {
      const newRelativePathQuery =
        window.location.pathname +
        (count > 0 ? '?' : '') +
        searchParams.toString();
      history.replaceState(null, '', newRelativePathQuery);
    }
  }
};

export const setQueryParameters = (key: string, values: string[]) => {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    let initCount = 0;
    searchParams.forEach(() => initCount++);
    searchParams.delete(key);
    values.forEach((val) => searchParams.append(key, val));
    let count = 0;
    searchParams.forEach(() => count++);

    if (initCount !== 0 || count !== 0) {
      const newRelativePathQuery =
        window.location.pathname +
        (count > 0 ? '?' : '') +
        searchParams.toString();
      history.replaceState(null, '', newRelativePathQuery);
    }
  }
};

export const clearQueryParameters = () => {
  if ('URLSearchParams' in window) {
    history.replaceState(null, '', window.location.pathname);
  }
};

export const hasQueryParameters = (values: string[]) => {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    for (const val of values) {
      if (searchParams.has(val)) {
        return true;
      }
    }
  }
  return false;
};

export const getCurrencyConstant = (key: string): ICurrencyOption => ({
  label: currencies[key].label,
  code: key,
  value: currencies[key].label,
  icon: currencies[key].icon,
  lightIcon: currencies[key].lightIcon,
});

export const getCalendarDate = (date: Moment) => {
  return moment(date).format('ddd, MMM Do');
};

export const scrollTo = (ref: HTMLDivElement | null, settings: object = {}) => {
  if (ref) {
    scrollToComponent(ref, {
      offset: 0,
      align: 'middle',
      duration: 300,
      ...settings,
    });
  }
};

export const getDateQueryParams = (
  startDate: Moment | null,
  endDate: Moment | null,
  questionMark = true
) => {
  let params = startDate
    ? `${questionMark ? '?' : ''}${filterParams.start}=${formatAvailabilityDate(
        startDate
      )}`
    : '';
  if (startDate && endDate && !startDate.isSame(endDate, 'day')) {
    params += `&${filterParams.end}=${formatAvailabilityDate(endDate)}`;
  }
  return params;
};

export const getPageElement = (
  pageElements: IPageElement[] | undefined,
  type: string
) => {
  if (pageElements) {
    for (const elem of pageElements) {
      if (elem.__typename === type) {
        return elem;
      }
    }
  }
  return null;
};

export const getSelectText = (
  options: IFilterSelectItem[],
  defaultText = 'Any',
  selectedText = 'Selected'
) => {
  const selected = options.filter((category) => category.selected);
  let text = defaultText;
  if (selected.length === 1) {
    text = selected[0].title;
  } else if (selected.length > 1) {
    text = `${selected.length} ${selectedText}`;
  }
  return text;
};

export const countryFlags: ICountryOption[] = [
  { label: 'English', value: '+93', image: gb1x1 },
  { label: 'Spanish', value: '+93', image: es1x1 },
  { label: 'French', value: '+93', image: is1x1 },
  { label: 'Deutch', value: '+93', image: jp1x1 },
  { label: 'Japanese', value: '+93', image: fr1x1 },
  { label: 'Íslenska', value: '+93', image: de1x1 },
];

export const useOuterClickNotifier = (
  onOuterClick: (e: any) => void,
  innerRef: React.RefObject<HTMLDivElement | HTMLButtonElement> | null,
  anotherRef?: React.RefObject<HTMLDivElement | HTMLButtonElement> | null
) => {
  useEffect(() => {
    function handleClickOutside(e: any) {
      if (anotherRef) {
        return (
          !!innerRef &&
          !!innerRef.current &&
          !innerRef.current.contains(e.target) &&
          !!anotherRef &&
          !!anotherRef.current &&
          !anotherRef.current.contains(e.target) &&
          onOuterClick(e)
        );
      } else {
        return (
          !!innerRef &&
          !!innerRef.current &&
          !innerRef.current.contains(e.target) &&
          onOuterClick(e)
        );
      }
    }
    if (!!innerRef && !!innerRef.current) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [onOuterClick, innerRef, anotherRef]);
};
export const randomBetween = (min: number, max: number) => {
  // https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
  return Math.floor(Math.random() * max) + min;
};

// I dont think this will be used again
export const boxesIntersect = (
  box1: number,
  box2: number,
  blurBoxSize: number
) => {
  return (
    (box1 <= box2 && box1 + blurBoxSize >= box2) ||
    (box2 <= box1 && box2 + blurBoxSize >= box1)
  );
};

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (phone: string, countryCodeNum: number[])=>{
 if (countryCodeNum.includes(phone.length)) {
    return true;
  }
  return false;
};

export const validateText = (text: string) => {
  if (text.length <= 99) {
    return true;
  }
  return false;
};

export const isBooking = (
  bookingObject: IBooking | IBookingError | null
): bookingObject is IBooking => {
  if (bookingObject === null) {
    return false;
  }
  return (bookingObject as any).error === undefined;
};

export const isPriceCounter = (
  object: number | IBookableExtraPriceCounter
): object is IBookableExtraPriceCounter => {
  return typeof object === 'object';
};

export const isTransport = (type: string) => {
  return type === 'transport' || type === 'blue-lagoon';
};

export const createDateTime = (
  date: Moment,
  time: string,
  addedHours?: number
) => {
  let hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);
  if (addedHours) {
    hours += addedHours;
  }
  const dateTime = moment.utc(date).set({
    h: hours,
    m: minutes,
    seconds: 0,
  });
  return moment(dateTime).format();
};

export const hideNav = (
  navItems: Array<{
    text: string;
    onClick: () => void;
  } | null>
) => {
  return navItems.filter((item) => !!item).length <= 2;
};

export const handleAxiosError = (e: AxiosError) => {
  if (e.response) {
    // console.log(e.response.data);
    // console.log(e.response.status);
    // console.log(e.response.headers);
  } else if (e.request) {
    // console.log(e.request);
  } else {
    // console.log('Error', e.message);
  }
  // console.log(e.config);
};

export const formatSearch = (text: string) => {
  return text.split(' ').join('+');
};

export const parseSearch = (text: string) => {
  return decodeURIComponent(text.split('+').join(' '));
};

export const formatDuration = (label: string) => {
  return label.split(' ').join('-').replace('+', 'plus').replace('1/2', 'half');
};

export const checkInputType = (dataFormat: DataFormat) => {
  switch (dataFormat) {
    case 'EMAIL_ADDRESS':
      return 'email';
    case 'PHONE_NUMBER':
      return 'tel';
    case 'URL':
      return 'url';
    case 'MONTH':
      return 'month';
    default:
      return 'text';
  }
};

// lodash keyby replacment
export const keyByIItem = (array: IItem[] | undefined, key: string) =>
  (array || []).reduce(
    (r, x) => ({ ...r, [key ? x[key] : x]: x }),
    {} as IItem[]
  );

// lodash camelCase replacement
// convert string to words
function toWords(input: string) {
  const regex =
    /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

  return input.match(regex);
}

// convert the input array to camel case
function toCamelCase(inputArray: RegExpMatchArray | null) {
  let result = '';
  if (inputArray) {
    for (let i = 0, len = inputArray.length; i < len; i++) {
      const currentStr = inputArray[i];

      let tempStr = currentStr.toLowerCase();

      if (i !== 0) {
        // convert first letter to upper case (the word is in lowercase)
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
      }

      result += tempStr;
    }
  }
  return result;
}

// this function call all other functions
export const camelize = (input: string) => {
  const words = toWords(input);

  return toCamelCase(words);
};

// lodash chunk
export const chunkTours = (
  arr: ITourListItem[] | ITourItem[],
  chunkSize = 1
) => {
  const tmp = [...arr];
  const cache: Array<Array<ITourListItem | ITourItem>> = [];
  if (chunkSize <= 0) {
    return cache;
  }
  while (tmp.length) {
    cache.push(tmp.splice(0, chunkSize));
  }
  return cache;
};

// maxby lodash replacement
export const maxByForExtraQuestion = (
  arr: IExtraQuestion[],
  iteratee: (a: IExtraQuestion) => number
) => {
  // const func = typeof iteratee === 'function' ? iteratee : (item: IExtraQuestion[]) => item[iteratee]
  const max = Math.max(...arr.map((question) => question.Index));
  return arr.find((item) => iteratee(item) === max);
};

export const randomString = () => {
  return Math.random().toString(36).substring(7);
};

export const stripSlashes = (text: string) => {
  let strippedText = text;
  if (strippedText[0] === '/') {
    strippedText = strippedText.substr(1, strippedText.length);
  }
  if (strippedText && strippedText[strippedText.length - 1] === '/') {
    strippedText = strippedText.substr(0, strippedText.length - 1);
  }
  return strippedText;
};

export const stripLangPath = (text: string) => {
  let strippedText = text;
  // TODO this works only for is now, will add the others later on
  if (strippedText.split('/')[0] === 'is') {
    strippedText = strippedText.substr(2, strippedText.length - 1);
  }
  return strippedText;
};

/**
 * Finds a person counter entry by its Id.
 *
 * @param counters - The IPersonCounter object containing all entries.
 * @param id - The Id of the entry to find.
 * @returns The matching person counter entry, or undefined if not found.
 */
export const getPersonCounterById = (counters: IPersonCounter, id: string) => {
  // Iterate over each key (entryId) in the counters object
  for (const entryId in counters) {
    if (counters.hasOwnProperty(entryId)) {
      const entry = counters[entryId];
      // Check if the current entry's Id matches the one we're searching for

      if (entry.Id === id) return entry; // Return the matching entry
    }
  }
  // If no entry is found with the given Id, return undefined
  return undefined;
};

export const countryCodesExtends: ICountryOption[] = [
  { label: 'Afghanistan', value: '+93', image: af, numLength: [9] },
  { label: 'Aland Islands', value: '+358', image: ax, numLength: [7, 8] },
  { label: 'Albania', value: '+355', image: al, numLength: [9] },
  { label: 'Algeria', value: '+213', image: dz, numLength: [9, 10] },
  { label: 'Andorra', value: '+376', image: ad, numLength: [6, 9] },
  { label: 'Angola', value: '+244', image: ao, numLength: [9] },
  { label: 'Antarctica', value: '+672', image: aq, numLength: [6] },
  { label: 'Argentina', value: '+54', image: ar, numLength: [10, 11] },
  { label: 'Armenia', value: '+374', image: am, numLength: [8, 9] },
  { label: 'Australia', value: '+61', image: au, numLength: [9, 10, 11] },
  { label: 'Austria', value: '+43', image: at, numLength: [10, 11, 12] },
  { label: 'Azerbaijan', value: '+994', image: az, numLength: [9] },
  { label: 'Bahamas', value: '+1-242', image: bs, numLength: [10] },
  { label: 'Bahrain', value: '+973', image: bh, numLength: [8] },
  { label: 'Bangladesh', value: '+880', image: bd, numLength: [9, 10] },
  { label: 'Barbados', value: '+1-246', image: bb, numLength: [7] },
  { label: 'Belarus', value: '+375', image: by, numLength: [9] },
  { label: 'Belgium', value: '+32', image: be, numLength: [9, 10] },
  { label: 'Belize', value: '+501', image: bz, numLength: [7] },
  { label: 'Benin', value: '+229', image: bj, numLength: [8] },
  { label: 'Bermuda', value: '+1-441', image: bm, numLength: [7] },
  { label: 'Bhutan', value: '+975', image: bt, numLength: [8] },
  { label: 'Bolivia', value: '+591', image: bo, numLength: [7, 8] },
  { label: 'Bosnia and Herzegovina', value: '+387', image: ba, numLength: [8] },
  { label: 'Botswana', value: '+267', image: bw, numLength: [7, 8] },
  { label: 'Brazil', value: '+55', image: br, numLength: [10, 11] },
  {
    label: 'British Indian Ocean Territory',
    value: '+246',
    image: io,
    numLength: [6],
  },
  { label: 'Brunei', value: '+673', image: bn, numLength: [7] },
  { label: 'Bulgaria', value: '+359', image: bg, numLength: [8, 9] },
  { label: 'Burkina Faso', value: '+226', image: bf, numLength: [8] },
  { label: 'Burundi', value: '+257', image: bi, numLength: [8] },
  { label: 'Cambodia', value: '+855', image: kh, numLength: [8, 9] },
  { label: 'Cameroon', value: '+237', image: cm, numLength: [8, 9] },
  { label: 'Canada', value: '+1', image: ca, numLength: [10] },
  { label: 'Cape Verde', value: '+238', image: cv, numLength: [7] },
  { label: 'Caribbean Netherlands', value: '+599', image: bq, numLength: [7] },
  { label: 'Cayman Islands', value: '+1-345', image: ky, numLength: [7] },
  {
    label: 'Central African Republic',
    value: '+236',
    image: cf,
    numLength: [8],
  },
  { label: 'Chad', value: '+235', image: td, numLength: [8] },
  { label: 'Chile', value: '+56', image: cl, numLength: [8, 9] },
  { label: 'China', value: '+86', image: cn, numLength: [11] },
  { label: 'Christmas Island', value: '+61', image: cx_flag, numLength: [5] },
  { label: 'Cocos (Keeling) Islands', value: '+61', image: cc, numLength: [5] },
  { label: 'Colombia', value: '+57', image: co, numLength: [10] },
  { label: 'Comoros', value: '+269', image: km, numLength: [7] },
  { label: 'Congo (DRC)', value: '+243', image: cd, numLength: [9] },
  { label: 'Congo (Republic)', value: '+242', image: cg, numLength: [9] },
  { label: 'Cook Islands', value: '+682', image: ck, numLength: [5] },
  { label: 'Costa Rica', value: '+506', image: cr, numLength: [8] },
  { label: 'Côte d Ivoire', value: '+225', image: ci, numLength: [8] },
  { label: 'Croatia', value: '+385', image: hr, numLength: [9, 10] },
  { label: 'Cuba', value: '+53', image: cu, numLength: [8] },
  { label: 'Curaçao', value: '+599', image: cw, numLength: [7, 9] },
  { label: 'Cyprus', value: '+357', image: cy, numLength: [9] },
  { label: 'Czech Republic', value: '+420', image: cz, numLength: [9] },
  { label: 'Denmark', value: '+45', image: dk, numLength: [8] },
  { label: 'Djibouti', value: '+253', image: dj, numLength: [8] },
  { label: 'Dominica', value: '+1-767', image: dm, numLength: [7] },
  {
    label: 'Dominican Republic',
    value: '+1-809',
    image: do_flag,
    numLength: [10],
  },
  { label: 'Ecuador', value: '+593', image: ec, numLength: [9] },
  { label: 'Egypt', value: '+20', image: eg, numLength: [9, 10] },
  { label: 'El Salvador', value: '+503', image: sv, numLength: [8] },
  { label: 'Equatorial Guinea', value: '+240', image: gq, numLength: [7, 9] },
  { label: 'Eritrea', value: '+291', image: er, numLength: [7] },
  { label: 'Estonia', value: '+372', image: ee, numLength: [7, 8] },
  { label: 'Eswatini', value: '+268', image: sz, numLength: [8] },
  { label: 'Ethiopia', value: '+251', image: et, numLength: [9, 10] },
  { label: 'Falkland Islands', value: '+500', image: fk, numLength: [5] },
  { label: 'Faroe Islands', value: '+298', image: fo, numLength: [6] },
  { label: 'Fiji', value: '+679', image: fj, numLength: [7] },
  { label: 'Finland', value: '+358', image: fi, numLength: [9, 10] },
  { label: 'France', value: '+33', image: fr, numLength: [9] },
  { label: 'French Guiana', value: '+594', image: gf, numLength: [9] },
  { label: 'French Polynesia', value: '+689', image: pf, numLength: [6] },
  { label: 'Gabon', value: '+241', image: ga, numLength: [7] },
  { label: 'Gambia', value: '+220', image: gm, numLength: [7] },
  { label: 'Georgia', value: '+995', image: ge, numLength: [9] },
  { label: 'Germany', value: '+49', image: de, numLength: [7, 10, 11] },
  { label: 'Ghana', value: '+233', image: gh, numLength: [9] },
  { label: 'Gibraltar', value: '+350', image: gi, numLength: [8] },
  { label: 'Greece', value: '+30', image: gr, numLength: [10] },
  { label: 'Greenland', value: '+299', image: gl, numLength: [6] },
  { label: 'Grenada', value: '+1-473', image: gd, numLength: [7] },
  { label: 'Guadeloupe', value: '+590', image: gp, numLength: [9] },
  { label: 'Guam', value: '+1-671', image: gu, numLength: [7] },
  { label: 'Guatemala', value: '+502', image: gt, numLength: [8] },
  { label: 'Guernsey', value: '+44-1481', image: gg, numLength: [6] },
  { label: 'Guinea', value: '+224', image: gn, numLength: [8] },
  { label: 'Guinea-Bissau', value: '+245', image: gw, numLength: [7] },
  { label: 'Guyana', value: '+592', image: gy, numLength: [7] },
  { label: 'Haiti', value: '+509', image: ht, numLength: [8] },
  { label: 'Honduras', value: '+504', image: hn, numLength: [8] },
  { label: 'Hong Kong', value: '+852', image: hk, numLength: [8] },
  { label: 'Hungary', value: '+36', image: hu, numLength: [8, 9] },
  { label: 'Iceland', value: '+354', image: is, numLength: [7] },
  { label: 'India', value: '+91', image: in_flag, numLength: [10] },
  { label: 'Indonesia', value: '+62', image: id, numLength: [9, 10, 11] },
  { label: 'Iran', value: '+98', image: ir, numLength: [10] },
  { label: 'Iraq', value: '+964', image: iq, numLength: [10] },
  { label: 'Ireland', value: '+353', image: ie, numLength: [9, 10] },
  { label: 'Isle of Man', value: '+44-1624', image: im, numLength: [6] },
  { label: 'Israel', value: '+972', image: il, numLength: [9] },
  { label: 'Italy', value: '+39', image: it, numLength: [10, 11] },
  { label: 'Jamaica', value: '+1-876', image: jm, numLength: [7] },
  { label: 'Japan', value: '+81', image: jp, numLength: [10] },
  { label: 'Jersey', value: '+44-1534', image: je, numLength: [6] },
  { label: 'Jordan', value: '+962', image: jo, numLength: [9] },
  { label: 'Kazakhstan', value: '+7', image: kz, numLength: [10] },
  { label: 'Kenya', value: '+254', image: ke, numLength: [9] },
  { label: 'Kiribati', value: '+686', image: ki, numLength: [5] },
  { label: 'Korea, North', value: '+850', image: kp, numLength: [9] },
  { label: 'Korea, South', value: '+82', image: kr, numLength: [10] },
  { label: 'Kuwait', value: '+965', image: kw, numLength: [8] },
  { label: 'Kyrgyzstan', value: '+996', image: kg, numLength: [9] },
  { label: 'Laos', value: '+856', image: la, numLength: [8, 9] },
  { label: 'Latvia', value: '+371', image: lv, numLength: [8] },
  { label: 'Lebanon', value: '+961', image: lb, numLength: [7, 8] },
  { label: 'Lesotho', value: '+266', image: ls_flag, numLength: [8] },
  { label: 'Liberia', value: '+231', image: lr, numLength: [7] },
  { label: 'Libya', value: '+218', image: ly, numLength: [9, 10] },
  { label: 'Liechtenstein', value: '+423', image: li, numLength: [7] },
  { label: 'Lithuania', value: '+370', image: lt, numLength: [8, 9] },
  { label: 'Luxembourg', value: '+352', image: lu, numLength: [8] },
  { label: 'Macao', value: '+853', image: mo, numLength: [8] },
  { label: 'Macedonia', value: '+389', image: mk, numLength: [8] },
  { label: 'Madagascar', value: '+261', image: mg, numLength: [9] },
  { label: 'Malawi', value: '+265', image: mw, numLength: [7, 9] },
  { label: 'Malaysia', value: '+60', image: my, numLength: [9, 10] },
  { label: 'Maldives', value: '+960', image: mv, numLength: [7] },
  { label: 'Mali', value: '+223', image: ml, numLength: [8] },
  { label: 'Malta', value: '+356', image: mt, numLength: [8] },
  { label: 'Marshall Islands', value: '+692', image: mh, numLength: [7] },
  { label: 'Martinique', value: '+596', image: mq, numLength: [9] },
  { label: 'Mauritania', value: '+222', image: mr, numLength: [8] },
  { label: 'Mauritius', value: '+230', image: mu, numLength: [7] },
  { label: 'Mayotte', value: '+262', image: yt, numLength: [9] },
  { label: 'Mexico', value: '+52', image: mx, numLength: [10] },
  { label: 'Micronesia', value: '+691', image: fm, numLength: [7] },
  { label: 'Moldova', value: '+373', image: md, numLength: [8] },
  { label: 'Monaco', value: '+377', image: mc, numLength: [8] },
  { label: 'Mongolia', value: '+976', image: mn, numLength: [8] },
  { label: 'Montenegro', value: '+382', image: me, numLength: [8] },
  { label: 'Montserrat', value: '+1-664', image: ms, numLength: [7] },
  { label: 'Morocco', value: '+212', image: ma, numLength: [9] },
  { label: 'Mozambique', value: '+258', image: mz, numLength: [9] },
  { label: 'Myanmar', value: '+95', image: mm, numLength: [8, 9] },
  { label: 'Namibia', value: '+264', image: na, numLength: [9] },
  { label: 'Nauru', value: '+674', image: nr, numLength: [7] },
  { label: 'Nepal', value: '+977', image: np, numLength: [10] },
  { label: 'Netherlands', value: '+31', image: nl, numLength: [9] },
  { label: 'New Caledonia', value: '+687', image: nc, numLength: [6] },
  { label: 'New Zealand', value: '+64', image: nz, numLength: [8, 9, 10] },
  { label: 'Nicaragua', value: '+505', image: ni, numLength: [8] },
  { label: 'Niger', value: '+227', image: ne, numLength: [8] },
  { label: 'Nigeria', value: '+234', image: ng, numLength: [10] },
  { label: 'Niue', value: '+683', image: nu, numLength: [4] },
  { label: 'Norfolk Island', value: '+672', image: nf, numLength: [5] },
  {
    label: 'Northern Mariana Islands',
    value: '+1-670',
    image: mp,
    numLength: [7],
  },
  { label: 'Norway', value: '+47', image: no, numLength: [8] },
  { label: 'Oman', value: '+968', image: om, numLength: [8] },
  { label: 'Pakistan', value: '+92', image: pk, numLength: [10] },
  { label: 'Palau', value: '+680', image: pw, numLength: [7] },
  { label: 'Palestinian Territory', value: '+970', image: ps, numLength: [9] },
  { label: 'Panama', value: '+507', image: pa, numLength: [8] },
  { label: 'Papua New Guinea', value: '+675', image: pg, numLength: [7] },
  { label: 'Paraguay', value: '+595', image: py, numLength: [9] },
  { label: 'Peru', value: '+51', image: pe, numLength: [9] },
  { label: 'Philippines', value: '+63', image: ph, numLength: [10] },
  { label: 'Pitcairn', value: '+64', image: pn, numLength: [6] },
  { label: 'Poland', value: '+48', image: pl, numLength: [9] },
  { label: 'Portugal', value: '+351', image: pt, numLength: [9] },
  { label: 'Puerto Rico', value: '+1-787', image: pr, numLength: [10] },
  { label: 'Qatar', value: '+974', image: qa, numLength: [8] },
  { label: 'Reunion', value: '+262', image: re, numLength: [9] },
  { label: 'Romania', value: '+40', image: ro, numLength: [9] },
  { label: 'Russia', value: '+7', image: ru, numLength: [10, 11] },
  { label: 'Rwanda', value: '+250', image: rw, numLength: [9] },
  { label: 'Saint Barthelemy', value: '+590', image: bl, numLength: [9] },
  { label: 'Saint Helena', value: '+290', image: sh, numLength: [4] },
  {
    label: 'Saint Kitts and Nevis',
    value: '+1-869',
    image: kn,
    numLength: [7],
  },
  { label: 'Saint Lucia', value: '+1-758', image: lc, numLength: [7] },
  { label: 'Saint Martin', value: '+590', image: mf, numLength: [9] },
  {
    label: 'Saint Pierre and Miquelon',
    value: '+508',
    image: pm,
    numLength: [6],
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: '+1-784',
    image: vc,
    numLength: [7],
  },
  { label: 'Samoa', value: '+685', image: ws, numLength: [7] },
  { label: 'San Marino', value: '+378', image: sm, numLength: [10] },
  { label: 'Sao Tome and Principe', value: '+239', image: st, numLength: [7] },
  { label: 'Saudi Arabia', value: '+966', image: sa, numLength: [9] },
  { label: 'Senegal', value: '+221', image: sn, numLength: [9] },
  { label: 'Serbia', value: '+381', image: rs, numLength: [8, 9] },
  { label: 'Seychelles', value: '+248', image: sc, numLength: [7] },
  { label: 'Sierra Leone', value: '+232', image: sl, numLength: [8] },
  { label: 'Singapore', value: '+65', image: sg, numLength: [8] },
  { label: 'Sint Maarten', value: '+1-721', image: sx, numLength: [7] },
  { label: 'Slovakia', value: '+421', image: sk, numLength: [9] },
  { label: 'Slovenia', value: '+386', image: si, numLength: [8] },
  { label: 'Solomon Islands', value: '+677', image: sb, numLength: [5] },
  { label: 'Somalia', value: '+252', image: so, numLength: [8] },
  { label: 'South Africa', value: '+27', image: za, numLength: [9, 10] },
  { label: 'South Sudan', value: '+211', image: ss, numLength: [9] },
  { label: 'Spain', value: '+34', image: es, numLength: [9] },
  { label: 'Sri Lanka', value: '+94', image: lk, numLength: [9] },
  { label: 'Sudan', value: '+249', image: sd, numLength: [9] },
  { label: 'Suriname', value: '+597', image: sr, numLength: [7] },
  { label: 'Svalbard and Jan Mayen', value: '+47', image: sj, numLength: [8] },
  { label: 'Sweden', value: '+46', image: se, numLength: [9, 10] },
  { label: 'Switzerland', value: '+41', image: ch, numLength: [9] },
  { label: 'Syria', value: '+963', image: sy, numLength: [9] },
  { label: 'Taiwan', value: '+886', image: tw, numLength: [9] },
  { label: 'Tajikistan', value: '+992', image: tj, numLength: [9] },
  { label: 'Tanzania', value: '+255', image: tz, numLength: [9, 10] },
  { label: 'Thailand', value: '+66', image: th, numLength: [9] },
  { label: 'Timor-Leste', value: '+670', image: tl, numLength: [8] },
  { label: 'Togo', value: '+228', image: tg, numLength: [8] },
  { label: 'Tokelau', value: '+690', image: tk, numLength: [4] },
  { label: 'Tonga', value: '+676', image: to, numLength: [5] },
  { label: 'Trinidad and Tobago', value: '+1-868', image: tt, numLength: [7] },
  { label: 'Tunisia', value: '+216', image: tn, numLength: [8] },
  { label: 'Turkey', value: '+90', image: tr, numLength: [10, 11] },
  { label: 'Turkmenistan', value: '+993', image: tm, numLength: [8] },
  {
    label: 'Turks and Caicos Islands',
    value: '+1-649',
    image: tc,
    numLength: [7],
  },
  { label: 'Tuvalu', value: '+688', image: tv, numLength: [5] },
  { label: 'Uganda', value: '+256', image: ug, numLength: [9] },
  { label: 'Ukraine', value: '+380', image: ua, numLength: [9] },
  { label: 'United Arab Emirates', value: '+971', image: ae, numLength: [9] },
  { label: 'United Kingdom', value: '+44', image: gb, numLength: [10, 11] },
  { label: 'United States', value: '+1', image: us, numLength: [10] },
  { label: 'Uruguay', value: '+598', image: uy, numLength: [8] },
  { label: 'Uzbekistan', value: '+998', image: uz, numLength: [9] },
  { label: 'Vanuatu', value: '+678', image: vu, numLength: [7] },
  { label: 'Vatican', value: '+379', image: va, numLength: [10] },
  { label: 'Venezuela', value: '+58', image: ve, numLength: [10] },
  { label: 'Vietnam', value: '+84', image: vn, numLength: [9, 10] },
  {
    label: 'Virgin Islands, British',
    value: '+1-284',
    image: vg,
    numLength: [7],
  },
  {
    label: 'Virgin Islands, U.S.',
    value: '+1-340',
    image: vi,
    numLength: [10],
  },
  { label: 'Wallis and Futuna', value: '+681', image: wf, numLength: [6] },
  { label: 'Western Sahara', value: '+212', image: eh, numLength: [9] },
  { label: 'Yemen', value: '+967', image: ye, numLength: [9] },
  { label: 'Zambia', value: '+260', image: zm, numLength: [9] },
  { label: 'Zimbabwe', value: '+263', image: zw, numLength: [9] },
];
