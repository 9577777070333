import axios, { AxiosInstance } from 'axios';
import { IBooking, ICart, IFlybus } from '../interfaces';
import { handleAxiosError } from '../utils';

interface IErrorLogApi {
    // getBooking(
    //   bookingNumber: string,
    //   currency: string,
    //   lang: string
    // ): Promise<IBooking | null>;
    logError(error: Error, lang: string):Promise<string | null>;
  }

class ErrorlogApi implements IErrorLogApi {
    httpClient: AxiosInstance;
    
    constructor() {
        const instance = axios.create({
          baseURL: process.env.GATSBY_PROXY_URL,
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'json'
        });
        this.httpClient = instance;
      }
   
    //LogFrontendError(FrontendError error) 
    async logError(
        error: Error | null,
        lang: string
      ): Promise<string | null> {
        try {
          const result = await this.httpClient.post(
            '/api/ErrorLogController/LogFrontendError',
            JSON.stringify(error)
          );
          console.log(result);
          return result.data;
        } catch (e) {
          if (!!e.response && e.response.status === 403) {
            return e.response.data;
          } else if (!!e.response && e.response.status === 500) {
            if (e.response.data.error) {
              e.response.data.error.ErrorMessage =
                lang === 'IS'
                  ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
                  : 'Something went wrong, please review your information and try again.';
            }
    
            return e.response.data;
          } else if (!!e.response && e.response.status === 422) {
            try {
              const firstError = Object.keys(e.response.data.error)[0];
              if (firstError) {
                e.response.data.error.ErrorMessage =
                  e.response.data.error[firstError].ErrorMessage;
              }
            } catch (e) {
              return e.response.data;
            }
    
            return e.response.data;
          } else if (e.request) {
            // console.log(e.request);
          }
          return null;
        }
      }
}
export default ErrorlogApi;