import React from 'react';
import { setCart } from '../../redux/actions/cartActions';
import { connect } from 'react-redux';
import {
  ITourDetails,
  ITranslationsTourBookingButton,
  ITranslationsTourBookingFreeNoFees,
} from '../../interfaces';
import cx from 'classnames';
import * as styles from './TourBookingPrivate.module.scss';
import TourBookingFreeNoFees from '../components/TourBookingFreeNoFees';
import TourBookingButtonPrivate from '../components/TourBookingButtonPrivate';

interface IProps {
  tour: ITourDetails;
  textButton: ITranslationsTourBookingButton;
  textFees: ITranslationsTourBookingFreeNoFees;
}

const TourBookingPrivate: React.FunctionComponent<IProps> = (props) => {
  const { tour, textFees, textButton } = props;

  return (
    <div className={cx(styles.container)}>
      <TourBookingFreeNoFees text={textFees} privateTour={true} />
      <div>
        <TourBookingButtonPrivate
          privateUrl={tour.privateTourUrl}
          text={textButton.buttonTextBeforeClickPrivateTour}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: any) => {
  const { cartReducers, currencyReducer } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  return { cartObject, currency };
};

export default connect(mapStateToProps, {
  setCart,
})(TourBookingPrivate);
