import React, { useRef } from 'react';
import * as styles from './index.module.scss';
import { cartDark as cart } from '../../../icons';
import Loader from '../../../components/Loader';
import { ITranslationsTourBookingButton } from '../../../interfaces/translations';

interface IProps {
  modalOpen: boolean;
  addingToCart: boolean;
  getTotalPersonCount(): number;
  addToCart(): void;
  toggleModal(value: boolean): void;
  text: ITranslationsTourBookingButton;
}

const TourBookingButton: React.FunctionComponent<IProps> = (props) => {
  const {
    modalOpen,
    getTotalPersonCount,
    addToCart,
    addingToCart,
    toggleModal,
    text,
  } = props;
  const button = useRef<HTMLButtonElement | null>(null);
  if (modalOpen && getTotalPersonCount() > 0) {
    return (
      <button
        onClick={() => addToCart()}
        className={styles.bookingButton}
        ref={button}
        aria-label='Add to cart'
      >
        {addingToCart === true ? (
          <Loader color='white' />
        ) : (
          <p>{text.buttonTextAfterClick}</p>
        )}
        <img src={cart} alt='Cart' onClick={() => addToCart()} />
      </button>
    );
  } else {
    return (
      <button
        onClick={() => {
          toggleModal(true);
        }}
        className={styles.bookingButton}
        aria-label='Book Now'
        data-cy='Book-Button'
      >
        {addingToCart === true ? (
          <Loader color='white' />
        ) : (
          text.buttonTextBeforeClick
        )}
      </button>
    );
  }
};

export default TourBookingButton;
