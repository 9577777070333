import React, { useRef, useState } from 'react';
import { setCart } from '../../redux/actions/cartActions';
import {
  TransportDatePicker,
  TransportSelect,
  TransportTime,
  BlueLagoonTime,
  TransportGuests,
  TransportPriceBreakdown
} from '../components';
import { connect } from 'react-redux';
import {
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ISelectTime,
  ITransportPersonCounter,
  AddOrRemove,
  ILagoonSelect,
  ITranslationsBookingModal,
  ITourDetails
} from '../../interfaces';
import { Moment } from 'moment';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  tour: ITourDetails;
  productId: string;
  transport: ITransport | null;
  transportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartDate: Moment;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  selectedBluelagoonTime: ILagoonSelect | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  changeSelectedLagoonTime(time: ILagoonSelect): void;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment): void;
  changeSelectedTime(time: ISelectTime, isReturn: boolean): void;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  getAvailabilityLeft(): number;
  addToCart(): void;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}

const BlueLagoonBookingMobile: React.FunctionComponent<IProps> = props => {
  const [isValid, triggerValid] = useState(true);

  const priceRef = useRef<HTMLDivElement | null>(null);

  const {
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    selectedEndTime,
    endTimeOptions,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    localeData,
    tour,
    transport
  } = props;

  function addToCart() {
    if (
      !!props.selectedStartDate &&
      !!props.selectedStartTime &&
      !!selectedBluelagoonTime
    ) {
      triggerValid(true);
      props.addToCart();
    } else {
      triggerValid(false);
    }
  }
  return (
    <React.Fragment>
      <div className='column'>
        <TransportSelect
          startPlaces={startPlaces}
          selectedStartPlace={selectedStartPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          list='Start'
          ariaLabel={localeData.from.inputLabel}
          disabled={false}
          toFromLabel={localeData.from}
          isMobile={true}
        />
      </div>
      <div className='column'>
        <TransportSelect
          startPlaces={endPlaces}
          selectedStartPlace={selectedEndPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          list='End'
          ariaLabel='to'
          disabled={false}
          toFromLabel={localeData.to}
          isMobile={true}
        />
      </div>
      <div className='column'>
        <TransportDatePicker
          selectedDate={selectedStartDate}
          isReturn={false}
          changeDate={props.changeSelectedDate}
          transportKey='blueLagoonTransportMobile'
          transport={transport}
          selectedStartPlace={null}
          selectedEndPlace={null}
        />
      </div>
      <div className='column'>
        <TransportTime
          selectedStartTime={selectedStartTime}
          starTimeOptions={starTimeOptions}
          isValid={isValid}
          changeSelectedTime={props.changeSelectedTime}
          isReturn={false}
          ariaLabel='Departure time'
          tabIndex='0'
          timeSelectorHeader={localeData.departureTime}
          isMobile={true}
        />
      </div>
      <div className='column'>
        <BlueLagoonTime
          selectedBluelagoonTime={selectedBluelagoonTime}
          blueLagoonTimeOptions={blueLagoonTimeOptions}
          selectedStartTime={selectedStartTime}
          selectedStartDate={selectedStartDate}
          isValid={isValid || !!selectedBluelagoonTime}
          ariaLabel={localeData.lagoonEntryTime}
          tabIndex='0'
          timeSelectorHeader={localeData.lagoonEntryTime}
          changeSelectedLagoonTime={props.changeSelectedLagoonTime}
        />
      </div>
      <div className='column'>
        <TransportTime
          isReturn={true}
          selectedEndTime={selectedEndTime}
          selectedBluelagoonTime={selectedBluelagoonTime}
          date={selectedStartDate}
          endTimeEndOptions={endTimeOptions}
          changeSelectedTime={props.changeSelectedTime}
          isValid={isValid || !!selectedEndTime}
          isLagoon={true}
          ariaLabel={localeData.returnTime}
          tabIndex='0'
          timeSelectorHeader={localeData.returnTime}
          menuPlacement='top'
        />
      </div>
      <div className='column'>
        <TransportGuests
          personCounter={personCounter}
          changePersonCounter={props.changePersonCounter}
          participantsSelectorHeader={localeData.participantsSelectorHeader}
          isLagoon={true}
          getTotalPersonCount={props.getTotalPersonCount}
          getAvailabilityLeft={props.getAvailabilityLeft}
        />
      </div>
      <div className='column'>
        <TransportPriceBreakdown
          personCounter={personCounter}
          getTotalPersonCount={props.getTotalPersonCount}
          getPersonCount={props.getPersonCount}
          withReturn={false}
          priceRef={priceRef}
          priceHeader={localeData.priceHeader}
          totalPriceString={localeData.totalPrice}
          noBookingFees={localeData.noBookingFees}
          freeCancellation={localeData.freeCancellation}
          smallPrint={localeData.smallPrint}
          addToCartButton={localeData.addToCartButton}
          addToCart={addToCart}
          addingToCart={addingToCart}
          tour={tour}
          isMobile={true}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (reducers: any) => {
  const { cartReducers, currencyReducer } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale } = reducers.translationReducers;
  return { cartObject, currency, locale };
};

export default connect(
  mapStateToProps,
  {
    setCart
  }
)(
  ({
    productId,
    transport,
    transportRoutes,
    startPlaces,
    endPlaces,
    selectedStartDate,
    selectedStartPlace,
    selectedEndPlace,
    selectedStartTime,
    selectedEndTime,
    starTimeOptions,
    endTimeOptions,
    personCounter,
    addingToCart,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    tour,
    locale,
    changeSelectedLagoonTime,
    changeSelectedPlace,
    changeSelectedDate,
    changeSelectedTime,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    getAvailabilityLeft,
    addToCart
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query BlueLagoonBookingMobile {
          allContentfulTranslationsPage {
            edges {
              node {
                ...TranslationPageBookingBox
              }
            }
          }
          allContentfulTranslationsBookingModal {
            edges {
              node {
                ...BookingModalHardcodedStrings
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <BlueLagoonBookingMobile
            localeData={
              data.allContentfulTranslationsBookingModal.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            productId={productId}
            transport={transport}
            transportRoutes={transportRoutes}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            selectedStartDate={selectedStartDate}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            personCounter={personCounter}
            addingToCart={addingToCart}
            selectedBluelagoonTime={selectedBluelagoonTime}
            blueLagoonTimeOptions={blueLagoonTimeOptions}
            changeSelectedLagoonTime={changeSelectedLagoonTime}
            changeSelectedPlace={changeSelectedPlace}
            changeSelectedDate={changeSelectedDate}
            changeSelectedTime={changeSelectedTime}
            changePersonCounter={changePersonCounter}
            getTotalPersonCount={getTotalPersonCount}
            getPersonCount={getPersonCount}
            getAvailabilityLeft={getAvailabilityLeft}
            addToCart={addToCart}
            locale={locale}
            tour={tour}
          />
        );
      }}
    />
  )
);
