import React from 'react';
import cx from 'classnames';
import * as styles from './BlogCard.module.scss';
import moment from 'moment';
import Title from '../Title';
import { IBlogItem } from '../../interfaces';
import { Link, Image } from '../index';

interface IProps {
  blogs: IBlogItem[];
  langPath: string;
}
function renderBlog(blog: IBlogItem, langPath: string) {
  if (!blog.mainImage) {
    return null;
  } else {
    return (
      <div
        key={blog.id}
        className={cx(
          'column',
          'is-full is-half-desktop',
          'vertical-margin',
          styles.item
        )}
      >
        <div className={cx(styles.item)}>
          <div className={styles.main}>
            <div className={styles.buttonDiv}>
              <Link langPath={langPath} to={`${blog.pathPrefix}/${blog.slug}`}>
                <button className={styles.readButton}>Read blog</button>
              </Link>
            </div>
            <Link langPath={langPath} to={`${blog.pathPrefix}/${blog.slug}`}>
              <div className={styles.mainContent}>
                <Title tag='h2' theme={['sizeDesktopMedium']}>
                  {blog.title}
                </Title>
                <p className={styles.date}>{moment(blog.date).format('LL')}</p>
                <p className={styles.summary}>
                  {blog.summary ? blog.summary.summary : null}
                </p>
              </div>
            </Link>
            <div className={styles.tags}>
              {blog.tags === null
                ? ''
                : blog.tags.map((tag) => (
                    <p key={tag.tag} className={styles.tagsP}>
                      {tag.tag}
                    </p>
                  ))}
            </div>
          </div>
          {blog.mainImage ? (
            <div>
              <Image
                fluidImage={{
                  ...blog.mainImage.fluid,
                  fluid: {
                    ...(blog.mainImage ? blog.mainImage.fluid : null),
                    sizes: '(max-width: 1087px) 50vw, 25vw',
                    alt: blog.mainImage.title,
                  },
                }}
                productPhoto={null}
                className={styles.blogImage}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const BlogCard: React.FunctionComponent<IProps> = (props) => {
  const { blogs, langPath } = props;
  return (
    <div className={cx('centered-content', styles.container)}>
      <div className={cx('columns', 'is-multiline', 'is-variable', 'is-6')}>
        {blogs.map((blog) => renderBlog(blog, langPath))}
      </div>
    </div>
  );
};

export default BlogCard;
