import React from 'react';
import * as styles from './index.module.scss';
import Icon from '../../../components/Icon';
import { ITranslationsCancellationPolicy } from '../../../interfaces/translations';

interface IProps {
  hardcodedStrings: ITranslationsCancellationPolicy;
}

const CancellationPolicy: React.FunctionComponent<IProps> = (props) => {
  function setMainUrl() {
    const url = window.location;
    return url.pathname.includes('/is') ? url.origin + '/is' : url.origin;
  }

  function getCancellationPolicy(receivedProps: IProps) {
    const { hardcodedStrings } = receivedProps;
    const { noBookingFees, freeCancellation, smallPrint } = hardcodedStrings;

    return (
      <div className={styles.container}>
        <ul>
          <li>
            <Icon name='CHECKMARK' />
            <span className={styles.paddingLeft}>{freeCancellation}</span>
          </li>
          <li>
            <Icon name='CHECKMARK' />
            <span className={styles.paddingLeft}>{noBookingFees}</span>
          </li>
        </ul>
        <a
          href={setMainUrl() + '/terms-and-conditions/'}
          target='_blank'
          rel='noopener noreferrer'
          className={styles.link}
        >
          {smallPrint}
        </a>
      </div>
    );
  }
  return getCancellationPolicy(props);
};

export default CancellationPolicy;
