import React from 'react';
import cx from 'classnames';

import * as styles from './index.module.scss';

const Title = ({ tag, theme, children, ...props }: any) => {
  const titleProps = { ...props };

  const allClasses = theme.map(
    (singleTheme: string | number) => styles[singleTheme]
  );

  titleProps.className = cx(allClasses, styles.title);
  return React.createElement(tag, titleProps, children);
};

export default Title;
