import { IPageContext, ICurrencyIcons } from '../interfaces';
import {
  Krona,
  Dollar,
  Euro,
  Pound,
  KronaLight,
  EuroLight,
  DollarLight,
  PoundLight
} from '../icons';

export * from './filters';

// not used
// Move into Contentful translations?
export const langLabels: { [key: string]: string } = {
  'en-US': 'en',
  'de-DE': 'de'
};

export const defaultPageContext: IPageContext = {
  slug: '/',
  locale: 'en-US',
  langKey: 'en',
  langPath: '',
  langSlugs: {},
  navColor: 'dark',
  logoColor: 'dark'
};

export const currencies: ICurrencyIcons = {
  is: { label: 'ISK', icon: Krona, lightIcon: KronaLight },
  eu: { label: 'EUR', icon: Euro, lightIcon: EuroLight },
  us: { label: 'USD', icon: Dollar, lightIcon: DollarLight },
  gb: { label: 'GBP', icon: Pound, lightIcon: PoundLight }
  /*   ca: 'CAD',
  jp: 'JPY',
  no: 'NOK',
  se: 'SEK',
  dk: 'DKK',
  ch: 'CHF' */
};

export const currencyCodes = Object.keys(currencies);
export const currencyLabels = Object.values(currencies);

// Sizes from Bulma
export const windowSizes = {
  tablet: 769,
  header: 950,
  desktop: 1088,
  widescreen: 1280,
  fullhd: 1472
};

export const colors = {
  coralRed: '#fa7268',
  darkBlue: '#294b98',
  lightGray: '#f7f7f7',
  mediumLightGray: '#adadad',
  mediumGray: '#676767',
  darkGray: '#2c2c2c'
};

// TODO HARDCODED might need to be translated not sure
export const levels: { [level: string]: string } = {
  VERY_EASY: 'Very easy',
  EASY: 'Easy',
  MODERATE: 'Moderate',
  CHALLENGING: 'Challenging',
  DEMANDING: 'Demanding',
  EXTREME: 'Extreme'
};

export const stickyNav = {
  height: 100,
  scrollPadding: 20
};

export const heroHeight = 800;
export const tourInfoHeight = 250;
export const blurBoxSize = 50;
