import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/is';
import 'moment-timezone';
import cx from 'classnames';
import { IPageContext, IReducers } from '../../interfaces';
import { Header, Footer, Transition } from '../index';
import * as styles from './Layout.module.scss';
import { defaultPageContext } from '../../constants';
import SkipNavigation from '../SkipNavigation';
import { graphql, StaticQuery } from 'gatsby';
import { changeIcelandair } from '../../../src/redux/actions/layoutActions';
import ErrorlogApi from '../../../src/api/errorlogApi';
interface IExternalProps {
  children: React.ReactNode;
  pageContext: IPageContext;
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  // isDrawerOpen: any;
  announcementVisible: boolean;
  announcementVisibleMobile: boolean;
  locale: string;
  toggleDrawer(open: boolean): void;
  isIcelandair: boolean;
  changeIcelandair(parameter: boolean): void;
}

interface IProps extends IExternalProps {
  localeData: {
    layoutSkipNavigation: string;
    layoutError: {
      mainTitle: string;
      subtitle: string;
    };
  };
}

interface IState {
  error: Error | null;
  userFlybus: boolean;
}

class Layout extends React.Component<IProps, IState> {
  readonly state = { error: null, userFlybus: false };
  errorlogApi = new ErrorlogApi();

  componentDidMount() {
    moment.tz.setDefault('Atlantic/Reykjavik');
    // AOS.init({ once: true, duration: 500 });
  }
  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error });
    if (error) {
      this.errorlogApi.logError(error, this.props.locale);
    }
  }

  render() {
    const {
      children,
      pageContext,
      location,
      announcementVisible,
      announcementVisibleMobile,
      localeData,
      isIcelandair,
    } = this.props;
    const { error } = this.state;
    const icelandairUrl: boolean = location.pathname.includes('icelandair')
      ? true
      : false;
    moment.locale(pageContext.langKey);
    let context =
      Object.keys(pageContext).length === 0 ||
      pageContext.isCreatedByStatefulCreatePages
        ? defaultPageContext
        : pageContext;

    const display = error ? this.renderError() : children;
    context = error
      ? { ...context, logoColor: 'dark', navColor: 'dark' }
      : context;

    let styleContent = styles.content;
    if (announcementVisible) {
      styleContent = styles.contentWithAnnouncement;
      //styles.contentWithAnnouncement;
    } else if (announcementVisibleMobile) {
      if (!isIcelandair) {
        styleContent = styles.contentWithAnnouncementMobile;
        //styles.contentWithAnnouncementMobile;
      }
    } else {
      styleContent = styles.content;
    }
    const getQuery = (variable: string) => {
      const vars = this.props.location.search.substring(1).split('&');
      for (const v of vars) {
        const pair = v.split('=');
        if (pair[0] === variable) {
          return pair[1];
        }
      }

      return false;
    };
    const queryIsIcelandair = getQuery('headers') ? true : false;
    if (queryIsIcelandair || !isIcelandair) {
      this.props.changeIcelandair(queryIsIcelandair);
    }
    return (
      <div className={styles.container}>
        <SkipNavigation>{localeData.layoutSkipNavigation}</SkipNavigation>
        <Header
          pageContext={context}
          pathname={location.pathname}
          icelandairUrl={icelandairUrl}
          isUserFlybus={isIcelandair}
        />
        <div className={styleContent} id='main'>
          <Transition location={location}>{display}</Transition>
        </div>
        <Footer
          pageContext={context}
          icelandairUrl={icelandairUrl}
          isIcelandair={isIcelandair}
        />
      </div>
    );
  }

  renderError() {
    const { layoutError } = this.props.localeData;
    return (
      <div className={cx('centered-content', styles.errorBoundary)}>
        <h1>{layoutError.mainTitle}</h1>
        <p>{layoutError.subtitle}</p>
      </div>
    );
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { announcementVisible, announcementVisibleMobile, isIcelandair } =
    reducers.layoutReducer;
  const { locale } = reducers.translationReducers;
  return {
    announcementVisible,
    announcementVisibleMobile,
    locale,
    isIcelandair,
  };
};

// export default connect(mapStateToProps)(Layout);

export default connect(mapStateToProps, { changeIcelandair })(
  ({
    children,
    pageContext,
    location,
    // isDrawerOpen,
    announcementVisible,
    announcementVisibleMobile,
    toggleDrawer,
    locale,
    isIcelandair,
    changeIcelandair: changerIcelandair,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query Layout {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                tourListTitle {
                  singular
                  plural
                }
                tourListArrows {
                  version1
                  version2
                }
                layoutError {
                  mainTitle
                  subtitle
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Layout
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            // eslint-disable-next-line react/no-children-prop
            children={children}
            pageContext={pageContext}
            location={location}
            // isDrawerOpen={isDrawerOpen}
            announcementVisible={announcementVisible}
            announcementVisibleMobile={announcementVisibleMobile}
            toggleDrawer={toggleDrawer}
            locale={locale}
            isIcelandair={isIcelandair}
            changeIcelandair={changerIcelandair}
          />
        );
      }}
    />
  )
);
