import React from 'react';

export const NavDown = () => (
  <svg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M.85811.5a.85546.85546 0 01.60681.25148l2.54341 2.47074L6.54544.75148a.85831.85831 0 011.20317.01046.85829.85829 0 01.01046 1.20317L4.00833 5.64976.2513 1.96511A.85831.85831 0 01.8581.50001z'
      fill='#2C2C2C'
    />
  </svg>
);
export default NavDown;
