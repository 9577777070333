import React from 'react';
import * as styles from './index.module.scss';
import cx from 'classnames';
import { ITranslationsTourBookingFreeNoFees } from '../../../interfaces/translations';
import Icon from '../../../components/Icon';

interface IProps {
  text: ITranslationsTourBookingFreeNoFees;
  privateTour: boolean | undefined;
}

const TourBookingFreeNoFees: React.FunctionComponent<IProps> = (props) => {
  const { text, privateTour } = props;
  return (
    <div className={privateTour ? styles.container : 'column is-3'}>
      <div className={cx(styles.bookingText, styles.bookingTextLeft)}>
        <Icon name='CHECKMARK' />
        <p>{text.tourBookingBoxFreeCancellation}</p>
      </div>
      <div className={styles.bookingText}>
        <Icon name='CHECKMARK' />
        <p>{text.tourBookingBoxNoBookingFees}</p>
      </div>{' '}
    </div>
  );
};

export default TourBookingFreeNoFees;
