import React, { useState } from 'react';
import {
  ITransportOption,
  ISelectPickupValue,
  ITransportList,
  IReducers
} from '../../interfaces';
import Select, { components } from 'react-select';
import {
  customNoIndicator,
  noArrowStyle,
  errorStyle
} from '../../styles/ReactSelect';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  placeList: ITransportOption[] | null;
  list?: ITransportList;
  selected: ISelectPickupValue | null;
  disabled: boolean;
  canContinue?: boolean;
  isMobile?: boolean;
  ariaLabel: string;
  tabIndex: string;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    pickUpSelectionPlaceholder: {
      version1: string;
      version2: string;
    };
  };
}

const TransportPickupDropoff: React.FunctionComponent<IProps> = props => {
  const [open, triggerSelect] = useState(false);
  const {
    placeList,
    list,
    selected,
    disabled,
    ariaLabel,
    isMobile,
    localeData
  } = props;
  function getStyle() {
    const { canContinue } = props;

    if (props.disabled) {
      return noArrowStyle;
    } else if (!canContinue && !selected) {
      return errorStyle;
    } else {
      if (props.isMobile) {
        return customNoIndicator;
      } else {
        return customNoIndicator;
      }
    }
  }
  if (!!placeList || !!selected) {
    return (
      <div style={{ margin: '10px 0px 10px' }}>
        <Select
          menuPortalTarget={document.body}
          placeholder={
            isMobile
              ? localeData.pickUpSelectionPlaceholder.version1
              : localeData.pickUpSelectionPlaceholder.version2
          }
          options={placeList ? placeList : undefined}
          value={selected ? selected : null}
          isMulti={false}
          isDisabled={disabled}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={!isMobile}
          menuIsOpen={open}
          components={{ Group: components.Group }}
          styles={getStyle()}
          onMenuOpen={() => triggerSelect(true)}
          onMenuClose={() => triggerSelect(false)}
          onChange={(place: any) =>
            list ? props.changeSelectedPlace(place, list) : null
          }
          aria-label={ariaLabel}
          tabIndex={props.tabIndex}
        />
      </div>
    );
  }
  return (
    <Select
      placeholder=''
      styles={getStyle()}
      isDisabled={true}
      aria-label={ariaLabel}
    />
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    placeList,
    list,
    selected,
    disabled,
    canContinue,
    isMobile,
    ariaLabel,
    tabIndex,
    changeSelectedPlace,
    locale
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportPickupDropoff {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                pickUpSelectionPlaceholder {
                  version1
                  version2
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <TransportPickupDropoff
            localeData={
              data.allContentfulTranslationsBookingModal.edges.filter(
                (node: { node: { node_locale: any } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            placeList={placeList}
            list={list}
            selected={selected}
            disabled={disabled}
            canContinue={canContinue}
            isMobile={isMobile}
            ariaLabel={ariaLabel}
            tabIndex={tabIndex}
            changeSelectedPlace={changeSelectedPlace}
            locale={locale}
          />
        );
      }}
    />
  )
);
