import React from 'react';

// arrowleft
export const ArrowLeft = () => (
  <svg
    width='11'
    height='8'
    fill='none'
    viewBox='0 0 11 8'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M.646 3.646a.5.5 0 000 .708l3.182 3.182a.5.5 0 10.708-.708L1.707 4l2.829-2.828a.5.5 0 10-.708-.708L.646 3.646zM11 3.5H1v1h10v-1z'
      fill='#2C2C2C'
    />
  </svg>
);
export default ArrowLeft;
