import React from 'react';
import * as styles from './index.module.scss';
import Title from '../../../components/Title';
import TourCountPersons from '../TourCountPersons';
import {
  IPersonCounter,
  ISortedAvailability,
  ISelectValue,
} from '../../../interfaces';
import { Moment } from 'moment';

type AddOrRemove = 'addOne' | 'removeOne';

interface IProps {
  personCounter: IPersonCounter | null;
  availability: ISortedAvailability | null;
  header: string;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  getTotalPersonCount(): number;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
}

const TourBookingGuests: React.FunctionComponent<IProps> = ({
  personCounter,
  getTotalPersonCount,
  changePersonCount,
  availability,
  selectedDate,
  selectedTime,
  header,
}) => {
  return (
    <div className={styles.guestsPadding}>
      <Title tag='h3' theme={['sizeDesktopSmall']}>
        {header}
      </Title>
      <TourCountPersons
        personCounter={personCounter}
        getTotalPersonCount={getTotalPersonCount}
        changePersonCount={changePersonCount}
        availability={availability}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
      />
    </div>
  );
};

export default TourBookingGuests;
