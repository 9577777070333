import React from 'react';
import { check, checkReverse } from '../../icons';
import * as styles from './CheckBox.module.scss';

interface IProps {
  children: React.ReactNode;
  checked: boolean;
  isError?: boolean;
  blueBorderColor?: boolean;
  toggleCheckbox(check: boolean): void;
}

const CheckBox: React.FunctionComponent<IProps> = (props) => {
  const { checked, toggleCheckbox, isError, children, blueBorderColor } = props;
  let style = null;
  let checkMark = null;
  if (blueBorderColor) {
    if (checked) {
      style = styles.checkMarkContainerBlueChecked;
      checkMark = checkReverse;
    } else {
      style = styles.checkMarkContainerBlue;
      checkMark = check;
    }
  } else {
    style = styles.checkMarkContainer;
    checkMark = check;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className={style} onClick={() => toggleCheckbox(!checked)}>
        <img
          src={checkMark}
          alt='Check mark'
          style={{ visibility: checked ? 'visible' : 'hidden' }}
        />
      </div>

      <label style={{ flex: 5, padding: 10, color: isError ? 'red' : '' }}>
        {children}
      </label>
    </div>
  );
};

export default CheckBox;
