import React from 'react';
import Markdown from 'markdown-to-jsx';

interface Props {
  text: string;
}

const DescriptionText = ({ text }: Props) => {
  const formattedMarkdown = text.replace(/&#39;/g, '&apos;');
  return <Markdown>{formattedMarkdown}</Markdown>;
};

export default DescriptionText;
