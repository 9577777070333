import React from 'react';
import * as styles from './index.module.scss';
import { ITranslationsSagaPoints } from '../../../interfaces/translations';
import { Title } from '../../../../src/components';

interface IProps {
  hardcodedStrings: ITranslationsSagaPoints;
}
const SagaPoints: React.FunctionComponent<IProps> = (props) => {
  function getCancellationPolicy(receivedProps: IProps) {
    const { hardcodedStrings } = receivedProps;
    const { sagaTitle, sagaText, sagaInput } = hardcodedStrings;

    return (
      <div className={styles.container}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {sagaTitle}
        </Title>
        <p className={styles.textContainer}>{sagaText}</p>
      </div>
    );
  }
  return getCancellationPolicy(props);
};

export default SagaPoints;
