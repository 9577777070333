import React from 'react';
import Icon from '../../../components/Icon/index';
import * as styles from './index.module.scss';

interface ISoAProps {
  subtractOrAdd: string;
  onClick: () => void;
}

const SubtractOrAdd: React.FunctionComponent<ISoAProps> = ({
  subtractOrAdd,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={subtractOrAdd === 'subtract' ? 'subtract' : 'add'}
      data-cy={subtractOrAdd === 'subtract' ? 'Subtract-Button' : 'Add-Button'}
      className={styles.button}
    >
      <Icon name={subtractOrAdd === 'subtract' ? 'ARROWLEFT' : 'ARROWRIGHT'} />
    </button>
  );
};

export default SubtractOrAdd;
