import React from 'react';

import * as styles from './index.module.scss';
import {
  IPickupSelection,
  IPlace,
  ITranslationsSingularPlural,
  IReducers,
} from '../../../interfaces';
import Title from '../../../components/Title';
import Icon from '../../../components/Icon';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  pickupSelection: IPickupSelection | null;
  dropoffSelection: IPickupSelection | null;
  meetingPoints: IPlace[] | null;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    meetingPointsHeader: string;
    meetingPointsSubtext: ITranslationsSingularPlural;
  };
}

const TourBookingMeetingPoints: React.FunctionComponent<IProps> = (props) => {
  const { pickupSelection, dropoffSelection, meetingPoints, localeData } =
    props;
  const available =
    (!!pickupSelection && pickupSelection !== 'UNAVAILABLE') ||
    (!!dropoffSelection && dropoffSelection !== 'UNAVAILABLE');
  const unavailable =
    pickupSelection === 'UNAVAILABLE' &&
    !!dropoffSelection &&
    dropoffSelection === 'UNAVAILABLE';

  if (!!meetingPoints && available) {
    return (
      <div className={styles.meetingPointsContainer}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {localeData.meetingPointsHeader}
        </Title>
        <p className={styles.label}>{localeData.meetingPointsSubtext.plural}</p>
        <ul>
          {meetingPoints.map((place, i) => (
            <li key={place.Id || i}>
              <Icon name='SQUARE' /> {place.Name}
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (!!meetingPoints && !!pickupSelection && unavailable) {
    return (
      <div className={styles.meetingPointsContainer}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {localeData.meetingPointsHeader}
        </Title>
        <p className={styles.label}>
          {meetingPoints.length > 1
            ? localeData.meetingPointsSubtext.plural
            : localeData.meetingPointsSubtext.singular}
        </p>
        <ul>
          {meetingPoints.map((place, i) => (
            <li key={place.Id || i}>
              <Icon name='SQUARE' /> {place.Name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    pickupSelection,
    dropoffSelection,
    meetingPoints,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourBookingMeetingPoints {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                meetingPointsHeader
                meetingPointsSubtext {
                  singular
                  plural
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TourBookingMeetingPoints
            localeData={
              data.allContentfulTranslationsBookingModal.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            pickupSelection={pickupSelection}
            dropoffSelection={dropoffSelection}
            meetingPoints={meetingPoints}
            locale={locale}
          />
        );
      }}
    />
  )
);
