// extracted by mini-css-extract-plugin
export var hero = "TourHeroPrivate-module--hero--2kY3O";
export var image = "TourHeroPrivate-module--image--2Vncr";
export var imageMobile = "TourHeroPrivate-module--imageMobile--3aDBh";
export var right = "TourHeroPrivate-module--right--16bpa";
export var darker = "TourHeroPrivate-module--darker--3OvpW";
export var content = "TourHeroPrivate-module--content--32G49";
export var titleContainer = "TourHeroPrivate-module--titleContainer--AtZXq";
export var line = "TourHeroPrivate-module--line--1eroD";
export var titleContainerMobile = "TourHeroPrivate-module--titleContainerMobile--2WqNC";
export var titleContainerAddToCart = "TourHeroPrivate-module--titleContainerAddToCart--CCf5h";
export var infoContainer = "TourHeroPrivate-module--infoContainer--1L9f0";
export var rating = "TourHeroPrivate-module--rating--eOmK4";
export var offerBanner = "TourHeroPrivate-module--offerBanner--23iT8";
export var ratingInfoWrapper = "TourHeroPrivate-module--ratingInfoWrapper--3p-s-";
export var ratingInfo = "TourHeroPrivate-module--ratingInfo--1bYjG";
export var info = "TourHeroPrivate-module--info--3lL7a";
export var infoItem = "TourHeroPrivate-module--infoItem--3r93r";
export var infoItem2 = "TourHeroPrivate-module--infoItem2--2wA6j";
export var loading = "TourHeroPrivate-module--loading--20Gaw";
export var bookNav = "TourHeroPrivate-module--bookNav--PqJa_";
export var itemContainer = "TourHeroPrivate-module--itemContainer--2l33M";
export var mobileItem = "TourHeroPrivate-module--mobileItem--3wUFm";
export var languageMobileItem = "TourHeroPrivate-module--languageMobileItem--3ZNMz";
export var addToCartButton = "TourHeroPrivate-module--addToCartButton--1fVnT";
export var exit = "TourHeroPrivate-module--exit--2yWB4";
export var exitContainer = "TourHeroPrivate-module--exitContainer--Dv0Hy";
export var hideNav = "TourHeroPrivate-module--hideNav--31aar";
export var price = "TourHeroPrivate-module--price--iTiUW";
export var originalPrice = "TourHeroPrivate-module--originalPrice--34B-d";
export var offerPrice = "TourHeroPrivate-module--offerPrice--2kzma";
export var column = "TourHeroPrivate-module--column--3i9ub";
export var columnTEST = "TourHeroPrivate-module--columnTEST--26TIJ";
export var row = "TourHeroPrivate-module--row--2UImV";
export var rowPrice = "TourHeroPrivate-module--rowPrice--3Yaqw";
export var banner = "TourHeroPrivate-module--banner--1dRrM";
export var priceContainer = "TourHeroPrivate-module--priceContainer--1qVQt";
export var ghostMobile = "TourHeroPrivate-module--ghostMobile--2_lcS";