import React from 'react';
import * as styles from './index.module.scss';
import { TransportPickupDropoff } from '../../components';
import TransportButton from '../TransportButton';
import TourBookingFreeNoFees from '../TourBookingFreeNoFees';
import {
  ITransportOption,
  ISelectPickupValue,
  ITransportList,
  ITranslationsBookingBox,
  IReducers,
} from '../../../interfaces';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  dropdownOpen: boolean;
  addingToCart: boolean;
  startPlaces: ITransportOption[] | null;
  selectedStartPlace: ISelectPickupValue | null;
  isValid: boolean;
  endPlaces: ITransportOption[] | null;
  selectedEndPlace: ISelectPickupValue | null;
  button: React.MutableRefObject<HTMLButtonElement | null>;
  pickupDiv: React.MutableRefObject<HTMLDivElement | null>;
  dropoffDiv: React.MutableRefObject<HTMLDivElement | null>;
  flybus: boolean;
  locale: string;
  addToCart(): void;
  openBookingDropdown(): void;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingBox; // TODO maybe do diffrently
}

const TransportBookingBox: React.FunctionComponent<IProps> = ({
  dropdownOpen,
  addToCart,
  addingToCart,
  openBookingDropdown,
  startPlaces,
  selectedStartPlace,
  changeSelectedPlace,
  isValid,
  endPlaces,
  selectedEndPlace,
  button,
  pickupDiv,
  dropoffDiv,
  flybus,
  localeData,
}) => {
  return (
    <div className={cx('column level is-gapless', styles.container)}>
      <div className='column'>
        <label className={styles.bookingLabel}>
          {localeData.tourBookingBoxFrom}
        </label>
        <div ref={pickupDiv}>
          <TransportPickupDropoff
            placeList={startPlaces}
            list={'Start'}
            selected={selectedStartPlace}
            changeSelectedPlace={changeSelectedPlace}
            disabled={false}
            canContinue={isValid}
            ariaLabel='from'
            tabIndex='0'
          />
        </div>
      </div>
      <div className='column'>
        <label className={styles.bookingLabel}>
          {localeData.tourBookingBoxTo}
        </label>
        <div ref={dropoffDiv}>
          <TransportPickupDropoff
            placeList={endPlaces}
            list={'End'}
            selected={selectedEndPlace}
            changeSelectedPlace={changeSelectedPlace}
            disabled={false}
            canContinue={isValid}
            ariaLabel='to'
            tabIndex='0'
          />
        </div>
      </div>
      <TourBookingFreeNoFees text={localeData} />
      <TransportButton
        dropdownOpen={dropdownOpen}
        addToCart={addToCart}
        button={button}
        addingToCart={addingToCart}
        openBookingDropdown={openBookingDropdown}
        flybus={flybus}
        text={localeData.tourBookingBoxMainButton}
      />
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    dropdownOpen,
    addToCart,
    addingToCart,
    openBookingDropdown,
    startPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    isValid,
    endPlaces,
    selectedEndPlace,
    button,
    pickupDiv,
    dropoffDiv,
    flybus,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportBookingBox {
          allContentfulTranslationsPage {
            edges {
              node {
                ...TranslationPageBookingBox
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TransportBookingBox
            localeData={
              data.allContentfulTranslationsPage.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            dropdownOpen={dropdownOpen}
            addToCart={addToCart}
            addingToCart={addingToCart}
            openBookingDropdown={openBookingDropdown}
            startPlaces={startPlaces}
            selectedStartPlace={selectedStartPlace}
            changeSelectedPlace={changeSelectedPlace}
            isValid={isValid}
            endPlaces={endPlaces}
            selectedEndPlace={selectedEndPlace}
            button={button}
            pickupDiv={pickupDiv}
            dropoffDiv={dropoffDiv}
            flybus={flybus}
            locale={locale}
          />
        );
      }}
    />
  )
);
