import React from 'react';
import * as styles from './index.module.scss';
import Title from '../../../components/Title';
import TourPickup from '../TourPickup/index';
import { IPickupDropoff, ISelectValue, IProduct } from '../../../interfaces';

type pickOrDrop = 'pickup' | 'dropoff';

interface IProps {
  pickupDropoffText: string;
  pickupAndDropoffPlaces: IPickupDropoff | null;
  selectedPickup: ISelectValue | null;
  product: IProduct | null;
  isValid: boolean;
  changePickupDropoff(event: ISelectValue, pickupOrDropoff: pickOrDrop): void;
  changeSelectOpen(newState: boolean, pickupOrDropoff: pickOrDrop): void;
}

const TourBookingPickup: React.FunctionComponent<IProps> = (props) => {
  const {
    pickupDropoffText,
    pickupAndDropoffPlaces,
    selectedPickup,
    changePickupDropoff,
    product,
    changeSelectOpen,
    isValid,
  } = props;
  return (
    <div className={styles.pickup}>
      <div className={styles.pickupText}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {pickupDropoffText}
        </Title>
      </div>
      <div className={styles.pickupPadding}>
        <TourPickup
          pickup={pickupAndDropoffPlaces}
          selectedPickup={selectedPickup}
          changePickup={changePickupDropoff}
          pickupSelection={product ? product.PickupSelection : null}
          toggleSelect={changeSelectOpen}
          isValid={isValid || !!selectedPickup}
        />
      </div>
    </div>
  );
};

export default TourBookingPickup;
