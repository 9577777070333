import {
  SET_ANNOUNCMENTVISSIBLE,
  SET_ANNOUNCMENTVISSIBLE_MOBILE,
  SET_DISMISS,
  SET_ICELANDAIR
} from '../actions/types';

interface IState {
  announcementVisible: boolean;
  announcementVisibleMobile: boolean;
  dismiss: boolean;
  isIcelandair: boolean;
  sagaNumber: number | null;
}

interface ILayoutPayload {
  layoutPayload: boolean;
  dissmissPayload: boolean;
  icelandairPayload: boolean;
  sagaNumberPayload: number | null;
}

const initialState: IState = {
  announcementVisible: false,
  announcementVisibleMobile: false,
  dismiss: false,
  isIcelandair: false,
  sagaNumber: null
};

export default (
  state = initialState,
  action: { type: string; payload: ILayoutPayload }
) => {
  switch (action.type) {
    case SET_ANNOUNCMENTVISSIBLE:
      return { ...state, announcementVisible: action.payload.layoutPayload };
    case SET_ANNOUNCMENTVISSIBLE_MOBILE:
      return {
        ...state,
        announcementVisibleMobile: action.payload.layoutPayload
      };
    case SET_DISMISS:
      return {
        ...state,
        dismiss: action.payload.dissmissPayload
      };
    case SET_ICELANDAIR:
      return {
        ...state,
        isIcelandair: action.payload.icelandairPayload
      };
    default:
      return state;
  }
};
