import React from 'react';

import * as styles from './BorderDropdown.module.scss';

interface IProps {
  visible: JSX.Element;
  children: React.ReactNode;
  openCurrency?: boolean;
  component: string;
  close?: boolean;
  toggleToursIcon?(): void;
}
interface IState {
  open: boolean;
  optionsHeight: number;
}
class BorderDropdown extends React.Component<IProps, IState> {
  visible: HTMLDivElement | null = null;
  dropdown: HTMLDivElement | null = null;
  readonly state = {
    open: false,
    optionsHeight: 0,
  };
  componentDidMount() {
    this.updateOptionsHeight();
    document.addEventListener('click', this.handleClickOutside);
  }
  componentDidUpdate(prevProps: IProps) {
    this.updateOptionsHeight();

    if (prevProps.openCurrency !== this.props.openCurrency) {
      this.setState({ open: false });
    }
    if (prevProps.close !== this.props.close) {
      this.setState({ open: false });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  // TODO Refactor this, don't have a special function for onKeyPress
  handleToursEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (this.props.toggleToursIcon) {
        this.props.toggleToursIcon();
      }
      this.setState({ open: !this.state.open });
    }
  };
  render() {
    const { optionsHeight, open } = this.state;
    const { visible, children, component } = this.props;

    let styled = styles.options;
    if (component === 'flagDropdown') {
      styled = styles.options;
    } else if (component === 'searchDropdown') {
      styled = styles.searchOptions;
    } else if (component === 'toursDropdown') {
      styled = styles.toursOptions;
    }
    return (
      <div className={styles.container} ref={(elem) => (this.visible = elem)}>
        {component !== 'toursDropdown' ? (
          <button
            onClick={() => {
              if (this.state.open) {
                this.setState({ open: false });
              } else {
                this.setState({ open: true });
              }
            }}
            onKeyPress={(e) => this.handleToursEnter(e)}
          >
            {visible}
          </button>
        ) : null}
        {component === 'toursDropdown' ? (
          <button
            onClick={() => {
              this.setState({ open: !this.state.open });
            }}
            aria-pressed={open ? true : false}
          >
            {visible}
          </button>
        ) : null}
        <div
          className={open ? styled : null}
          style={{
            height: open ? optionsHeight : 0,
            width: open ? 'min-content' : 0,
            overflow: 'hidden',
          }}
        >
          <div ref={(elem) => (this.dropdown = elem)}>{children}</div>
        </div>
      </div>
    );
  }

  handleClickOutside = (event: any) => {
    if (
      !!this.dropdown &&
      !this.dropdown.contains(event.target) &&
      !!this.visible &&
      !this.visible.contains(event.target)
    ) {
      this.setState({ open: false });
    }
  };

  updateOptionsHeight = () => {
    if (this.dropdown) {
      const height = this.dropdown.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

export default BorderDropdown;
