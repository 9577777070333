import React from 'react';
import * as styles from './index.module.scss';

interface IProps {
  errorMessage: string;
}

const TourBookingError: React.FunctionComponent<IProps> = ({
  errorMessage,
}) => {
  return (
    <div>
      <p className={styles.error}>{errorMessage}</p>
    </div>
  );
};

export default TourBookingError;
