// extracted by mini-css-extract-plugin
export var label = "Questions-module--label--15g6D";
export var labelRed = "Questions-module--labelRed--3nE2d";
export var required = "Questions-module--required--UuINX";
export var input = "Questions-module--input--AANzj";
export var textInput = "Questions-module--textInput--ARZc9";
export var inputCountry = "Questions-module--inputCountry--4GXvN";
export var errorField = "Questions-module--errorField--2nOyb";
export var sectionHead = "Questions-module--sectionHead--3gjJk";
export var sectionLine = "Questions-module--sectionLine--1R9KA";
export var sectionTitle = "Questions-module--sectionTitle--ezN01";
export var mainContactDetails = "Questions-module--mainContactDetails--3Qa1X";
export var area = "Questions-module--area--37QGp";