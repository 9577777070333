import axios, { AxiosInstance } from 'axios';
import { IBooking, ICart, IFlybus } from '../interfaces';
import { handleAxiosError } from '../utils';
interface IBookingApi {
  getBooking(
    bookingNumber: string,
    currency: string,
    lang: string
  ): Promise<IBooking | null>;
}
class BookingApi implements IBookingApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async getBooking(
    bookingNumber: string,
    currency: string,
    lang: string
  ): Promise<IBooking | null> {
    try {
      const result = await this.httpClient.get('/api/Booking/GetBooking', {
        params: {
          bookingNumber,
          currency,
          lang
        }
      });

      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
   
   async createExternalBooking(
    bookingFlybus: IFlybus,
    hashString: string | null,
    orderId: string | null,
    lang: string
  ): Promise<string | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Booking/CreateExternalPartnerBooking',
        JSON.stringify(bookingFlybus),
        {
          params: {
            hashString,
            orderId
          }
        }
      );
      console.log(result);
      return result.data;
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }
  async insertExternalBooking(
    bookingNumber: string,
    partnerNumber: string,
    status: string,
    orderId: string,
    returnHash: string,
    lang: string
  ): Promise<string | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Booking/InsertExternalPartnerBooking',
        {},
        {
          params: {
            bookingNumber,
            partnerNumber,
            status,
            orderId,
            returnHash
          }
        }
      );
      console.log(result);
      return result.data;
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }
    async checkExternalBooking(
      bookingNumber: string
    ): Promise<boolean | null> {
      try {
        const result = await this.httpClient.get('/api/Booking/CheckExternalPartnerBooking', {
          params: {
            bookingNumber
          }
        });
  
        return result.data;
      } catch (e) {
        handleAxiosError(e);
        return null;
      }
    }
  async updateExternalBooking(
    bookingNumber: string,
    partnerNumber: string,
    status: string,
    orderId: string,
    returnHash: string,
    sagaClubNumber: string,
    cartId: string | null,
    lang: string
  ): Promise<string | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Booking/UpdateExternalPartnerBooking',
        {},
        {
          params: {
            bookingNumber,
            partnerNumber,
            status,
            orderId,
            returnHash,
            sagaClubNumber,
            cartId
          }
        }
      );
      console.log(result);
      return result.data;
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }
}

export default BookingApi;
