import React, { useState } from 'react';
import { countryCodesExtends } from '../../../src/utils';
import {
  IReducers,
  IMainContactQuestions,
  ICountryOption,
} from '../../interfaces';
import Select, { components } from 'react-select';
import {
  noArrowStyle,
  errorStyle,
  customNoIndicatorCountryPicker,
  mobileMenuCountryPicker,
} from '../../styles/ReactSelect';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import * as styles from './CheckoutCountryCode.module.scss';
import { windowSizes } from '../../constants';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';

interface IExternalProps {
  disabled: boolean;
  canContinue?: boolean;
  isMobile?: boolean;
  changeSelectedCountryCode(country: ICountryOption): void;
  locale: string;
  contactQuestion?: IMainContactQuestions;
  selectedCountryCode?: ICountryOption | null;
}

interface IProps extends IExternalProps {
  localeData: {
    pickUpSelectionPlaceholder: {
      version1: string;
      version2: string;
    };
  };
}

const { Option, SingleValue } = components;
const CustomOption = (props: any) => (
  <Option {...props}>
    <img src={props.data.image} className={styles.flag} />
    <div>
      {props.data.label}{' '}
      <div className={styles.countryNumber}>{props.data.value}</div>
    </div>
  </Option>
);

const CustomSingleValue = (props: any) => (
  <SingleValue {...props}>
    <div className={styles.container}>
      <img src={props.data.image} className={styles.flag} />
      <div className={styles.countryNumber}>{props.data.value}</div>
    </div>
  </SingleValue>
);

const CheckoutCountryCode: React.FunctionComponent<IProps & IWindowSizeProps> =
  (props) => {
    const [open, triggerSelect] = useState(false);
    const {
      contactQuestion,
      selectedCountryCode,
      localeData,
      changeSelectedCountryCode,
    } = props;
    const isMobile = props.windowWidth <= windowSizes.header;
    function getStyle() {
      const { canContinue } = props;

      if (props.disabled) {
        return noArrowStyle;
      } else if (!canContinue && !selectedCountryCode) {
        return errorStyle;
      } else {
        if (props.isMobile) {
          return mobileMenuCountryPicker;
        } else {
          return customNoIndicatorCountryPicker;
        }
      }
    }
    let options: ICountryOption[] = countryCodesExtends
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((code) => {
        return {
          label: code.label,
          value: code.value,
          image: code.image,
          numLength: code.numLength,
        };
      });

    function getStyles() {
      if (isMobile) {
        return mobileMenuCountryPicker;
      }
      return customNoIndicatorCountryPicker;
    }

    if (!!contactQuestion) {
      return (
        <Select
          menuPortalTarget={document.body}
          placeholder={
            isMobile
              ? localeData.pickUpSelectionPlaceholder.version1
              : localeData.pickUpSelectionPlaceholder.version2
          }
          options={options ? options : undefined}
          value={selectedCountryCode ? selectedCountryCode : null}
          isMulti={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={!isMobile}
          menuIsOpen={open}
          components={{
            Option: CustomOption,
            SingleValue: CustomSingleValue,
          }}
          styles={getStyles()}
          onMenuOpen={() => triggerSelect(true)}
          onMenuClose={() => triggerSelect(false)}
          onChange={(place: any) => changeSelectedCountryCode(place)}
        />
      );
    }
    return <Select placeholder='' styles={getStyle()} isDisabled={true} />;
  };

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  withWindowSize(
    ({
      disabled,
      canContinue,
      changeSelectedCountryCode,
      locale,
      contactQuestion,
      selectedCountryCode,
      windowWidth,
      windowHeight,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query CheckoutCountryCode {
            allContentfulTranslationsBookingModal {
              edges {
                node {
                  node_locale
                  pickUpSelectionPlaceholder {
                    version1
                    version2
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <CheckoutCountryCode
              localeData={
                data.allContentfulTranslationsBookingModal.edges.filter(
                  (node: { node: { node_locale: any } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              disabled={disabled}
              canContinue={canContinue}
              changeSelectedCountryCode={changeSelectedCountryCode}
              locale={locale}
              contactQuestion={contactQuestion}
              selectedCountryCode={selectedCountryCode}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
            />
          );
        }}
      />
    )
  )
);
