import React from 'react';
import * as Icons from '../../../assets/icons';
import {
  Airplane,
  AirplaneLanding,
  AirplaneTakeoff,
  AirplaneTaxiing,
  AirplaneTilt,
  Alarm,
  At,
  Baby,
  BabyCarriage,
  Backpack,
  Bag,
  BagSimple,
  Balloon,
  Bank,
  Bed,
  Bell,
  Binoculars,
  Bird,
  Boat,
  Boot,
  Bus,
  Camera,
  Car,
  ChatCircle,
  Check,
  Clock,
  Compass,
  CompassTool,
  CookingPot,
  CreditCard,
  FileText,
  File,
  FirstAidKit,
  Flag,
  Flashlight,
  Globe,
  HandHeart,
  Handshake,
  Heart,
  HeartStraight,
  House,
  IceCream,
  Image,
  Key,
  Leaf,
  MapPin,
  MapTrifold,
  Mountains,
  Megaphone,
  Parachute,
  PersonSimpleWalk,
  Phone,
  Plant,
  Receipt,
  RocketLaunch,
  ShoppingCart,
  ShoppingCartSimple,
  Smiley,
  Snowflake,
  Star,
  Seat,
  Sun,
  Tent,
  ThumbsUp,
  Train,
  Tree,
  Truck,
  Umbrella,
  User,
  UserCircle,
  UserSound,
  Users,
  Warning,
  WifiHigh,
  XCircle,
} from '@phosphor-icons/react';

interface IIconProps {
  name: string;
  color?: string; // Color is now an optional prop
}

export interface IIconMapProps {
  [index: string]: (color?: string) => JSX.Element;
}

const iconMap: IIconMapProps = {
  ANNOUNCMENTWARNING: () => Icons.AnnouncementWarning(),
  ARROWDOWN: () => Icons.ArrowDown(),
  ARROWLEFT: () => Icons.ArrowLeft(),
  ARROWRIGHT: () => Icons.ArrowRight(),
  ARROWRIGHTWHITE: () => Icons.ArrowRightWhite(),
  ARROWSCROLLTO: () => Icons.ArrowScrollTo(),
  ARROWUPDOWN: () => Icons.ArrowsUpDown(),
  BIN: () => Icons.Bin(),
  BURGER: () => Icons.Burger(),
  CALENDAR: () => Icons.Calendar(),
  CARTEMPTY: () => Icons.CartEmpty(),
  CHECKCIRCLE: () => Icons.CheckCircle(),
  CHECKMARK: () => Icons.CheckMarkIcon(),
  CLOSE: () => Icons.Close(),
  DOUBLESEATS: () => Icons.DoubleSeats(),
  DOUBLESEATS_NEW: () => Icons.DoubleSeats_new(),
  ENGLAND: () => Icons.England(),
  EXIT: () => Icons.Exit(),
  EXITMINI: () => Icons.ExitMini(),
  HEARTCIRCLE: () => Icons.HeartCircle(),
  HEARTCIRCLE_NEW: () => Icons.HeartCircle_new(),
  ICELAND: () => Icons.Iceland(),
  FILEERROR: () => Icons.FileError(),
  FILTERSEARCH: () => Icons.FilterSearch(),
  FLYBUS: () => Icons.Flybus(),
  FLYBUSPLUS: () => Icons.FlybusPlus(),
  LOGODARK: () => Icons.LogoDark(),
  LOGODARKICELANDIA: () => Icons.LogoDarkIcelandia(),
  LOGOMOB: () => Icons.LogoMob(),
  LOGOMOBICELANDIA: () => Icons.LogoMobIcelandia(),
  NAVDOWN: () => Icons.NavDown(),
  NAVUP: () => Icons.NavUp(),
  LOGOMOBILE: () => Icons.LogoMobile(),
  PRIZECIRCLE: () => Icons.PrizeCircle(),
  SQUARE: () => Icons.Square(),
  THIRDPARTY: () => Icons.ThirdParty(),
  TIMERCLOCK: () => Icons.TimerClock(),
  TIMERCLOCK_NEW: () => Icons.TimerClock_new(),
  TRIPADVISORBANNERICON: () => Icons.TripAdvisorBannerIcon(),
  TRIPADVISOR2020: () => Icons.TripAdvisor2020(),
  WARNING: () => Icons.Warning(),
  WIFI: () => Icons.Wifi(),
  WIFI_NEW: () => Icons.Wifi_new(),
  // Phosphor Icon Library
  PHOSPHOR_AIRPLANE: (color = '') => (
    <Airplane size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_AIRPLANE_LANDING: (color = '') => (
    <AirplaneLanding size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_AIRPLANE_TAKEOFF: (color = '') => (
    <AirplaneTakeoff size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_AIRPLANE_TAXIING: (color = '') => (
    <AirplaneTaxiing size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_AIRPLANE_TILT: (color = '') => (
    <AirplaneTilt size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_ALARM: (color = '') => (
    <Alarm size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_AT: (color = '') => <At size={40} weight='thin' color={color} />,
  PHOSPHOR_BABY: (color = '') => <Baby size={40} weight='thin' color={color} />,
  PHOSPHOR_BABYCARRIAGE: (color = '') => (
    <BabyCarriage size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_BACKPACK: (color = '') => (
    <Backpack size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_BAG: (color = '') => <Bag size={40} weight='thin' color={color} />,
  PHOSPHOR_BAGSIMPLE: (color = '') => (
    <BagSimple size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_BALLOON: (color = '') => (
    <Balloon size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_BANK: (color = '') => <Bank size={40} weight='thin' color={color} />,
  PHOSPHOR_BED: (color = '') => <Bed size={40} weight='thin' color={color} />,
  PHOSPHOR_BELL: (color = '') => <Bell size={40} weight='thin' color={color} />,
  PHOSPHOR_BINOCULARS: (color = '') => (
    <Binoculars size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_BIRD: (color = '') => <Bird size={40} weight='thin' color={color} />,
  PHOSPHOR_BOAT: (color = '') => <Boat size={40} weight='thin' color={color} />,
  PHOSPHOR_BOOT: (color = '') => <Boot size={40} weight='thin' color={color} />,
  PHOSPHOR_BUS: (color = '') => <Bus size={40} weight='thin' color={color} />,
  PHOSPHOR_CAMERA: (color = '') => (
    <Camera size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_CAR: (color = '') => <Car size={40} weight='thin' color={color} />,
  PHOSPHOR_CHATCIRCLE: (color = '') => (
    <ChatCircle size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_CHECK: (color = '') => (
    <Check size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_CLOCK: (color = '') => (
    <Clock size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_COMPASS: (color = '') => (
    <Compass size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_COMPASSTOOL: (color = '') => (
    <CompassTool size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_COOKINGPOT: (color = '') => (
    <CookingPot size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_CREDITCARD: (color = '') => (
    <CreditCard size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_FILETEXT: (color = '') => (
    <FileText size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_FILE: (color = '') => <File size={40} weight='thin' color={color} />,
  PHOSPHOR_FIRSTAIDKIT: (color = '') => (
    <FirstAidKit size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_FLAG: (color = '') => <Flag size={40} weight='thin' color={color} />,
  PHOSPHOR_FLASHLIGHT: (color = '') => (
    <Flashlight size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_GLOBE: (color = '') => (
    <Globe size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_HANDHEART: (color = '') => (
    <HandHeart size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_HANDSHAKE: (color = '') => (
    <Handshake size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_HEART: (color = '') => (
    <Heart size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_HEARTSTRAIGHT: (color = '') => (
    <HeartStraight size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_HOUSE: (color = '') => (
    <House size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_ICECREAM: (color = '') => (
    <IceCream size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_IMAGE: (color = '') => (
    <Image size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_KEY: (color = '') => <Key size={40} weight='thin' color={color} />,
  PHOSPHOR_LEAF: (color = '') => <Leaf size={40} weight='thin' color={color} />,
  PHOSPHOR_MAPPIN: (color = '') => (
    <MapPin size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_MAPTRIFOLD: (color = '') => (
    <MapTrifold size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_MOUNTAINS: (color = '') => (
    <Mountains size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_MEGAPHONE: (color = '') => (
    <Megaphone size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_PARACHUTE: (color = '') => (
    <Parachute size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_PERSONSIMPLEWALK: (color = '') => (
    <PersonSimpleWalk size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_PHONE: (color = '') => (
    <Phone size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_PLANT: (color = '') => (
    <Plant size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_RECEIPT: (color = '') => (
    <Receipt size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_ROCKETLAUNCH: (color = '') => (
    <RocketLaunch size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_SHOPPINGCART: (color = '') => (
    <ShoppingCart size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_SHOPPINGCARTSIMPLE: (color = '') => (
    <ShoppingCartSimple size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_SMILEY: (color = '') => (
    <Smiley size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_SNOWFLAKE: (color = '') => (
    <Snowflake size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_STAR: (color = '') => <Star size={40} weight='thin' color={color} />,
  PHOSPHOR_SUN: (color = '') => <Sun size={40} weight='thin' color={color} />,
  PHOSPHOR_TENT: (color = '') => <Tent size={40} weight='thin' color={color} />,
  PHOSPHOR_THUMBSUP: (color = '') => (
    <ThumbsUp size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_TRAIN: (color = '') => (
    <Train size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_TREE: (color = '') => <Tree size={40} weight='thin' color={color} />,
  PHOSPHOR_TRUCK: (color = '') => (
    <Truck size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_UMBRELLA: (color = '') => (
    <Umbrella size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_USER: (color = '') => <User size={40} weight='thin' color={color} />,
  PHOSPHOR_USERCIRCLE: (color = '') => (
    <UserCircle size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_USERSOUND: (color = '') => (
    <UserSound size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_USERS: (color = '') => (
    <Users size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_WARNING: (color = '') => (
    <Warning size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_WIFIHIGH: (color = '') => (
    <WifiHigh size={40} weight='thin' color={color} />
  ),
  PHOSPHOR_XCIRCLE: (color = '') => (
    <XCircle size={40} weight='thin' color={color} />
  ),
};

const Icon = ({ name, color }: IIconProps) => {
  // If iconMap[name] exists and is a function, call it with color, else return null
  return iconMap[name] ? iconMap[name](color) : null;
};

export default Icon;
