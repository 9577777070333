import React from 'react';
import cx from 'classnames';

import * as styles from './styles/BookableExtraCollapse.module.scss';
import { colors } from '../../constants';

interface IProps {
  title: string;
  selected: boolean;
  toggleSelect: () => void;
  open: boolean;
  toggleOpen: () => void;
  children: React.ReactNode;
  included: boolean;
  error?: boolean;
}

interface IState {
  height: number | string;
  overflow: boolean;
}

class BookableExtraCollapse extends React.Component<IProps, IState> {
  readonly state = { height: 0, overflow: false };

  content: HTMLDivElement | null = null;

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
    /* setTimeout(this.updateHeight, 500); */
  }

  componentDidUpdate(prevProps: IProps) {
    this.updateHeight();
    if (prevProps.open !== this.props.open) {
      setTimeout(
        () => this.setState({ overflow: this.props.open }),
        this.props.open ? 200 : 0
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  render() {
    const {
      title,
      selected,
      toggleSelect,
      open,
      toggleOpen,
      children,
      included,
      error,
    } = this.props;
    const { height } = this.state;

    return (
      <div
        className={cx(styles.container, {
          [styles.open]: open,
          [styles.error]: error && !open,
        })}
      >
        <div className={styles.upper}>
          <button className={styles.checkBox} onClick={toggleSelect}>
            {/* 
            <img
              src={filterCheck}
              alt='Check mark'
              style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
            */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 14.638 10.688'
              style={{
                visibility: selected ? 'visible' : 'hidden',
                stroke: included ? '#c1c1c1' : colors.darkBlue,
              }}
            >
              <path
                id='check_1_'
                d='M16.638 6l-8.689 8.688L4 10.739'
                className='cls-1'
                data-name='check (1)'
                transform='translate(-3 -5)'
              />
            </svg>
          </button>
          <button className={styles.text} onClick={toggleOpen}>
            <p>
              <strong>{title}</strong>
            </p>
          </button>
          <button className={styles.arrow} onClick={toggleOpen}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              id='baseline-keyboard_arrow_down-24px'
              viewBox='0 0 24 24'
            >
              <path
                id='Path_272'
                d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'
                data-name='Path 272'
                className='cls-1'
              />
              <path
                id='Path_273'
                d='M0 0h24v24H0z'
                className='cls-2'
                data-name='Path 273'
                transform='translate(-30 -14)'
              />
            </svg>
          </button>
        </div>
        <div
          className={cx(styles.contentContainer, {
            [styles.closed]: !this.state.overflow,
          })}
          style={{ height: open && height ? height : 0 }}
        >
          <div ref={(elem) => (this.content = elem)}>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    );
  }

  updateHeight = () => {
    const { height } = this.state;
    if (this.content) {
      const contentHeight = this.content.clientHeight;
      if (contentHeight !== height) {
        this.setState({ height: contentHeight });
        setTimeout(() => this.setState({ height: 'auto' }), 500);
      }
    }
  };
}

export default BookableExtraCollapse;
