import React from 'react';
import * as styles from './index.module.scss';

interface IProps {
  value: number;
}

/* TODO Væri betra fyrir aðgengi ef þetta væri Input element   */
const NumberInputValue: React.FunctionComponent<IProps> = ({ value }) => {
  return (
    <div className={styles.value}>
      <p>{value}</p>
    </div>
  );
};

export default NumberInputValue;
