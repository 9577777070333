import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import cx from 'classnames';
import { getLocale } from '../../redux/actions/translationActions';

import * as styles from './TextParser.module.scss';
import { connect } from 'react-redux';
import { IReducers } from '../../interfaces';

interface IProps {
  source: string;
  className?: string;
  style?: React.CSSProperties;
  darkMode?: boolean;
  skipContainer?: boolean;
}

const Link: React.FC<{ href: string }> = ({ children, href }) => (
  <a href={href} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
);
const CustomH3: React.FC = ({ children }) => {
  const lineColor = setColor(children?.toLocaleString());
  return children?.toLocaleString().includes('Bus Stop') ? (
    <h3 className={cx(styles.containerColor)}>{children}</h3>
  ) : (
    <h3>{children}</h3>
  );
};

const TextParser: React.FunctionComponent<IProps> = ({
  source,
  className,
  style,
  darkMode,
  skipContainer,
}) => {
  if (!source) {
    return null;
  }

  const src = source
    .split('<br /><br />')
    .join('<br />')
    .replace(/&#39;/g, '&apos;');
  return (
    <div
      className={cx(
        { [styles.container]: !skipContainer },
        { [styles.dark]: darkMode },
        className
      )}
      style={style}
    >
      <ReactMarkdown
        options={{
          overrides: {
            a: {
              component: Link,
            },
            h3: {
              component: CustomH3,
            },
          },
        }}
      >
        {src}
      </ReactMarkdown>
    </div>
  );
};

TextParser.defaultProps = {
  darkMode: false,
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale, lang } = reducers.translationReducers;
  return { locale, lang };
};

export default connect(mapStateToProps, { getLocale })(TextParser);
function setColor(text: string | undefined): string | undefined {
  if (text == undefined) {
    return undefined;
  }

  const busStop = 'Bus Stop ';
  const firstIndex = text?.indexOf(busStop) + busStop.length;
  var busNumber = text?.substring(firstIndex, firstIndex + 2);
  if (busNumber.slice(-1) == ' ') busNumber = busNumber.slice(0, -1);
  switch (busNumber) {
    case '1':
      return 'blue';
    case '2':
      return 'yellow';
    case '3':
      return 'yellow';
    case '4':
      return 'blue';
    case '5':
      return 'yellow';
    case '6':
      return 'yellow';
    case '8':
      return 'pink';
    case '9':
      return 'green';
    case '11':
      return 'pink';
    case '12':
      return 'red';
    case '13':
      return 'red';
    case '14':
      return 'green';
    case '15':
      return 'blue';
    default:
      return undefined;
  }
}
function showLineText(text: string | undefined): React.ReactNode {
  if (text == undefined) {
    return 'Unknown line';
  }
  return `${text.charAt(0).toUpperCase() + text.slice(1)} line`;
}
function getBackgroundColorLine(
  lineColor: string | undefined
): import('classnames').Argument {
  switch (lineColor) {
    case 'blue':
      return styles.blue;
    case 'yellow':
      return styles.yellow;
    case 'red':
      return styles.red;
    case 'pink':
      return styles.pink;
    case 'green':
      return styles.green;
    default:
      return undefined;
  }
}
