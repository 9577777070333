import React from 'react';
import { connect } from 'react-redux';
import ls from 'local-storage';

import { setCart } from '../redux/actions/cartActions';
import {
  ICurrencyOption,
  IProduct,
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ITransportRouteStation,
  ITransportStartTimes,
  ISelectTime,
  IPriceCategory,
  ITransportPersonCounter,
  AddOrRemove,
  IBlueLagoonCart,
  ICart,
  ITransportLeg,
  IReducers,
  IBlueLagoon,
  ILagoonSelect,
  ITransportCounter,
  ITourDetails
} from '../interfaces';
import { Loader } from '../components';
import { TransportApi, CartApi, BlueLagoonApi } from '../api';
import moment, { Moment } from 'moment';
import { formatAvailabilityDate, createDateTime } from '../utils';
import BlueLagoonBooking from './BlueLagoonBooking/BlueLagoonBooking';
import BlueLagoonBookingMobile from './BlueLagoonBooking/BlueLagoonBookingMobile';

interface IProps {
  tour: ITourDetails;
  currency: ICurrencyOption;
  productId: string;
  product: IProduct | null;
  cartObject: ICart | null;
  isMobile?: boolean;
  lang: string;
  tourTitle: string;
  setCart(newCart: ICart): void;
}

interface IState {
  transport: ITransport | null;
  times: ITransportStartTimes | null;
  blueLagoon: IBlueLagoon[] | null;
  sortedTransportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  isLoading: boolean;
  transportRoutes: ITransportOption | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartDate: Moment;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  selectedBluelagoonTime: ILagoonSelect | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
}

class BlueLagoonEngine extends React.Component<IProps, IState> {
  bluelagoonApi = new BlueLagoonApi();
  transportApi = new TransportApi();
  cartApi = new CartApi();
  readonly state: IState = {
    blueLagoon: null,
    transport: null,
    times: null,
    sortedTransportRoutes: null,
    isLoading: true,
    transportRoutes: null,
    startPlaces: null,
    endPlaces: null,
    selectedStartPlace: null,
    selectedEndPlace: null,
    selectedStartDate: moment(new Date()),
    selectedStartTime: null,
    selectedEndTime: null,
    starTimeOptions: null,
    endTimeOptions: null,
    personCounter: null,
    addingToCart: false,
    selectedBluelagoonTime: null,
    blueLagoonTimeOptions: null
  };

  async componentDidMount() {
    const { currency } = this.props;
    await this.getTransportAvailability(currency.value);
    // await this.setBlueLagoonAvailability();
    if (!!this.state.transport && !!this.state.transport.RouteStations) {
      const sortedStations = this.sortTransportRoutes(
        this.state.transport.RouteStations,
        this.state.transport.Legs
      );
      this.setState({ sortedTransportRoutes: sortedStations });
    }

    if (!!this.state.transport && !!this.state.transport.PriceCategories) {
      this.setPersonCounter(this.state.transport.PriceCategories);
    }

    if (this.state.sortedTransportRoutes) {
      if (
        !!this.state.transport &&
        !!this.state.transport.DefaultFromStationId
      ) {
        const stations = this.getEndPlaceIds(
          this.state.transport.DefaultFromStationId
        );
        const sortedEndStations = this.sortEndRoutes(stations);
        if (sortedEndStations) {
          const endPlaces = this.getPlacesOptions(sortedEndStations);
          this.setState({ endPlaces });
        }
      }
      const startPlaces = this.getPlacesOptions(
        this.state.sortedTransportRoutes
      );
      this.setState({ startPlaces }, () => {
        if (
          !!this.state.transport &&
          !!this.state.transport.DefaultFromStationId
        ) {
          this.setDefaultPlaces();
        }
      });
    }
    this.setTimeOptions();
  }

  setEndPlaces() {
    if (this.state.selectedStartPlace) {
      const stations = this.getEndPlaceIds(
        this.state.selectedStartPlace.routeId
      );
      if (this.state.transport && this.state.transport.Legs) {
        const sortedEndStations = this.sortEndRoutes(stations);
        if (sortedEndStations) {
          const endPlaces = this.getPlacesOptions(sortedEndStations);
          if (endPlaces) {
            this.setState({ endPlaces });
          }
        }
      }
    }
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.currency !== this.props.currency) {
      this.updateCurrency();
    }
    if (
      this.state.selectedStartPlace !== prevState.selectedStartPlace &&
      !!this.state.selectedStartPlace
    ) {
      this.setEndPlaces();
      /* const stations = this.getEndPlaceIds(
        this.state.selectedStartPlace.routeId
      );
      if (this.state.transport && this.state.transport.Legs) {
        const sortedEndStations = this.sortEndRoutes(stations);
        if (!!sortedEndStations) {
          const endPlaces = this.getPlacesOptions(sortedEndStations);
          if (!!endPlaces) {
            this.setState({ endPlaces });
          }
        }
      } */
    }

    if (
      prevState.selectedStartDate !== this.state.selectedStartDate ||
      prevState.selectedStartPlace !== this.state.selectedStartPlace ||
      prevState.selectedEndPlace !== this.state.selectedEndPlace
    ) {
      await this.setBlueLagoonAvailability();
    }

    if (
      /* (!!this.state.selectedStartPlace &&
        !!this.state.selectedEndPlace &&
        !!this.state.selectedStartDate &&
        !this.state.selectedStartTime &&
        !this.state.starTimeOptions) || */
      !!this.state.selectedStartPlace &&
      !!this.state.selectedEndPlace &&
      !!this.state.selectedStartDate &&
      this.state.selectedStartDate !== prevState.selectedStartDate
    ) {
      this.setTimeOptions();
    }

    // ef að startpalce, eða endplace eða startime er breyttur, þá skipta um verð
  }
  render() {
    const {
      isLoading,
      transport,
      sortedTransportRoutes,
      startPlaces,
      endPlaces,
      personCounter,
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate,
      starTimeOptions,
      endTimeOptions,
      selectedStartTime,
      selectedEndTime,
      addingToCart,
      selectedBluelagoonTime,
      blueLagoonTimeOptions
    } = this.state;
    const { productId, isMobile, tourTitle, tour } = this.props;
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            margin: 20
          }}
        >
          <Loader />
        </div>
      );
    } /* else if (
            parseInt(productId, 10) !== 9 &&
            !!availability &&
            Object.keys(availability).length === 0 &&
            !isLoading
          ) {
            return (
              <div>
                <p>Not available</p>
              </div>
            );
          } */ else {
      if (isMobile) {
        return (
          <BlueLagoonBookingMobile
            productId={productId}
            transport={transport}
            transportRoutes={sortedTransportRoutes}
            personCounter={personCounter}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartDate={selectedStartDate}
            blueLagoonTimeOptions={blueLagoonTimeOptions}
            selectedBluelagoonTime={selectedBluelagoonTime}
            addingToCart={addingToCart}
            changeSelectedPlace={this.changeSelectedPlace}
            changeSelectedDate={this.changeSelectedDate}
            changeSelectedTime={this.changeSelectedTime}
            changeSelectedLagoonTime={this.changeSelectedLagoonTime}
            changePersonCounter={this.changePersonCounter}
            getTotalPersonCount={this.getTotalPersonCount}
            getPersonCount={this.getPersonCount}
            addToCart={this.addToCart}
            getAvailabilityLeft={this.getAvailabilityLeft}
            tour={tour}
          />
        );
      } else {
        return (
          <BlueLagoonBooking
            productId={productId}
            transport={transport}
            transportRoutes={sortedTransportRoutes}
            personCounter={personCounter}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartDate={selectedStartDate}
            blueLagoonTimeOptions={blueLagoonTimeOptions}
            selectedBluelagoonTime={selectedBluelagoonTime}
            addingToCart={addingToCart}
            changeSelectedPlace={this.changeSelectedPlace}
            changeSelectedDate={this.changeSelectedDate}
            changeSelectedTime={this.changeSelectedTime}
            changeSelectedLagoonTime={this.changeSelectedLagoonTime}
            changePersonCounter={this.changePersonCounter}
            getTotalPersonCount={this.getTotalPersonCount}
            getPersonCount={this.getPersonCount}
            addToCart={this.addToCart}
            getAvailabilityLeft={this.getAvailabilityLeft}
            tourTitle={tourTitle}
            tour={tour}
          />
        );
      }
    }
  }
  setDefaultPlaces = () => {
    const { transport, startPlaces, endPlaces } = this.state;
    if (!!transport && !!transport.DefaultFromStationId && !!startPlaces) {
      startPlaces.map(place => {
        if (!!place && !!place.options) {
          place.options.map(option => {
            if (
              !!option &&
              !!option.routeId &&
              option.routeId === transport.DefaultFromStationId
            ) {
              this.setState({ selectedStartPlace: option });
            }
            /* if (
              !!option &&
              !!option.routeId &&
              option.routeId === transport.DefaultToStationId
            ) {
              this.setState({ selectedEndPlace: option });
            } */
          });
        }
      });
    }
    if (!!transport && !!transport.DefaultToStationId && !!endPlaces) {
      endPlaces.map(place => {
        if (!!place && !!place.options) {
          place.options.map(option => {
            if (
              !!option &&
              !!option.routeId &&
              option.routeId === transport.DefaultToStationId
            ) {
              this.setState({ selectedEndPlace: option });
            }
          });
        }
      });
    }
  };
  updateCurrency = async () => {
    const { currency, productId, lang } = this.props;
    this.setState({ isLoading: true });
    const id = parseInt(productId, 10);
    const availability = await this.transportApi.getTransport(
      id,
      currency.value,
      lang
    );
    await this.getTransportStartTimes(currency.value);
    await this.getTransportPrices();
    if (availability) {
      this.setState({ transport: availability, isLoading: false });
    }
  };

  getTransportAvailability = async (currency: string) => {
    const id = parseInt(this.props.productId, 10);
    const availability = await this.transportApi.getTransport(
      id,
      currency,
      this.props.lang
    );
    if (availability) {
      if (availability.NextAvailableDate) {
        this.changeSelectedDate(moment(availability.NextAvailableDate));
      }
      this.setState({ transport: availability, isLoading: false });
    }
  };

  setBlueLagoonAvailability = async () => {
    const { lang } = this.props;
    const { selectedStartDate } = this.state;
    if (selectedStartDate) {
      const availability = await this.bluelagoonApi.GetAvailability(
        moment(selectedStartDate).toDate(),
        lang
      );
      if (availability) {
        const timeOptions = availability.map(time => ({
          label: time.BlueLagoonEntryTime,
          value: time.BlueLagoonEntryTime
        }));
        this.setState({
          blueLagoon: availability,
          blueLagoonTimeOptions: timeOptions
        });
      }
    }
  };

  getAvailabilityLeft = () => {
    const { selectedBluelagoonTime, blueLagoon } = this.state;
    if (!!selectedBluelagoonTime && !!blueLagoon) {
      const availability = blueLagoon.find(
        b => b.BlueLagoonEntryTime === selectedBluelagoonTime.value
      );
      if (availability) {
        return availability.Available;
      }
      return 0;
    }
    return 10;
  };

  async setTimeOptions() {
    const { currency } = this.props;
    await this.getTransportStartTimes(currency.value);
    await this.getTransportPrices();
    this.getStartTimeOptions(this.state.times);
  }

  async getTransportStartTimes(currency: string) {
    const {
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate
    } = this.state;
    if (!!selectedStartPlace && !!selectedEndPlace) {
      const productId = parseInt(this.props.productId, 10);
      const fromId = selectedStartPlace.routeId;
      const toId = selectedEndPlace.routeId;
      const times = await this.transportApi.getStartTimes(
        productId,
        fromId,
        toId,
        selectedStartDate,
        currency,
        true,
        this.props.lang
      );
      if (times) {
        if (times.Prices) {
          this.changePersonCounterPrice(times.Prices);
        }
        this.setState({ times });
      }
    }
  }

  async getTransportPrices() {
    const { currency, lang } = this.props;
    const {
      selectedStartPlace,
      selectedEndPlace,
      selectedStartDate,
      selectedStartTime
    } = this.state;
    if (!!selectedStartPlace && !!selectedEndPlace && !!selectedStartTime) {
      const productId = parseInt(this.props.productId, 10);
      const fromId = selectedStartPlace.routeId;
      const toId = selectedEndPlace.routeId;
      const prices = await this.transportApi.getPrices(
        productId,
        fromId,
        toId,
        selectedStartDate,
        selectedStartTime.label.split(' ')[0],
        currency.label,
        lang
      );
      if (prices) {
        this.changePersonCounterPrice(prices);
      }
    }
  }
  sortTransportRoutes = (
    stations: ITransportRouteStation[] | null,
    legs: ITransportLeg[] | null
  ) => {
    if (stations) {
      const array: { [key: string]: any } = [];
      if (legs) {
        const fromStationIds = legs.map(leg => leg.FromStationId);
        stations.map(route => {
          if (!!route && !!route.Places && fromStationIds.includes(route.Id)) {
            if (!array[route.Type]) {
              array[route.Type] = [{ id: route.Id, places: route.Places }];
            } else {
              array[route.Type].push({ id: route.Id, places: route.Places });
            }
          }
        });
      }
      return array as ISortedTransport;
    }
    return null;
  };
  sortEndRoutes = (stations: ITransportRouteStation[] | null) => {
    if (stations) {
      const array: { [key: string]: any } = [];
      stations.map(route => {
        if (!!route && !!route.Places) {
          if (!array[route.Type]) {
            array[route.Type] = [{ id: route.Id, places: route.Places }];
          } else {
            array[route.Type].push({ id: route.Id, places: route.Places });
          }
        }
      });
      return array as ISortedTransport;
    }

    return null;
  };

  getPlacesOptions = (placeList: ISortedTransport) => {
    if (placeList) {
      const options = Object.keys(placeList).map(key => {
        const array: ISelectPickupValue[] = [];
        placeList[key].map(type => {
          if (type.places) {
            type.places.map(place => {
              array.push({
                label: place.Name,
                value: place.Id + '-' + type.id,
                routeId: type.id
              });
            });
          }
        });
        return { label: key, options: array };
      });
      return options;
    }
    return null;
  };

  changeSelectedPlace = async (
    place: ISelectPickupValue,
    list: ITransportList
  ) => {
    if (list === 'Start') {
      this.setState(
        {
          selectedStartPlace: place,
          // selectedEndPlace: null,
          times: null,
          starTimeOptions: null,
          endTimeOptions: null,
          blueLagoonTimeOptions: null,
          selectedStartTime: null,
          selectedEndTime: null,
          selectedBluelagoonTime: null
        },
        () => {
          this.setTimeOptions();
        }
      );
    } else if (list === 'End') {
      this.setState(
        {
          selectedEndPlace: place,
          times: null,
          starTimeOptions: null,
          endTimeOptions: null,
          selectedStartTime: null,
          selectedEndTime: null,
          selectedBluelagoonTime: null
        },
        () => {
          this.setTimeOptions();
        }
      );
    }
  };

  getEndPlaceIds(routeId: number) {
    const { transport } = this.state;
    if (!!transport && !!transport.Legs) {
      const legIds = transport.Legs.map(leg => {
        if (leg.FromStationId === routeId) {
          return leg.ToStationId;
        }
        return null;
      }).filter(elem => !!elem);
      const routes = transport.RouteStations.map(route => {
        if (legIds.includes(route.Id)) {
          return route;
        }
        return null;
      }).filter(elem => !!elem);
      return routes.filter(elem => !!elem) as ITransportRouteStation[];
    }
    return [] as ITransportRouteStation[];
  }

  getStartTimeOptions(times: ITransportStartTimes | null) {
    const { blueLagoonTimeOptions } = this.state;
    if (!!times && !!times.StartTimes && !!times.ReturnStartTimes) {
      const startTimes = [] as ISelectTime[];
      const endTimes = [] as ISelectTime[];
      times.StartTimes.map(time => {
        startTimes.push({
          label: time.PeakTime
            ? time.StartTime + ' - lower price'
            : time.StartTime,
          value: time.Id
        });
      });
      times.ReturnStartTimes.map(time => {
        endTimes.push({ label: time.StartTime, value: time.Id });
      });

      if (blueLagoonTimeOptions) {
        const newLagoonTimes = [...blueLagoonTimeOptions];
        const highestEndTime = endTimes.reduce((a, b) => {
          return a.label >= b.label ? a : b;
        });
        newLagoonTimes.map(o => {
          if (
            !!o &&
            !!highestEndTime &&
            moment(o.label, 'HH:mm').isAfter(
              moment(highestEndTime.label, 'HH:mm').subtract(1, 'hour')
            )
          ) {
            const index = blueLagoonTimeOptions.findIndex(a => {
              return a === o;
            });
            newLagoonTimes.splice(index);
          }
        });
        this.setState({ blueLagoonTimeOptions: newLagoonTimes });
      }

      this.setState({
        starTimeOptions: startTimes,
        endTimeOptions: endTimes,
        selectedStartTime: startTimes.length === 0 ? startTimes[0] : null
      });
    }
  }

  changeSelectedDate = (date: Moment) => {
    this.setState({
      selectedStartDate: date,
      selectedStartTime: null,
      selectedEndTime: null,
      starTimeOptions: null,
      endTimeOptions: null,
      times: null,
      blueLagoon: null,
      selectedBluelagoonTime: null
    });
  };

  changeSelectedTime = (time: ISelectTime, isReturn: boolean) => {
    if (!isReturn) {
      this.setState(
        {
          selectedStartTime: time,
          selectedBluelagoonTime: null,
          selectedEndTime: null
        },
        () => this.getTransportPrices()
      );
    } else {
      this.setState({ selectedEndTime: time });
    }
  };

  changeSelectedLagoonTime = (time: ILagoonSelect) => {
    const { transport } = this.state;
    if (this.getAvailabilityLeft() < 10) {
      if (!!transport && !!transport.PriceCategories) {
        this.setPersonCounter(transport.PriceCategories);
      }
    }
    this.setState({ selectedBluelagoonTime: time, selectedEndTime: null });
  };

  changePersonCounter = (addOrRemove: AddOrRemove, personType: string) => {
    const { personCounter } = this.state;
    if (addOrRemove === 'addOne') {
      const totalPersonCount = this.getTotalPersonCount();
      const lessThanTen = totalPersonCount < 10;
      if (!!personCounter && !!personCounter[personType] && lessThanTen) {
        const maxPerMaster = this.checkMaxPerMaster(personType);
        const isAvailable = this.getAvailabilityLeft() > totalPersonCount;
        if (
          (isAvailable &&
            maxPerMaster === 0 &&
            personCounter[personType].Default) ||
          (isAvailable && maxPerMaster > personCounter[personType].Count)
        ) {
          const newPersonCounter = { ...personCounter };
          newPersonCounter[personType].Count =
            newPersonCounter[personType].Count + 1;
          this.setState({ personCounter: newPersonCounter });
        }
      }
    } else if (addOrRemove === 'removeOne') {
      if (personCounter) {
        const newPersonCounter = { ...personCounter };
        const newPersonCounterItem = newPersonCounter[personType];
        if (
          !!newPersonCounter &&
          !!newPersonCounter[personType] &&
          newPersonCounterItem.MinCount !== null &&
          newPersonCounterItem.MinCount >= 0 &&
          newPersonCounterItem.MinCount < newPersonCounterItem.Count
        ) {
          newPersonCounter[personType].Count =
            newPersonCounter[personType].Count - 1;
          this.checkCounter();

          this.setState({ personCounter: newPersonCounter });
        }
      }
    }
  };

  checkCounter() {
    const { personCounter } = this.state;
    if (personCounter) {
      const newPersonCounter = { ...personCounter };
      Object.keys(personCounter).map(personType => {
        const maxPerMasterNumber = this.checkMaxPerMaster(personType);
        if (
          (maxPerMasterNumber !== 0 ||
            personCounter[personType].MaxPerMaster !== 0) &&
          maxPerMasterNumber < personCounter[personType].Count
        ) {
          newPersonCounter[personType].Count = maxPerMasterNumber;
        }
      });
      this.setState({ personCounter: newPersonCounter });
    }
  }
  checkMaxPerMaster(personType: string) {
    const { personCounter } = this.state;
    if (personCounter) {
      const newPersonCounter = { ...personCounter };
      let counter = 0;
      Object.keys(personCounter).map(key => {
        const personCounterItem = personCounter[key];
        const newPersonCounterItem = newPersonCounter[personType];
        if (
          !!newPersonCounterItem &&
          !!newPersonCounterItem.MaxPerMaster &&
          personCounterItem.Id === newPersonCounterItem.MasterCategoryId
        ) {
          counter = personCounterItem.Count * newPersonCounterItem.MaxPerMaster;
        }
      });
      return counter;
    }
    return 0;
  }

  setPersonCounter(priceCategories: IPriceCategory[]) {
    const personCounter = priceCategories.reduce(
      (acc, val) => {
        const key = val.Name;
        if (!!key && !acc[key]) {
          acc[key] = {
            Id: val.Id,
            Name: val.Name,
            Dependent: val.Dependent,
            MasterCategoryId: val.MasterCategoryId,
            MaxPerMaster: val.MaxPerMaster ? val.MaxPerMaster : 0,
            Count: val.Default ? 1 : 0,
            MinCount: val.MinCount,
            MinAge: val.MinAge,
            MaxAge: val.MaxAge,
            Price: val.Price,
            ReturnPrice: val.ReturnPrice,
            Default: val.Default,
            Occupancy: val.Occupancy
          };
        }
        return acc;
      },
      {} as ITransportPersonCounter
    );
    this.setState({ personCounter });
  }

  changePersonCounterPrice(priceCategories: IPriceCategory[]) {
    const { personCounter } = this.state;
    if (personCounter) {
      const newCounter = { ...personCounter };
      Object.keys(personCounter).map(key => {
        priceCategories.map(category => {
          if (category.Name === key) {
            newCounter[key].Price = category.Price;
            newCounter[key].ReturnPrice = category.ReturnPrice;
          }
        });
      });
      this.setState({ personCounter: newCounter });
    }
  }

  getTotalPersonCount = () => {
    const { personCounter } = this.state;
    let total = 0;
    if (personCounter) {
      Object.keys(personCounter).map(personType => {
        if (personCounter[personType]) {
          total += personCounter[personType].Count;
        }
      });
      return total;
    }
    return 0;
  };

  getPersonCount = (name: string) => {
    const { personCounter } = this.state;
    if (!!personCounter && !!personCounter[name]) {
      return personCounter[name].Count;
    }
    return 0;
  };

  getValidPersonCount = () => {
    const { personCounter } = this.state;
    if (personCounter) {
      const person = Object(personCounter).find(
        (p: ITransportCounter) => p.Default && p.Count > 0
      );
      if (person) {
        return person.Count;
      }
    }
    return 0;
  };

  getLegId(startOrEnd: ITransportList): ITransportLeg | null {
    const { transport, selectedStartPlace, selectedEndPlace } = this.state;
    if (
      !!transport &&
      !!transport.Legs &&
      !!selectedStartPlace &&
      !!selectedEndPlace
    ) {
      let startLeg;
      let endLeg;
      if (startOrEnd === 'Start') {
        startLeg = transport.Legs.find(leg => {
          if (
            leg.FromStationId === selectedStartPlace.routeId &&
            leg.ToStationId === selectedEndPlace.routeId
          ) {
            return true;
          }
          return false;
        });
      } else {
        endLeg = transport.Legs.find(leg => {
          if (
            leg.ToStationId === selectedStartPlace.routeId &&
            leg.FromStationId === selectedEndPlace.routeId
          ) {
            return true;
          }
          return false;
        });
      }
      if (startLeg) {
        return startLeg;
      } else if (endLeg) {
        return endLeg;
      }
    }
    return null;
  }

  createCartObject() {
    const {
      personCounter,
      selectedStartDate,
      selectedStartPlace,
      selectedStartTime,
      selectedEndPlace,
      selectedEndTime,
      transport,
      selectedBluelagoonTime
    } = this.state;
    const guests: Array<{ Id: string }> = [];
    if (personCounter) {
      Object.keys(personCounter).map(key => {
        const Id = personCounter[key].Id.toString();
        const personCounterItem = personCounter[key];
        const person = { ...personCounter };
        if (
          !!personCounterItem &&
          !!personCounterItem.MinCount &&
          personCounterItem.MinCount > person[key].Count
        ) {
          return;
        }
        for (let i = 0; i < person[key].Count; i++) {
          guests.push({
            Id
          });
        }
      });
    }
    const product = {
      Id: parseInt(this.props.productId, 10)
    };

    const startLegId = this.getLegId('Start');
    // const endLegId = this.getLegId('End');
    if (
      !transport ||
      !selectedStartDate ||
      !selectedStartPlace ||
      !selectedStartTime ||
      !selectedEndTime ||
      !selectedEndPlace ||
      !personCounter ||
      !startLegId ||
      !selectedBluelagoonTime
    ) {
      return;
    }

    const object: IBlueLagoonCart = {
      BookingType: 'TRANSPORT',
      Guests: guests,
      Product: product,
      Date: formatAvailabilityDate(selectedStartDate),
      StartLegId: startLegId.Id,
      StartTimeId: selectedStartTime.value,
      PickupAddress: { Id: parseInt(selectedStartPlace.value, 10) },
      DropoffAddress: { Id: parseInt(selectedEndPlace.value, 10) },
      ReturnDate: formatAvailabilityDate(selectedStartDate),
      ReturnLegId: startLegId.Id,
      ReturnStartTimeId: selectedEndTime.value,
      ReturnPickupAddress: { Id: parseInt(selectedEndPlace.value, 10) },
      ReturnDropoffAddress: { Id: parseInt(selectedStartPlace.value, 10) },
      BlueLagoonEntryTime: createDateTime(
        selectedStartDate,
        selectedBluelagoonTime.value
      )
    };
    return object;
  }

  addToCart = async () => {
    const { currency, cartObject, lang } = this.props;
    let cartId: string;
    if (!cartObject) {
      cartId = ls.get('cartId');
    } else {
      cartId = cartObject.Id;
    }
    const newCartObject = this.createCartObject();

    if (newCartObject) {
      this.setState({ addingToCart: true });
      const newCart = await this.cartApi.addToCart(
        currency.value,
        cartId,
        newCartObject,
        lang
      );
      let success = false;
      if (newCart) {
        ls.set('cartId', newCart.Id);
        this.props.setCart(newCart);
        success = true;
      }
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({ addingToCart: false });
      return success;
    }
    // return false if newCartObject fails
    return false;
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer, translationReducers } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { lang } = translationReducers;
  return { cartObject, currency, lang };
};

export default connect(
  mapStateToProps,
  {
    setCart
  }
)(BlueLagoonEngine);
