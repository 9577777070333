import React from 'react';
import {
  isInclusivelyBeforeDay,
  DayPickerSingleDateController
} from 'react-dates';
import 'react-dates/initialize';
import './styles//datePicker.css';
import moment, { Moment } from 'moment';
import { ISelectPickupValue, ITransport } from '../../interfaces';

interface IProps {
  selectedDate: Moment | null;
  startDate?: Moment;
  selectedStartDate?: Moment;
  nextAvailableDate?: Date | null;
  isReturn: boolean;
  transportKey: string;
  transport: ITransport | null;
  changeDate(date: Moment, transportReturn: boolean): void;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
}

const TransportDatePicker: React.FunctionComponent<IProps> = ({
  transport,
  isReturn,
  startDate,
  nextAvailableDate,
  transportKey,
  selectedDate,
  changeDate,
  selectedStartPlace,
  selectedEndPlace,
}) => {
  const checkAvailabilityExpressions = (day: any) => {
    let result = true;
    if (transport && transport.AvailabilityExpressions) {
      for (let i = 0; i < transport.AvailabilityExpressions.length; i++) {
        const startDate = moment(transport.AvailabilityExpressions[i].startDate);
        const endDate = moment(transport.AvailabilityExpressions[i].endDate);
        const cron = transport.AvailabilityExpressions[i].cron;
        const weekDay = day.format('ddd');

        if (
          day >= startDate &&
          day < endDate &&
          (cron.includes(weekDay) || !cron)
        )
          result = false;
        break;
      }
    } else {
      result = false;
    }
    return result;
  };

  const checkClosures = (day: any) => {
    if (!transport || !transport.Closures) {
      return false;
    }

    const isInRange = (item: any, selectedPlace: any, flag: string) => {
      const startDate = moment(item.startDate);
      const endDate = moment(item.endDate);
      return (
        day >= startDate &&
        day < endDate &&
        selectedPlace?.routeId === 1600 &&
        item.flags[0] === flag
      );
    };

    return transport.Closures.some((item: any) => {
      return (
        isInRange(item, selectedEndPlace, 'newseason') ||
        isInRange(item, selectedStartPlace, 'newseason2')
      );
    });
  };

  const isInclusivebd = (day: any) => {
    if (!!startDate && isReturn) {
      return isInclusivelyBeforeDay(
        day,
        moment(startDate).subtract(1, 'day')
      );
    } else {
      return isInclusivelyBeforeDay(
        day,
        moment(
          nextAvailableDate ? nextAvailableDate : new Date()
          ).subtract(1, 'day')
        );
    }
  };

  const dayChecker = (day: any) => {
    const pastDays = isInclusivebd(day);
    if (pastDays) {
      return pastDays;
    }
    const closures = checkClosures(day);
    if (closures) {
      return closures;
    }
    return checkAvailabilityExpressions(day);
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ alignItems: 'center' }}>
        <DayPickerSingleDateController
          initialVisibleMonth={startDate !== undefined ? () => startDate || moment() : null }
          key={transportKey}
          date={selectedDate}
          numberOfMonths={1}
          focused={true}
          onFocusChange={focus => focus}
          onDateChange={newDate =>
            newDate ? changeDate(newDate, isReturn) : null
          }
          isOutsideRange={day => dayChecker(day)}
          hideKeyboardShortcutsPanel={true}
          noBorder={true}
        />
      </div>
    </div>
  );
};

export default TransportDatePicker;
