import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  changeAnnouncementVisibleMobile,
  changeDismiss,
} from '../../redux/actions/layoutActions';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import * as styles from './index.module.scss';
import { Link } from '../../components';
import { logoSvg, searchDark } from '../../icons';
import Icon from '../../components/Icon';
import TourCart from '../../BookingEngine/TourCart';
import Announcement from '../Annoncement';
import {
  IContentfulHeaderObject,
  IReducers,
  IHeaderList,
} from '../../interfaces';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  langPath: string;
  contentfulData: {
    allContentfulHeader: IHeaderList;
  };
  open: boolean;
  searchValue: string;
  searchOpen: boolean;
  openCart: boolean;
  announcement?: IContentfulHeaderObject;
  drawerLinkClicked: string;
  updateSearch(event: React.ChangeEvent<HTMLInputElement>): void;
  handleEnter(e: React.KeyboardEvent<HTMLInputElement>): void;
  onSearchClick(): void;
  cartChange(open: boolean): void;
  onchange(): void;
  setOpenAndCart(): void;
  changeAnnouncementVisibleMobile(parameter: boolean): void;
  changeDismiss(parameter: boolean): void;
  dismiss: boolean;
  locale: string;
  isIcelandairUrl: boolean;
  isIcelandair: boolean;
  expiredAnnouncement(): boolean;
}

interface IProps extends IExternalProps {
  localeData: {
    myBookingButton: string;
    headerSearch: {
      inputLabel: string;
      inputPlaceholder: string;
    };
  };
}

const HeaderMobile: React.FunctionComponent<IProps> = ({
  onSearchClick,
  langPath,
  openCart,
  searchOpen,
  cartChange,
  contentfulData,
  onchange,
  setOpenAndCart,
  searchValue,
  updateSearch,
  handleEnter,
  open,
  announcement,
  changeAnnouncementVisibleMobile,
  drawerLinkClicked,
  changeDismiss,
  localeData,
  dismiss,
  isIcelandairUrl,
  isIcelandair,
  expiredAnnouncement,
}) => {
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [yCord, setYCord] = useState(0);
  const [burgerPressed, setBurgerPressed] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const componentDidUnmount = useCallback(() => {
    return changeAnnouncementVisibleMobile(false);
  }, [changeAnnouncementVisibleMobile]);

  function hideAnnouncement() {
    const storageUserFlybus = isIcelandair;
    const userFlybusData = storageUserFlybus;
    if (isIcelandairUrl || userFlybusData) announcement = undefined;
  }

  hideAnnouncement();
  useEffect(() => {
    return () => {
      componentDidUnmount();
    };
  }, [componentDidUnmount]);

  useEffect(() => {
    if (dismiss) {
      setShowAnnouncement(false);
    }
  }, [dismiss]);

  useEffect(() => {
    if (announcement) {
      if (
        announcement.announcement.announcement &&
        yCord === 0 &&
        !dismiss &&
        !expiredAnnouncement()
      ) {
        if (!drawerOpen) {
          setShowAnnouncement(true);
        }
        changeAnnouncementVisibleMobile(true);
      }
    }
  }, [
    announcement,
    yCord,
    changeAnnouncementVisibleMobile,
    dismiss,
    drawerOpen,
  ]);

  const initializeVisible = useCallback(() => {
    if (announcement) {
      if (
        announcement.announcement.announcement &&
        !dismiss &&
        !expiredAnnouncement()
      ) {
        changeAnnouncementVisibleMobile(true);
      }
    }
  }, [changeAnnouncementVisibleMobile, announcement, dismiss]);

  // Used for when user clicks on link in header sidebar
  useEffect(() => {
    if (drawerLinkClicked.length > 0) {
      if (announcement) {
        if (
          announcement.announcement.announcement &&
          yCord === 0 &&
          !dismiss &&
          !expiredAnnouncement()
        ) {
          setShowAnnouncement(true);
          // Her þarf að setja redux
          changeAnnouncementVisibleMobile(true);
        }
      }
    }
  }, [
    drawerLinkClicked,
    announcement,
    changeAnnouncementVisibleMobile,
    yCord,
    dismiss,
  ]);

  useEffect(() => {
    initializeVisible();
  }, [initializeVisible]);

  useEffect(() => {
    if (searchOpen) {
      if (inputEl && inputEl.current) {
        inputEl.current.focus();
      }
    }
  }, [searchOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (burgerPressed) {
        setBurgerPressed(false);
      }
    }, 2000);
  }, [burgerPressed]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setYCord(currPos.y);
      const isShow = currPos.y > prevPos.y;
      if (
        isShow !== showAnnouncement &&
        !dismiss &&
        !drawerOpen &&
        !expiredAnnouncement()
      ) {
        setShowAnnouncement(isShow);
        if (
          currPos.y === 0 &&
          isShow === true &&
          burgerPressed === true &&
          !dismiss
        ) {
          setShowAnnouncement(false);
        }
        changeAnnouncementVisibleMobile(isShow);
      }
      if (currPos.y > prevPos.y && currPos.y !== 0 && !dismiss) {
        setShowAnnouncement(false);
        changeAnnouncementVisibleMobile(false);
      }
      if (
        currPos.y === 0 &&
        burgerPressed === false &&
        !dismiss &&
        !drawerOpen &&
        !expiredAnnouncement()
      ) {
        setShowAnnouncement(true);
        changeAnnouncementVisibleMobile(true);
      }
    },
    [showAnnouncement, burgerPressed]
  );

  const close = (parameter: boolean) => {
    changeDismiss(parameter);
    changeAnnouncementVisibleMobile(false);
  };

  return (
    <div>
      {showAnnouncement && announcement && !expiredAnnouncement() && (
        <Announcement
          announcement={announcement}
          mobile={true}
          closeAnnouncement={close}
        />
      )}
      <header
        className={
          showAnnouncement && !expiredAnnouncement() && announcement
            ? styles.headerMobileWithAnnouncement
            : styles.headerMobile
          // styles.headerMobile
        }
      >
        <div className='centered-content'>
          <div className={styles.upperMobile} onClick={() => scroll(0, 0)}>
            <TourCart
              light={false}
              langPath={langPath}
              mobileOpen={openCart}
              mobile={true}
              searchOpen={searchOpen}
              cartChange={cartChange}
              contentfulData={contentfulData}
              isIcelandair={isIcelandair}
            />
            {isIcelandair ? null : (
              <div className={styles.searchContainer}>
                <button
                  onClick={onSearchClick}
                  style={{ height: '100%' }}
                  data-cy='Search-Button-Mobile'
                >
                  <img
                    src={searchDark}
                    alt='Search'
                    className={styles.search}
                  />
                </button>
              </div>
            )}
            {open ? (
              <div
                className={styles.exit}
                onClick={() => {
                  onchange();
                  if (announcement) {
                    if (
                      announcement.announcement.announcement &&
                      yCord === 0 &&
                      !dismiss
                    ) {
                      setShowAnnouncement(true);
                      setDrawerOpen(false);
                    }
                  }
                }}
              >
                <Icon name='EXIT' />
              </div>
            ) : (
              <div
                className={styles.burger}
                onClick={() => {
                  onchange();
                  setShowAnnouncement(false);
                  setBurgerPressed(true);
                  setDrawerOpen(true);
                }}
              >
                <Icon name='BURGER' />
              </div>
            )}
          </div>
          {isIcelandair || isIcelandairUrl ? (
            <div className={styles.lowerMobile}>
              <div className={styles.logoMobile}>
                <img src={logoSvg} />
              </div>
            </div>
          ) : (
            <div
              className={styles.lowerMobile}
              onClick={() => setOpenAndCart()}
            >
              <Link langPath={langPath} to=''>
                <div className={styles.logoMobile}>
                  <img src={logoSvg} />
                </div>
              </Link>
            </div>
          )}
          {searchOpen ? (
            <div style={{ marginBottom: 10 }}>
              <label htmlFor='search' title='search'>
                {localeData.headerSearch.inputLabel}
              </label>
              <input
                type='text'
                value={searchValue}
                onChange={updateSearch}
                className={styles.searchInput}
                onKeyDown={handleEnter}
                disabled={!searchOpen}
                style={{
                  width: searchOpen ? '100%' : 0,
                  opacity: searchOpen ? 1 : 0,
                }}
                ref={inputEl}
                aria-labelledby='Search'
                data-cy='Search-Input'
                id='search'
                placeholder={localeData.headerSearch.inputPlaceholder}
              />
            </div>
          ) : null}
        </div>
      </header>
      <div style={{ width: '100%', height: searchOpen ? 89 : 54 }} />
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { announcementVisibleMobile, dismiss, isIcelandair } =
    reducers.layoutReducer;
  const { locale } = reducers.translationReducers;
  return { announcementVisibleMobile, dismiss, locale, isIcelandair };
};

export default connect(mapStateToProps, {
  changeAnnouncementVisibleMobile,
  changeDismiss,
})(
  ({
    langPath,
    contentfulData,
    open,
    searchValue,
    searchOpen,
    openCart,
    announcement,
    drawerLinkClicked,
    updateSearch,
    handleEnter,
    onSearchClick,
    cartChange,
    onchange,
    setOpenAndCart,
    changeAnnouncementVisibleMobile,
    changeDismiss,
    dismiss,
    locale,
    isIcelandairUrl,
    isIcelandair,
    expiredAnnouncement,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query HeaderMobile {
          allContentfulHeader {
            edges {
              node {
                node_locale
                myBookingButton
                headerSearch {
                  inputLabel
                  inputPlaceholder
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <HeaderMobile
            localeData={
              data.allContentfulHeader.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            langPath={langPath}
            contentfulData={contentfulData}
            open={open}
            searchValue={searchValue}
            searchOpen={searchOpen}
            openCart={openCart}
            announcement={announcement}
            drawerLinkClicked={drawerLinkClicked}
            updateSearch={updateSearch}
            handleEnter={handleEnter}
            onSearchClick={onSearchClick}
            cartChange={cartChange}
            onchange={onchange}
            setOpenAndCart={setOpenAndCart}
            changeAnnouncementVisibleMobile={changeAnnouncementVisibleMobile}
            changeDismiss={changeDismiss}
            dismiss={dismiss}
            locale={locale}
            isIcelandairUrl={isIcelandairUrl}
            isIcelandair={isIcelandair}
            expiredAnnouncement={expiredAnnouncement}
          />
        );
      }}
    />
  )
);
