import React from 'react';

export const TripAdvisor2020 = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    width='54.672'
    height='54.712'
    viewBox='0 0 53.7 41.7'
  >
    <style>{'.st0{fill:#1d1d1b}'}</style>
    <path
      className='st0'
      d='M26.8 0c-8.1 0-14.6 6.6-14.6 14.6s6.6 14.6 14.6 14.6 14.6-6.6 14.6-14.6S34.9 0 26.8 0zm0 27.6c-7.2 0-13-5.8-13-13s5.8-13 13-13 13 5.8 13 13-5.8 13-13 13zm-3.2-12c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8zm10.1 0c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8zm1.6-3.7l1.7-1.8h-3.7c-1.8-1.3-4-2-6.4-2s-4.6.7-6.4 2h-3.7l1.7 1.8c-1 .9-1.6 2.3-1.6 3.7 0 2.8 2.3 5.1 5.1 5.1 1.3 0 2.5-.5 3.4-1.3l1.6 1.8 1.6-1.8c.9.8 2.1 1.3 3.4 1.3 2.8 0 5.1-2.3 5.1-5.1-.2-1.4-.8-2.8-1.8-3.7zm-13.5 7.2c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4zm5-3.6c0-2.2-1.6-4.2-3.8-5 1.2-.5 2.4-.8 3.8-.8 1.3 0 2.6.3 3.8.8-2.1.9-3.8 2.8-3.8 5zm5.1 3.6c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4zM18.7 30.8s-1.8-1.4-5-1.7c-3.2-.4-5.5 1.3-5.5 1.3s2.1.9 5.4 1.2c3.2.3 5.1-.8 5.1-.8zM13.7 28s-1.6-1.6-4.6-2.8c-3-1.1-5.8-.4-5.8-.4s1.8 1.7 5 2.9c3.2 1.3 5.4.3 5.4.3zM9 24.1s-.9-2.3-3.4-4.6S0 16.9 0 16.9s1.1 2.4 3.8 4.9C6.4 24.2 9 24.1 9 24.1zM8 19.8s-.3-2.4-2.1-5.3c-1.8-2.8-4.7-4-4.7-4s.4 2.7 2.4 5.7S8 19.8 8 19.8zM8.2 13.9s-.1-2.5-1.6-5.5-4.2-4.5-4.2-4.5.2 2.7 1.8 6c1.6 3.2 4 4 4 4z'
    />
    <g>
      <path
        className='st0'
        d='M15.3 26.4s-.7-1.9-2.7-3.7c-2-1.9-4.5-2.2-4.5-2.2s.9 2 3 4c2 1.9 4.2 1.9 4.2 1.9zM20.3 29.1s-1-1-2.9-1.7-3.7-.4-3.7-.4 1.2 1.1 3.2 1.8c2 .8 3.4.3 3.4.3zM25.2 30.1s-1.1-.4-2.7-.4-2.8.9-2.8.9 1.2.4 2.9.4c1.8 0 2.6-.9 2.6-.9zM13 21.9s-.3-2-1.9-4.2c-1.6-2.3-4-3.1-4-3.1s.5 2.2 2.1 4.6 3.8 2.7 3.8 2.7zM11.1 15.8s.4-2-.3-4.7c-.6-2.7-2.5-4.3-2.5-4.3s-.4 2.2.3 5.1c.7 2.8 2.5 3.9 2.5 3.9z'
      />
    </g>
    <g>
      <path
        className='st0'
        d='M35 30.8s1.8-1.4 5-1.7c3.2-.4 5.5 1.3 5.5 1.3s-2.1.9-5.4 1.2c-3.2.3-5.1-.8-5.1-.8zM40 28s1.6-1.6 4.6-2.8c3-1.1 5.8-.4 5.8-.4s-1.8 1.7-5 2.9C42.2 29 40 28 40 28zM44.7 24.1s.9-2.3 3.4-4.6 5.6-2.7 5.6-2.7-1.1 2.4-3.8 4.9c-2.6 2.5-5.2 2.4-5.2 2.4zM45.6 19.8s.3-2.4 2.1-5.3c1.8-2.8 4.7-4 4.7-4s-.4 2.7-2.4 5.7-4.4 3.6-4.4 3.6zM45.5 13.9s.1-2.5 1.6-5.5 4.2-4.5 4.2-4.5-.2 2.7-1.8 6c-1.6 3.2-4 4-4 4z'
      />
      <g>
        <path
          className='st0'
          d='M38.4 26.4s.7-1.9 2.7-3.7c2-1.9 4.5-2.2 4.5-2.2s-.9 2-3 4c-2.1 1.9-4.2 1.9-4.2 1.9zM33.4 29.1s1-1 2.9-1.7S40 27 40 27s-1.2 1.1-3.2 1.8c-2 .8-3.4.3-3.4.3zM28.5 30.1s1.1-.4 2.7-.4c1.6.1 2.8.9 2.8.9s-1.2.4-2.9.4c-1.8 0-2.6-.9-2.6-.9zM40.6 21.9s.3-2 1.9-4.2c1.6-2.3 4-3.1 4-3.1s-.5 2.2-2.1 4.6-3.8 2.7-3.8 2.7zM42.6 15.8s-.4-2 .3-4.7c.6-2.7 2.5-4.3 2.5-4.3s.3 2.2-.4 5.1c-.6 2.8-2.4 3.9-2.4 3.9z'
        />
      </g>
    </g>
    <circle className='st0' cx='26.8' cy='30.8' r='.8' />
  </svg>
);
export default TripAdvisor2020;
