import React from 'react';
// import * as styles from './PrizeCircle.scss';

export const PrizeCircle = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='54.672'
    height='54.712'
    viewBox='0 0 54.672 54.712'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2.5px'
    stroke='#2d8377'
  >
    <g id='Prize' transform='translate(-890.841 -770.752)'>
      <path
        id='Path_39'
        d='M899.732 816.534a26.086 26.086 0 1 1 36.891 0 26.967 26.967 0 0 1-3.953 3.25 26.017 26.017 0 0 1-32.938-3.25z'
        stroke='#afafaf'
        data-name='Path 39'
      />
      <g
        id='Group_44'
        data-name='Group 44'
        transform='translate(899.749 784.707)'
      >
        <path
          id='Path_40'
          d='M916.263 801a8.854 8.854 0 0 1-8.854-8.854 8.854 8.854 0 0 1 8.854-8.854 8.854 8.854 0 0 1 8.854 8.854 8.853 8.853 0 0 1-8.854 8.854z'
          stroke='#2d8377'
          data-name='Path 40'
          transform='translate(-897.834 -783.295)'
        />
        <path
          id='Path_41'
          d='M915.466 800.019l-8.644 8.643-1.719-6.2-6.2-1.719 9.575-9.575'
          stroke='#2d8377'
          data-name='Path 41'
          transform='translate(-898.898 -782.311)'
        />
        <path
          id='Path_44'
          d='M924.142 791.356l9.359 9.359-6.2 1.719-1.719 6.2-8.644-8.643'
          stroke='#2d8377'
          data-name='Path 44'
          transform='translate(-896.644 -782.287)'
        />
      </g>
    </g>
  </svg>
);

export default PrizeCircle;
