import React from 'react';

export const HeartCircle = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' enableBackground='new 0 0 50 50' xmlSpace='preserve'>
    <style>{'.st2_icon{fill:#e58170;stroke:#e58170;stroke-width:.386;stroke-miterlimit:10}'}</style>
    <g id='Layer_1'>
      <path
        d='M46.6 48.5H3.4c-1 0-1.9-.8-1.9-1.9V3.4c0-1 .8-1.9 1.9-1.9h43.3c1 0 1.9.8 1.9 1.9v43.3c-.1 1-.9 1.8-2 1.8z'
        style={{
          fill: 'none',
          stroke: '#e58170',
          strokeWidth: 1.6626,
          strokeMiterlimit: 10,
        }}
      />
      <path
        d='M6 17.8c.2-.2.5-.3.8-.1.4.1.9.3 1.4.4.1 0 .3.1.4.2 1.4 1.3 2.7 2.7 4.1 4 .2.2.2.5.1.7-.2.2-.4.3-.7.2-.5-.1-1-.3-1.5-.4h-.2v.1c1.8 1.1 3.6 2.1 5.4 3.2-.4-1-.8-1.9-1.1-2.9-.1-.2 0-.5.1-.6.1-.1.4-.2.6-.2.8.1 1.5.4 2 1.1.2.3.5.5.8.8.2.1.3.3.5.3 1.9.9 3.8 1.9 5.7 2.8 1.3.6 2.6 1.3 4 1.6.5.1.9.2 1.4.4.9.3 1.4 1.2 1.2 2.1-.3.9-1.2 1.4-2.1 1.2l-3.3-.9c-.6-.2-1-.6-1.2-1.2-.1-.3-.2-.3-.4-.4l-6.6-1.8c-.2 0-.3-.1-.5-.2-2.3-1.4-4.7-2.7-7-4.1-.4-.3-.7-.6-.7-1.1 0-.4-.2-.5-.5-.6-.2-.1-.5-.2-.7-.3-.7-1.2-1.4-2.5-2.1-3.7.1-.3.1-.4.1-.6zm11.2 7.7c1.3.7 2.5 1.5 3.7 2.3.3.2.7.3 1.1.5.8.2 1.6.5 2.4.7.1 0 .3 0 .4-.1v-.1c-2.2-1.1-4.5-2.2-6.7-3.3-.4-.2-.8-.6-1.2-.9-.2-.2-.4-.4-.7-.6.2.6.4 1.2 1 1.5zm11.9 5.9c.4.1.7-.1.8-.4.1-.3-.1-.6-.5-.7-1-.3-2-.6-3-.8-.4-.1-.7 0-.8.3-.1.3.1.6.5.7.5.1 1 .3 1.5.4.5.3 1 .4 1.5.5zM7.8 19.1s-.1 0 0 0c.3.7.6 1.3 1 2 0 .1.1.1.2.1.4.1.8.2 1.1.3v-.1l-2.3-2.3z'
        style={{
          fill: '#e58170',
          stroke: '#e58170',
          strokeWidth: 0.193,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className='st2_icon'
        d='M7.6 12.2c.2 1 .3 2 .5 3 .1.8.2 1.2.3 2.1.4.3.8.6 1.1.8-.2-1.5-.4-3.6-.6-5.2.4.1.7.2 1 .3.1 0 .1.1.2.2 1.3 2.5 2.5 4.9 3.8 7.4.2.4.5.6.9.7 4.6 1.3 9.3 2.6 13.9 3.8.1 0 .3.1.4.1.1-.4.2-.7.3-1.1-.1 0-.3-.1-.4-.1-4.6-1.3-9.1-2.5-13.7-3.8-.2-.1-.4-.2-.5-.4-1.3-2.5-2.5-5-3.8-7.5-.1-.2-.3-.4-.6-.5-.6-.2-1.2-.3-1.8-.5-.3-.1-.6-.1-.8.1-.2.4-.2.5-.2.6z'
      />
      <path
        className='st2_icon'
        d='m32.7 25.4 3.3.9c.8.2 1.4.7 1.8 1.4.4.8.9 1.5 1.5 2.2.9 1 .5 2.3-.8 2.7-.6.2-1.3.4-1.9.5-.5.1-1 .1-1.5 0-1.1-.3-2.3-.6-3.4-.9-.1 0-.2-.1-.3-.1.1-.4.2-.7.3-1.1.1 0 .2.1.3.1 1.1.3 2.1.6 3.2.9.3.1.7.1 1 0 .7-.1 1.3-.3 2-.5.4-.1.5-.4.3-.8-.5-.6 0 0-.6-.9 0 0-.9-1.1-.9-1 0 0-.2-.3-.9-.9-.4-.4-2.1-1-2.6-1.1-.4-.1-4.1-1.1-4.5-1.3.1-.4.2-.7.3-1.1.1.1 3.4 1 3.4 1z'
      />
      <path
        style={{
          fill: '#e58170',
          stroke: '#e58170',
          strokeWidth: 1.544,
          strokeMiterlimit: 10,
        }}
        d='M17.2 38.4h26.9'
      />
      <path
        d='M34.6 29.3s1.2.5 2.4.4c1.2-.1 1.6-.5 1.6-.5'
        style={{
          fill: 'none',
          stroke: '#e58170',
          strokeWidth: 1.0222,
          strokeMiterlimit: 10,
        }}
      />
    </g>
  </svg>
);
export default HeartCircle;
