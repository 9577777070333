import React from 'react';

import {
  ITransportPersonCounter,
  ITransportCounter,
  IReducers,
} from '../../../interfaces';
import * as styles from './index.module.scss';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  personCounter: ITransportPersonCounter;
  personCounterItem: ITransportCounter;
  countKey: string;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    age: string;
  };
}

const TransportCounterNames: React.FunctionComponent<IProps> = ({
  personCounterItem,
  countKey,
  personCounter,
  localeData,
}) => {
  return (
    <div className={styles.textContainer}>
      <p className={styles.mainText}>{countKey}</p>
      <p className={styles.secondaryText}>
        {!!personCounterItem.MinAge && !!personCounterItem.MaxAge
          ? personCounter[countKey].MinAge +
            (personCounterItem.MaxAge > personCounterItem.MinAge
              ? ' - ' + personCounter[countKey].MaxAge
              : '+') +
            ' ' +
            localeData.age
          : !!personCounterItem.MinAge && !personCounterItem.MaxAge
          ? personCounterItem.MinAge + '+ ' + localeData.age
          : null}
      </p>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({ personCounter, personCounterItem, countKey, locale }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportCounterNames {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                age
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => (
        <TransportCounterNames
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          personCounter={personCounter}
          countKey={countKey}
          personCounterItem={personCounterItem}
          locale={locale}
        />
      )}
    />
  )
);
