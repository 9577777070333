import React from 'react';
import cx from 'classnames';

import * as styles from './BackgroundImage.module.scss';

interface IProps {
  src: string;
  alt?: string;
  align?: string;
  className?: string;
}

const BackgroundImage: React.FunctionComponent<IProps> = ({
  src,
  alt,
  align,
  className,
}) => (
  <div
    className={cx(styles.container, className)}
    style={{ backgroundImage: `url(${src})`, objectPosition: align }}
    title={alt}
  />
);

export default BackgroundImage;
