import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
  ICountryOption,
} from '../../interfaces';
import * as styles from './Questions.module.scss';
import { checkInputType } from '../../utils';
import CheckoutCountryCode from '../CheckoutCountryCode/CheckoutCountryCode';

interface IProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  changeVal(val: string): void;
  selectedCountryCode?: ICountryOption | null;
  changeSelectedCountryCode?(country: ICountryOption): void;
  showCountryCode?: boolean;
}

const TextQuestion: React.FunctionComponent<IProps> = (props) => {
  const {
    bookingQuestion,
    contactQuestion,
    selectedCountryCode,
    changeSelectedCountryCode,
  } = props;
  const inputLabelId =
    bookingQuestion && bookingQuestion.Label
      ? bookingQuestion.Label.replace(/\s+/g, '-')
      : 'NeedIbookingd';

  if (bookingQuestion) {
    return (
      <>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}
        <label
          htmlFor={inputLabelId}
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </label>

        <input
          id={inputLabelId}
          type={checkInputType(bookingQuestion.DataFormat)}
          className={cx(
            styles.textInput,
            !bookingQuestion.isValid ? styles.errorField : null
          )}
          value={bookingQuestion.value ? bookingQuestion.value : ''}
          placeholder={
            bookingQuestion.Placeholder ? bookingQuestion.Placeholder : ''
          }
          onChange={(e) => props.changeVal(e.target.value)}
        />
      </>
    );
  } else if (contactQuestion) {
    // TODO need to get a unique ID for every input
    const inputLabelId = contactQuestion.Label
      ? contactQuestion.Label.replace(/\s+/g, '-')
      : 'NeedcontactQuestionId';
    if (changeSelectedCountryCode) {
      return (
        <div>
          <label
            htmlFor={inputLabelId}
            className={cx(styles.label, {
              [styles.required]: contactQuestion.Required,
            })}
          >
            {contactQuestion.Label}
          </label>
          <div className='columns'>
            <div className='column is-5'>
              <div
                className={cx(
                  styles.inputCountry,
                  !contactQuestion.isValid && !selectedCountryCode
                    ? styles.errorField
                    : null
                )}
              >
                <CheckoutCountryCode
                  selectedCountryCode={selectedCountryCode}
                  disabled={false}
                  changeSelectedCountryCode={changeSelectedCountryCode}
                  contactQuestion={contactQuestion}
                />
              </div>
            </div>
            <div className='column is-6-full'>
              <input
                id={inputLabelId}
                type={checkInputType(contactQuestion.DataFormat)}
                className={cx(
                  styles.input,
                  !contactQuestion.isValid ? styles.errorField : null
                )}
                value={contactQuestion.value ? contactQuestion.value : ''}
                placeholder={
                  contactQuestion.Placeholder ? contactQuestion.Placeholder : ''
                }
                onChange={(e) => props.changeVal(e.target.value)}
              />
              {!contactQuestion.isValid ? (
                <label className={styles.labelRed}>
                  Invalid phone number (make sure country code is correct)
                </label>
              ) : null}
            </div>
          </div>
          <label className={styles.label}>
            Select your country code and input your phone number without the
            initial '0'. <br /> Please note that this contact information is
            strictly for inquiries related to your day tour. No promotional
            communications will be sent.
          </label>
        </div>
      );
    } else {
      return (
        <div>
          <label
            htmlFor={inputLabelId}
            className={cx(styles.label, {
              [styles.required]: contactQuestion.Required,
            })}
          >
            {contactQuestion.Label}
          </label>
          <input
            id={inputLabelId}
            type={checkInputType(contactQuestion.DataFormat)}
            className={cx(
              styles.input,
              !contactQuestion.isValid ? styles.errorField : null
            )}
            value={contactQuestion.value ? contactQuestion.value : ''}
            placeholder={
              contactQuestion.Placeholder ? contactQuestion.Placeholder : ''
            }
            onChange={(e) => props.changeVal(e.target.value)}
          />
          {!contactQuestion.isValid && contactQuestion.Id == 'email' ? (
            <label className={styles.labelRed}>
              Invalid email format ('email@example.com')
            </label>
          ) : null}
        </div>
      );
    }
  }
  return null;
};

export default TextQuestion;
