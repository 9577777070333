// extracted by mini-css-extract-plugin
export var container = "TourReviews-module--container--QQUuw";
export var slide = "TourReviews-module--slide--bk62m";
export var title = "TourReviews-module--title--3aR-0";
export var stars = "TourReviews-module--stars--2__OE";
export var dots = "TourReviews-module--dots--3Lzbs";
export var bottom = "TourReviews-module--bottom--3pzxt";
export var icon = "TourReviews-module--icon--3WVBk";
export var backgroundBoxes = "TourReviews-module--backgroundBoxes--282x-";
export var smallBox = "TourReviews-module--smallBox--HtWO1";
export var bigBox = "TourReviews-module--bigBox--2F5_u";
export var gradient = "TourReviews-module--gradient--1I_Rj";