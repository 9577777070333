import React from 'react';

export const ExitMini = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10pt'
    height='10pt'
    viewBox='0 0 10 10'
  >
    <g fill='#6f7377'>
      <path d='M.320312 9.226562L9.230469.316406c.175781-.175781.460937-.175781.636719 0 .171874.175782.171874.460938 0 .636719L.957031 9.863281c-.175781.175781-.460937.175781-.636719 0-.175781-.175781-.175781-.460937 0-.636719zm0 0' />
      <path d='M.953125.316406l8.910156 8.910156c.175781.175782.175781.460938 0 .636719-.175781.175781-.460937.175781-.636719 0L.316406.953125c-.171875-.175781-.171875-.460937 0-.636719.175782-.175781.460938-.175781.636719 0zm0 0' />
    </g>
  </svg>
);
export default ExitMini;
