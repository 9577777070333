import React from 'react';
import Img from 'gatsby-image';

import { IFluidObject } from '../../interfaces';
import { carbonNeutralTour } from '../../../src/icons';

interface IProps {
  fluid: IFluidObject;
  alt?: string;
  fadeIn?: boolean;
  align?: string;
  className?: string;
  loading?: `auto` | `lazy` | `eager`;
}

// Images with width more than 4000px are not available
const removeIfTooLarge = (srcSet: string) => {
  const srcArray = srcSet.split(',');
  const validArray: string[] = [];
  srcArray.forEach((src) => {
    const regex = /w=[0-9]+/g;
    const found = src.match(regex);
    let valid = true;
    if (found) {
      for (const f of found) {
        const width = parseInt(f.split('=')[1], 10);
        if (width && width > 4000) {
          valid = false;
          break;
        }
      }
    }
    if (valid) {
      validArray.push(src);
    }
  });
  return validArray.join(',');
};

const FluidImage = ({
  fluid,
  alt,
  fadeIn,
  className,
  align,
  loading,
}: IProps) => {
  return (
    <Img
      fadeIn={fadeIn}
      fluid={{
        ...fluid,
        srcSet: removeIfTooLarge(fluid != null ? fluid.srcSet : '' || ''),
        srcSetWebp: removeIfTooLarge(
          (fluid != null ? fluid.srcSetWebp : '') || ''
        ),
      }}
      className={className || ''}
      alt={alt}
      imgStyle={{ objectPosition: align }}
      style={{ maxHeight: '100%', maxWidth: '100%' }}
      loading={loading}
    />
  );
};

FluidImage.defaultProps = {
  fadeIn: true,
};

export default FluidImage;
