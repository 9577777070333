import React from 'react';
import * as styles from './index.module.scss';
import { Icon } from '../../../../src/components';

interface IProps {
  privateUrl: string | undefined;
  text: string;
}

const TourBookingButtonPrivate: React.FunctionComponent<IProps> = (props) => {
  const { privateUrl, text } = props;
  return (
    <button
      onClick={() => {
        if (privateUrl) window.open(privateUrl);
      }}
      className={styles.bookingButton}
      aria-label='Book Now'
      data-cy='Book-Button'
    >
      {text} <Icon name='ARROWRIGHTWHITE' />
    </button>
  );
};

export default TourBookingButtonPrivate;
