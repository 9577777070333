import { SET_CART, RESET_CART } from './types';
import { ICart } from '../../interfaces';

export const setCart = (cart: ICart | null) => {
  return {
    type: SET_CART,
    payload: cart
  };
};
export const resetCart = () => {
  return {
    type: RESET_CART
  };
};