import React from 'react';
import moment, { Moment } from 'moment';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import cx from 'classnames';

import '../../BookingEngine/components/styles/datePicker.css';
import * as styles from './Hero.module.scss';
import * as buttonStyles from '../Button/index.module.scss';
import {
  IImage,
  IPageContext,
  ITourCategory,
  IBanner,
  IFrontPageSearchBox,
} from '../../interfaces';
import {
  Image,
  FilterSelect,
  Link,
  Mobile,
  Desktop,
  Title,
  Icon,
} from '../index';
import { Calendar, carbonNeutralNew } from '../../icons'; // TODO skipta ut fyrir icon component
import {
  getDateQueryParams,
  getCalendarDate,
  getSelectText,
} from '../../utils';
import { filterParams } from '../../constants';
import BookingDropdown from '../../BookingEngine/components/BookingDropdown';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';

import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
interface ISelectCategory extends ITourCategory {
  selected: boolean;
}

interface IProps {
  title: string;
  subtitle?: string;
  image?: IImage;
  categories: ITourCategory[];
  pageContext: IPageContext;
  banner?: IBanner;
  bannerImages?: IImage[];
  isFrontPage?: boolean;
  searchBox: IFrontPageSearchBox;
  mobileButtons: {
    version1: string;
    version2: string;
  };
}

interface IState {
  alertHeight: number;
  alertHeightMobile: number;
  imageHeightMobile: number;
  categories: ISelectCategory[];
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: 'startDate' | 'endDate';
  datePlaceholder: string;
  open: boolean;
}

class Hero extends React.Component<IProps & IWindowSizeProps, IState> {
  readonly state: IState = {
    alertHeight: 0,
    alertHeightMobile: 0,
    imageHeightMobile: 0,
    categories: [],
    startDate: null,
    endDate: null,
    focusedInput: 'startDate',
    datePlaceholder: '',
    open: true,
  };

  alert: HTMLDivElement | null = null;
  alertMobile: HTMLDivElement | null = null;
  imageMobile: HTMLDivElement | null = null;
  // suddenly this started throwing an error and I dont know why, changed to any for now
  calendarDropdown: any; //BookingDropdown | null = null;
  categoriesDropdown: any; //BookingDropdown | null = null;
  alertHeightTimeout: NodeJS.Timer | null = null;

  componentDidMount() {
    this.updateAlertHeight();
    this.alertHeightTimeout = setTimeout(this.updateAlertHeight, 300);
    window.addEventListener('resize', this.updateAlertHeight);

    const categories = this.props.categories.map((cat) => ({
      ...cat,
      selected: false,
    }));
    this.setState({ categories });
  }

  componentWillUnmount() {
    if (this.alertHeightTimeout) {
      clearTimeout(this.alertHeightTimeout);
    }
    window.removeEventListener('resize', this.updateAlertHeight);
  }

  slides(deskOrMobile: string) {
    const { bannerImages, pageContext } = this.props;
    return (
      <Slider
        className={
          deskOrMobile === 'desktop' ? styles.slider : styles.sliderMobile
        }
      >
        {bannerImages
          ? bannerImages.map((value: any, index: any) => {
              return (
                <Link
                  langPath={pageContext.langPath}
                  to={value.slug}
                  key={index}
                >
                  <Slide
                    index={index}
                    key={index}
                    className={
                      deskOrMobile === 'desktop'
                        ? styles.sliderStyleDesktop
                        : styles.sliderStyleMobile
                    }
                  >
                    <Image
                      fluidImage={value}
                      className={
                        deskOrMobile === 'desktop'
                          ? styles.image
                          : styles.imageMobile
                      }
                    />
                  </Slide>
                </Link>
              );
            })
          : null}
      </Slider>
    );
  }

  renderDots(len: any) {
    const table = [];
    for (let i = 0; i < len; i++) {
      table.push(
        <Dot key={i} slide={i} className={styles.dotContainer}>
          <span aria-hidden='true' className={styles.dots}>
            &bull;
          </span>
        </Dot>
      );
    }
    return table;
  }

  renderHeroImages(deskOrMobile: string) {
    const { image, bannerImages } = this.props;
    // multiple images
    if (!!bannerImages && bannerImages.length > 1) {
      return (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={45}
          totalSlides={bannerImages.length}
          isPlaying={true}
          interval={6000}
          dragEnabled={false}
          infinite={true}
        >
          {this.slides(deskOrMobile)}
          {deskOrMobile === 'desktop' ? (
            <div className={styles.renderDots}>
              {this.renderDots(bannerImages.length)}
            </div>
          ) : null}
        </CarouselProvider>
      );
    }
    // one image
    else if (!!bannerImages && bannerImages.length === 1) {
      return (
        <Link to={bannerImages[0].slug || '/'} langPath={''}>
          <Image
            fluidImage={bannerImages[0]}
            loading='auto'
            fadeIn={false}
            className={
              deskOrMobile === 'desktop'
                ? styles.imageClickable
                : styles.imageMobile
            }
          />
        </Link>
      );
    }
    // if all else fails use the hero image as fallback. Will remove once all contentful pages have been correctly set up
    else {
      return (
        <Link to={'tour/golden-circle-direct/'} langPath={''}>
          <Image
            fluidImage={image}
            className={
              deskOrMobile === 'desktop'
                ? styles.imageClickable
                : styles.imageMobile
            }
          />
        </Link>
      );
    }
  }

  render() {
    const { subtitle, banner, isFrontPage, pageContext, mobileButtons } =
      this.props;
    const queryParams = this.getQueryParams();
    return (
      <React.Fragment>
        <Desktop>
          {this.renderHeroImages('desktop')}
          <div className={styles.infoContainer}>
            {banner ? (
              <div className={styles.badges}>
                {banner.info.map((text, i) => {
                  return (
                    <div key={i}>
                      {text.iconName == 'CNPositive' ? (
                        <div className={styles.badgeCarbon}>
                          <img src={carbonNeutralNew} alt={text.infoText} />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: i === 0 ? '45px' : '35px',
                            marginRight: '15px',
                          }}
                        >
                          <Icon
                            name={
                              text.iconName == null
                                ? 'empty'
                                : text.iconName.toUpperCase()
                            }
                          />
                        </div>
                      )}
                      <p>{text.infoText}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          <div className={styles.content}>
            <div className='centered-content'>
              <Title tag='h1' theme={['sizeDesktopLarge']}>
                {this.props.title}
              </Title>
              <p>{subtitle}</p>
              {this.renderSearchBox()}
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div
            className={styles.imageContainer}
            ref={(element) => (this.imageMobile = element)}
          >
            {this.renderHeroImages('mobile')}
          </div>
          {isFrontPage && (
            <div className={cx(styles.buttonWrapper, 'centered-content')}>
              <Link
                className={buttonStyles.button}
                langPath={pageContext.langPath}
                queryParams={queryParams}
                to={'tour/flybus'}
              >
                {mobileButtons.version1}
              </Link>

              <Link
                className={buttonStyles.secondary}
                langPath={pageContext.langPath}
                queryParams={queryParams}
                to={'tours-activities'}
              >
                {mobileButtons.version2}
              </Link>
            </div>
          )}
        </Mobile>
        {/* this.state.open ? (
          <div className={styles.banner}>
            <div className={styles.bannerContent}>
              <div className={styles.left}>
                <span>
                  This is a new version of the Reykjavik Excursions website. If
                  you have any problems, you can view tours and book on our
                  current website:
                </span>
                <a
                  className={styles.agentButton}
                  href=' https://www.re.is/?utm_preview=.GTM-56FTV53_OPT-WX6R9.1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  www.re.is
                </a>
              </div>

              <div className={styles.right}>
                <img
                  src={whiteExitButton}
                  alt='exit'
                  className={styles.exit}
                  onClick={() => this.setState({ open: false })}
                />
              </div>
            </div>
          </div>
        ) : null */}
      </React.Fragment>
    );
  }

  renderSearchBox() {
    const { pageContext, searchBox } = this.props;
    const { categories, startDate, endDate, focusedInput, datePlaceholder } =
      this.state;
    const queryParams = this.getQueryParams();

    return (
      <div className={styles.searchContainer}>
        <div className={styles.search}>
          <div style={{ marginRight: 10 }}>
            <p className='dropdown-title'>
              {searchBox.frontPageSearchBoxWhenInput.inputLabel}
            </p>
            <BookingDropdown
              placeHolder={this.getDateText()}
              Icon={{ icon: Calendar, alt: 'Calendar' }}
              ref={(elem: HTMLDivElement) => (this.calendarDropdown = elem)}
              backgroundColor={true}
            >
              <div className='dropdown-calendar'>
                <DayPickerRangeController
                  initialVisibleMonth={null}
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={this.updateDateRange}
                  onFocusChange={this.updateDateFocus}
                  focusedInput={focusedInput}
                  onNextMonthClick={() => this.setState({ datePlaceholder })}
                  onPrevMonthClick={() => this.setState({ datePlaceholder })}
                  isOutsideRange={(day) =>
                    isInclusivelyBeforeDay(
                      day,
                      moment(new Date()).subtract(1, 'day')
                    )
                  }
                  minimumNights={0}
                />
                <button onClick={this.clearDateSelection}>
                  {searchBox.frontPageSearchBoxCalendarClear}
                </button>
              </div>
            </BookingDropdown>
          </div>

          <div style={{ marginLeft: 10 }}>
            <p className='dropdown-title'>
              {searchBox.frontPageSearchBoxTypeOfTourInputBox.inputLabel}
            </p>
            <BookingDropdown
              placeHolder={getSelectText(
                categories,
                searchBox.frontPageSearchBoxTypeOfTourInputBox.inputPlaceholder,
                searchBox.frontPageSearchBoxTypeOfTourInputBox.altInputLabel
              )}
              ref={(elem: HTMLDivElement) => (this.categoriesDropdown = elem)}
              backgroundColor={true}
              dropdownHeight='285px'
              maxWidth='100%'
            >
              <FilterSelect items={categories} select={this.selectCategory} />
            </BookingDropdown>
          </div>
        </div>
        <Link
          className='square-button'
          langPath={pageContext.langPath}
          queryParams={queryParams}
          to={'tours-activities'}
          style={{ zIndex: 1 }}
        >
          <p style={{ color: '$dark-gray' }}>
            {searchBox.frontPageSearchBoxSearchButton}
          </p>
          <Icon name='ARROWRIGHTWHITE' />
        </Link>
      </div>
    );
  }

  selectCategory = (index: number) => {
    const { categories } = this.state;
    categories[index].selected = !categories[index].selected;
    this.setState({ categories });
  };

  updateDateRange = (d: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const { startDate, endDate } = this.state;
    let end = d.endDate;
    if (startDate && endDate) {
      end = null;
    }
    this.setState({ startDate: d.startDate, endDate: end });
  };

  clearDateSelection = () => {
    this.setState({
      startDate: null,
      endDate: null,
      focusedInput: 'startDate',
    });
  };

  updateDateFocus = (focus: 'startDate' | 'endDate' | null) => {
    this.setState({ focusedInput: focus || 'startDate' });
  };

  updateAlertHeight = () => {
    if (this.alert) {
      const alertHeight = this.alert.clientHeight;
      this.setState({ alertHeight });
    }
    if (this.alertMobile) {
      const alertHeightMobile = this.alertMobile.clientHeight;
      this.setState({ alertHeightMobile });
    }
    if (this.imageMobile) {
      const imageHeightMobile = this.imageMobile.clientHeight;
      this.setState({ imageHeightMobile });
    }
  };

  getDateText = () => {
    const { startDate, endDate } = this.state;
    const { searchBox } = this.props;
    if (startDate && endDate && !startDate.isSame(endDate, 'day')) {
      return `${getCalendarDate(startDate)} - ${getCalendarDate(endDate)}`;
    }
    if (startDate) {
      return getCalendarDate(startDate);
    }
    return searchBox.frontPageSearchBoxWhenInput.inputPlaceholder;
  };

  getQueryParams = () => {
    const { categories, startDate, endDate } = this.state;

    const dateParams = getDateQueryParams(startDate, endDate, false);
    const typeParam = categories
      .filter((cat) => cat.selected)
      .map((cat) => `${filterParams.type}=${cat.slug}`)
      .join('&');
    const queryParams =
      dateParams || typeParam
        ? `?${dateParams}${dateParams && typeParam ? '&' : ''}${typeParam}`
        : '';
    return queryParams;
  };
}

export default withWindowSize(Hero);
