import React from 'react';

const ThirdParty = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='54.672'
    height='54.712'
    viewBox='0 0 54.672 54.712'
  >
    <style>
      {
        '.prefix__st1{fill:none;stroke:#2d8377;stroke-width:2.5;stroke-linecap:round;stroke-linejoin:round}'
      }
    </style>
    <path
      id='prefix__Path_39'
      d='M1.3 27.3C1.3 12.9 13 1.2 27.4 1.2s26.1 11.7 26.1 26.1c0 14.4-11.7 26.1-26.1 26.1 0 0 0 0 0 0-1.7 0-3.4-.2-5.1-.5C10 50.5 1.2 39.8 1.3 27.3z'
      fill='none'
      stroke='#afafaf'
      strokeWidth={2.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      className='prefix__st1'
      d='M38.9 28H14.3V14.8h24.6l5.6 6.6zM25.6 28h4.7v17.2h-4.7zM25.6 9.7h4.7v5.1h-4.7z'
    />
  </svg>
);

export default ThirdParty;
