import React from 'react';

import * as styles from './PrivateHero.module.scss';
import { IImage, IReducers } from '../../interfaces';
import { Image } from '../../components';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';
import Icon from '../Icon';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  title: string;
  subtitle: string;
  onNavClick: () => void;
  image?: IImage;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    privateToursContactUsNavigation: string;
  };
}
class PrivateHero extends React.Component<IProps & IWindowSizeProps> {
  render() {
    const { title, image, onNavClick, localeData } = this.props;
    return (
      <div className={styles.container}>
        <Image fluidImage={image} className={styles.image} />
        <div className={styles.content}>
          <div className='centered-content'>
            <h1>{title}</h1>
          </div>
        </div>
        <div className={styles.enquiryNav}>
          <button onClick={onNavClick} className={styles.navText}>
            {localeData.privateToursContactUsNavigation}
            <Icon name='ARROWSCROLLTO' />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  withWindowSize(
    ({
      title,
      subtitle,
      onNavClick,
      image,
      windowHeight,
      windowWidth,
      locale,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query PrivateHero {
            allContentfulTranslationsPage {
              edges {
                node {
                  node_locale
                  privateToursContactUsNavigation
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <PrivateHero
              localeData={
                data.allContentfulTranslationsPage.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              title={title}
              subtitle={subtitle}
              onNavClick={onNavClick}
              image={image}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              locale={locale}
            />
          );
        }}
      />
    )
  )
);
