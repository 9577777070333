import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as styles from './TourReviews.module.scss';
import { IReview, IReducers } from '../../interfaces';
import { OverflowText } from '../index';
import { windowSizes } from '../../constants';
import { star, tripAdvisor } from '../../icons';
import withWindowSize, { IWindowSizeProps } from '../../hocs/withWindowSize';
import { graphql, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  reviews: IReview[];
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: IReviewStrings;
}

interface IReviewStrings {
  reviewsHeader: string; // Reviews
  reviewsReviewed: string; // reviewed
}

function renderIcon(company?: string) {
  let icon;
  let alt;

  if (company === 'Tripadvisor') {
    icon = tripAdvisor;
    alt = 'Trip Advisor';
  }

  return icon && <img src={icon} alt={alt} />;
}

const TourReviews: React.FunctionComponent<IProps & IWindowSizeProps> = ({
  reviews,
  windowWidth,
  localeData,
}) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    dotsClass: cx('slick-dots', styles.dots),
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: windowSizes.widescreen,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: windowSizes.tablet,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <h2 className={cx('centered-content', 'element-title')}>
        {localeData.reviewsHeader}
      </h2>
      <Slider {...settings} className={styles.container}>
        {reviews.map((review) => {
          const stars = [];
          for (let i = 0; i < review.stars; i++) {
            stars.push(<img src={star} alt='Star' key={i} />);
          }
          return !review.mainText ? null : (
            <div className={styles.slide} key={review.id}>
              <div>
                <div className={styles.stars}>{stars}</div>
                <strong className={styles.title}>{`"${review.title}"`}</strong>
                {review.mainText && (
                  <OverflowText
                    text={review.mainText.mainText}
                    height={windowWidth < windowSizes.tablet ? 100 : 400}
                    gradientClassName={styles.gradient}
                  />
                )}
                <div className={styles.bottom}>
                  <div className={styles.icon}>
                    {renderIcon(review.reviewCompany)}
                  </div>
                  <div>
                    {review.reviewerName && (
                      <strong>{review.reviewerName}</strong>
                    )}
                    {review.reviewDate && (
                      <p>
                        {localeData.reviewsReviewed}{' '}
                        {moment(review.reviewDate).format('LL')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className={styles.backgroundBoxes}>
        <div className={styles.smallBox} />
        <div className={styles.bigBox} />
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  withWindowSize(
    ({
      reviews,
      windowWidth,
      windowHeight,
      locale,
    }: IExternalProps & IWindowSizeProps) => (
      <StaticQuery
        query={graphql`
          query TourReviews {
            allContentfulTranslationsPage {
              edges {
                node {
                  node_locale
                  reviewsHeader
                  reviewsReviewed
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <TourReviews
              localeData={
                data.allContentfulTranslationsPage.edges.filter(
                  (node: { node: { node_locale: string } }) => {
                    return node.node.node_locale === locale;
                  }
                )[0].node
              }
              reviews={reviews}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              locale={locale}
            />
          );
        }}
      />
    )
  )
);
