import React from 'react';
import { BookingDropdown } from '../../components';
import { Traveler } from '../../../icons';
import { Moment } from 'moment';
import {
  ISelectValue,
  ITranslationsTourBokingGuestDropDown,
} from '../../../interfaces';
import * as styles from './index.module.scss';

interface IProps {
  modalOpen: boolean;
  isValid: boolean;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  toggleModal(value: boolean): void;
  getTotalPersonCount(): number;
  getMinToBook(): number;
  triggerValid(value: boolean): void;
  text: ITranslationsTourBokingGuestDropDown;
}

const TourBookingGuestDropdown: React.FunctionComponent<IProps> = (props) => {
  const {
    modalOpen,
    toggleModal,
    getTotalPersonCount,
    getMinToBook,
    isValid,
    selectedDate,
    selectedTime,
    triggerValid,
    text,
  } = props;
  return (
    <div onClick={modalOpen ? undefined : () => toggleModal(true)}>
      <label className={styles.bookingLabel}>
        {text.tourBookingBoxNumberOfGuests.label}
      </label>
      <BookingDropdown
        Icon={{ icon: Traveler, alt: 'Guest icon' }}
        placeHolder={
          getTotalPersonCount() > 1
            ? getTotalPersonCount() +
              ' ' +
              text.tourBookingBoxNumberOfGuestsPlaceholders.plural
            : getTotalPersonCount() +
              ' ' +
              text.tourBookingBoxNumberOfGuestsPlaceholders.singular
        }
        isValid={
          (getTotalPersonCount() > 0 &&
            getMinToBook() <= getTotalPersonCount()) ||
          isValid
        }
        disabled={!selectedDate || !selectedTime}
        errorText={
          !isValid && getTotalPersonCount() < 1
            ? text.tourBookingBoxNumberOfGuests.labelErrorMessage
            : ''
        }
        checkForError={() => {
          if (!selectedDate || !selectedTime) {
            triggerValid(false);
          }
        }}
      >
        {null}
      </BookingDropdown>
    </div>
  );
};

export default TourBookingGuestDropdown;
