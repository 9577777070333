import React from 'react';

import * as styles from './TitleArea.module.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
}

const TitleArea: React.FunctionComponent<IProps> = ({ title, children }) => (
  <div className={styles.container}>
    <div className='centered-content'>
      <h1>{title}</h1>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
);

export default TitleArea;
