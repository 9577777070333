import React from 'react';
import cx from 'classnames';

import * as styles from './Input.module.scss';

interface IProps {
  title: string;
  name?: string;
  error?: boolean;
  errorText?: string;
  darkMode?: boolean;
  children: React.ReactNode;
}

const Input: React.FunctionComponent<IProps> = (props) => {
  const { title, name, error, errorText, darkMode, children } = props;
  return (
    <React.Fragment>
      <label
        className={cx(styles.title, {
          [styles.lightTitle]: !darkMode,
          [styles.required]: errorText,
        })}
        htmlFor={name}
      >
        {title}
      </label>
      {children}
      {error && errorText ? (
        <p className={styles.errorText}>{errorText}</p>
      ) : null}
    </React.Fragment>
  );
};

Input.defaultProps = {
  darkMode: false,
};

export default Input;
