import React from 'react';
import { Moment } from 'moment';

import {
  ISortedAvailability,
  ISelectValue,
  IRate,
  IBookableExtraState,
  ITranslationsPriceBreakdown,
} from '../../interfaces';
import { formatPrice, formatDate, isPriceCounter } from '../../utils';
import * as styles from './styles/TourPrices.module.scss';

//import useTranslation from '../../../hooks/useTranslation';
interface IProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  bookableExtraCounter: IBookableExtraState | null;
  selectedTime: ISelectValue | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;

  getRateIndex(Rates: IRate[]): number;
  getPersonCount(personType: string): number;
  localeData: ITranslationsPriceBreakdown;
}

// not used ?
/* interface ITourPricing {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  getRateIndex(Rates: IRate[]): number;
} */

export const TourPrice: React.FunctionComponent<IProps> = (props) => {
  const {
    availability,
    selectedDate,
    selectedTime,
    getRateIndex,
    getPersonCount,
    localeData,
  } = props;

  if (
    !!selectedDate &&
    !!selectedTime &&
    !!availability &&
    !!availability[formatDate(selectedDate)] &&
    !!availability[formatDate(selectedDate)][selectedTime.value] &&
    !availability[formatDate(selectedDate)][selectedTime.value].SoldOut
  ) {
    const { Rates } =
      availability[formatDate(selectedDate)][selectedTime.value];
    const rate = getRateIndex(Rates);
    let totalGuests = 0;
    Rates[rate].PriceCategories.forEach(
      (price) => (totalGuests += getPersonCount(price.Name))
    );

    return (
      <div className={styles.container}>
        {totalGuests > 0 ? (
          <React.Fragment>
            {Rates[rate].PriceCategories.map((price, index) => {
              const personCount = getPersonCount(price.Name);
              if (
                personCount > 0 &&
                !!price &&
                !!price.Price &&
                (!!price.Price.Amount || price.Price.Amount === 0)
              ) {
                return (
                  <div key={index} className={styles.priceItem}>
                    <div>
                      <p key={price.Id}>
                        {personCount} x {price.Name}
                      </p>
                    </div>
                    <div>
                      <p className={styles.noWrap}>
                        {formatPrice(
                          personCount * price.Price.Amount,
                          price.Price.Currency
                        )}{' '}
                        {price.Price.Currency}
                      </p>
                    </div>
                  </div>
                );
              }
              return null;
            })}
            <TourPickupPrice {...props} />
            <TourDropOffPrice {...props} />
            <TourExtraPrice {...props} />
          </React.Fragment>
        ) : (
          <p style={{ paddingBottom: 10 }}>{localeData.noGuests}</p>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <p style={{ paddingBottom: 10 }}>{localeData.notAvailable}</p>
      </div>
    );
  }
};

/* const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};
 */
/* export TourPrice = connect(mapStateToProps)(
  ({
    availability,
    selectedDate,
    selectedTime,
    getRateIndex,
    getPersonCount,
    bookableExtraCounter,
    selectedDropoff,
    selectedPickup,
    locale
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourPriceQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                notAvailable
                noGuests
                pickUp
                dropOff
                extras
              }
            }
          }
        }
      `}
      render={data => (
        <TourPrice
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          availability={availability}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          getRateIndex={getRateIndex}
          getPersonCount={getPersonCount}
          bookableExtraCounter={bookableExtraCounter}
          selectedDropoff={selectedDropoff}
          selectedPickup={selectedPickup}
          locale={locale}
        />
      )}
    />
  )
); */

export const TourPickupPrice: React.FunctionComponent<IProps> = (props) => {
  /*   const { t } = useTranslation();
   */ const {
    availability,
    selectedDate,
    selectedTime,
    selectedPickup,
    getRateIndex,
    getPersonCount,
    localeData,
  } = props;
  if (
    !!selectedDate &&
    !!selectedTime &&
    !!availability &&
    !!selectedPickup &&
    !!selectedPickup.value &&
    !!availability[formatDate(selectedDate)][selectedTime.value]
  ) {
    const { Rates } =
      availability[formatDate(selectedDate)][selectedTime.value];
    const rateIndex = getRateIndex(Rates);
    if (Rates[rateIndex].PickupPricingType === 'PRICED_SEPARATELY') {
      return (
        <div className={styles.category}>
          <div>
            <strong>{localeData.pickUp}</strong>
          </div>
          {Rates[rateIndex].PriceCategories.map((price) => {
            const personCount = getPersonCount(price.Name);
            if (
              personCount > 0 &&
              !!price &&
              !!price.PickupPrice &&
              (!!price.PickupPrice.Amount || price.PickupPrice.Amount === 0)
            ) {
              return (
                <div
                  key={price.PickupPrice.Amount + price.PickupPrice.Currency}
                  className={styles.priceItem}
                >
                  <div>
                    <p key={price.Id}>
                      {personCount} x {price.Name}
                    </p>
                  </div>
                  <div>
                    <p className={styles.noWrap}>
                      {formatPrice(
                        personCount * price.PickupPrice.Amount,
                        price.PickupPrice.Currency
                      )}{' '}
                      {price.PickupPrice.Currency}
                    </p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
  }
  return null;
};

export const TourDropOffPrice: React.FunctionComponent<IProps> = (props) => {
  /*   const { t } = useTranslation();
   */ const {
    availability,
    selectedDate,
    selectedTime,
    selectedDropoff,
    getRateIndex,
    getPersonCount,
    localeData,
  } = props;
  if (
    !!selectedDate &&
    !!selectedTime &&
    !!availability &&
    !!selectedDropoff &&
    !!selectedDropoff.value &&
    !!availability[formatDate(selectedDate)][selectedTime.value]
  ) {
    const { Rates } =
      availability[formatDate(selectedDate)][selectedTime.value];
    const rateIndex = getRateIndex(Rates);
    if (Rates[rateIndex].DropoffPricingType === 'PRICED_SEPARATELY') {
      return (
        <div className={styles.category}>
          <div>
            <strong>{localeData.dropOff}</strong>
          </div>
          {Rates[rateIndex].PriceCategories.map((price) => {
            const personCount = getPersonCount(price.Name);
            if (
              personCount > 0 &&
              !!price &&
              !!price.DropoffPrice &&
              (!!price.DropoffPrice.Amount || price.DropoffPrice.Amount === 0)
            ) {
              return (
                <div
                  key={price.DropoffPrice.Amount + price.DropoffPrice.Currency}
                  className={styles.priceItem}
                >
                  <div>
                    <p key={price.Id}>
                      {personCount} x {price.Name}
                    </p>
                  </div>
                  <div>
                    <p className={styles.noWrap}>
                      {formatPrice(
                        personCount * price.DropoffPrice.Amount,
                        price.DropoffPrice.Currency
                      )}{' '}
                      {price.DropoffPrice.Currency}
                    </p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
  }
  return null;
};

// I dont think this is used anymore
/* export const TourTransportPricing: React.FunctionComponent<ITourPricing> = props => {
  const { availability, selectedDate, selectedTime, getRateIndex } = props;
  const pricings = [];
  if (
    !!selectedDate &&
    !!selectedTime &&
    !!availability &&
    !!availability[formatDate(selectedDate)][selectedTime.value]
  ) {
    const { Rates } = availability[formatDate(selectedDate)][
      selectedTime.value
    ];

    const rate = getRateIndex(Rates);
    if (Rates[rate].PickupSelectionType !== 'UNAVAILABLE') {
      if (Rates[rate].PickupPricingType === 'INCLUDED_IN_PRICE') {
        pricings.push(
          <div>
            <label>{'.pickUpIncluded'}</label>
          </div>
        );
      } else if (Rates[rate].PickupPricingType === 'PRICED_SEPARATELY') {
        pricings.push(
          <div>
            <label>{'.pickUpPriceFrom'}</label>
            {Rates[rate].PriceCategories.map(price => {
              return (
                <div
                  key={price.PickupPrice.Amount + price.PickupPrice.Currency}
                >
                  <p key={price.Id}>
                    {price.Name}:{' '}
                    {formatPrice(
                      price.PickupPrice.Amount,
                      price.PickupPrice.Currency
                    )}{' '}
                    {price.PickupPrice.Currency}
                  </p>
                </div>
              );
            })}
          </div>
        );
      }
    }

    if (Rates[rate].DropoffSelectionType !== 'UNAVAILABLE') {
      if (Rates[rate].DropoffPricingType === 'INCLUDED_IN_PRICE') {
        pricings.push(
          <div>
            <label>{'.dropOffIncluded'}</label>
          </div>
        );
      } else if (Rates[rate].DropoffPricingType === 'PRICED_SEPARATELY') {
        pricings.push(
          <div>
            <label>{'.dropOffPriceFrom'}</label>
            {Rates[rate].PriceCategories.map(price => {
              return (
                <div
                  key={price.DropoffPrice.Amount + price.DropoffPrice.Currency}
                >
                  <p key={price.Id}>
                    {price.Name}:{' '}
                    {formatPrice(
                      price.DropoffPrice.Amount,
                      price.DropoffPrice.Currency
                    )}{' '}
                    {price.DropoffPrice.Currency}
                  </p>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
  return <React.Fragment>{pricings}</React.Fragment>;
}; */

export const TourExtraPrice: React.FunctionComponent<IProps> = (props) => {
  const { bookableExtraCounter, localeData } = props;
  if (bookableExtraCounter) {
    let extraCounter = 0;
    Object.keys(bookableExtraCounter).map((key) => {
      const extra = bookableExtraCounter[key];
      if (bookableExtraCounter[key].selected) {
        if (!!extra && isPriceCounter(extra.counter)) {
          const priceCounter = extra.counter;
          extraCounter += Object.keys(extra.counter)
            .map((s) => priceCounter[s].count)
            .reduce((acc, num) => {
              acc += num;
              return acc;
            });
        } else if (!!extra && !isPriceCounter(extra.counter)) {
          extraCounter += extra.counter;
        }
      }
    });
    return (
      <React.Fragment>
        {extraCounter > 0 ? (
          <div className={styles.category}>
            <div>
              <strong>{localeData.extras}</strong>
            </div>{' '}
            {Object.keys(bookableExtraCounter).map((key) => {
              if (bookableExtraCounter[key]) {
                const extra = bookableExtraCounter[key];
                if (!!extra && extra.PricingType === 'PRICED_SEPARATELY') {
                  if (isPriceCounter(extra.counter)) {
                    const counter = extra.counter;
                    const priceCounter = Object.keys(counter).map(
                      (countKey) => {
                        const thisCounter = counter[countKey];
                        const prices = extra.Prices
                          ? extra.Prices.find(
                              (i) =>
                                i.PriceCategoryId ===
                                thisCounter.PriceCategoryId
                            )
                          : null;
                        return { ...prices, ...counter[countKey] };
                      }
                    );
                    return priceCounter.map((count) => {
                      if (count.count > 0) {
                        return (
                          <div
                            key={extra.Title ? extra.Title : ''}
                            className={styles.priceItem}
                          >
                            <div>
                              <p>
                                {count.count} x {count.Name + ' ' + extra.Title}
                              </p>
                            </div>
                            <p className={styles.noWrap}>
                              {count.Amount * count.count} {count.Currency}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    });
                  } else if (!isPriceCounter(extra.counter) && !!extra.Prices) {
                    if (extra.Prices.length === 1) {
                      const prices = {
                        ...{ count: extra.counter },
                        ...extra.Prices[0],
                      };
                      if (prices.count > 0) {
                        return (
                          <div
                            key={extra.Title ? extra.Title : ''}
                            className={styles.priceItem}
                          >
                            <div>
                              <p>
                                {prices.count} x {extra.Title}
                              </p>
                            </div>
                            <div>
                              <p className={styles.noWrap}>
                                {prices.Amount * prices.count} {prices.Currency}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    }
                  }
                }
              }
              return null;
            })}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
  return <div />;
};
