import withProvider from './src/redux/withProvider';
import './src/styles/fonts.scss';
import './src/styles/global.scss';
// import LogRocket from 'logrocket';
export const wrapRootElement = withProvider;

const transitionDelay = 300;

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay
    )
  }
  return false
}

// To do. Add logrocket in production
// export const onClientEntry = () => {
//   LogRocket.init(process.env.GATSBY_LOGROCKET_API_KEY);
// };