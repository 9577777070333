// does not need to be translated
export const dropdowns: { [key: string]: string } = {
  orderBy: 'orderBy',
  date: 'date',
  type: 'type',
  groupSize: 'groupSize',
  price: 'price',
  duration: 'duration',
  language: 'language'
};
// does not need to be translated ?
export const filterParams: { [key: string]: string } = {
  orderBy: 'sort',
  start: 'start',
  end: 'end',
  type: 'type',
  groupSize: 'groupSize',
  priceMin: 'priceMin',
  priceMax: 'priceMax',
  duration: 'duration',
  language: 'language',
  search: 'search'
};

export const orderByOptions = [
  { key: 'popular', label: 'Most popular' },
  { key: 'price-lh', label: 'Price (Low to High)' },
  { key: 'price-hl', label: 'Price (High to Low)' },
  { key: 'duration-sl', label: 'Duration (Short to Long)' },
  { key: 'duration-ls', label: 'Duration (Long to Short)' }
];

const hour = 60;
const day = hour * 24;
export const durationOptions = [
  { label: '1/2 day', max: day / 4 },
  { label: '1 day', max: day },
  { label: '2 days', max: day * 2 },
  { label: '3 days +', max: null }
];

export const languageOptions = [
  'English',
  'Danish',
  'French',
  'German',
  'Spanish',
  'Swedish'
];

export const seasonOptions = ['Winter', 'Summer', 'Autumn', 'Fall'];

export const groupSizes: { [size: string]: string } = {
  'private-tour': 'Private Tours',
  'small-group': 'Small Groups'
};
