import React, { useState, useRef } from 'react';
import BorderDropdown from '../BorderDropdown/BorderDropdown';
import * as styles from './index.module.scss';
import { searchDark } from '../../icons';

interface IProps {
  searchValue: string;
  text: {
    inputLabel: string;
    inputPlaceholder: string;
  };
  updateSearch(event: React.ChangeEvent<HTMLInputElement>): void;
  handleEnter(e: React.KeyboardEvent<HTMLInputElement>): void;
  onSearchClick(): void;
}

const SearchDropdown: React.FunctionComponent<IProps> = ({
  searchValue,
  text,
  updateSearch,
  handleEnter,
  onSearchClick,
}) => {
  const [closeSearch, setCloseSearch] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);

  const handleEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      setCloseSearch(!closeSearch);
    }
    handleEnter(event);
  };

  const onClick = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
    onSearchClick();
  };

  const visible = (
    <div className={styles.visibleStyle}>
      <div onClick={onClick} style={{ height: '100%' }} id='Search-Button'>
        <img
          src={searchDark}
          alt='Search'
          id='Search'
          className={styles.search}
        />
      </div>
    </div>
  );

  return (
    <BorderDropdown
      visible={visible}
      component='searchDropdown'
      close={closeSearch}
    >
      <div className={styles.container}>
        <label htmlFor='search' className={styles.label}>
          {text.inputLabel}
        </label>
        <input
          type='text'
          value={searchValue}
          onChange={updateSearch}
          className={styles.searchInput}
          onKeyDown={(e) => handleEnterPressed(e)}
          data-cy='Search-Input'
          aria-label='Search'
          placeholder={text.inputPlaceholder}
          name='search'
          ref={inputEl}
          id='search'
        />
      </div>
    </BorderDropdown>
  );
};

export default SearchDropdown;
