import React from 'react';

export const Calendar = () => (
  <svg
    id='calendar-60'
    xmlns='http://www.w3.org/2000/svg'
    width='15.5'
    height='15.5'
    viewBox='0 0 15.5 15.5'
  >
    <path
      id='Path_18'
      data-name='Path 18'
      d='M15.016,1.453H12.594V.484A.484.484,0,0,0,12.109,0H10.656a.484.484,0,0,0-.484.484v.969H5.328V.484A.484.484,0,0,0,4.844,0H3.391a.484.484,0,0,0-.484.484v.969H.484A.484.484,0,0,0,0,1.938V15.016a.484.484,0,0,0,.484.484H15.016a.484.484,0,0,0,.484-.484V1.938A.484.484,0,0,0,15.016,1.453Zm-4.844.969h2.422v.969H10.172Zm-7.266,0H5.328v.969H2.906ZM13.613,13.887H2.028V6.17H13.613Z'
    />
  </svg>
);
export default Calendar;
