import React, { useState, useRef, useEffect } from 'react';
import BorderDropdown from '../BorderDropdown/BorderDropdown';
import { ILink } from '../../interfaces';
import { Link } from '../../components';
import * as styles from './index.module.scss';
import cx from 'classnames';
import Icon from '../Icon';

interface IProps {
  nameOfLink: string | undefined;
  links: ILink[] | undefined;
  active: boolean | null;
  light: boolean;
  langPath: string;
}

const ToursDropdown: React.FunctionComponent<IProps> = ({
  nameOfLink,
  links,
  light,
  active,
  langPath,
}) => {
  const [closeTours, setCloseTours] = useState(false);
  const [toggle, setToggle] = useState(false);
  const divRef = useRef(null);

  const handleClickOutsideSearch = (event: MouseEvent) => {
    const divConstRef: any = divRef.current;
    if (divRef.current) {
      if (!divConstRef.contains(event.target) && toggle) {
        setToggle(!toggle);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearch);
  }, [toggle]);
  const handleOnClick = () => {
    setCloseTours(!closeTours);
  };

  const visible = (
    <div
      className={cx(styles.visibleStyle, styles.defaultColor)}
      onClick={() => setToggle(!toggle)}
      ref={divRef}
    >
      {nameOfLink}
      <div className={styles.navIcon}>
        <Icon name={toggle ? 'NAVUP' : 'NAVDOWN'} />
      </div>
    </div>
  );

  const toggleIcon = () => {
    setToggle(!toggle);
  };
  return (
    <BorderDropdown
      visible={visible}
      component='toursDropdown'
      close={closeTours}
      toggleToursIcon={toggleIcon}
    >
      <div className={styles.container}>
        <ul>
          {links
            ? links.map((link) => (
                <li
                  onClick={() => handleOnClick()}
                  key={link.id}
                  className={styles.listElement}
                >
                  <Link
                    className={cx(light ? styles.linkLight : styles.linkDark, {
                      [styles.activeLink]: active,
                    })}
                    langPath={langPath}
                    to={link.page.slug}
                  >
                    {link.title}
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </div>
    </BorderDropdown>
  );
};

export default ToursDropdown;
