import React from 'react';
import { Provider } from 'react-redux';

import createStore from './createStore';

const store = createStore();

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: any) => (
  <Provider store={store}>{element}</Provider>
);
