import React from 'react';

import Input from './Input';
import InputDropdown from './InputDropdown';
import * as styles from './SelectInput.module.scss';

interface IProps {
  title: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
  error?: boolean;
  errorText?: string;
  darkMode?: boolean;
  open: boolean;
  toggleOpen: () => void;
}

const onSelect = (opt: string, props: IProps) => {
  const { onChange, toggleOpen } = props;
  onChange(opt);
  toggleOpen();
};

const SelectInput: React.FunctionComponent<IProps> = (props) => {
  const {
    title,
    value,
    options,
    error,
    errorText,
    darkMode,
    open,
    toggleOpen,
  } = props;

  return (
    <Input
      title={title}
      error={error}
      errorText={errorText}
      darkMode={darkMode}
    >
      <InputDropdown
        open={open}
        toggleOpen={toggleOpen}
        selectedText={value}
        error={error}
        title={title}
      >
        {options &&
          options.map((opt) => (
            <button
              key={opt}
              onClick={() => onSelect(opt, props)}
              className={styles.option}
            >
              {opt}
            </button>
          ))}
      </InputDropdown>
    </Input>
  );
};

export default SelectInput;
