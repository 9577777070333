import React from 'react';
import * as styles from './index.module.scss';
import { ITransportPersonCounter } from '../../../interfaces';
import SubractOrAdd from '../SubtractOrAdd';
import NumberInputValue from '../NumberInputValue';

type AddOrRemove = 'addOne' | 'removeOne';

interface IProps {
  personCounter: ITransportPersonCounter | null;
  countKey: string;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
}

const TransportNumberInput: React.FunctionComponent<IProps> = ({
  personCounter,
  changePersonCounter,
  countKey,
}) => {
  const subtract = () => {
    changePersonCounter('removeOne', countKey);
  };

  const add = () => {
    changePersonCounter('addOne', countKey);
  };
  if (personCounter && personCounter[countKey]) {
    return (
      <div className={styles.buttonContainer}>
        <SubractOrAdd subtractOrAdd='subtract' onClick={subtract} />
        <NumberInputValue value={personCounter[countKey].Count} />
        <SubractOrAdd subtractOrAdd='add' onClick={add} />
      </div>
    );
  }
  return null;
};

export default TransportNumberInput;
