import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IReducers } from '../../interfaces';
import { connect } from 'react-redux';
import { changeLocale } from '../../redux/actions/translationActions';
import BorderDropdown from '../BorderDropdown/BorderDropdown';
import Link from '../Link/Link';
import * as styles from './index.module.scss';
import Icon from '../Icon';

interface IProps {
  locale: string;
  langaugeSwithcerData: ILangSwitcher;
  slug: string;
  query: string;
  changeLocale(locale: string, lang: string): void;
}

interface ILangSwitcher {
  headerlanguageSwitcher: IOption[];
}

interface IOption {
  iconName: string;
  text: string;
  locale: string;
  lang: string;
  langPath: string;
}

const LanguageDropdown: React.FunctionComponent<IProps> = ({
  locale,
  langaugeSwithcerData,
  changeLocale,
  slug,
  query,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [currentLangIcon, setCurrentLangIcon] = useState('');
  const divRefV = useRef<HTMLDivElement>(null);

  const handleUserKeyPress = useCallback(
    (event) => {
      if (divRefV.current) {
        if (!divRefV.current.contains(event.target) && toggle) {
          setToggle(!toggle);
        }
      }
    },
    [toggle]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleUserKeyPress);
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (locale && langaugeSwithcerData) {
      langaugeSwithcerData.headerlanguageSwitcher.forEach((item: IOption) => {
        if (item.locale === locale) {
          setCurrentLangIcon(item.iconName);
        }
      });
    }
  }, [locale, langaugeSwithcerData]);

  const visible = (
    <div
      className={styles.visibleStyle}
      onClick={() => setToggle(!toggle)}
      ref={divRefV}
    >
      <span className={styles.flagIcon}>
        <Icon name={currentLangIcon} />
      </span>
      <div className={styles.arrow}>
        <Icon name={toggle ? 'NAVUP' : 'NAVDOWN'} />
      </div>
    </div>
  );

  const changeLanguage = (item: IOption) => {
    setDropDownOpen(!dropdownOpen);
    changeLocale(item.locale, item.lang);
  };
  // Remove '/' from slug if present to prevent double '//' in <Link> component
  const slicedSlug = slug.charAt(0) === '/' ? slug.slice(1) : slug;
  let slicedSlugIcelandair = slicedSlug;
  if (query) {
    slicedSlugIcelandair = slicedSlug + query;
  }
  return (
    <BorderDropdown
      visible={visible}
      openCurrency={dropdownOpen}
      component='flagDropdown'
      close={dropdownOpen}
    >
      {langaugeSwithcerData.headerlanguageSwitcher.map((option: IOption) => (
        <Link
          langPath={option?.langPath || ''}
          to={slicedSlugIcelandair ? slicedSlugIcelandair : slicedSlug}
          key={option.iconName}
        >
          <button
            key={option.text}
            onClick={() => changeLanguage(option)}
            className={styles.currencyButton}
          >
            <div className={styles.labelContainer}>
              <div className={styles.item}>
                <Icon name={option.iconName} />
              </div>
              <div className={styles.item}>
                <p>{option.text}</p>
              </div>
            </div>
          </button>
        </Link>
      ))}
    </BorderDropdown>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps, { changeLocale })(LanguageDropdown);
