import React from 'react';
import cx from 'classnames';

import {
  ITourDetails,
  IProduct,
  ITransport,
  // IReducers,
  ITranslationsTourMainStrings,
} from '../../interfaces';
import * as styles from './TourMain.module.scss';
import { Image, OverflowText } from '../../components';
// import { StaticQuery, graphql } from 'gatsby';
// import { connect } from 'react-redux';

interface IProps {
  contentfulTour: ITourDetails;
  product: IProduct | null;
  transport?: ITransport | null;
  productLoaded: boolean;
  imageLine?: boolean;
  strings: ITranslationsTourMainStrings;
  // locale: string;
}

const TEXT_HEIGHT = 465;

function renderMainText(props: IProps) {
  const { contentfulTour, product, productLoaded, transport, strings } = props;
  const { mainText } = contentfulTour;
  let text =
    mainText && mainText.mainText.trim().length > 0
      ? mainText.mainText
      : undefined;

  if (!text) {
    if (product && product.Description) {
      text = product.Description;
    } else if (transport && transport.Description) {
      text = transport.Description;
    } else {
      if (!productLoaded) {
        text = strings.loading;
      } else {
        text = strings.notAvailable;
      }
    }
  }

  return (
    <OverflowText
      text={text}
      height={TEXT_HEIGHT}
      className={styles.textContainer}
    />
  );
}

function renderMainImage(props: IProps) {
  const { contentfulTour, product, productLoaded, imageLine } = props;
  const { secondaryImage } = contentfulTour;

  const productPhoto =
    product && product.Photos && product.Photos.length > 1
      ? product.Photos[1]
      : null;

  const imgAvailable = secondaryImage || productPhoto;

  return (
    <div
      data-aos='fade-left'
      className={cx(styles.imageContainer, { [styles.noImage]: !imgAvailable })}
    >
      <Image
        fluidImage={secondaryImage}
        productPhoto={productPhoto}
        loadingProduct={!productLoaded}
        className={styles.image}
      />
      {imageLine && imgAvailable && (
        <div data-aos='fade-right' className={styles.imageLine} />
      )}
    </div>
  );
}

const TourMain: React.FunctionComponent<IProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={cx('centered-content', styles.main)}>
        {renderMainText(props)}
        <div className={styles.space} />
        {renderMainImage(props)}
      </div>
    </div>
  );
};

TourMain.defaultProps = {
  imageLine: true,
};
export default TourMain;

/* const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    contentfulTour,
    product,
    productLoaded,
    imageLine,
    locale,
    strings
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourMainQuery {
          allContentfulTranslationsPage {
            edges {
              node {
                node_locale
                tourDescriptionLoading
                tourDescriptionNotAvailable
              }
            }
          }
        }
      `}
      render={data => (
        <TourMain
          localeData={
            data.allContentfulTranslationsPage.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          product={product}
          contentfulTour={contentfulTour}
          productLoaded={productLoaded}
          imageLine={imageLine}
          locale={locale}
          strings={strings}
        />
      )}
    />
  )
); */
