import React from 'react';

export const Wifi = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' enableBackground='new 0 0 50 50' xmlSpace='preserve'>
    <style>{'.st7_icon{fill:#da7d6d}'}</style>
    <g id='Layer_1'>
      <path
        d='M46.6 48.6H3.4c-1 0-1.9-.8-1.9-1.9V3.5c0-1 .8-1.9 1.9-1.9h43.3c1 0 1.9.8 1.9 1.9v43.3c-.1 1-.9 1.8-2 1.8z'
        style={{
          fill: 'none',
          stroke: '#e58170',
          strokeWidth: 1.6626,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className='st7_icon'
        d='M24.6 30.6c-1.8 0-3.4 1.6-3.4 3.5s1.5 3.5 3.4 3.5S28 36 28 34.1c-.1-1.9-1.6-3.5-3.4-3.5M24.6 24.7c-2.8 0-5.3 1.2-7.2 3.3-.1.2-.1.4 0 .5l1.4 1.5c.2.2.4.2.5 0 1.3-1.6 3.2-2.5 5.2-2.5s3.9.9 5.2 2.5c.2.2.4.2.5 0l1.4-1.5c.2-.2.2-.4 0-.5-1.7-2.2-4.3-3.3-7-3.3z'
      />
      <path
        className='st7_icon'
        d='M34.5 25.8c-.2.2-.4.2-.5 0-2.5-2.7-5.8-4.2-9.4-4.2-3.6 0-7 1.5-9.4 4.2-.2.2-.4.2-.5 0l-1.4-1.5c-.2-.2-.2-.4 0-.5 3-3.2 7.1-5.1 11.4-5.1s8.4 1.8 11.4 5.1c.2.2.1.4 0 .5l-1.6 1.5z'
      />
      <path
        className='st7_icon'
        d='M38.2 21.6c-.2.2-.4.2-.5 0-3.4-3.8-8.2-5.9-13.2-5.9s-9.7 2.1-13.2 5.9c-.2.2-.4.2-.5 0l-1.5-1.5c-.2-.2-.2-.4 0-.5 3.9-4.3 9.5-6.7 15.1-6.7s11.2 2.5 15.1 6.7c.2.2.2.4 0 .5l-1.3 1.5z'
      />
    </g>
  </svg>
);

export default Wifi;
