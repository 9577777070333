import React from 'react';
import * as styles from './index.module.scss';
import { IPersonCounter, ISelectValue } from '../../../interfaces';
import NumberInput from '../NumberInput/index';
import CounterNames from '../CounterNames/index';
import { Moment } from 'moment';

interface IProps {
  personCounter: IPersonCounter;
  personKey: string;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
  setErrorDate(dateBool: boolean): void;
  setErrorTime(timeBool: boolean): void;
}
type AddOrRemove = 'addOne' | 'removeOne';
const TourCountPerson: React.FunctionComponent<IProps> = ({
  personCounter,
  personKey,
  changePersonCount,
  selectedDate,
  setErrorDate,
  selectedTime,
  setErrorTime,
}) => {
  const changePersonCounter = (addOrSubtrack: string) => {
    if (selectedDate && selectedTime) {
      if (addOrSubtrack === 'addOne') {
        setErrorDate(false);
        setErrorTime(false);
        changePersonCount('addOne', personKey, personCounter);
      } else {
        setErrorDate(false);
        setErrorTime(false);
        changePersonCount('removeOne', personKey, personCounter);
      }
    } else {
      if (!selectedDate) {
        setErrorDate(true);
      }
      if (!selectedTime) {
        setErrorTime(true);
      }
    }
  };

  return (
    <div key={personCounter[personKey].Id} className={styles.container}>
      <CounterNames personCounter={personCounter} counterKey={personKey} />
      <div className={styles.buttonContainer}>
        <NumberInput
          value={personCounter[personKey].count}
          add={() => changePersonCounter('addOne')}
          subtract={() => changePersonCounter('removeOne')}
        />
      </div>
    </div>
  );
};

export default TourCountPerson;
