import React from 'react';
import { Moment } from 'moment';

import {
  ISortedAvailability,
  ISelectValue,
  IPersonCounter,
  IRate,
  IProductPrice,
  ICurrencyOption,
  IBookableExtraState,
} from '../../interfaces';
import { formatPrice, formatDate, isPriceCounter } from '../../utils';
import * as styles from './styles/TourPrices.module.scss';

interface IProps {
  bookableExtraCounter: IBookableExtraState | null;
  currency: ICurrencyOption;
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  personCounter: IPersonCounter | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  title: string;

  getRateIndex(Rates: IRate[]): number;
}

// lodash sum replacement
const sum = (arr: number[]) =>
  arr.reduce((acc, num) => {
    acc += num;
    return acc;
  }, 0);

function getBookableExtraPrice(
  bookableExtraCounter: IBookableExtraState | null
) {
  if (bookableExtraCounter) {
    const price = Object.keys(bookableExtraCounter).map((key) => {
      if (
        !!bookableExtraCounter[key] &&
        !!bookableExtraCounter[key].counter &&
        bookableExtraCounter[key].selected
      ) {
        const extra = bookableExtraCounter[key];
        const counter = bookableExtraCounter[key].counter;
        if (isPriceCounter(counter)) {
          return sum(
            Object.keys(counter).map((counterKey) => {
              if (
                !!counter &&
                !!counter[counterKey] &&
                counter[counterKey].count > 0
              ) {
                return counter[counterKey].count * counter[counterKey].Amount;
              }
              return 0;
            })
          );
        } else if (!!extra && extra.PricingType === 'INCLUDED_IN_PRICE') {
          return 0;
        } else if (!!extra && !!extra.Prices) {
          return sum(extra.Prices.map((p) => p.Amount * counter));
        } else {
          return 0;
        }
      }
      return 0;
    });
    return sum(price);
  } else {
    return 0;
  }
}
const TotalPrice: React.FunctionComponent<IProps> = (props) => {
  const {
    currency,
    availability,
    selectedTime,
    selectedDate,
    personCounter,
    getRateIndex,
    selectedPickup,
    selectedDropoff,
    bookableExtraCounter,
    title,
  } = props;
  let totalPersonPrice = 0;
  let totalPickupPrice = 0;
  let totalDropoffPrice = 0;
  if (
    !!selectedDate &&
    !!selectedTime &&
    !!availability &&
    !!personCounter &&
    !!availability[formatDate(selectedDate)] &&
    !!availability[formatDate(selectedDate)][selectedTime.value]
  ) {
    const { Rates } =
      availability[formatDate(selectedDate)][selectedTime.value];
    const rateIndex = getRateIndex(Rates);
    if (Rates[rateIndex].PricedPerPerson) {
      Rates[rateIndex].PriceCategories.map((price: IProductPrice) => {
        Object.keys(personCounter).map((person) => {
          if (
            price.Name === person &&
            personCounter[price.Name] &&
            !!price.Price &&
            !!price.Price.Amount
          ) {
            totalPersonPrice =
              totalPersonPrice +
              price.Price.Amount * personCounter[price.Name].count;
          }
        });
      });
    }
    if (Rates[rateIndex].PickupPricingType === 'PRICED_SEPARATELY') {
      Rates[rateIndex].PriceCategories.map((price: IProductPrice) => {
        Object.keys(personCounter).map((person) => {
          if (
            price.Name === person &&
            personCounter[price.Name] &&
            !!price.PickupPrice &&
            !!price.PickupPrice.Amount
          ) {
            totalPickupPrice =
              totalPickupPrice +
              price.PickupPrice.Amount * personCounter[price.Name].count;
          }
        });
      });
    }
    if (Rates[rateIndex].DropoffPricingType === 'PRICED_SEPARATELY') {
      Rates[rateIndex].PriceCategories.map((price: IProductPrice) => {
        Object.keys(personCounter).map((person) => {
          if (
            price.Name === person &&
            personCounter[price.Name] &&
            !!price.DropoffPrice &&
            !!price.DropoffPrice &&
            !!price.DropoffPrice.Amount
          ) {
            totalDropoffPrice =
              totalDropoffPrice +
              price.DropoffPrice.Amount * personCounter[price.Name].count;
          }
        });
      });
    }
    if (!selectedPickup || (!!selectedPickup && !selectedPickup.value)) {
      totalPickupPrice = 0;
    }
    if (!selectedDropoff || (!!selectedDropoff && !selectedDropoff.value)) {
      totalDropoffPrice = 0;
    }
    const totalPrice =
      totalPersonPrice +
      totalPickupPrice +
      totalDropoffPrice +
      getBookableExtraPrice(bookableExtraCounter);

    return (
      <div className={styles.totalPrice}>
        <p>{title}</p>
        <p data-cy='Total-Price-And-Currency'>
          {formatPrice(totalPrice, currency.value)} {currency.label}
        </p>
      </div>
    );
  }

  return null;
};

export default TotalPrice;
