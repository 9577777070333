import React from 'react';
import cx from 'classnames';

import * as styles from './TourList.module.scss';
import { Icon, Desktop, Mobile, Title } from '../index';
import { ITourListItem, ICurrencyOption, IReducers } from '../../interfaces';
import { chunkTours } from '../../utils';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ProductCard from '../ProductCard';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  tours: ITourListItem[];
  loading: boolean;
  langPath: string;
  currency: ICurrencyOption;
  carousel?: boolean;
  locale: string;
  privateTours?: boolean;
}

interface IProps extends IExternalProps {
  localeData: {
    tourListTitle: {
      singular: string;
      plural: string;
    };
    tourListArrows: {
      version1: string;
      version2: string;
    };
  };
}

// const BlueLagoonBooking: React.FunctionComponent<IProps> = props => {
const TourList: React.FunctionComponent<IProps> = (props) => {
  const { tours, carousel, localeData } = props;
  const toursChunk = chunkTours(props.tours, 4);
  const toursChunkMobile = chunkTours(props.tours, 2);

  function slidesHelper(element: any, deskOrMobile: string) {
    const item: any = [];
    let deskMobileBool = true;
    if (deskOrMobile === 'desktop') {
      deskMobileBool = false;
    } else {
      deskMobileBool = true;
    }
    element.forEach((i: ITourListItem) => {
      {
        // TODO refactor these functions
        // There is no order for the functions where we don't get this error
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        item.push(renderTour(i, deskMobileBool, 'slider'));
      }
    });
    return item;
  }

  function slides(deskOrMobile: string) {
    const toursSlidesChunk = chunkTours(props.tours, 4);
    const toursSlidesChunkMobile = chunkTours(props.tours, 2);
    if (deskOrMobile === 'desktop') {
      return (
        <Slider>
          {toursSlidesChunk
            ? toursSlidesChunk.map((value, index) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        styles.container
                      )}
                      data-cy='Tour-List-Slides'
                    >
                      {slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    } else {
      return (
        <Slider className={styles.sliderMobile}>
          {toursSlidesChunkMobile
            ? toursSlidesChunkMobile.map((value, index) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        'is-variable',
                        'is-2',
                        'is-mobile',
                        styles.container
                      )}
                      data-cy='Tour-List-Slides'
                    >
                      {slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    }
  }

  function renderTour(tour: any, mobile: boolean, sliderOrNot: string) {
    const { loading } = props;
    const { contentfulTour } = tour;
    const { id } = contentfulTour;
    const { product, transportPrice } = tour;
    const price =
      product && product.DefaultPrice ? product.DefaultPrice : transportPrice;
    const discountPrice =
      product && product.DiscountPrice ? product.DiscountPrice : null;
    const DefaultPrices = {
      defaultPrice: price,
      discountPrice: discountPrice,
    };

    let desk = '';
    if (sliderOrNot === 'slider') {
      desk = 'is-3-desktop';
    } else {
      desk = 'is-6 is-3-desktop';
    }

    return (
      <div
        key={id}
        className={
          mobile
            ? cx('tour-item', 'column', 'is-half ', styles.tourItem)
            : cx('tour-item', 'column', desk, styles.tourItem)
        }
      >
        <ProductCard
          tour={contentfulTour}
          defaultPrices={DefaultPrices}
          loadingPrices={loading}
          currency={props.currency.value}
          langPath={props.langPath}
          privateTours={props.privateTours}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Mobile>
        {!carousel ? (
          <div
            className={cx(
              'columns',
              'is-multiline',
              'is-variable',
              'is-2',
              'is-mobile',
              styles.container
            )}
            data-cy='Tour-List'
          >
            {tours.map((tour) => renderTour(tour, true, 'not-slider'))}
          </div>
        ) : null}
        {carousel && toursChunkMobile.length <= 2 ? (
          <div
            className={cx(
              'columns',
              'is-multiline',
              'is-variable',
              'is-2',
              'is-mobile',
              styles.container
            )}
            data-cy='Tour-List-Carousel'
          >
            {tours.map((tour) => renderTour(tour, true, 'not-slider'))}
          </div>
        ) : null}
        {toursChunkMobile.length > 2 && carousel ? (
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={50}
            totalSlides={toursChunkMobile.length}
            dragEnabled={false}
            infinite={true}
          >
            <div
              className={styles.containerCarousel}
              data-cy='Tour-List-Carousel'
            >
              {slides('mobile')}
              {toursChunkMobile.length > 1 ? (
                <ButtonBack className={styles.buttonBack}>
                  <>
                    <Icon name='ARROWLEFT' />
                    <span>{localeData.tourListArrows.version2}</span>
                  </>
                </ButtonBack>
              ) : null}
              {toursChunkMobile.length > 1 ? (
                <ButtonNext className={styles.buttonNext}>
                  <>
                    <Icon name='ARROWRIGHT' />
                    <span>{localeData.tourListArrows.version1}</span>
                  </>
                </ButtonNext>
              ) : null}
            </div>
          </CarouselProvider>
        ) : null}
      </Mobile>
      <Desktop>
        {!carousel ? (
          <>
            <div className={styles.searchResultTitle}>
              <Title tag='h2' theme={['sizeDesktopMedium']}>
                {tours.length +
                  ' ' +
                  (tours.length > 1
                    ? localeData.tourListTitle.plural
                    : localeData.tourListTitle.singular)}
              </Title>
            </div>
            <div
              className={cx('columns', 'is-multiline', styles.container)}
              data-cy='Tour-List'
            >
              {tours.map((tour) => renderTour(tour, false, 'not-slider'))}
            </div>
          </>
        ) : null}

        {toursChunk.length === 1 && carousel ? (
          <div
            className={cx(
              'columns',
              'is-multiline',
              styles.container,
              styles.isCarousel,
              styles.extraMargin
            )}
            data-cy='Tour-List-Carousel'
          >
            {tours.map((tour) => renderTour(tour, false, 'not-slider'))}
          </div>
        ) : null}
        {toursChunk.length > 1 && carousel ? (
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={45}
            totalSlides={toursChunk.length}
            dragEnabled={false}
            infinite={true}
          >
            <div
              className={styles.containerCarousel}
              data-cy='Tour-List-Carousel'
            >
              {slides('desktop')}
              {toursChunk.length > 1 ? (
                <ButtonBack className={styles.buttonBack}>
                  <>
                    <Icon name='ARROWLEFT' />
                    <span>{localeData.tourListArrows.version2}</span>
                  </>
                </ButtonBack>
              ) : null}
              {toursChunk.length > 1 ? (
                <ButtonNext className={styles.buttonNext}>
                  <>
                    <Icon name='ARROWRIGHT' />
                    <span>{localeData.tourListArrows.version1}</span>
                  </>
                </ButtonNext>
              ) : null}
            </div>
          </CarouselProvider>
        ) : null}
      </Desktop>
    </React.Fragment>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    tours,
    loading,
    langPath,
    currency,
    carousel,
    locale,
    privateTours,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourList3 {
          allContentfulTranslationsVarious {
            edges {
              node {
                node_locale
                tourListTitle {
                  singular
                  plural
                }
                tourListArrows {
                  version1
                  version2
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <TourList
            localeData={
              data.allContentfulTranslationsVarious.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            tours={tours}
            loading={loading}
            langPath={langPath}
            currency={currency}
            carousel={carousel}
            locale={locale}
            privateTours={privateTours}
          />
        );
      }}
    />
  )
);
