import React from 'react';
import cx from 'classnames';
import * as styles from './FilterDropdown.module.scss';
import Icon from '../Icon';

interface IProps {
  open: boolean;
  selectedText: string;
  selected?: boolean;
  children: React.ReactNode;
  defaultButton: boolean;
  toggleOpen: () => void;
}

interface IState {
  optionsHeight: number;
  mouseDownOutside: boolean;
}

class FilterDropdown extends React.Component<IProps, IState> {
  readonly state = { optionsHeight: 0, mouseDownOutside: false };

  options: HTMLDivElement | null = null;
  dropdown: HTMLDivElement | null = null;

  componentDidMount() {
    this.updateOptionsHeight();
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  componentDidUpdate() {
    this.updateOptionsHeight();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  render() {
    const {
      open,
      toggleOpen,
      selectedText,
      selected,
      children,
      defaultButton,
    } = this.props;
    const { optionsHeight } = this.state;
    return (
      <div
        className={cx(styles.container, { [styles.open]: open })}
        ref={(elem) => (this.dropdown = elem)}
      >
        <button
          className={defaultButton ? styles.selected : styles.selectedMobile}
          onClick={toggleOpen}
        >
          <p>{selectedText}</p>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow}>
              <Icon name='ARROWDOWN' />
            </div>
          </div>
        </button>
        <div
          className={styles.options}
          style={{ height: open ? optionsHeight : 0 }}
        >
          <div ref={(elem) => (this.options = elem)}>{children}</div>
        </div>
        <div
          className={cx(
            styles.active,
            { [styles.activeOpen]: open && defaultButton },
            { [styles.activeSelected]: selected }
          )}
        />
      </div>
    );
  }

  handleMouseDown = (event: any) => {
    if (
      this.dropdown &&
      !this.dropdown.contains(event.target) &&
      this.props.open
    ) {
      this.setState({ mouseDownOutside: true });
    }
  };

  handleMouseUp = (event: any) => {
    let windowInnerWidth = 0;
    if (typeof window !== 'undefined') {
      windowInnerWidth = window.innerWidth;
    }
    const clientX = event.clientX;
    if (
      this.dropdown &&
      !this.dropdown.contains(event.target) &&
      this.props.open &&
      this.state.mouseDownOutside &&
      windowInnerWidth - clientX >= 17
    ) {
      this.props.toggleOpen();
    }
    this.setState({ mouseDownOutside: false });
  };

  updateOptionsHeight = () => {
    if (this.options) {
      const height = this.options.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

export default FilterDropdown;
