import React from 'react';
import cx from 'classnames';

import * as styles from './TextInput.module.scss';
import * as inputStyles from './Input.module.scss';
import Input from './Input';

interface IProps {
  title: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  type?: 'text' | 'textarea' | 'email' | 'date' | 'number';
  name?: string;
  error?: boolean;
  errorText?: string;
  max?: number;
  darkMode?: boolean;
}

function renderInput({
  type,
  value,
  onChange,
  name,
  max,
  error,
  darkMode,
}: IProps) {
  const inputStyle = darkMode ? inputStyles.darkInput : inputStyles.lightInput;
  if (type === 'textarea') {
    return (
      <textarea
        value={value}
        onChange={onChange}
        className={cx(inputStyle, styles.input, styles.textarea, {
          [inputStyles.error]: error,
        })}
        name={name}
        maxLength={max}
        id={name}
      />
    );
  }
  return (
    <input
      value={value}
      onChange={onChange}
      className={cx(inputStyle, styles.input, { [inputStyles.error]: error })}
      type={type}
      name={name}
      max={max}
      maxLength={max}
      id={name}
    />
  );
}

const TextInput: React.FunctionComponent<IProps> = (props) => {
  const { title, name, error, errorText, darkMode } = props;
  return (
    <Input
      title={title}
      name={name}
      error={error}
      errorText={errorText}
      darkMode={darkMode}
    >
      {renderInput(props)}
    </Input>
  );
};

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
