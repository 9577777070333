import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setCart } from '../../redux/actions/cartActions';
import { BlueLagoonModal } from '../components';
import {
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ISelectTime,
  ITransportPersonCounter,
  AddOrRemove,
  ILagoonSelect,
  ITranslationsBookingBox,
  ITranslationsBookingModal,
  ITourDetails
} from '../../interfaces';
import { useOuterClickNotifier } from '../../utils';
import { Moment } from 'moment';
import { StaticQuery, graphql } from 'gatsby';
import TransportBookingBox from '../components/TransportBookingBox';

interface IExternalProps {
  tour: ITourDetails;
  productId: string;
  transport: ITransport | null;
  transportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartDate: Moment;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  selectedBluelagoonTime: ILagoonSelect | null;
  blueLagoonTimeOptions: ILagoonSelect[] | null;
  tourTitle: string;
  changeSelectedLagoonTime(time: ILagoonSelect): void;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment): void;
  changeSelectedTime(time: ISelectTime, isReturn: boolean): void;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  getAvailabilityLeft(): number;
  addToCart(): Promise<boolean>;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    bookingBox: ITranslationsBookingBox;
    bookingModal: ITranslationsBookingModal;
  };
}

const BlueLagoonBooking: React.FunctionComponent<IProps> = props => {
  const [dropdownOpen, triggerDropdown] = useState(false);
  const [isValid, triggerValid] = useState(true);

  const dropdown = useRef<HTMLDivElement | null>(null);
  const dropoffDiv = useRef<HTMLDivElement | null>(null);
  const pickupDiv = useRef<HTMLDivElement | null>(null);
  const priceRef = useRef<HTMLDivElement | null>(null);
  const button = useRef<HTMLButtonElement | null>(null);

  useOuterClickNotifier(e => {
    if (
      !!dropoffDiv &&
      !!dropoffDiv.current &&
      !!dropoffDiv.current.contains(e.target) &&
      !!pickupDiv &&
      !!pickupDiv.current &&
      !!pickupDiv.current.contains(e.target)
    ) {
      triggerDropdown(true);
    }
  }, null);
  useOuterClickNotifier(
    e => {
      if (
        !!dropdown &&
        !!dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !!button &&
        !!button.current &&
        !button.current.contains(e.target) &&
        !!priceRef &&
        !!priceRef.current &&
        !priceRef.current.contains(e.target)
      ) {
        triggerDropdown(false);
      }
    },
    pickupDiv,
    dropoffDiv
  );

  const {
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    selectedEndTime,
    endTimeOptions,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    tourTitle,
    changeSelectedDate,
    changeSelectedTime,
    transport,
    changeSelectedLagoonTime,
    getTotalPersonCount,
    getPersonCount,
    changePersonCounter,
    getAvailabilityLeft,
    tour
  } = props;
  const totalPersonCount = props.getTotalPersonCount();
  useEffect(() => {
    if (
      !!selectedStartDate &&
      !!selectedStartTime &&
      !!selectedEndTime &&
      !!selectedBluelagoonTime &&
      totalPersonCount > 0
    ) {
      triggerValid(true);
    }
  }, [
    selectedStartDate,
    selectedStartTime,
    selectedBluelagoonTime,
    selectedEndTime,
    totalPersonCount
  ]);
  async function addToCart() {
    if (
      !!props.selectedStartDate &&
      !!props.selectedStartTime &&
      !!selectedBluelagoonTime &&
      totalPersonCount > 0
    ) {
      triggerValid(true);
      const success = await props.addToCart();
      if (success) {
        triggerDropdown(false);
      }
    } else {
      triggerValid(false);
    }
  }
  function openBookingDropdown() {
    if (!!props.selectedStartPlace && !!props.selectedEndPlace) {
      triggerDropdown(true);
      triggerValid(true);
    } else {
      triggerValid(false);
    }
  }

  return (
    <React.Fragment>
      <TransportBookingBox
        dropdownOpen={dropdownOpen}
        addToCart={addToCart}
        addingToCart={addingToCart}
        openBookingDropdown={openBookingDropdown}
        startPlaces={startPlaces}
        selectedStartPlace={selectedStartPlace}
        changeSelectedPlace={changeSelectedPlace}
        isValid={isValid}
        endPlaces={endPlaces}
        selectedEndPlace={selectedEndPlace}
        button={button}
        pickupDiv={pickupDiv}
        dropoffDiv={dropoffDiv}
        flybus={false}
      />
      <BlueLagoonModal
        tourTitle={tourTitle}
        modalOpen={dropdownOpen}
        toggleModal={triggerDropdown}
        startPlaces={startPlaces}
        selectedStartPlace={selectedStartPlace}
        isValid={isValid}
        changeSelectedPlace={changeSelectedPlace}
        endPlaces={endPlaces}
        selectedEndPlace={selectedEndPlace}
        changeSelectedDate={changeSelectedDate}
        selectedStartDate={selectedStartDate}
        selectedStartTime={selectedStartTime}
        changeSelectedTime={changeSelectedTime}
        starTimeOptions={starTimeOptions}
        transport={transport}
        selectedBluelagoonTime={selectedBluelagoonTime}
        blueLagoonTimeOptions={blueLagoonTimeOptions}
        changeSelectedLagoonTime={changeSelectedLagoonTime}
        selectedEndTime={selectedEndTime}
        endTimeOptions={endTimeOptions}
        personCounter={personCounter}
        priceRef={priceRef}
        addingToCart={addingToCart}
        getTotalPersonCount={getTotalPersonCount}
        getPersonCount={getPersonCount}
        addToCart={addToCart}
        changePersonCounter={changePersonCounter}
        getAvailabilityLeft={getAvailabilityLeft}
        tour={tour}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (reducers: any) => {
  const { cartReducers, currencyReducer } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale } = reducers.translationReducers;
  return { cartObject, currency, locale };
};
// note bookingbox is no longer used here, remove if i decide not to send it in as a props
export default connect(
  mapStateToProps,
  {
    setCart
  }
)(
  ({
    productId,
    transport,
    transportRoutes,
    startPlaces,
    endPlaces,
    selectedStartDate,
    selectedStartPlace,
    selectedEndPlace,
    selectedStartTime,
    selectedEndTime,
    starTimeOptions,
    endTimeOptions,
    personCounter,
    addingToCart,
    selectedBluelagoonTime,
    blueLagoonTimeOptions,
    tourTitle,
    tour,
    locale,
    changeSelectedLagoonTime,
    changeSelectedPlace,
    changeSelectedDate,
    changeSelectedTime,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    getAvailabilityLeft,
    addToCart
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query BlueLagoonBooking {
          allContentfulTranslationsPage {
            edges {
              node {
                ...TranslationPageBookingBox
              }
            }
          }
          allContentfulTranslationsBookingModal {
            edges {
              node {
                ...BookingModalHardcodedStrings
              }
            }
          }
        }
      `}
      render={data => {
        const localeData = {
          bookingBox: data.allContentfulTranslationsPage.edges.filter(
            (node: { node: { node_locale: string } }) => {
              return node.node.node_locale === locale;
            }
          )[0].node,
          bookingModal: data.allContentfulTranslationsBookingModal.edges.filter(
            (node: { node: { node_locale: string } }) => {
              return node.node.node_locale === locale;
            }
          )[0].node
        };
        return (
          <BlueLagoonBooking
            localeData={localeData}
            productId={productId}
            transport={transport}
            transportRoutes={transportRoutes}
            startPlaces={startPlaces}
            endPlaces={endPlaces}
            selectedStartDate={selectedStartDate}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            starTimeOptions={starTimeOptions}
            endTimeOptions={endTimeOptions}
            personCounter={personCounter}
            addingToCart={addingToCart}
            selectedBluelagoonTime={selectedBluelagoonTime}
            blueLagoonTimeOptions={blueLagoonTimeOptions}
            changeSelectedLagoonTime={changeSelectedLagoonTime}
            changeSelectedPlace={changeSelectedPlace}
            changeSelectedDate={changeSelectedDate}
            changeSelectedTime={changeSelectedTime}
            changePersonCounter={changePersonCounter}
            getTotalPersonCount={getTotalPersonCount}
            getPersonCount={getPersonCount}
            getAvailabilityLeft={getAvailabilityLeft}
            addToCart={addToCart}
            locale={locale}
            tourTitle={tourTitle}
            tour={tour}
          />
        );
      }}
    />
  )
);
