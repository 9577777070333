import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import * as styles from './index.module.scss';
import cx from 'classnames';
import Drawer from 'rc-drawer';
import HeaderLinks from '../HeaderLinks';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import FilterSelect from '../FilterDropdown/FilterSelect';
import FilterLangSelect from '../FilterLangSelect';
import { tripAdvisorMobile } from '../../icons';
import { changeLocale } from '../../redux/actions/translationActions';
import { stripSlashes, stripLangPath } from '../../utils/index';
import {
  ICurrencyOption,
  IPageContext,
  IFilterSelectItem,
  IReducers,
  ICart,
} from '../../interfaces';

interface IExternalProps {
  open: boolean;
  searchOpen: boolean;
  pageContext: IPageContext;
  contentfulData: any;
  pathname: string;
  currencyOptions: IFilterSelectItem[];
  currency: ICurrencyOption;
  dropdownOpen: boolean;
  locale: string;
  langPath: string;
  cartObject: ICart | null;
  cartChange(open: boolean): void;
  toggleDropdownOpen(): void;
  selectCurrency(index: number): void;
  setDrawerLinksClicked(randomStr: string): void;
  changeLocale(locale: string, lang: string): void;
  isIcelandair: boolean;
  isIcelandairUrl: boolean;
  queryIcelandair: string;
}

interface IProps extends IExternalProps {
  langaugeSwithcerData: ILangOptions[];
}

interface ILangOptions {
  iconName: string;
  text: string;
  locale: string;
  lang: string;
  langPath: string;
}

interface ILangSelected {
  title: string;
  locale: string;
  lang: string;
  selected: boolean;
  iconName: string;
}

interface IProps extends IExternalProps {
  localeData: {
    myBookingButton: string;
    currency: string;
    currencyChangeWarning: string;
    languageLabel: string;
  };
}

const HeaderDrawer: React.FunctionComponent<IProps> = ({
  open,
  searchOpen,
  pageContext,
  contentfulData,
  pathname,
  cartChange,
  currencyOptions,
  currency,
  dropdownOpen,
  localeData,
  cartObject,
  toggleDropdownOpen,
  selectCurrency,
  setDrawerLinksClicked,
  langaugeSwithcerData,
  locale,
  changeLocale,
  langPath,
  isIcelandair,
  isIcelandairUrl,
  queryIcelandair,
}) => {
  const [langDropDownToggle, setLangDropdownToggle] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [languageOptions, setLanguageOptions] = useState<ILangSelected[]>([]);

  const currencyWarning: IFilterSelectItem[] = [];
  currencyWarning.push({
    title: localeData.currencyChangeWarning,
    selected: false,
  } as IFilterSelectItem);

  useEffect(() => {
    const langTmp: ILangSelected[] = [];
    if (locale && langaugeSwithcerData) {
      langaugeSwithcerData.forEach((element: ILangOptions) => {
        if (element.locale === locale) {
          setSelectedLanguage(element.text);
          langTmp.push({
            title: element.text,
            locale: element.locale,
            lang: element.lang,
            iconName: element.iconName,
            selected: true,
          });
        } else {
          langTmp.push({
            title: element.text,
            locale: element.locale,
            lang: element.lang,
            iconName: element.iconName,
            selected: false,
          });
        }
      });
      setLanguageOptions(langTmp);
    }
  }, [locale, langaugeSwithcerData]);

  const select = (item: number) => {
    changeLocale(languageOptions[item].locale, languageOptions[item].lang);
    setSelectedLanguage(languageOptions[item].title);
    setLangDropdownToggle(!langDropDownToggle);
  };

  return (
    <div>
      <Drawer
        height='100%'
        width='100%'
        open={open}
        placement='right'
        handler={false}
        level={null}
        className={
          searchOpen
            ? styles.drawerContainerAllSearch
            : styles.drawerContainerAll
        }
      >
        <div className={styles.drawerContainer}>
          <div className={cx('centered-content', styles.drawerBody)}>
            <HeaderLinks
              langPath={pageContext.langPath}
              contentfulHeader={contentfulData.allContentfulHeader}
              pageContext={pageContext}
              pathname={pathname}
              open={open}
              light={false}
              mobile={true}
              setOpen={() => cartChange(open)}
              setDrawerLinksClicked={setDrawerLinksClicked}
              isIcelandairUrl={isIcelandairUrl}
              isIcelandair={isIcelandair}
            />
            <p className={styles.filterTitle}>{localeData.currency}</p>
            {!!cartObject &&
            !!cartObject.Items &&
            cartObject.Items.length > 0 ? (
              <div style={{ backgroundColor: 'white', marginBottom: '40px' }}>
                <FilterDropdown
                  selectedText={
                    currencyOptions === undefined ||
                    currencyOptions.length === 0
                      ? ''
                      : currency.label
                  }
                  open={dropdownOpen}
                  toggleOpen={() => toggleDropdownOpen()}
                  defaultButton={false}
                >
                  <div className={styles.currencyError}>
                    {localeData.currencyChangeWarning}
                  </div>
                </FilterDropdown>
              </div>
            ) : (
              <div style={{ backgroundColor: 'white', marginBottom: '40px' }}>
                <FilterDropdown
                  selectedText={
                    currencyOptions === undefined ||
                    currencyOptions.length === 0
                      ? ''
                      : currency.label
                  }
                  open={dropdownOpen}
                  toggleOpen={() => toggleDropdownOpen()}
                  defaultButton={false}
                >
                  <FilterSelect
                    items={currencyOptions}
                    select={selectCurrency}
                  />
                </FilterDropdown>
              </div>
            )}
            <p className={styles.filterTitle}>{localeData.languageLabel}</p>
            <div style={{ backgroundColor: 'white', marginBottom: '40px' }}>
              <FilterDropdown
                selectedText={selectedLanguage}
                open={langDropDownToggle}
                toggleOpen={() => {
                  setLangDropdownToggle(!langDropDownToggle);
                }}
                defaultButton={false}
              >
                <FilterLangSelect
                  items={languageOptions}
                  select={select}
                  langPath={langPath}
                  slug={stripLangPath(stripSlashes(pathname))}
                  queryIcelandair={queryIcelandair}
                />
              </FilterDropdown>
            </div>

            <div
              className={cx(
                'columns',
                'is-multiline',
                'is-variable',
                'is-2',
                'is-mobile'
              )}
              style={{ justifyContent: 'center' }}
            >
              <div className={'column'}>
                <a
                  href='https://booking.re.is/mybooking/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.drawerButton}
                >
                  <span className={styles.myBookingText}>
                    {localeData.myBookingButton}
                  </span>
                </a>
              </div>
            </div>
            {isIcelandair ? null : (
              <div className={styles.icelandairHeader}>
                <a
                  href='https://www.tripadvisor.com/Attraction_Review-g189970-d1556635-Reviews-Reykjavik_Excursions-Reykjavik_Capital_Region.html'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    style={{
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '11em',
                      maxHeight: '11em',
                      paddingBottom: '50px',
                    }}
                    src={tripAdvisorMobile}
                    alt='Trip Advisor'
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  const { cartObject } = reducers.cartReducers;
  const { isIcelandair } = reducers.layoutReducer;
  return { locale, cartObject, isIcelandair };
};

export default connect(mapStateToProps, { changeLocale })(
  ({
    open,
    searchOpen,
    pageContext,
    contentfulData,
    pathname,
    currencyOptions,
    currency,
    dropdownOpen,
    langPath,
    cartObject,
    changeLocale,
    cartChange,
    toggleDropdownOpen,
    selectCurrency,
    setDrawerLinksClicked,
    locale,
    isIcelandair,
    isIcelandairUrl,
    queryIcelandair,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query HeaderDrawer {
          allContentfulHeader {
            edges {
              node {
                node_locale
                myBookingButton
                currency
                currencyChangeWarning
                languageLabel
                languageSwitcher {
                  iconName
                  text
                  locale
                  lang
                  langPath
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <HeaderDrawer
            localeData={
              data.allContentfulHeader.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node
            }
            open={open}
            searchOpen={searchOpen}
            pageContext={pageContext}
            contentfulData={contentfulData}
            pathname={pathname}
            currencyOptions={currencyOptions}
            currency={currency}
            dropdownOpen={dropdownOpen}
            cartChange={cartChange}
            toggleDropdownOpen={toggleDropdownOpen}
            selectCurrency={selectCurrency}
            setDrawerLinksClicked={setDrawerLinksClicked}
            cartObject={cartObject}
            langaugeSwithcerData={
              data.allContentfulHeader.edges.filter(
                (node: { node: { node_locale: string } }) => {
                  return node.node.node_locale === locale;
                }
              )[0].node.languageSwitcher
            }
            locale={locale}
            changeLocale={changeLocale}
            langPath={langPath}
            isIcelandair={isIcelandair}
            isIcelandairUrl={isIcelandairUrl}
            queryIcelandair={queryIcelandair}
          />
        );
      }}
    />
  )
);
