import React from 'react';
import { TransportStartTimes } from '../../components';
import Title from '../../../components/Title';
import * as styles from './index.module.scss';
import { Moment } from 'moment';
import { ISelectTime, ILagoonSelect } from '../../../interfaces';

type IMenuPlacement = 'auto' | 'bottom' | 'top';

interface IProps {
  selectedStartTime?: ISelectTime | null;
  selectedEndTime?: ISelectTime | null;
  isLagoon?: boolean;
  date?: Moment;
  selectedBluelagoonTime?: ILagoonSelect | null;
  starTimeOptions?: ISelectTime[] | null;
  isValid: boolean;
  disabled?: boolean;
  isReturn: boolean;
  endTimeEndOptions?: ISelectTime[] | null;
  ariaLabel: string;
  tabIndex: string;
  menuPlacement?: IMenuPlacement;
  timeSelectorHeader: string;
  isMobile?: boolean;
  isLoadingTimes?: boolean;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
}

const TransportTime: React.FunctionComponent<IProps> = ({
  selectedStartTime,
  starTimeOptions,
  changeSelectedTime,
  isValid,
  disabled,
  isReturn,
  endTimeEndOptions,
  ariaLabel,
  tabIndex,
  menuPlacement,
  timeSelectorHeader,
  selectedBluelagoonTime,
  selectedEndTime,
  isLagoon,
  date,
  isMobile,
  isLoadingTimes,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.labelText}>
        <Title tag='h3' theme={['sizeDesktopSmall']}>
          {timeSelectorHeader}
        </Title>
      </div>
      <div className={styles.labelPadding}>
        <TransportStartTimes
          selectedStartTime={selectedStartTime}
          startTimeOptions={starTimeOptions}
          changeSelectedTime={changeSelectedTime}
          isValid={isValid || !!selectedStartTime} // athuga
          tabIndex={tabIndex}
          disabled={disabled}
          isReturn={isReturn}
          endTimeEndOptions={endTimeEndOptions}
          ariaLabel={ariaLabel}
          menuPlacement={menuPlacement}
          selectedBluelagoonTime={selectedBluelagoonTime}
          selectedEndTime={selectedEndTime}
          isLagoon={isLagoon}
          date={date}
          isMobile={isMobile}
          isLoadingTimes={isLoadingTimes}
        />
      </div>
    </div>
  );
};

export default TransportTime;
