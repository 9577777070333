import React from 'react';
import * as styles from './index.module.scss';
import Button from '../../../components/Button';
import { ICart, IReducers } from '../../../interfaces';
import { connect } from 'react-redux';
import { BookingApi } from '../../../../src/api';
import ls from 'local-storage';
import { changeIcelandair } from '../../../../src/redux/actions/layoutActions';

interface IProps {
  changeIcelandair(parameter: boolean): void;
  cartObject: ICart | null;
  sagaClubNumber: string;
}
interface IExternalProps {
  //cartObject: ICart | null;
}
interface IProps extends IExternalProps {}
const TransportCancelButton: React.FunctionComponent<IProps> = ({
  cartObject,
  sagaClubNumber,
}) => {
  return (
    <div className={styles.modalPrice}>
      <div>
        <Button onClick={() => cancelBooking(cartObject, sagaClubNumber)}>
          {ls.get('lang') == 'EN' ? 'Cancel & return' : 'Hætta við og skila'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { isIcelandair } = reducers.layoutReducer;
  const { currency } = reducers.currencyReducer;
  return { currency, isIcelandair };
};

export default connect(mapStateToProps, { changeIcelandair })(
  TransportCancelButton
);
function cancelBooking(cartObject: ICart | null, sagaClubNumber: string) {
  const lang = ls.get('lang');
  const storageUserFlybus = localStorage.getItem('flybusData');
  const userFlybusData = storageUserFlybus
    ? JSON.parse(storageUserFlybus)
    : null;
  //const bn = this.props.cartObject;
  const bookingApi = new BookingApi();
  const cartInfo = !!cartObject?.Items ? cartObject?.Items[0] : null;
  if (userFlybusData) {
    bookingApi.updateExternalBooking(
      cartInfo ? cartInfo?.BookingNumber ?? 'New' : 'New',
      userFlybusData.PartnerBookingNumber,
      'Booking canceled button',
      userFlybusData.OrderId,
      '',
      sagaClubNumber,
      null,
      lang
    );
    dispatchIcelandair(false);
  }
}
function dispatchIcelandair(parameter: boolean) {
  localStorage.removeItem('flybusData');
  changeIcelandair(parameter);
  window.location.replace('http://www.icelandair.com/manage-booking');
}
