import React from 'react';
import cx from 'classnames';

import * as styles from './NumberInput.module.scss';
import * as inputStyles from './Input.module.scss';
import Input from './Input';

interface IProps {
  title: string;
  value: number;
  onChange: (value: number) => void;
  name?: string;
  error?: boolean;
  errorText?: string;
  max?: number;
  min?: number;
  darkMode?: boolean;
}

const increment = (props: IProps) => {
  const { value, max, onChange } = props;
  let newVal = value + 1;
  if (max && newVal > max) {
    newVal = max;
  }
  onChange(newVal);
};

const decrement = (props: IProps) => {
  const { value, min, onChange } = props;
  let newVal = value - 1;
  if (min && newVal < min) {
    newVal = min;
  }
  onChange(newVal);
};

const updateNumber = (
  event: React.ChangeEvent<HTMLInputElement>,
  props: IProps
) => {
  const value = parseInt(event.target.value, 10);
  if (Number.isInteger(value)) {
    props.onChange(value);
  }
};

const NumberInput: React.FunctionComponent<IProps> = (props) => {
  const { title, value, name, error, errorText, max, min, darkMode } = props;
  return (
    <Input
      title={title}
      name={name}
      error={error}
      errorText={errorText}
      darkMode={darkMode}
    >
      <div className={styles.number}>
        <button onClick={() => decrement(props)} style={{ fontSize: 25 }}>
          -
        </button>
        <input
          value={value}
          onChange={(e) => updateNumber(e, props)}
          className={cx(
            darkMode ? inputStyles.darkInput : inputStyles.lightInput,
            styles.input,
            { [inputStyles.error]: error }
          )}
          type='number'
          name={name}
          max={max}
          min={min}
          id={name}
        />
        <button onClick={() => increment(props)}>+</button>
      </div>
    </Input>
  );
};

export default NumberInput;
