import React from 'react';
import * as styles from './index.module.scss';
import Icon from '../../../components/Icon';
import { ITourDetails } from '../../../interfaces';

interface IProps {
  tour: ITourDetails;
}

const TextBox: React.FunctionComponent<IProps> = ({ tour }) => {
  if (tour.bookingModalTextBox) {
    return (
      <div>
        <div className={styles.infoLine} style={{ marginBottom: '5px' }} />
        <div className={styles.container}>
          <div>
            <Icon name='ANNOUNCMENTWARNING' />
          </div>
          <div className={styles.textContainer}>
            <p>
              {tour.bookingModalTextBox
                ? tour.bookingModalTextBox.bookingModalTextBox
                : null}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TextBox;
