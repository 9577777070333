import React from 'react';
import moment, { Moment } from 'moment';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';

import Input from './Input';
import InputDropdown from './InputDropdown';
import { getCalendarDate } from '../../utils';

interface IProps {
  title: string;
  startDate: Moment | null;
  endDate: Moment | null;
  open: boolean;
  toggleOpen: () => void;
  onChange: (arg: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => void;
  clearDateSelection: () => void;
  name?: string;
  error?: boolean;
  errorText?: string;
  darkMode?: boolean;
  dateText: string;
  dateClear: string;
}

interface IState {
  focusedInput: 'startDate' | 'endDate';
  datePlaceholder: string;
}

class DateInput extends React.Component<IProps, IState> {
  readonly state: IState = {
    focusedInput: 'startDate',
    datePlaceholder: ''
  };

  render() {
    const {
      title,
      name,
      error,
      errorText,
      startDate,
      endDate,
      open,
      toggleOpen,
      onChange,
      darkMode,
      dateClear
    } = this.props;
    const { focusedInput, datePlaceholder } = this.state;

    return (
      <Input
        title={title}
        name={name}
        error={error}
        errorText={errorText}
        darkMode={darkMode}
      >
        <InputDropdown
          open={open}
          toggleOpen={toggleOpen}
          selectedText={this.getDateText()}
          error={error}
          title={title}
        >
          <div className='dropdown-calendar'>
            <DayPickerRangeController
              initialVisibleMonth={null}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={onChange}
              onFocusChange={this.updateDateFocus}
              focusedInput={focusedInput}
              onNextMonthClick={() => this.setState({ datePlaceholder })}
              onPrevMonthClick={() => this.setState({ datePlaceholder })}
              isOutsideRange={day =>
                isInclusivelyBeforeDay(
                  day,
                  moment(new Date()).subtract(1, 'day')
                )
              }
              minimumNights={0}
            />
            <button onClick={this.clearDateSelection}>{dateClear}</button>
          </div>
        </InputDropdown>
      </Input>
    );
  }

  clearDateSelection = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ focusedInput: 'startDate' });
    this.props.clearDateSelection();
  };

  updateDateFocus = (focus: 'startDate' | 'endDate' | null) => {
    this.setState({ focusedInput: focus || 'startDate' });
  };

  getDateText = () => {
    const { startDate, endDate, dateText } = this.props;
    if (startDate && endDate && !startDate.isSame(endDate, 'day')) {
      return `${getCalendarDate(startDate)} - ${getCalendarDate(endDate)}`;
    }
    if (startDate) {
      return getCalendarDate(startDate);
    }
    return dateText;
  };
}

export default DateInput;
