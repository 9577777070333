import React from 'react';
import { Moment } from 'moment';
import Select, { components } from 'react-select';
import cx from 'classnames';
import { ISortedAvailability, IReducers } from '../../../interfaces';
import * as styles from './index.module.scss';
import { formatDate } from '../../../utils';
import { mobileMenu } from './TourTimeAvailability.ReactSelect';
import { colors } from '../../../constants';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: { label: string; value: string } | null;
  isValid: boolean;
  isMobile?: boolean;
  open?: boolean;
  locale: string;
  triggerDate?: (date: boolean) => void;
  changeSelectedTime(event: any): void;
}

interface IProps extends IExternalProps {
  localeData: {
    inputLabel: string;
    inputPlaceholder: string;
    altInputLabel: string;
  };
}

const overideSelectStyles = {
  container: (provided: any) => ({ ...provided, width: '100%' }),
  control: (provided: any, { isFocused }: any) => {
    const borderBottom = isFocused ? '2px solid #294b98' : null;
    const boxShadow = null;
    const borderColor = '#e4e7e7';
    const backgroundColor = ' white';
    const maxHeight = 50;
    const borderRadius = '0px';
    return {
      ...provided,
      borderBottom,
      boxShadow,
      borderColor,
      maxHeight,
      backgroundColor,
      borderRadius,
      '&:hover': {
        borderColor: null,
      },
    };
  },
};

const TourTimeAvailability: React.FunctionComponent<IProps> = (props) => {
  const {
    selectedDate,
    availability,
    selectedTime,
    changeSelectedTime,
    isMobile,
    isValid,
    localeData,
  } = props;
  const notAvailable = localeData.altInputLabel;
  const selectDepartureTime = localeData.inputPlaceholder;
  function getStyles() {
    return { ...mobileMenu, ...overideSelectStyles };
  }
  if (!!selectedDate && !!availability) {
    let options = availability[formatDate(selectedDate)]
      ? Object.values(availability[formatDate(selectedDate)]).map((avail) => {
          if (!avail.SoldOut) {
            return {
              label: avail.StartTime,
              value: avail.StartTime,
            };
          }

          return null;
        })
      : [{ value: notAvailable, label: notAvailable }];

    options = options.filter((x) => !!x);
    if (!!options && options.length < 6) {
      return (
        <React.Fragment>
          {!isValid ? (
            <p
              style={{
                justifyContent: 'center',
                display: 'flex',
                color: colors.coralRed,
              }}
            >
              {localeData.inputLabel}
            </p>
          ) : null}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'flex-start',
              width: '85%',
              marginTop: isMobile ? 0 : 0,
            }}
          >
            {options.map((option) => {
              if (!!option && !!option.value) {
                return (
                  <button
                    key={option.value}
                    className={cx(styles.option, {
                      [styles.open]:
                        JSON.stringify(selectedTime) === JSON.stringify(option),
                    })}
                    value={option.value}
                    onClick={() => {
                      changeSelectedTime(option);
                      if (isMobile && props.triggerDate) {
                        props.triggerDate(!props.open);
                      }
                    }}
                  >
                    <p className={styles.buttonText}>{option.label}</p>
                  </button>
                );
              }
              return null;
            })}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'flex-start',
            width: '85%',
            marginTop: isMobile ? 15 : 15,
            paddingBottom: isMobile ? 170 : 0,
          }}
        >
          <Select
            placeholder={selectDepartureTime}
            options={options}
            value={selectedTime ? selectedTime : null}
            isClearable={false}
            isSearchable={false}
            onChange={(event: any) => {
              changeSelectedTime(event);
            }}
            components={{ SingleValue: components.SingleValue }}
            styles={getStyles()}
            isLoading={options === null ? true : false}
          />
        </div>
      );
    }
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({
    availability,
    selectedDate,
    selectedTime,
    isValid,
    isMobile,
    open,
    triggerDate,
    changeSelectedTime,
    locale,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourTimeAvailability {
          allContentfulTranslationsVarious {
            edges {
              node {
                tourTimeAvailability {
                  inputLabel
                  inputPlaceholder
                  altInputLabel
                }
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => (
        <TourTimeAvailability
          localeData={
            data.allContentfulTranslationsVarious.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          availability={availability}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          isValid={isValid}
          isMobile={isMobile}
          open={open}
          triggerDate={triggerDate}
          changeSelectedTime={changeSelectedTime}
          locale={locale}
        />
      )}
    />
  )
);
