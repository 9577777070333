import React from 'react';

const Flybus = () => (
  <svg
    viewBox='0 0 218 86'
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    clipRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit='2'
  >
    <g fillRule='nonzero'>
      <path
        d='M0 69.6l5-26.2H.6L2 35.8h4.4l.3-1.8c.6-4.1 2.3-8.3 5.7-11.1 2.8-2.3 6.4-3.2 9.7-3.2 2.3 0 4.1.3 5.3.8l-1.7 7.9c-.9-.3-1.9-.6-3.2-.6-3.3 0-5.3 2.8-5.9 6.2l-.3 1.7H23l-1.5 7.6h-6.6l-5 26.2H0v.1zM23 69.6l9.4-49.1h10L33 69.6H23zM52.6 35.8l2.1 14.7c.5 3.5.8 6 1.1 8.1h.1c.8-2.1 1.4-4.3 3-8L65 35.8h10.5L63.4 60.1c-4.5 9-8.5 15.1-13 19.4-3.9 3.7-8.6 5.4-10.9 5.7l-2.2-8.5c1.6-.5 3.8-1.3 5.7-2.7 2.3-1.6 4.2-3.6 5.5-5.9.3-.5.3-.9.1-1.6L42 35.8h10.6z'
        fill='#fbb900'
      />
      <path
        d='M92.2 20.5l-4 19.4h.1c2.5-3 6.3-4.8 10.4-4.8 7.1 0 10.2 5.8 10.2 11.8 0 10.9-7.2 23.5-22.4 23.5-8 0-12-3.5-12-9.5 0-2.1.3-4.2.8-6.2l6.8-34.2h10.1zm-7 34.8c-.3 1.1-.4 2.4-.3 3.7.1 2.4 1.5 3.7 3.8 3.7 5.8 0 9.9-8 9.9-13.9 0-3.3-1.4-5.8-4.4-5.8-3.6 0-7 3.6-8.3 9.5l-.7 2.8zM147.2 35.8l-4.3 22.8c-.8 4.3-1.4 8-1.9 11h-8.8l.6-5.5-.1-.1c-3.5 4.4-7.5 6.3-11.6 6.3-5.5 0-8.7-3-8.7-9.5 0-1.8.3-3.9.7-6.2l3.6-18.9h10l-3.5 18.1c-.3 1.5-.5 3.2-.5 4.5 0 2.1.9 3.9 3.4 3.9 3.4 0 6.9-4.1 8.2-10.8l3-15.7h9.9v.1zM149.1 60.7c1.9 1 5.6 2.4 8.9 2.4 2.8 0 4.2-1.4 4.2-3 0-1.7-1.1-2.8-4.2-4.4-3.9-1.9-7.1-4.8-7.1-9 0-7.1 6.2-11.7 14.9-11.7 3.9 0 7.1.8 8.6 1.7l-2.3 7.1c-1.4-.6-3.8-1.4-6.8-1.4-2.6 0-4.4 1.1-4.4 3 0 1.4 1.4 2.5 4.1 3.8 3.7 1.8 7.6 4.6 7.6 9.3 0 7.7-6.6 12-15.6 11.9-4.2-.1-8.3-1.2-10.3-2.6l2.4-7.1z'
        fill='#004188'
      />
    </g>
  </svg>
);

export default Flybus;
