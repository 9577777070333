import React from 'react';
import * as styles from './EmptyCart.module.scss';
import { Icon, Button, Link } from '../../components';
import { IReducers } from '../../../src/interfaces';
import { connect } from 'react-redux';

interface IProps {
  langPath: string;
  hardcodedStrings: {
    cartEmptyTitle: string;
    cartEmptySubtitle: string;
    cartEmptyButton: string;
  };
  isIcelandair: boolean;
}

const EmptyCart = ({ langPath, hardcodedStrings, isIcelandair }: IProps) => {
  return (
    <div className={styles.emptyCartContainer}>
      <div className={styles.contentWrapper}>
        <Icon name='CARTEMPTY' />
        <h2>{hardcodedStrings.cartEmptyTitle}</h2>
        <p>{hardcodedStrings.cartEmptySubtitle}</p>
      </div>

      <div className={styles.buttonWrapper}>
        <Button>
          {isIcelandair ? (
            hardcodedStrings.cartEmptyButton
          ) : (
            <Link langPath={langPath} to={'tours-activities'}>
              {hardcodedStrings.cartEmptyButton}
            </Link>
          )}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (reducers: IReducers) => {
  const { isIcelandair } = reducers.layoutReducer;
  return {
    isIcelandair,
  };
};
export default connect(mapStateToProps)(EmptyCart);
