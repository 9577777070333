import React, { useState, useEffect } from 'react';
import { setCart } from '../../redux/actions/cartActions';
import { connect } from 'react-redux';
import {
  ICurrencyOption,
  IProduct,
  IPickupDropoff,
  ISelectValue,
  ISortedAvailability,
  IPersonCounter,
  IBookingCutoff,
  IRate,
  IBookableExtraState,
  DataType,
  ITourDetails
} from '../../interfaces';
import { Moment } from 'moment';
import TourModal from '../components/TourModal';
import TourBookingBox from '../components/TourBookingBox';

interface IProps {
  tour: ITourDetails;
  currency: ICurrencyOption;
  product: IProduct | null;
  tourTitle: string;
  pickupAndDropoffPlaces: IPickupDropoff | null;
  selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  selectedDate: Moment | null;
  selectedMonth: Moment | null;
  focus: boolean;
  availability: ISortedAvailability | null;
  selectedTime: ISelectValue | null;
  personCounter: IPersonCounter | null;
  addingToCart: boolean;
  selectPickupOpen: boolean;
  selectDropoffOpen: boolean;
  bookableExtraCounter: IBookableExtraState | null;
  resetSelectedTimeAndObject(): void;
  onChangeDate(date: Moment | null): void;
  onFocusChange(focus: boolean | null): void;
  getAvailability(fromDate: Date, monthsToAdd: number): void;
  isDayBlocked(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  isDayDisabled(
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ): boolean;
  handleSelectedTime(event: { label: string; value: string }): void;
  getTotalPersonCount(): number;
  changePersonCount(
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ): void;
  getRateIndex(rates: IRate[]): number;
  getPersonCount(personType: string): number;
  changePickupDropoff(event: ISelectValue, pickupOrDropoff: pickOrDrop): void;
  changeSelectOpen(newState: boolean, pickupOrDropoff: pickOrDrop): void;
  addToCart(): Promise<boolean>;
  changeBookableExtraCounter(
    addorRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ): void;
  changeAnswer(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ): void;
  getMinToBook(): number;
  getMaxToBook(): number | null;
  toggleSelectExtra(name: string): void;
}
type AddOrRemove = 'addOne' | 'removeOne';
type pickOrDrop = 'pickup' | 'dropoff';

const TourBooking: React.FunctionComponent<IProps> = props => {
  const [isValid, triggerValid] = useState(true);
  const [modalOpen, toggleModal] = useState(false);
  const [availabilityIsLoading, setAvailabilityIsLoading] = useState(false);

  const {
    product,
    availability,
    selectedDate,
    selectedMonth,
    selectedTime,
    pickupAndDropoffPlaces,
    selectedPickup,
    selectedDropoff,
    focus,
    personCounter,
    addingToCart,
    tourTitle,
    bookableExtraCounter,
    resetSelectedTimeAndObject,
    onChangeDate,
    onFocusChange,
    getAvailability,
    isDayBlocked,
    isDayDisabled,
    handleSelectedTime,
    getMaxToBook,
    getTotalPersonCount,
    changePersonCount,
    changePickupDropoff,
    changeSelectOpen,
    changeBookableExtraCounter,
    changeAnswer,
    toggleSelectExtra,
    getRateIndex,
    getPersonCount,
    currency,
    getMinToBook,
    tour
  } = props;

  useEffect(() => {
    setAvailabilityIsLoading(false);
  }, [availability]);

  function setToogleModal(value: boolean) {
    toggleModal(value);
  }
  function loadingAvailability(loading: boolean) {
    setAvailabilityIsLoading(loading);
  }

  async function addToCart() {
    // const { getTotalPersonCount, getMinToBook } = props;
    if (!selectedDate || !selectedTime) {
      triggerValid(false);
    } else if (
      !!product &&
      !!product.PickupSelection &&
      product.PickupSelection === 'PRESELECTED' &&
      !selectedPickup
    ) {
      triggerValid(false);
    } else if (
      !!product &&
      !!product.DropoffSelection &&
      product.DropoffSelection === 'PRESELECTED' &&
      !selectedDropoff
    ) {
      triggerValid(false);
    } else if (
      getMinToBook() > getTotalPersonCount() ||
      getTotalPersonCount() <= 0
    ) {
      triggerValid(false);
    } else {
      const success = await props.addToCart();
      if (success) {
        toggleModal(false);
      }
    }
  }
  return (
    <React.Fragment>
      <TourBookingBox
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        product={product}
        isValid={isValid}
        getTotalPersonCount={getTotalPersonCount}
        getMinToBook={getMinToBook}
        triggerValid={triggerValid}
        addToCart={addToCart}
        addingToCart={addingToCart}
      />
      <TourModal
        product={product}
        tourTitle={tourTitle}
        availabilityIsLoading={availabilityIsLoading}
        availability={availability}
        selectedDate={selectedDate}
        selectedMonth={selectedMonth}
        selectedTime={selectedTime}
        focus={focus}
        resetSelectedTimeAndObject={resetSelectedTimeAndObject}
        onChangeDate={onChangeDate}
        onFocusChange={onFocusChange}
        getAvailability={getAvailability}
        isDayBlocked={isDayBlocked}
        isDayDisabled={isDayDisabled}
        loadingAvailability={loadingAvailability}
        handleSelectedTime={handleSelectedTime}
        isValid={isValid}
        getMaxToBook={getMaxToBook}
        personCounter={personCounter}
        getTotalPersonCount={getTotalPersonCount}
        changePersonCount={changePersonCount}
        pickupAndDropoffPlaces={pickupAndDropoffPlaces}
        selectedPickup={selectedPickup}
        changePickupDropoff={changePickupDropoff}
        changeSelectOpen={changeSelectOpen}
        selectedDropoff={selectedDropoff}
        bookableExtraCounter={bookableExtraCounter}
        changeBookableExtraCounter={changeBookableExtraCounter}
        changeAnswer={changeAnswer}
        toggleSelectExtra={toggleSelectExtra}
        getRateIndex={getRateIndex}
        getPersonCount={getPersonCount}
        currency={currency}
        addToCart={addToCart}
        addingToCart={addingToCart}
        modalOpen={modalOpen}
        toggleModal={setToogleModal}
        tour={tour}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (reducers: any) => {
  const { cartReducers, currencyReducer } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  return { cartObject, currency };
};

export default connect(
  mapStateToProps,
  {
    setCart
  }
)(TourBooking);
