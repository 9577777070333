import React, { useState } from 'react';
import TransportPrice from '../TransportPrice';
import TransportTotalPrice from '../TransportTotalPrice';
import Title from '../../../components/Title';
import * as styles from './index.module.scss';
import CancellationPolicy from '../CancellationPolicy';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import TextBox from '../../components/TextBox';
import {
  ITransportPersonCounter,
  IReducers,
  ICurrencyOption,
  ITranslationsCancellationPolicy,
  ITourDetails,
  ITranslationsSagaPoints,
} from '../../../interfaces';
import { connect } from 'react-redux';
import TransportCancelButton from '../TransportCancelButton';
import SagaPoints from '../SagaPoints';

interface IProps {
  priceRef: React.MutableRefObject<HTMLDivElement | null>;
  currency: ICurrencyOption;
  personCounter: ITransportPersonCounter | null;
  withReturn?: boolean;
  priceHeader: string;
  totalPriceString: string;
  noBookingFees: string;
  freeCancellation: string;
  smallPrint: string;
  addToCartButton: string;
  addingToCart: boolean;
  tour: ITourDetails;
  isMobile?: boolean;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  addToCart(): Promise<void> | void | Promise<boolean>;
  isIcelandair: boolean;
  sagaTitle?: string;
  sagaText?: string;
  sagaInput?: string;
}

const TransportPriceBreakdown: React.FunctionComponent<IProps> = ({
  currency,
  personCounter,
  withReturn,
  getPersonCount,
  priceHeader,
  priceRef,
  totalPriceString,
  noBookingFees,
  freeCancellation,
  smallPrint,
  addToCartButton,
  addToCart,
  addingToCart,
  tour,
  isMobile,
  isIcelandair,
  sagaText,
  sagaTitle,
  sagaInput,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  if (personCounter) {
    return (
      <>
        <div
          className={styles.modalPrice}
          style={{ position: 'relative' }}
          ref={priceRef}
        >
          <Title tag='h3' theme={['sizeDesktopSmall']}>
            {priceHeader}
          </Title>
          <TransportPrice
            personCounter={personCounter}
            getPersonCount={getPersonCount}
            withReturn={withReturn}
            setTotalPrice={setTotalPrice}
          />
          <TransportTotalPrice
            totalPrice={totalPrice}
            currency={currency}
            totalPriceString={totalPriceString}
          />
          <CancellationPolicy
            hardcodedStrings={
              {
                freeCancellation: freeCancellation,
                noBookingFees: noBookingFees,
                smallPrint: smallPrint,
              } as ITranslationsCancellationPolicy
            }
          />
          {isIcelandair ? (
            <SagaPoints
              hardcodedStrings={
                {
                  sagaText: sagaText,
                  sagaTitle: sagaTitle,
                  sagaInput: sagaInput,
                } as ITranslationsSagaPoints
              }
            />
          ) : null}
          {isMobile ? <TextBox tour={tour} /> : null}
          {!isMobile ? (
            <div className={styles.checkoutButton}>
              <Button onClick={addToCart}>
                {addingToCart === true ? (
                  <Loader size='medium' color='white' />
                ) : (
                  addToCartButton
                )}
              </Button>
              <br />
              {isIcelandair ? (
                <TransportCancelButton cartObject={null} sagaClubNumber='' />
              ) : null}
            </div>
          ) : null}
          {!isMobile ? <TextBox tour={tour} /> : null}
        </div>
        {isMobile ? (
          <div className={styles.checkoutButton}>
            <Button onClick={addToCart}>
              {addingToCart === true ? (
                <Loader size='medium' color='white' />
              ) : (
                addToCartButton
              )}
            </Button>
            <br />
            {isIcelandair ? (
              <TransportCancelButton cartObject={null} sagaClubNumber='' />
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
  return null;
};

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  return { currency };
};

export default connect(mapStateToProps)(TransportPriceBreakdown);
