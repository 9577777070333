import React from 'react';
import * as styles from './index.module.scss';
import { Link } from '../../components';
import ToursDropdown from '../ToursDropdown';
import ToursToggleMobile from '../ToursToggleMobile';
import { IHeaderList, IPageContext, IHeader } from '../../interfaces';
import cx from 'classnames';
import { randomString } from '../../utils';
import { stripSlashes } from '../../utils/index';

interface IProps {
  langPath: string;
  contentfulHeader: IHeaderList;
  pageContext: IPageContext;
  pathname: string;
  open: boolean;
  light: boolean;
  mobile: boolean;
  setOpen(value: boolean): void;
  setDrawerLinksClicked?(randomStr: string): void;
  isIcelandairUrl: boolean;
  isIcelandair: boolean;
}
const HeaderLinks: React.FunctionComponent<IProps> = (props) => {
  const { setDrawerLinksClicked, isIcelandair } = props;

  const renderLinks = () => {
    const { edges } = props.contentfulHeader;
    const { langPath, locale } = props.pageContext;
    const currentPath = stripSlashes(props.pathname);
    const isIcelandairUrl = props.isIcelandairUrl;
    let headerData: IHeader | null = null;
    for (const edge of edges) {
      if (edge.node.node_locale === locale) {
        headerData = edge.node;
      }
    }

    if (!headerData) {
      return null;
    }
    if (props.mobile) {
      if (isIcelandairUrl || isIcelandair) {
        return null;
      } else {
        return (
          <React.Fragment>
            {headerData.links.links.map((link, index) => {
              if (link.externalLink || link.page) {
                const slug = link.externalLink || link.page.slug;
                const active = currentPath === stripSlashes(slug);
                if (link.__typename !== 'ContentfulNavigation') {
                  return (
                    <div
                      key={index.toString()}
                      className={styles.drawerLinks}
                      onClick={() => {
                        props.setOpen(false);
                        setDrawerLinksClicked
                          ? setDrawerLinksClicked(randomString())
                          : null;
                      }}
                    >
                      <Link
                        key={index.toString()}
                        className={cx(
                          props.light ? styles.linkLight : styles.linkDark,
                          {
                            [styles.activeLink]: active,
                          }
                        )}
                        langPath={langPath}
                        to={slug}
                      >
                        {link.title || link.page.title}
                      </Link>
                    </div>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <ToursToggleMobile
                    nameOfLink={link.title}
                    links={link.links}
                    langPath={langPath}
                    setOpen={props.setOpen}
                    key={index.toString()}
                  />
                );
              }
            })}
          </React.Fragment>
        );
      }
    }
    if (isIcelandairUrl || isIcelandair) {
      return null;
    } else {
      return (
        <React.Fragment>
          {headerData.links.links.map((link) => {
            if (link.externalLink || link.page) {
              const slug = link.externalLink || link.page.slug;
              const active = currentPath === stripSlashes(slug);
              if (link.__typename !== 'ContentfulNavigation') {
                // Todo athuga betri lausn
                return (
                  <Link
                    key={link.id}
                    className={cx(
                      props.light ? styles.linkLight : styles.linkDark,
                      {
                        [styles.activeLink]: active,
                      }
                    )}
                    langPath={langPath}
                    to={slug}
                  >
                    {link.title || link.page.title}
                  </Link>
                );
              } else {
                return null;
              }
            } else {
              const slug = link.externalLink;
              let active = null;
              if (slug) {
                active = currentPath === stripSlashes(slug);
              }
              return (
                <ToursDropdown
                  nameOfLink={link.title}
                  links={link.links}
                  active={active}
                  light={props.light}
                  langPath={langPath}
                  key={link.id}
                />
              );
            }
          })}
        </React.Fragment>
      );
    }
  };
  return renderLinks();
};
export default HeaderLinks;
