import axios, { AxiosInstance } from 'axios';
import { handleAxiosError } from '../utils';
import { IProduct, IDefaultPrice, IFlybus } from '../interfaces/index';

interface IProductApi {
  getProduct(
    productIds: string,
    currency: string,
    lang: string
  ): Promise<IProduct | null>;
  getDefaultPrices(
    tourIds: string[],
    currency: string,
    lang: string
  ): Promise<IDefaultPrice[] | null>;
}

class ProductApi implements IProductApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async getProduct(
    productId: string,
    currency: string,
    lang: string
  ): Promise<IProduct | null> {
    try {
      const result = await this.httpClient.get('/api/Product/GetProduct', {
        params: {
          productId,
          currency,
          lang
        }
      });
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async getDefaultPrices(
    tourIds: string[],
    currency: string,
    lang: string
  ): Promise<IDefaultPrice[] | null> {
    try {
      const idList = tourIds.map((id: string) => parseInt(id, 10));
      const result = await this.httpClient.post(
        '/api/Product/GetDefaultPrices',
        idList,
        { params: { currency, lang } }
      );
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }

  async hashHandle(
    baseString: string | null,
    orderId: string | null,
    lang: string
  ): Promise<IFlybus | null> {
    try {
      console.log("queryHash in post, "+ baseString);
      const result = await this.httpClient.post(
        '/api/Product/GetBookingFromHash',
        {},
        {
          params: {
            baseString,
            orderId
          }
        }
      );
      console.log(result);
      return result.data;
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }

  async generateHashFromBooking(
    externalOrderId: string,
    partnerBookingNumber: string,
    bookingNumber: string,
    currency: string,
    date: string,
    productId: string,
    productName: string,
    totalAmount: number,
    lang: string
  ): Promise<string | null> {
    try {
      const result = await this.httpClient.post(
        '/api/Product/GenerateHashFromBooking',
        {},
        {
          params: {
            externalOrderId,
            partnerBookingNumber,
            bookingNumber,
            currency,
            date,
            productId,
            productName,
            totalAmount
          }
        }
      );
      console.log(result);
      return result.data;
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        return e.response.data;
      } else if (!!e.response && e.response.status === 500) {
        if (e.response.data.error) {
          e.response.data.error.ErrorMessage =
            lang === 'IS'
              ? 'Eitthvað fór úrskeiðis. Vinsamlegast farið yfir upplýsingarnar og reynið aftur.'
              : 'Something went wrong, please review your information and try again.';
        }

        return e.response.data;
      } else if (!!e.response && e.response.status === 422) {
        try {
          const firstError = Object.keys(e.response.data.error)[0];
          if (firstError) {
            e.response.data.error.ErrorMessage =
              e.response.data.error[firstError].ErrorMessage;
          }
        } catch (e) {
          return e.response.data;
        }

        return e.response.data;
      } else if (e.request) {
        // console.log(e.request);
      }
      return null;
    }
  }
}

export default ProductApi;
