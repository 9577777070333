import React from 'react';
import * as styles from './index.module.scss';
import SubractOrAdd from '../SubtractOrAdd/index';
import NumberInputValue from '../NumberInputValue/index';
interface IProps {
  value: number;
  add: () => void;
  subtract: () => void;
}
const NumberInput: React.FunctionComponent<IProps> = ({
  value,
  add,
  subtract,
}) => {
  return (
    <div className={styles.container}>
      <SubractOrAdd subtractOrAdd='subtract' onClick={subtract} />
      <NumberInputValue value={value} />
      <SubractOrAdd subtractOrAdd='add' onClick={add} />
    </div>
  );
};
export default NumberInput;
