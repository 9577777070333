import React from 'react';

export const DoubleSeats = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M38.2619 17.6334h-2.4403c-.1627 0-.3254.1608-.3254.3217v.4021c0 .1608.1627.3217.3254.3217h.7321v1.0455c0 1.1259-.8948 2.0105-2.0336 2.0105v-.1608c0-.4021-.4068-.8847-.8948-.8847H6.21204c-.40672 0-.89479.4021-.89479.8847v.1608c-1.13883 0-2.03363-.8846-2.03363-2.0105v-1.0455h.73211c.16269 0 .32538-.1609.32538-.3217v-.4021c0-.1609-.16269-.3217-.32538-.3217H1.57538c-.16269 0-.32538.1608-.32538.3217v.4021c0 .1608.16269.3217.32538.3217h.7321v1.0455c0 1.6084 1.30152 2.9756 3.00977 2.9756v.1608c0 .4022.40672.8847.89479.8847H33.7066c.4067 0 .8948-.4021.8948-.8847V22.7c1.6269 0 3.0098-1.2868 3.0098-2.9756v-1.0455h.8134c.1627 0 .3254-.1609.3254-.3217v-.4021c-.244-.1609-.4067-.3217-.4881-.3217zM34.6279 30.679h-.7396l-.3287-6.2435h-2.0545l.0822 1.0146H8.24837l.08218-1.0146H6.27607l-.32871 6.2435h-.73962c-.16436 0-.32871.1561-.32871.3122v.3902c0 .1561.16435.3122.32871.3122h3.53371c.16436 0 .32871-.1561.32871-.3122v-.3902c0-.1561-.16435-.3122-.32871-.3122h-.82179l.16435-3.3559H31.7516l.1644 3.3559h-.6575c-.1643 0-.3287.1561-.3287.3122v.3902c0 .1561.1644.3122.3287.3122h3.5337c.1644 0 .3288-.1561.3288-.3122v-.3902c-.1644-.1561-.3288-.3122-.4931-.3122z'
      fill='#FA7268'
    />
    <path
      d='M22.8064 9.83229c0-.24127.2441-.40211.4068-.40211h6.7516c.244 0 .4067.16084.4067.40211l.4067 9.89201h2.0337l-.4068-9.89201c-.0813-1.28676-1.1388-2.33226-2.4403-2.33226h-6.7516c-1.3016 0-2.359 1.0455-2.4404 2.33226l-.4067 9.89201h2.0336l.4067-9.89201zM9.3845 9.83229c0-.24127.24403-.40211.40672-.40211h6.75158c.2441 0 .4068.16084.4068.40211l.4067 9.89201h2.0336l-.4067-9.89201c-.0813-1.28676-1.1388-2.33226-2.4404-2.33226H9.79122c-1.30151 0-2.359 1.0455-2.44034 2.33226l-.40673 9.89201h2.03363l.40672-9.89201z'
      fill='#FA7268'
    />
  </svg>
);

export default DoubleSeats;
