import React from 'react';

// a
export const ArrowScrollTo = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='61.109'
    height='61.109'
    viewBox='0 0 61.109 61.109'
  >
    <path
      d='M30.555 0a30.555 30.555 0 1030.554 30.555A30.549 30.549 0 0030.555 0zm26.612 30.555A26.612 26.612 0 1130.555 3.943a26.611 26.611 0 0126.612 26.612zM45.77 30l-.85-.85a1.473 1.473 0 00-2.107.025L32.649 39.709V16.263a1.483 1.483 0 00-1.478-1.478h-1.232a1.483 1.483 0 00-1.478 1.478v23.446L18.3 29.162a1.484 1.484 0 00-2.107-.025l-.85.85a1.48 1.48 0 000 2.094l14.164 14.182a1.48 1.48 0 002.094 0L45.77 32.082a1.465 1.465 0 000-2.082z'
      fill='#fa7268'
    />
  </svg>
);
export default ArrowScrollTo;
