import React from 'react';
import moment, { Moment } from 'moment';
import ls from 'local-storage';
import { connect } from 'react-redux';

import { setCart } from '../redux/actions/cartActions';
import {
  ICurrencyOption,
  IPickupDropoff,
  ISortedAvailability,
  ICartObject,
  IProduct,
  IPersonCounter,
  ISelectValue,
  IBookableExtraState,
  IRate,
  ICart,
  IBookingCutoff,
  IBookableExtraPriceCounter,
  IAnswerState,
  IQuestion,
  DataType,
  IAddExtra,
  IGuestExtra,
  IExtraQuestion,
  IReducers,
  ITourDetails,
  IAvailability,
  ITranslationsTourBookingButton,
} from '../interfaces';
import { PickupApi, AvailabilityApi, CartApi } from '../api/index';
import { Loader } from '../components';
import {
  formatDate,
  formatDateEnglishLocale,
  isPriceCounter,
  maxByForExtraQuestion,
  getPersonCounterById,
} from '../utils';
import { isInclusivelyBeforeDay } from 'react-dates';

import TourBooking from './TourBooking/TourBooking';
import TourBookingMobile from './TourBooking/TourBookingMobile';
import { Tire } from '../icons';
import { graphql, StaticQuery } from 'gatsby';
import TourBookingPrivate from './TourBookingPrivate/TourBookingPrivate';

interface IExternalProps {
  currency: ICurrencyOption;
  productId: string;
  tourTitle: string;
  product: IProduct | null;
  isMobile?: boolean;
  setCart(newCart: ICart): void;
  locale: string;
  lang: string;
  tour: ITourDetails;
}

interface IProps extends IExternalProps {
  localeData: {
    tourBookingBoxMissingOrSoldOut: string;
    tourBookingBoxMainButton: ITranslationsTourBookingButton;
    tourBookingBoxNoBookingFees: string;
    tourBookingBoxFreeCancellation: string;
  };
}

interface IState {
  isLoading: boolean;
  pickupAndDropoffPlaces: IPickupDropoff | null;

  /*   selectedPickup: IPickupPlace | null;
   */ selectedPickup: ISelectValue | null;
  selectedDropoff: ISelectValue | null;
  selectedDate: Moment | null;
  selectedMonth: Moment | null;
  focus: boolean;
  availability: ISortedAvailability | null;
  selectedTime: ISelectValue | null;
  currentCartId: string | null;
  validTime: boolean;
  validPickup: boolean;
  validDropoff: boolean;
  personCounter: IPersonCounter | null;

  needDropoff: boolean;
  bookableExtraCounter: IBookableExtraState | null;
  rateIndex: number;
  selectPickupOpen: boolean;
  selectDropoffOpen: boolean;
  addingToCart: boolean;
}

type AddOrRemove = 'addOne' | 'removeOne';
type pickOrDrop = 'pickup' | 'dropoff';

// lodash sum replacement
const sum = (arr: number[]) =>
  arr.reduce((acc, num) => {
    acc += num;
    return acc;
  }, 0);

class TourEngine extends React.Component<IProps, IState> {
  readonly state: IState = {
    isLoading: true,
    pickupAndDropoffPlaces: null,
    selectedPickup: null,
    selectedDropoff: null,
    selectedDate: moment(new Date()),
    focus: true,
    availability: null,
    selectedTime: null,
    selectedMonth: null,
    currentCartId: null,
    validTime: true,
    validPickup: true,
    validDropoff: true,
    personCounter: null,
    bookableExtraCounter: null,
    needDropoff: false,
    rateIndex: 0,
    selectPickupOpen: false,
    selectDropoffOpen: false,
    addingToCart: false,
  };

  pickupApi = new PickupApi();
  availabilityApi = new AvailabilityApi();
  cartApi = new CartApi();
  dropdown: HTMLDivElement | null = null;
  counter: HTMLDivElement | null = null;
  button: HTMLButtonElement | null = null;

  isPrivateTour = this.props.tour.category?.[0].slug == 'private-tours';
  async componentDidMount() {
    /*     document.addEventListener('click', this.handleClickOutside);
     */ const { productId, lang } = this.props;

    if (this.isPrivateTour) {
      this.setState({ isLoading: false });
    } else {
      const pickupAndDropoffPlaces = await this.pickupApi.getPickupAddresses(
        productId,
        lang
      );
      this.setState({ pickupAndDropoffPlaces });

      await this.getAvailability(new Date(), 0);
      if (this.state.currentCartId === null) {
        const cartId = ls.get('cartId');
        this.setState({ currentCartId: cartId });
      }
      const { availability } = this.state;
      if (availability) {
        this.setState({ availability });
      }

      this.setPersonCounters();
      this.checkNextAvailableDay();
    }
  }
  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.isPrivateTour == false) {
      if (prevProps.currency !== this.props.currency) {
        await this.getAvailability(
          this.state.selectedDate
            ? moment(this.state.selectedDate).toDate()
            : new Date(),
          0
        );
        this.changeBookableExtraCurrency();
      }
      if (prevState.selectedTime !== this.state.selectedTime) {
        const { availability, selectedDate, selectedTime } = this.state;
        if (
          !!availability &&
          !!selectedDate &&
          !!selectedTime &&
          !!availability[formatDate(selectedDate)] &&
          !!availability[formatDate(selectedDate)][selectedTime.value]
        ) {
          const { Rates } =
            availability[formatDate(selectedDate)][selectedTime.value];
          const rateIndex = this.getRateIndex(Rates);
          this.setState({ rateIndex });
          if (Rates[rateIndex].BookableExtras) {
            this.setBookableExtraCounter();
          }
        }
      }
      if (prevState.rateIndex !== this.state.rateIndex) {
        this.changeBookableExtraRate();
      }
    }
  }

  componentWillUnmount() {
    /*     document.removeEventListener('click', this.handleClickOutside);
     */
  }

  render() {
    const { product, isMobile, tourTitle, localeData, tour } = this.props;
    const {
      isLoading,
      availability,
      selectedDate,
      selectedMonth,
      selectedTime,
      pickupAndDropoffPlaces,
      selectedPickup,
      selectedDropoff,
      focus,
      validTime,
      validPickup,
      validDropoff,
      personCounter,
      addingToCart,
      selectPickupOpen,
      selectDropoffOpen,
      /*       needDropoff,*/
      bookableExtraCounter,
    } = this.state;

    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            margin: 20,
          }}
        >
          <Loader />
        </div>
      );
    } else if (
      !!availability &&
      Object.keys(availability).length === 0 &&
      !isLoading && // || this.isAvailableSeat() === 0
      this.isPrivateTour == false
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <img src={Tire} alt='error' style={{ height: 35 }} />
          <p style={{ padding: 10 }}>
            {localeData.tourBookingBoxMissingOrSoldOut}
          </p>
        </div>
      );
    } else {
      if (isMobile) {
        return (
          <TourBookingMobile
            product={product}
            pickupAndDropoffPlaces={pickupAndDropoffPlaces}
            selectedPickup={selectedPickup}
            selectedDropoff={selectedDropoff}
            selectedDate={selectedDate}
            selectedMonth={selectedMonth}
            focus={focus}
            availability={availability}
            selectedTime={selectedTime}
            validTime={validTime}
            validPickup={validPickup}
            validDropoff={validDropoff}
            personCounter={personCounter}
            addingToCart={addingToCart}
            resetSelectedTimeAndObject={this.resetSelectedTimeAndObject}
            onChangeDate={this.onChangeDate}
            onFocusChange={this.onFocusChange}
            getAvailability={this.getAvailability}
            isDayBlocked={this.isDayBlocked}
            isDayDisabled={this.isDayDisabled}
            handleSelectedTime={this.handleSelectedTime}
            getTotalPersonCount={this.getTotalPersonCount}
            getMinToBook={this.getMinToBook}
            changePersonCount={this.changePersonCount}
            getRateIndex={this.getRateIndex}
            getPersonCount={this.getPersonCount}
            changePickupDropoff={this.changePickupDropoff}
            changeSelectOpen={this.changeSelectOpen}
            addToCart={this.addToCart}
            selectPickupOpen={selectPickupOpen}
            selectDropoffOpen={selectDropoffOpen}
            bookableExtraCounter={bookableExtraCounter}
            changeBookableExtraCounter={this.changeBookableExtraCounter}
            changeAnswer={this.changeAnswer}
            toggleSelectExtra={this.toggleSelectExtra}
            tour={tour}
          />
        );
      } else {
        if (this.isPrivateTour) {
          return (
            <TourBookingPrivate
              tour={tour}
              textFees={{
                tourBookingBoxNoBookingFees:
                  localeData.tourBookingBoxNoBookingFees,
                tourBookingBoxFreeCancellation:
                  localeData.tourBookingBoxFreeCancellation,
              }}
              textButton={localeData.tourBookingBoxMainButton}
            />
          );
        } else {
          return (
            <TourBooking
              product={product}
              tourTitle={tourTitle}
              pickupAndDropoffPlaces={pickupAndDropoffPlaces}
              selectedPickup={selectedPickup}
              selectedDropoff={selectedDropoff}
              selectedDate={selectedDate}
              selectedMonth={selectedMonth}
              focus={focus}
              availability={availability}
              selectedTime={selectedTime}
              /*validTime={validTime}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        validPickup={validPickup}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        validDropoff={validDropoff}*/
              personCounter={personCounter}
              addingToCart={addingToCart}
              resetSelectedTimeAndObject={this.resetSelectedTimeAndObject}
              onChangeDate={this.onChangeDate}
              onFocusChange={this.onFocusChange}
              getAvailability={this.getAvailability}
              isDayBlocked={this.isDayBlocked}
              isDayDisabled={this.isDayDisabled}
              handleSelectedTime={this.handleSelectedTime}
              getTotalPersonCount={this.getTotalPersonCount}
              getMinToBook={this.getMinToBook}
              getMaxToBook={this.getMaxToBook}
              changePersonCount={this.changePersonCount}
              getRateIndex={this.getRateIndex}
              getPersonCount={this.getPersonCount}
              changePickupDropoff={this.changePickupDropoff}
              changeSelectOpen={this.changeSelectOpen}
              addToCart={this.addToCart}
              selectPickupOpen={selectPickupOpen}
              selectDropoffOpen={selectDropoffOpen}
              bookableExtraCounter={bookableExtraCounter}
              changeBookableExtraCounter={this.changeBookableExtraCounter}
              changeAnswer={this.changeAnswer}
              toggleSelectExtra={this.toggleSelectExtra}
              tour={tour}
            />
          );
        }
      }
    }
  }

  changeSelectOpen = (newState: boolean, pickupOrDropoff: pickOrDrop) => {
    if (pickupOrDropoff === 'pickup') {
      this.setState({ selectPickupOpen: newState });
    } else if (pickupOrDropoff === 'dropoff') {
      this.setState({ selectDropoffOpen: newState });
    }
  };

  getRateIndex = (rates: IRate[]) => {
    const personCount = this.getTotalPersonCount();
    const rate = rates.findIndex((o) => {
      if (o.MaxPerBooking === 0) {
        return personCount >= o.MinPerBooking;
      }
      return (
        personCount >= o.MinPerBooking &&
        (personCount <= o.MaxPerBooking || !o.MaxPerBooking)
      );
    });
    if (rate === -1) {
      return 0;
    }
    return rate;
  };

  setPersonCounters() {
    const { product } = this.props;
    if (product) {
      const personCounter = product.PriceCategories.reduce((acc, val) => {
        const key = val.Name;
        if (!acc[key]) {
          acc[key] = {
            Id: val.Id,
            masterCategoryId: val.MasterCategoryId,
            maxPerMaster: val.MaxPerMaster,
            count: 0,
            minCount: val.MinCount,
            minAge: val.MinAge,
            maxAge: val.MaxAge,
            price: val.Price,
            pickupPrice: val.PickupPrice,
            dropoffPrice: val.DropoffPrice,
            occupancy: val.Occupancy,
            default: val.Default,
          };
        }
        return acc;
      }, {} as IPersonCounter);
      this.setState({ personCounter });
    }
  }

  /* If bookable extra has product that is preselected and is not free */
  setPreselected(extra: IBookableExtraState) {
    Object.entries(extra).forEach(([_, value]) => {
      if (!value.Free && value.selected) {
        value.preselected = true;
      }
    });
  }

  reduceBookableExtra(Rate: IRate) {
    return Rate.BookableExtras
      ? Rate.BookableExtras.reduce((acc, val) => {
          const key = val.Title;

          if (!!key && !acc[key]) {
            let counter: number | IBookableExtraPriceCounter = 0;
            if (!!val.Prices && val.PricedPerPerson) {
              counter = val.Prices.reduce((acc2, val2) => {
                const key2 = val2.Name;
                if (!acc2[key2]) {
                  acc2[key2] = {
                    Name: val2.Name,
                    Amount: val2.Amount,
                    Currency: val2.Currency,
                    PriceCategoryId: val2.PriceCategoryId,
                    count: 0,
                  };
                }
                return acc2;
              }, {} as IBookableExtraPriceCounter);
            }

            let answers: IAnswerState | null = null;
            if (!!val.Questions && !!val.Id && !!val.Title) {
              if (val.Prices) {
                const obj: { [key: number]: number } = {};
                val.Prices.map(
                  (p) => (obj[p.PriceCategoryId ? p.PriceCategoryId : 0] = 0)
                );
                answers = {
                  Id: val.Id,
                  Quantity: obj,
                  BookingQuestion: val.Included ? true : false,
                  Title: val.Title,
                  Questions: [] /* val.Questions.map(q => ({
                  Id: q.Id,
                  Answer: [''],
                  categoryId: null
                })) */,
                };
              }
            }
            acc[key] = {
              ...val,
              counter,
              selected: val.Included ? true : false,
              answers,
              preselected: false,
            };
          }
          return acc;
        }, {} as IBookableExtraState)
      : ({} as IBookableExtraState);
  }

  changeBookableExtraRate() {
    const {
      availability,
      selectedDate,
      selectedTime,
      rateIndex,
      bookableExtraCounter,
    } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];

      if (Rates) {
        let newBookableExtras: IBookableExtraState = {};
        if (Rates[rateIndex]) {
          newBookableExtras = this.reduceBookableExtra(Rates[rateIndex]);
        } else {
          newBookableExtras = this.reduceBookableExtra(Rates[0]);
        }
        if (bookableExtraCounter) {
          Object.keys(bookableExtraCounter).map((key) => {
            if (newBookableExtras[key]) {
              newBookableExtras[key] = bookableExtraCounter[key];
            }
          });
          this.setState({ bookableExtraCounter: newBookableExtras });
        }
      }
    }
  }

  // gera fall hér sem skiptir um currency

  changeBookableExtraCurrency() {
    const {
      availability,
      selectedDate,
      selectedTime,
      rateIndex,
      bookableExtraCounter,
    } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];
      let newBookableExtras: IBookableExtraState = {};
      if (Rates[rateIndex]) {
        newBookableExtras = this.reduceBookableExtra(Rates[rateIndex]);
      } else {
        newBookableExtras = this.reduceBookableExtra(Rates[0]);
      }

      if (bookableExtraCounter) {
        const prufa = { ...bookableExtraCounter };
        Object.keys(prufa).map((key) => {
          if (
            !!newBookableExtras[key] &&
            !!newBookableExtras[key].Prices &&
            !!prufa[key] &&
            !!prufa[key].Prices
          ) {
            prufa[key].Prices = newBookableExtras[key].Prices;
            if (
              !!bookableExtraCounter[key].counter &&
              !!newBookableExtras[key].counter
            ) {
              const counter = prufa[key].counter;
              const newCounter = newBookableExtras[key].counter;
              if (
                !!counter &&
                isPriceCounter(counter) &&
                !!newCounter &&
                isPriceCounter(newCounter)
              ) {
                Object.keys(counter).map((c) => {
                  if (!!counter[c] && !!newCounter[c]) {
                    counter[c].Currency = newCounter[c].Currency;
                    counter[c].Amount = newCounter[c].Amount;
                  }
                });
              }
            }
          }
        });
        this.setState({ bookableExtraCounter: prufa });
      }
    }
  }

  setBookableExtraCounter() {
    const { availability, selectedDate, selectedTime, rateIndex } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];
      if (!!Rates && !!Rates[rateIndex]) {
        const bookableExtraCounter = this.reduceBookableExtra(Rates[rateIndex]);
        this.setPreselected(bookableExtraCounter);
        this.setState({ bookableExtraCounter });
      } else if (!!Rates && !Rates[rateIndex] && !!Rates[0]) {
        const bookableExtraCounter = this.reduceBookableExtra(Rates[0]);
        this.setState({ bookableExtraCounter });
      }
    }
  }

  async setNextAvailableDate() {
    const { availability } = this.state;

    if (!availability) {
      return;
    }

    const nextAvailableDateIndex = Object.values(availability).findIndex((a) =>
      Object.values(a).every((b) => !b.SoldOut)
    );

    const nextAvailableTimeIndex =
      nextAvailableDateIndex !== -1
        ? Object.values(
            Object.values(availability)[nextAvailableDateIndex]
          ).findIndex((a) => Object.values(a).every((b) => !b.SoldOut))
        : 0;

    let sortedDates = Object.keys(availability).sort(
      (firstSort, secondSort) => {
        return (
          (moment(firstSort).utc().format('X') as any) -
          (moment(secondSort).utc().format('X') as any)
        );
      }
    );

    this.setState({ isLoading: false });
    if (sortedDates.length) {
      const nextDate = sortedDates[nextAvailableDateIndex];
      const times = availability[moment(nextDate, 'LL').format('LL')];
      if (times) {
        const label = Object.keys(times)[nextAvailableTimeIndex];
        const value = label;
        // if next available date is in the next month then getAvailability will skip todays month +2.
        // This is added to fetch that month

        // we need to use the english locale here or there might be some comparison errors
        const momentEnglisLocale = moment().locale('en');
        const nextDateMonth = momentEnglisLocale.month(nextDate).month();
        if (nextDateMonth !== new Date().getMonth()) {
          const sortedDate = sortedDates[nextAvailableDateIndex];
          const sortedDateConverted = moment(sortedDate, 'LL')
            .locale('en')
            .format('LL');

          await this.getAvailability(new Date(sortedDateConverted), 1);
        }
        const timeObject = { label, value };
        this.setState(
          {
            selectedDate: moment(nextDate, 'LL'),
            selectedTime: timeObject,
            selectedMonth: moment(nextDate, 'LL'),
          },
          () => {
            timeObject ? this.setAvailabilityPersonCounter() : null;
          }
        );
      }
    }
  }

  async checkNextAvailableDay() {
    const { availability } = this.state;
    const { productId } = this.props;

    if (availability) {
      let sortedDates = Object.keys(availability).sort(
        (firstSort, secondSort) => {
          return (
            (moment(firstSort).utc().format('X') as any) -
            (moment(secondSort).utc().format('X') as any)
          );
        }
      );

      /** Handles empty dates and checks again */
      if (!sortedDates.length /*|| !isNextDateAvailable*/) {
        const newDate = await this.availabilityApi.getNextAvailableDay(
          productId
        );
        if (newDate) {
          // newDate.getMonth() !== new Date().getMonth() ? 1 : 0 þetta þarf moguelga að koma í staðinn fyrir 0
          await this.getAvailability(newDate, 0);
        } else {
          await this.getFutureAvailability();
        }
      }
      this.setNextAvailableDate();
    }
  }

  async getFutureAvailability() {
    let date = new Date();
    const avail = await this.getAvailabilityFor6Months(date);
    if (!!avail && !!this.state.availability) {
      date.setMonth(date.getMonth() + 6);
      await this.getAvailabilityFor6Months(date);
    }
  }

  // TourBookableExtras

  getTotalPersonCount = () => {
    const { personCounter } = this.state;
    if (personCounter) {
      return sum(
        Object.keys(personCounter).map((personType) => {
          if (personCounter[personType]) {
            return (
              personCounter[personType].count *
              personCounter[personType].occupancy
            );
          }
          return 0;
        })
      );
    }
    return 0;
  };

  getMinToBook = () => {
    const { availability, selectedDate, selectedTime } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value] &&
      !!availability[formatDate(selectedDate)][selectedTime.value].MinToBook
    ) {
      const minToBook =
        availability[formatDate(selectedDate)][selectedTime.value].MinToBook;
      if (!!minToBook && minToBook > 0) {
        return minToBook;
      }
    }
    return 1;
  };

  getMaxToBook = () => {
    const { availability, selectedDate, selectedTime } = this.state;

    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      let maxToBook = 0;
      let unlimitedMax = false;
      availability[formatDate(selectedDate)][selectedTime.value].Rates.map(
        (rate) => {
          if (rate.MaxPerBooking > maxToBook) {
            maxToBook = rate.MaxPerBooking;
          }
          if (!rate.MaxPerBooking) {
            unlimitedMax = true;
          }
        }
      );

      if (unlimitedMax) {
        maxToBook = 0;
      }

      if (!!maxToBook && maxToBook > 0) {
        return maxToBook;
      }
    }

    return null;
  };

  checkBookableExtraCounter(personType: string) {
    const { bookableExtraCounter } = this.state;
    const totalPersonCount = this.getTotalPersonCount();
    if (bookableExtraCounter) {
      Object.keys(bookableExtraCounter).map((key) => {
        if (bookableExtraCounter[key].LimitByPax) {
          const counter = bookableExtraCounter[key].counter;
          if (isPriceCounter(counter)) {
            const personCount = this.getPersonCount(personType);
            if (personCount < counter[personType].count) {
              while (personCount < counter[personType].count) {
                this.changeBookableExtraCounter('removeOne', key, personType);
              }
            }
          } else if (
            !isPriceCounter(counter) &&
            bookableExtraCounter[key].counter === totalPersonCount + 1
          ) {
            const newCounter = bookableExtraCounter;
            newCounter[key].counter = counter - 1;
            this.setState({ bookableExtraCounter: newCounter });
          }
        }
      });
    }
  }

  changeBookableExtraCounter = (
    addOrRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ) => {
    const { bookableExtraCounter } = this.state;
    if (bookableExtraCounter) {
      if (
        addOrRemove === 'addOne' &&
        !!extraType &&
        !!bookableExtraCounter[extraType]
      ) {
        const maxCounter = this.getTotalPersonCount();
        const maxPerBooking = bookableExtraCounter[extraType].MaxPerBooking;
        const counter = bookableExtraCounter[extraType].counter;
        const limitByPax = bookableExtraCounter[extraType].LimitByPax;
        if (isPriceCounter(counter) && !!priceCategory) {
          const guestCount = this.getPersonCount(priceCategory);
          const totalCount = this.getTotalExtraCount(counter);
          if (
            !!extraType &&
            !!bookableExtraCounter[extraType] &&
            (maxCounter === 0 ||
              (limitByPax &&
                maxCounter > totalCount &&
                counter[priceCategory].count < guestCount) ||
              (maxPerBooking && maxPerBooking > counter[priceCategory].count) ||
              (maxPerBooking === 0 && !limitByPax))
          ) {
            const newCounter = { ...bookableExtraCounter };
            const newPriceCounter = { ...counter };
            newPriceCounter[priceCategory].count =
              newPriceCounter[priceCategory].count + 1;
            const questions = newCounter[extraType].Questions;
            const answers = newCounter[extraType].answers;
            if (!!questions && !!answers) {
              this.addToQuestions(
                questions,
                answers,
                newPriceCounter[priceCategory].PriceCategoryId
              );
            }
            newCounter[extraType].counter = newPriceCounter;
            this.setState({ bookableExtraCounter: newCounter });
          }
        } else {
          if (
            !isPriceCounter(counter) &&
            !!extraType &&
            !!bookableExtraCounter[extraType] &&
            (maxCounter === 0 ||
              (limitByPax && maxCounter > counter) ||
              (maxPerBooking && maxPerBooking > counter) ||
              (maxPerBooking === 0 && !limitByPax))
          ) {
            const newCounter = { ...bookableExtraCounter };
            const extraCount = newCounter[extraType].counter;
            if (!isPriceCounter(extraCount)) {
              newCounter[extraType].counter = extraCount + 1;
              const questions = newCounter[extraType].Questions;
              const answers = newCounter[extraType].answers;
              if (!!questions && !!answers) {
                this.addToQuestions(questions, answers, 0);
              }
              this.setState({ bookableExtraCounter: newCounter });
            }
          }
        }
      } else if (
        addOrRemove === 'removeOne' &&
        !!extraType &&
        !!bookableExtraCounter[extraType] &&
        !!bookableExtraCounter[extraType].counter
      ) {
        const counter = bookableExtraCounter[extraType].counter;
        if (isPriceCounter(counter)) {
          const newCounter = { ...bookableExtraCounter };
          const newPriceCounter = { ...counter };
          if (
            !!priceCategory &&
            !!newPriceCounter[priceCategory] &&
            !!newPriceCounter[priceCategory].count
          ) {
            newPriceCounter[priceCategory].count =
              newPriceCounter[priceCategory].count - 1;
            newCounter[extraType].counter = newPriceCounter;
            const questions = newCounter[extraType].Questions;
            const answers = newCounter[extraType].answers;
            if (!!questions && !!answers) {
              this.removeFromQuestions(
                questions,
                answers,
                newPriceCounter[priceCategory].PriceCategoryId
              );
            }
            this.setState({ bookableExtraCounter: newCounter });
          }
        } else {
          if (
            !isPriceCounter(counter) &&
            !!extraType &&
            !!bookableExtraCounter[extraType] &&
            counter > 0
          ) {
            const newCounter = { ...bookableExtraCounter };
            const extraCount = newCounter[extraType].counter;
            if (!isPriceCounter(extraCount)) {
              newCounter[extraType].counter = extraCount - 1;
              const questions = newCounter[extraType].Questions;
              const answers = newCounter[extraType].answers;
              if (!!questions && !!answers) {
                this.removeFromQuestions(questions, answers, 0);
              }
              this.setState({ bookableExtraCounter: newCounter });
            }
          }
        }
      }
    }
  };

  getMaxIndexForExtras() {
    const { bookableExtraCounter } = this.state;
    const maxCounter: number[] = [];
    if (bookableExtraCounter) {
      Object.keys(bookableExtraCounter).map((key) => {
        const answers = bookableExtraCounter[key].answers;
        if (
          !!key &&
          !!bookableExtraCounter[key] &&
          !!answers &&
          !!answers.Questions
        ) {
          const extra = maxByForExtraQuestion(
            answers.Questions,
            (a: IExtraQuestion) => a.Index
          );
          if (extra) {
            maxCounter.push(extra.Index);
          }
        }
      });
    }
    const max = maxCounter.length ? Math.max(...maxCounter) : undefined;
    return max;
  }

  addToQuestions(
    questions: IQuestion[] | null,
    answers: IAnswerState | null,
    categoryId: number
  ) {
    if (!!questions && !!answers) {
      questions.map((q) => {
        if (!!q.Id && !!answers) {
          if (answers.Questions) {
            const newIndex = this.getMaxIndexForExtras();
            // const newIndex = maxBy(answers.Questions, i => i.Index);
            answers.Questions.push({
              Index: newIndex ? newIndex + 1 : 1,
              Id: q.Id,
              Answer: null,
              selectValue: null,
              time: null,
              CategoryId: categoryId ? categoryId : 0,
              valid: true,
              dataType: q.DataType,
            });
          } else {
            const newIndex = this.getMaxIndexForExtras();
            answers.Questions = [
              {
                Index: newIndex ? newIndex + 1 : 1,
                Id: q.Id,
                Answer: null,
                selectValue: null,
                time: null,
                CategoryId: categoryId ? categoryId : 0,
                valid: true,
                dataType: q.DataType,
              },
            ];
          }
        }
      });
      answers.Quantity[categoryId] += 1;
    }
  }
  removeFromQuestions(
    questions: IQuestion[] | null,
    answers: IAnswerState | null,
    categoryId: number
  ) {
    if (!!questions && !!answers) {
      questions.map((q) => {
        if (!!q.Id && !!answers && !!answers.Questions) {
          let withAnswer = null;
          // loop backwards in the array to find last
          for (let i = answers.Questions.length - 1; i >= 0; i--) {
            const answer = answers.Questions[i];
            if (answer.Id === q.Id && answer.CategoryId === categoryId) {
              withAnswer = answer;
              break;
            }
          }
          if (withAnswer) {
            const index = answers.Questions.indexOf(withAnswer);
            answers.Questions.splice(index, 1);
          } else {
            answers.Questions.pop();
          }
        }
        answers.Quantity[categoryId] -= 1;
      });
    }
  }

  changeAnswer = (
    type: DataType,
    key: string,
    index: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
    // event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { bookableExtraCounter } = this.state;
    if (
      !!bookableExtraCounter &&
      !!bookableExtraCounter[key] &&
      !!bookableExtraCounter[key].answers
    ) {
      const answerStates = bookableExtraCounter[key].answers;
      if (!!answerStates && answerStates.Questions) {
        const answer = answerStates.Questions.find((a) => {
          return (
            a.Index === index &&
            a.CategoryId === categoryId &&
            a.Id === questionId
          );
        });
        const questionIndex = answer
          ? answerStates.Questions.indexOf(answer)
          : null;
        const extraCounter = { ...bookableExtraCounter };
        const answers = extraCounter[key].answers;
        if (
          !!answers &&
          !!answers.Questions &&
          !!questionIndex &&
          !!answers.Questions[questionIndex]
        ) {
          const foundQuestion = answers.Questions[questionIndex];
          if (type === 'OPTIONS' && !!selectValue && !!foundQuestion) {
            foundQuestion.selectValue = selectValue;
          } else if (type === 'DATE_AND_TIME' && !!time && !!foundQuestion) {
            foundQuestion.time = time;
          } else if (type === 'INT' && !!value && !!foundQuestion) {
            foundQuestion.Answer = value;
          } else {
            answers.Questions[questionIndex].Answer = value;
          }
          foundQuestion.valid = true;
          this.setState({ bookableExtraCounter: extraCounter });
        }
      }
    }
  };

  toggleSelectExtra = (name: string) => {
    const { bookableExtraCounter } = this.state;

    if (
      !!bookableExtraCounter &&
      !!bookableExtraCounter[name] &&
      !bookableExtraCounter[name].Included
    ) {
      const newExtra = { ...bookableExtraCounter };

      newExtra[name].selected = !bookableExtraCounter[name].selected;

      this.setState({ bookableExtraCounter: newExtra });
    }
  };

  getTotalExtraCount(counter: IBookableExtraPriceCounter) {
    let count = 0;
    Object.keys(counter).map((key) => {
      count += counter[key].count;
    });
    return count;
  }

  // TourCountPerson

  isAvailableSeat() {
    const { availability, selectedDate, selectedTime } = this.state;

    // Check if the required parameters are present.
    if (!availability || !selectedDate || !selectedTime) return 0;

    const formattedDate = formatDate(selectedDate);
    const timeSlot = availability[formattedDate]?.[selectedTime.value];

    // Check if the timeslot is defined.
    if (!timeSlot) return 0;

    if (timeSlot.UnlimitedAvailability) {
      // Assume unlimited seats are represented by a large number.
      return 1000;
    } else if (timeSlot.Available) {
      // Check against the total person count if available seats are defined.
      const availableSeats = timeSlot.Available - this.getTotalPersonCount();
      return availableSeats > 0 ? availableSeats : 0;
    }

    // Default to no available seats.
    return 0;
  }

  /**
   * Determines whether the count of persons of a specific type can be increased by one.
   * */
  canIncreasePersonCount = (
    personType: string,
    personCounter: IPersonCounter
  ): boolean => {
    const maxPerMasterNumber = this.getMaxPerMaster(personType, personCounter);
    const maxToBook = this.getMaxToBook();
    const totalPax = this.getTotalPersonCount();
    const seatsLeft = this.isAvailableSeat();

    const ocuupancyPerPerson = personCounter[personType].occupancy;
    const count = personCounter[personType].count;

    // If total number of persons has reached the max a booking can have
    if (!!maxToBook && totalPax >= maxToBook) return false;

    // If the total number of persons has reached the available seat count
    if (seatsLeft === 0) return false;

    // If the number of persons for the given personType has reached the max per master number
    if (
      maxPerMasterNumber !== null &&
      ocuupancyPerPerson * count >= maxPerMasterNumber
    )
      return false;

    return true;
  };

  /**
   * Determines whether the count of persons of a specific type can be decreased by one.
   * */
  canDecreasePersonCount = (
    personType: string,
    personCounter: IPersonCounter
  ) => personCounter[personType].minCount < personCounter[personType].count;

  /**
   * Increases the count of persons of a specific type by one.
   * */
  increasePersonCount = (personType: string, personCounter: IPersonCounter) => {
    if (!this.canIncreasePersonCount(personType, personCounter)) return;

    personCounter[personType].count = personCounter[personType].count + 1;

    this.setState({ personCounter: personCounter }, () => this.checkRate());
  };

  /**
   * Decreases the count of persons of a specific type by one.
   * */
  decreasePersonCount = (personType: string, personCounter: IPersonCounter) => {
    if (!this.canDecreasePersonCount(personType, personCounter)) return;

    personCounter[personType].count = personCounter[personType].count - 1;

    this.makeCountersReflectMasterChange();
    this.checkBookableExtraCounter(personType);
    this.setState({ personCounter: personCounter }, () => this.checkRate());
  };

  /**
   * Changes the count of persons of a specific type by one.
   * */
  changePersonCount = (
    addOrRemove: AddOrRemove,
    personType: string,
    personCounter: IPersonCounter
  ) => {
    // Early halt if personCounter is not set and personType is invalid
    if (!personCounter && !personCounter[personType]) return;

    const newPersonCounter = { ...personCounter };
    switch (addOrRemove) {
      case 'addOne':
        this.increasePersonCount(personType, newPersonCounter);
        break;

      case 'removeOne':
        this.decreasePersonCount(personType, newPersonCounter);
        break;
    }
  };

  /**
   * Updates the state of the personCounter to reflect the change to thier master.
   * */
  makeCountersReflectMasterChange() {
    const { personCounter } = this.state;
    if (personCounter) {
      const newPersonCounter = { ...personCounter };

      Object.entries(newPersonCounter).forEach(([personType, counter]) => {
        const maxPerMasterNumber = this.getMaxPerMaster(
          personType,
          personCounter
        );

        if (maxPerMasterNumber === null) return;

        if (counter.maxPerMaster === 0 || maxPerMasterNumber >= counter.count)
          return;

        counter.count = maxPerMasterNumber;
      });

      this.setState({ personCounter: newPersonCounter });
    }
  }

  /**
   * Calculates the maximum number of persons that can be booked for a
   * specific personType, in occurrence with thier maxPerMaster value.
   */
  getMaxPerMaster(personType: string, personCounter: IPersonCounter) {
    const maxPerMaster = personCounter[personType].maxPerMaster;

    // Check if the personType has limit to max per master
    if (!maxPerMaster) return null;

    // Retrieve the master category details for the specified personType.
    const master = getPersonCounterById(
      personCounter,
      personCounter[personType].masterCategoryId
    );

    if (!master) return null;

    return maxPerMaster * master.count;
  }

  checkRate() {
    const { availability, selectedDate, selectedTime, rateIndex } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];
      const thisRate = this.getRateIndex(Rates);

      if (rateIndex !== thisRate) {
        this.setState({ rateIndex: thisRate }, () => {
          this.changeBookableExtraRate();
          this.setAvailabilityPersonCounter();
        });
      }
    }
  }

  // TourTimeAvailability
  handleSelectedTime = (event: { label: string; value: string }) => {
    const { availability, selectedDate } = this.state;
    if (selectedDate) {
      const date = formatDate(selectedDate);
      if (!!event && !!availability && !!availability[date]) {
        this.setState(
          {
            selectedTime: event,
          },
          () => {
            this.changeBookableExtraRate();
            this.setAvailabilityPersonCounter();
            // this.setBookableExtraCounter();
          }
        );
      }
    }
  };
  setAvailabilityPersonCounter() {
    const {
      availability,
      selectedDate,
      selectedTime,
      personCounter,
      rateIndex,
    } = this.state;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][
        selectedTime.value
      ] /*  && !!personCounter */
    ) {
      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];

      const currentRateIndex = this.getRateIndex(Rates);
      const availabilityLeft = this.isAvailableSeat();
      const newPersonCounter = Rates[currentRateIndex].PriceCategories.reduce(
        (acc, val) => {
          const key = val.Name;
          if (!acc[key]) {
            acc[key] = {
              Id: val.Id,
              masterCategoryId: val.MasterCategoryId,
              maxPerMaster: val.MaxPerMaster,
              count: val.Default && availabilityLeft > 0 ? 1 : 0,
              minCount: val.MinCount,
              minAge: val.MinAge,
              maxAge: val.MaxAge,
              price: val.Price,
              pickupPrice: val.PickupPrice,
              dropoffPrice: val.DropoffPrice,
              occupancy: val.Occupancy,
              default: val.Default,
            };
          }
          return acc;
        },
        {} as IPersonCounter
      );

      if (personCounter) {
        Object.keys(personCounter).map((key) => {
          const personCount = personCounter[key].count;
          const count = personCounter[key].default
            ? personCount - 1
            : personCount;
          for (let i = 0; i < count; i++) {
            this.changePersonCount('addOne', key, newPersonCounter);
          }
        });
      }
      this.setState({ personCounter: newPersonCounter });
      // bara gert í fyrsta sinn sem er kallað í þetta fall
      if (rateIndex !== currentRateIndex) {
        this.setState({ rateIndex: currentRateIndex });
      }
    }
  }

  // this component
  createCartObject() {
    const {
      availability,
      selectedPickup,
      selectedDropoff,
      selectedDate,
      personCounter,
      selectedTime,
      bookableExtraCounter,
    } = this.state;
    const { productId } = this.props;
    if (
      !!selectedTime &&
      !!selectedTime.value &&
      !!availability &&
      !!selectedDate &&
      !!personCounter // &&
      // !!bookableExtraCounter
    ) {
      /*       const persons = availability[formatDate(selectedDate)][
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    selectedTime.value
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ].Rates[0].PriceCategories.map(person => person); */

      // Guests
      const guests: Array<{ Id: string; Extras: IGuestExtra[] | null }> = [];
      Object.keys(personCounter).map((key) => {
        const Id = personCounter[key].Id;
        const person = { ...personCounter };
        /*         if (person[key].minCount > person[key].count) {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          return;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        } */
        for (let i = 0; i < person[key].count; i++) {
          guests.push({
            Id,
            Extras: null,
          });
        }
      });

      const extraArray: IAddExtra[] = [];
      let validExtra = true;
      if (bookableExtraCounter) {
        Object.keys(bookableExtraCounter).map((key) => {
          const extra = bookableExtraCounter[key];
          if (
            !!bookableExtraCounter &&
            !!bookableExtraCounter[key] &&
            bookableExtraCounter[key].selected
          ) {
            const answers = bookableExtraCounter[key].answers;
            const prices = bookableExtraCounter[key].Prices;
            const counter = bookableExtraCounter[key].counter;
            if (prices) {
              prices.map((price) => {
                if (
                  !!answers &&
                  !!answers.Id &&
                  !!answers.Quantity[
                    price.PriceCategoryId ? price.PriceCategoryId : 0
                  ] &&
                  answers.Quantity[
                    price.PriceCategoryId ? price.PriceCategoryId : 0
                  ] > 0
                ) {
                  const newObj = {
                    categoryId: price.PriceCategoryId
                      ? price.PriceCategoryId
                      : 0,
                    Id: answers.Id,
                    Quantity:
                      answers.Quantity[
                        price.PriceCategoryId ? price.PriceCategoryId : 0
                      ],
                    Questions: answers.Questions
                      ? answers.Questions.map((q) => {
                          if (
                            q.CategoryId ===
                              (price.PriceCategoryId
                                ? price.PriceCategoryId
                                : 0) &&
                            !!q
                          ) {
                            if (
                              !!q &&
                              !!q.Answer &&
                              !!q.time &&
                              q.dataType === 'DATE_AND_TIME'
                            ) {
                              const answer = q.Answer + ' ' + q.time;
                              return {
                                Id: q.Id,
                                Answer: [answer],
                              };
                            } else if (
                              !!q &&
                              !!q.Answer &&
                              q.dataType !== 'DATE_AND_TIME' &&
                              q.dataType !== 'OPTIONS'
                            ) {
                              return {
                                Id: q.Id,
                                Answer: [q.Answer],
                              };
                            } else if (
                              !!q &&
                              !!q.selectValue &&
                              q.dataType === 'OPTIONS'
                            ) {
                              const answer = q.selectValue.map((s) => {
                                return s.value;
                              });
                              return {
                                Id: q.Id,
                                Answer: answer,
                              };
                            } else {
                              validExtra = false;
                              const newCounter = { ...bookableExtraCounter };
                              if (
                                !!newCounter &&
                                !!newCounter[key] &&
                                !!newCounter[key].answers
                              ) {
                                const answer = newCounter[key].answers;
                                if (!!answer && !!answer.Questions) {
                                  answer.Questions.map((p) => {
                                    if (p.Index === q.Index) {
                                      p.valid = false;
                                    }
                                  });

                                  this.setState({
                                    bookableExtraCounter: newCounter,
                                  });
                                }
                              }
                              return null;
                            }
                          } else {
                            return null;
                          }
                        }).filter((f) => f)
                      : null,
                  };

                  extraArray.push(newObj);
                } else if (!answers && !!counter && !!extra && !!extra.Id) {
                  if (
                    !!counter &&
                    isPriceCounter(counter) &&
                    !!counter[price.Name] &&
                    counter[price.Name].count > 0
                  ) {
                    const newObj = {
                      categoryId: price.PriceCategoryId,
                      Id: extra.Id,
                      Quantity: counter[price.Name].count,
                      Questions: null,
                    };
                    extraArray.push(newObj);
                  } else if (
                    !!counter &&
                    !isPriceCounter(counter) &&
                    counter > 0
                  ) {
                    const newObj = {
                      categoryId: 0,
                      Id: extra.Id,
                      Quantity: counter,
                      Questions: null,
                    };
                    extraArray.push(newObj);
                  }
                }
              });
            } else {
              if (
                !!counter &&
                !isPriceCounter(counter) &&
                counter > 0 &&
                !!extra &&
                !!extra.Id
              ) {
                const newObj = {
                  categoryId: 0,
                  Id: extra.Id,
                  Quantity: counter,
                  Questions: null,
                };
                extraArray.push(newObj);
              }
            }
          } else {
            const counter = bookableExtraCounter[key].counter;

            if (
              !!counter &&
              !isPriceCounter(counter) &&
              !!extra &&
              !!extra.Id &&
              !extra.answers
            ) {
              const newObj = {
                categoryId: 0,
                Id: extra.Id,
                Quantity: counter,
                Questions: null,
              };
              extraArray.push(newObj);
            }
          }
        });
      }
      if (!validExtra) {
        return;
      }

      if (extraArray) {
        for (let i = 0; i < extraArray.length; i++) {
          const extra = extraArray[i];
          let categoryAmount = extra.Quantity;

          while (categoryAmount > 0) {
            for (let j = 0; j < guests.length; j++) {
              if (categoryAmount == 0) {
                break;
              }

              const guest = guests[j];

              if (!guest.Extras) {
                guest.Extras = [];
              }

              const guestExtra = guest.Extras.find((g) => g.Id === extra.Id);
              if (guestExtra) {
                guestExtra.Quantity++;
              } else {
                const guestExtra: IGuestExtra = {
                  Id: extra.Id,
                  Quantity: 1,
                  Questions: extra.Questions,
                };
                guest.Extras.push(guestExtra);
              }

              categoryAmount--;
            }
          }
        }
      }

      const { Rates } =
        availability[formatDate(selectedDate)][selectedTime.value];
      const rateIndex = this.state.rateIndex;

      if (
        Rates[rateIndex].PickupSelectionType === 'PRESELECTED' &&
        !selectedPickup
      ) {
        this.setState({ validPickup: false });
        return;
      }
      if (
        Rates[rateIndex].DropoffSelectionType === 'PRESELECTED' &&
        !selectedDropoff
      ) {
        this.setState({ validDropoff: false });
        return;
      }

      const object: ICartObject = {
        Guests: guests,
        PickupAddress: selectedPickup
          ? { Id: parseInt(selectedPickup.value, 10) }
          : null,
        DropoffAddress: selectedDropoff
          ? { Id: parseInt(selectedDropoff.value, 10) }
          : null,
        Product: { Id: parseInt(productId, 10) },
        Date: moment(selectedDate).format('YYYY-MM-DD'),
        StartTimeId:
          availability[formatDate(selectedDate)][selectedTime.value]
            .StartTimeId,
      };

      return object;
    }
    if (!selectedTime) {
      this.setState({ validTime: false });
      return;
    }
    return null;
  }

  addToCart = async () => {
    const { currency, lang } = this.props;
    const { currentCartId } = this.state;

    this.setState({ addingToCart: true });
    const cartObject = this.createCartObject();
    if (!cartObject) {
      this.setState({ addingToCart: false });
      return false;
    }
    const newCart = await this.cartApi.addToCart(
      currency.value,
      currentCartId,
      cartObject,
      lang
    );
    let success = false;
    if (!!newCart && !!currentCartId) {
      this.props.setCart(newCart);
      success = true;
    }
    if (!!newCart && !currentCartId) {
      // var currentCartId === null og cart !== null
      ls.set('cartId', newCart.Id);
      this.setState({ currentCartId: newCart.Id });
      this.props.setCart(newCart);
      success = true;
    }
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ addingToCart: false });
    return success;
  };

  // TourDatePicker
  getAvailability = async (fromDate: Date, monthsToAdd: number) => {
    const { productId, currency, lang } = this.props;
    const availability = await this.availabilityApi.getAvailability(
      productId,
      currency.value,
      moment(fromDate)
        .utc()
        .add(monthsToAdd, 'months')
        .startOf('month')
        .format(),
      moment(fromDate)
        .utc()
        .add(monthsToAdd + 1, 'months')
        .endOf('month')
        .format(),
      lang
    );
    const sortedAvailability = availability
      ? availability.reduce((acc, val) => {
          const key = moment(val.Date).utc().format('LL');
          if (!acc[key]) {
            acc[key] = {};
          }
          const array: any = [];

          val.StartTimes.map((value) => (array[value.StartTime] = value));
          acc[key] = array;
          return acc;
        }, {} as ISortedAvailability)
      : null;

    const nextAvailable = { ...this.state.availability, ...sortedAvailability };
    const loading = Object.keys(nextAvailable).length === 0;
    this.setState({ availability: nextAvailable, isLoading: loading });
  };

  sortAvailability(availability: IAvailability[] | null) {
    const sortedAvailability = availability
      ? availability.reduce((acc, val) => {
          const key = moment(val.Date).utc().format('LL');
          if (!acc[key]) {
            acc[key] = {};
          }
          const array: any = [];

          val.StartTimes.map((value) => (array[value.StartTime] = value));
          acc[key] = array;
          return acc;
        }, {} as ISortedAvailability)
      : null;

    const nextAvailable = { ...this.state.availability, ...sortedAvailability };
    const loading = Object.keys(nextAvailable).length === 0;
    this.setState({ availability: nextAvailable, isLoading: loading });
  }
  // TourDatePicker6Months
  getAvailabilityFor6Months = async (fromDate: Date) => {
    const { productId, currency, lang } = this.props;
    const { selectedDate } = this.state;
    const availability6months = await this.availabilityApi.getAvailability(
      productId,
      currency.value,
      moment(fromDate).utc().add(0, 'months').startOf('month').format(),
      moment(fromDate).utc().add(6, 'months').endOf('month').format(),
      lang
    );
    //this.sortAvailability(availability);
    const sortedAvailability = availability6months
      ? availability6months.reduce((acc, val) => {
          const key = moment(val.Date).utc().format('LL');
          if (!acc[key]) {
            acc[key] = {};
          }
          const array: any = [];

          val.StartTimes.map((value) => (array[value.StartTime] = value));
          acc[key] = array;
          return acc;
        }, {} as ISortedAvailability)
      : null;

    const nextAvailable = { ...this.state.availability, ...sortedAvailability };
    const loading = Object.keys(nextAvailable).length === 0;
    this.setState({ availability: nextAvailable, isLoading: loading });

    // if (availability && availability?.length > 0) {
    //   this.onChangeDate(moment(availability[0].Date));
    // }

    return availability6months;
  };

  onChangeDate = (date: Moment | null) => {
    if (date) {
      this.setState({ selectedDate: date }, () => {
        this.changeBookableExtraRate();
        const { product } = this.props;
        const { availability, selectedDate } = this.state;
        if (
          !!product &&
          !!product.BookingType &&
          product.BookingType === 'DATE' &&
          !!availability &&
          !!selectedDate &&
          !!availability[formatDate(selectedDate)]
        ) {
          this.setState({ selectedTime: { label: '00:00', value: '00:00' } });
        }
        // if there is only one available time then select it
        else if (
          !this.state.selectedTime &&
          !!availability &&
          !!selectedDate &&
          Object.keys(availability[formatDate(selectedDate)]).length === 1
        ) {
          this.setState({
            selectedTime: {
              label: Object.values(availability[formatDate(selectedDate)])[0]
                .StartTime,
              value: Object.values(availability[formatDate(selectedDate)])[0]
                .StartTime,
            } as ISelectValue,
          });
        }
      });
    }
  };

  resetSelectedTimeAndObject = () => {
    this.setState({ selectedTime: null, validTime: true });
  };

  onFocusChange = (focus: boolean | null) => {
    if (focus !== null) {
      this.setState({ focus });
    }
  };

  isDayBlocked = (day: Moment, availability: ISortedAvailability | null) => {
    if (!!availability && !!availability[formatDate(day)]) {
      return this.isSoldOut(availability, day);
      // return this.removeCutoffAndSoldOut(availability, day, bookingCutoff);
      /* if (!!availability && !!availability[formatDate(day)]) {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    return false;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  } */
    }
    return true;
  };

  isDayDisabled = (
    day: Moment,
    availability: ISortedAvailability | null,
    bookingCutoff: IBookingCutoff | null
  ) => {
    if (
      (!!availability && !availability[formatDate(day)]) ||
      isInclusivelyBeforeDay(day, moment(new Date()).subtract(1, 'day')) ||
      this.isInsideCutoffHours(availability, day, bookingCutoff)
    ) {
      return true;
    }
    return false;
  };

  isSoldOut(availability: ISortedAvailability | null, day: Moment) {
    let soldOut = true;
    if (
      !!availability &&
      !!availability[formatDate(day)] &&
      Object.keys(availability[formatDate(day)]).length > 0
    ) {
      const thisDate = formatDate(day);
      Object.keys(availability[formatDate(day)]).map((time) => {
        if (!availability[thisDate][time].SoldOut) {
          soldOut = false;
        }
      });
    }

    return soldOut;
  }

  isInsideCutoffHours(
    availability: ISortedAvailability | null,
    day: Moment,
    bookingCutoff: IBookingCutoff | null
  ) {
    let noAvailability = false;

    if (
      !!availability &&
      !!availability[formatDate(day)] &&
      Object.keys(availability[formatDate(day)]).length > 0
    ) {
      const thisDate = formatDateEnglishLocale(day);
      for (const iDate of Object.keys(availability[formatDate(day)])) {
        const time = iDate;
        const date = thisDate + ' ' + time;
        if (!!bookingCutoff && time !== '00:00') {
          const { Weeks, Days, Hours, Minutes } = bookingCutoff;

          const cutoff = moment(date, 'MMMM DD, YYYY hh:mma')
            .locale('en')
            .utc()
            .subtract(Weeks, 'weeks')
            .subtract(Days, 'days')
            .subtract(Hours, 'hours')
            .subtract(Minutes, 'minutes')
            .format('LLL');
          if (moment(cutoff, 'MMMM DD, YYYY hh:mma').isBefore(moment.now())) {
            delete availability[thisDate][time];
          } else {
            break;
          }
          if (Object.keys(availability[thisDate]).length === 0) {
            noAvailability = true;
          }
        }

        return noAvailability;
      }
    } else {
      noAvailability = true;
    }
    return noAvailability;
  }

  removeCutoffAndSoldOut(
    availability: ISortedAvailability | null,
    day: Moment,
    bookingCutoff: IBookingCutoff | null
  ) {
    let noAvailability = false;

    if (
      !!availability &&
      !!availability[formatDate(day)] &&
      Object.keys(availability[formatDate(day)]).length > 0
    ) {
      const thisDate = formatDate(day);
      Object.keys(availability[formatDate(day)]).map((time) => {
        const date = thisDate + ' ' + time;
        if (availability[thisDate][time].SoldOut) {
          delete availability[thisDate][time];
        }
        if (bookingCutoff) {
          const { Weeks, Days, Hours, Minutes } = bookingCutoff;
          const cutoff = moment(date, 'MMMM DD, YYYY hh:mma')
            .utc()
            .subtract(Weeks, 'weeks')
            .subtract(Days, 'days')
            .subtract(Hours, 'hours')
            .subtract(Minutes, 'minutes')
            .format('LLL');
          if (moment(cutoff, 'MMMM DD, YYYY hh:mma').isBefore(moment.now())) {
            delete availability[thisDate][time];
          }
          if (Object.keys(availability[thisDate]).length === 0) {
            /*delete availability[thisDate];*/
            noAvailability = true;
          }
        }

        return noAvailability;
      });
    } else {
      noAvailability = true;
    }

    return noAvailability;
  }

  // TourPrice

  getPersonCount = (personType: string) => {
    const { personCounter } = this.state;
    if (!!personCounter && !!personCounter[personType]) {
      return personCounter[personType].count;
    }
    return 0;
  };

  // TourPickup
  changePickupDropoff = (event: ISelectValue, pickupOrDropoff: pickOrDrop) => {
    const { pickupAndDropoffPlaces } = this.state;
    if (event) {
      const addressId = event.value;
      if (!!pickupAndDropoffPlaces && pickupOrDropoff === 'pickup') {
        if (addressId) {
          const foundPickup = this.findPickupDropoffFromAddressId(
            addressId,
            'pickup'
          );
          if (foundPickup) {
            this.setState({
              selectedPickup: {
                label: foundPickup.Name,
                value: foundPickup.Id,
              },
            });
          }
        } else {
          this.setState({ selectedPickup: event });
        }
      }
      if (!!pickupAndDropoffPlaces && pickupOrDropoff === 'dropoff') {
        if (addressId) {
          const foundDropoff = this.findPickupDropoffFromAddressId(
            addressId,
            'dropoff'
          );
          if (foundDropoff) {
            this.setState({
              selectedDropoff: {
                label: foundDropoff.Name,
                value: foundDropoff.Id,
              },
            });
          }
        } else {
          this.setState({ selectedDropoff: event });
        }
      }
    }
  };

  handleNeedPickupDropoff = (
    event: React.ChangeEvent<HTMLInputElement>,
    pickupOrDropoff: string
  ) => {
    const target = event.target;
    if (target) {
      if (pickupOrDropoff === 'pickup') {
        // this.setState({ needPickup: target.checked });
      } else if (pickupOrDropoff === 'dropoff') {
        this.setState({ needDropoff: target.checked });
      }
    }
  };

  findPickupDropoffFromAddressId(addressId: string, pickupOrDropoff: string) {
    const { pickupAndDropoffPlaces } = this.state;
    if (
      !!pickupAndDropoffPlaces &&
      !!pickupAndDropoffPlaces.PickupPlaces &&
      pickupOrDropoff === 'pickup'
    ) {
      return pickupAndDropoffPlaces.PickupPlaces.find((a) => {
        return a.Id === addressId;
      });
    } else if (
      !!pickupAndDropoffPlaces &&
      !!pickupAndDropoffPlaces.DropoffPlaces &&
      pickupOrDropoff === 'dropoff'
    ) {
      return pickupAndDropoffPlaces.DropoffPlaces.find((a) => {
        return a.Id === addressId;
      });
    }
    return null;
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale, lang } = reducers.translationReducers;
  return { cartObject, currency, locale, lang };
};

export default connect(mapStateToProps, {
  setCart,
})(
  ({
    currency,
    productId,
    tourTitle,
    product,
    isMobile,
    setCart,
    locale,
    lang,
    tour,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TourEngineQuery {
          allContentfulTranslationsPage {
            edges {
              node {
                node_locale
                tourBookingBoxMissingOrSoldOut
                ...TranslationPageBookingBox
              }
            }
          }
        }
      `}
      render={(data) => (
        <TourEngine
          localeData={
            data.allContentfulTranslationsPage.edges.filter(
              (node: { node: { node_locale: any } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          currency={currency}
          productId={productId}
          tourTitle={tourTitle}
          product={product}
          isMobile={isMobile}
          setCart={setCart}
          locale={locale}
          lang={lang}
          tour={tour}
        />
      )}
    />
  )
);
