import React from 'react';
import cx from 'classnames';

import * as styles from './ExclusiveTours.module.scss';
import { ITourItem } from '../../interfaces';
import { Image, Link } from '../../components';

interface IProps {
  title: string;
  tours: ITourItem[] | null;
  langPath: string;
}

const ExclusiveTours: React.FunctionComponent<IProps> = ({
  title,
  tours,
  langPath,
}) =>
  !tours ? null : (
    <div className='centered-content vertical-margin'>
      <h2 className={cx('element-title', styles.title)}>{title}</h2>
      <div className='columns is-gapless is-multiline'>
        {tours.map((tour) => (
          <Link
            to={`${tour.pathPrefix}/${tour.slug}`}
            langPath={langPath}
            className={cx('column is-6', styles.tour)}
            key={tour.id}
          >
            <Image fluidImage={tour.heroImage} className='fill-image' />
            <h3 className={styles.tourTitle}>{tour.title}</h3>
            <div
              className={styles.overlay}
              onClick={(e: any) => e.preventDefault()}
            >
              <h3>{tour.title}</h3>
              <p style={{ overflow: 'auto', paddingRight: '2rem' }}>
                {tour.summary}
              </p>
              <Link
                className='basic-button'
                to={`${tour.pathPrefix}/${tour.slug}`}
                langPath={langPath}
              >
                See more
              </Link>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );

export default ExclusiveTours;
