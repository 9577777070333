import React from 'react';
import { ISortedAvailability, ISelectValue } from '../../../interfaces';
import { Moment } from 'moment';
import { formatDate, formatHardcodedStringWithVariables } from '../../../utils';
import FilledBox from '../FilledBox';

interface IProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  text: string;
}

const MaxBookingText: React.FunctionComponent<IProps> = props => {
  function getMaxBookingText(receivedProps: IProps) {
    const { availability, selectedDate, selectedTime, text } = receivedProps;

    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value]
    ) {
      let maxToBook = 0;
      let unlimitedMax = false;
      availability[formatDate(selectedDate)][selectedTime.value].Rates.map(
        rate => {
          if (rate.MaxPerBooking > maxToBook) {
            maxToBook = rate.MaxPerBooking;
          }
          if (!rate.MaxPerBooking) {
            unlimitedMax = true;
          }
        }
      );

      if (unlimitedMax) {
        maxToBook = 0;
      }

      if (!!maxToBook && maxToBook > 1) {
        const formattedString = formatHardcodedStringWithVariables(text, [
          maxToBook.toString()
        ]);
        return (
          <div style={{ padding: '10px 0 10px 0' }}>
            <FilledBox />
            <p>{formattedString}</p>
          </div>
        );
      }
      return null;
    }
    return null;
  }

  return getMaxBookingText(props);
};

export default MaxBookingText;
