import React from 'react';
import * as styles from './index.module.scss';
import { ITransportPersonCounter } from '../../../interfaces';
import { formatPrice } from '../../../utils';

interface IProps {
  personCounter: ITransportPersonCounter | null;
  withReturn?: boolean;
  getPersonCount(name: string): number;
  setTotalPrice(total: number): void;
}

const TransportPrice: React.FunctionComponent<IProps> = ({
  personCounter,
  getPersonCount,
  withReturn,
  setTotalPrice,
}) => {
  if (personCounter) {
    let totalPrice = 0;
    return (
      <div className={styles.container}>
        {Object.keys(personCounter).map((key) => {
          const personCount = getPersonCount(key);
          const person = personCounter[key];
          const priceCategory = withReturn
            ? personCounter[key].ReturnPrice
            : personCounter[key].Price;
          if (personCount > 0 && !!priceCategory) {
            totalPrice = totalPrice + personCount * priceCategory.Amount;
            setTotalPrice(totalPrice);
            return (
              <div
                key={key + priceCategory.Amount + priceCategory.Currency}
                className={styles.priceItem}
              >
                <div>
                  <p key={person.Id}>
                    {personCount} x {person.Name}
                  </p>
                </div>
                <div>
                  <p className={styles.noWrap}>
                    {formatPrice(
                      personCount * priceCategory.Amount,
                      priceCategory.Currency
                    )}{' '}
                    {priceCategory.Currency}
                  </p>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default TransportPrice;
