import React, { useRef, useState, useEffect } from 'react';
import { TransportModal } from '../components';
import { connect } from 'react-redux';
import {
  ICurrencyOption,
  /* IProduct, */
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ISelectTime,
  ITransportPersonCounter,
  AddOrRemove,
  ITranslationsBookingModal,
  IReducers,
  ITourDetails,
} from '../../interfaces';

import TransportBookingBox from '../components/TransportBookingBox';
import { useOuterClickNotifier } from '../../utils';
import { Moment } from 'moment';
import { StaticQuery, graphql } from 'gatsby';

interface IExternalProps {
  currency: ICurrencyOption;
  productId: string;
  transport: ITransport | null;
  transportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartDate: Moment;
  selectedEndDate: Moment | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  returnTicket: boolean;
  locale: string;
  tour: ITourDetails;
  isLoadingStartTimes: boolean;
  isLoadingEndTimes: boolean;

  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment, transportReturn: boolean): void;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  addToCart(): Promise<boolean>;
  toggleReturnTicket(ticket: boolean): void;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
}

const TransportBooking: React.FunctionComponent<IProps> = (props) => {
  const [dropdownOpen, triggerDropdown] = useState(false);
  const [isValid, triggerValid] = useState(true);

  const dropdown = useRef<HTMLDivElement | null>(null);
  const dropoffDiv = useRef<HTMLDivElement | null>(null);
  const pickupDiv = useRef<HTMLDivElement | null>(null);
  const returnDiv = useRef<HTMLDivElement | null>(null);
  const priceRef = useRef<HTMLDivElement | null>(null);
  const button = useRef<HTMLButtonElement | null>(null);

  useOuterClickNotifier((e) => {
    if (
      !!dropoffDiv &&
      !!dropoffDiv.current &&
      !!dropoffDiv.current.contains(e.target) &&
      !!pickupDiv &&
      !!pickupDiv.current &&
      !!pickupDiv.current.contains(e.target)
    ) {
      triggerDropdown(true);
    }
  }, null);
  useOuterClickNotifier(
    (e) => {
      if (
        !!dropdown &&
        !!dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !!button &&
        !!button.current &&
        !button.current.contains(e.target) &&
        !!returnDiv &&
        !!returnDiv.current &&
        !returnDiv.current.contains(e.target) &&
        !!priceRef &&
        !!priceRef.current &&
        !priceRef.current.contains(e.target)
      ) {
        triggerDropdown(false);
      }
    },
    pickupDiv,
    dropoffDiv
  );

  const {
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    returnTicket,
    transport,
    selectedEndDate,
    selectedEndTime,
    productId,
    changeSelectedDate,
    changeSelectedTime,
    toggleReturnTicket,
    endTimeOptions,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
  } = props;

  useEffect(() => {
    if (returnTicket) {
      if (
        !!selectedStartDate &&
        !!selectedStartTime &&
        !!selectedEndDate &&
        !!selectedEndTime &&
        props.getTotalPersonCount() > 0
      ) {
        triggerValid(true);
      }
    } else {
      if (
        !!selectedStartDate &&
        !!selectedStartTime &&
        props.getTotalPersonCount() > 0
      ) {
        triggerValid(true);
      }
    }
  }, [
    returnTicket,
    selectedStartDate,
    selectedStartTime,
    selectedEndDate,
    selectedEndTime,
  ]);

  async function addToCart() {
    if (returnTicket) {
      if (
        !!props.selectedStartDate &&
        !!props.selectedStartTime &&
        !!props.selectedEndDate &&
        !!props.selectedEndTime &&
        props.getTotalPersonCount() > 0
      ) {
        triggerValid(true);
        const success = await props.addToCart();
        if (success) {
          triggerDropdown(false);
        }
      } else {
        triggerValid(false);
      }
    } else {
      if (
        !!props.selectedStartDate &&
        !!props.selectedStartTime &&
        props.getTotalPersonCount() > 0
      ) {
        triggerValid(true);
        const success = await props.addToCart();
        if (success) {
          triggerDropdown(false);
        }
      } else {
        triggerValid(false);
      }
    }
  }

  function openBookingDropdown() {
    if (!!props.selectedStartPlace && !!props.selectedEndPlace) {
      triggerDropdown(true);
      triggerValid(true);
    } else {
      triggerValid(false);
    }
  }
  if (tour.slug == 'icelandair') {
    return (
      <React.Fragment>
        <TransportModal
          modalOpen={dropdownOpen}
          toggleModal={triggerDropdown}
          startPlaces={startPlaces}
          endPlaces={endPlaces}
          selectedStartPlace={selectedStartPlace}
          selectedEndPlace={selectedEndPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          selectedStartDate={selectedStartDate}
          transport={transport}
          changeSelectedDate={changeSelectedDate}
          selectedStartTime={selectedStartTime}
          starTimeOptions={starTimeOptions}
          changeSelectedTime={changeSelectedTime}
          returnTicket={returnTicket}
          toggleReturnTicket={toggleReturnTicket}
          selectedEndDate={selectedEndDate}
          selectedEndTime={selectedEndTime}
          endTimeOptions={endTimeOptions}
          personCounter={personCounter}
          changePersonCounter={changePersonCounter}
          priceRef={priceRef}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          addToCart={addToCart}
          addingToCart={addingToCart}
          tour={tour}
          isLoadingStartTimes={isLoadingStartTimes}
          isLoadingEndTimes={isLoadingEndTimes}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TransportBookingBox
          dropdownOpen={dropdownOpen}
          addToCart={addToCart}
          addingToCart={addingToCart}
          openBookingDropdown={openBookingDropdown}
          startPlaces={startPlaces}
          selectedStartPlace={selectedStartPlace}
          changeSelectedPlace={changeSelectedPlace}
          isValid={isValid}
          endPlaces={endPlaces}
          selectedEndPlace={selectedEndPlace}
          button={button}
          pickupDiv={pickupDiv}
          dropoffDiv={dropoffDiv}
          flybus={productId === '9'}
        />
        <TransportModal
          modalOpen={dropdownOpen}
          toggleModal={triggerDropdown}
          startPlaces={startPlaces}
          endPlaces={endPlaces}
          selectedStartPlace={selectedStartPlace}
          selectedEndPlace={selectedEndPlace}
          isValid={isValid}
          changeSelectedPlace={changeSelectedPlace}
          selectedStartDate={selectedStartDate}
          transport={transport}
          changeSelectedDate={changeSelectedDate}
          selectedStartTime={selectedStartTime}
          starTimeOptions={starTimeOptions}
          changeSelectedTime={changeSelectedTime}
          returnTicket={returnTicket}
          toggleReturnTicket={toggleReturnTicket}
          selectedEndDate={selectedEndDate}
          selectedEndTime={selectedEndTime}
          endTimeOptions={endTimeOptions}
          personCounter={personCounter}
          changePersonCounter={changePersonCounter}
          priceRef={priceRef}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          addToCart={addToCart}
          addingToCart={addingToCart}
          tour={tour}
          isLoadingStartTimes={isLoadingStartTimes}
          isLoadingEndTimes={isLoadingEndTimes}
        />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer, translationReducers } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale } = translationReducers;
  return { cartObject, currency, locale };
};

export default connect(mapStateToProps)(
  ({
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    returnTicket,
    transport,
    selectedEndDate,
    selectedEndTime,
    currency,
    productId,
    transportRoutes,
    endTimeOptions,
    changeSelectedDate,
    changeSelectedTime,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    addToCart,
    toggleReturnTicket,
    locale,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportBookingQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                ...BookingModalHardcodedStrings
              }
            }
          }
        }
      `}
      render={(data) => (
        <TransportBooking
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          startPlaces={startPlaces}
          endPlaces={endPlaces}
          selectedStartPlace={selectedStartPlace}
          changeSelectedPlace={changeSelectedPlace}
          selectedEndPlace={selectedEndPlace}
          selectedStartDate={selectedStartDate}
          starTimeOptions={starTimeOptions}
          selectedStartTime={selectedStartTime}
          personCounter={personCounter}
          addingToCart={addingToCart}
          returnTicket={returnTicket}
          transport={transport}
          selectedEndDate={selectedEndDate}
          selectedEndTime={selectedEndTime}
          currency={currency}
          productId={productId}
          transportRoutes={transportRoutes}
          endTimeOptions={endTimeOptions}
          changeSelectedDate={changeSelectedDate}
          changeSelectedTime={changeSelectedTime}
          changePersonCounter={changePersonCounter}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          addToCart={addToCart}
          toggleReturnTicket={toggleReturnTicket}
          locale={locale}
          tour={tour}
          isLoadingStartTimes={isLoadingStartTimes}
          isLoadingEndTimes={isLoadingEndTimes}
        />
      )}
    />
  )
);
