import React from 'react';
import * as styles from './index.module.scss';
import { CheckBox } from '../../../components';

interface IProps {
  returnTicket: boolean;
  returnButtonText: string;
  toggleReturnTicket(ticket: boolean): void;
}

const TransportReturnToggle: React.FunctionComponent<IProps> = ({
  returnTicket,
  returnButtonText,
  toggleReturnTicket,
}) => {
  return (
    <div className={styles.container}>
      <CheckBox
        checked={returnTicket}
        toggleCheckbox={toggleReturnTicket}
        blueBorderColor={true}
      >
        {returnButtonText}
      </CheckBox>
    </div>
  );
};

export default TransportReturnToggle;
