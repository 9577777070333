import React, { FunctionComponent, SyntheticEvent } from 'react';
import * as styles from './index.module.scss';
import cx from 'classnames';

interface IButtonProps {
  disabled?: boolean;
  tag?: 'PRIMARY' | 'secondary';
  onClick?: (ev: SyntheticEvent) => void;
}

const Button: FunctionComponent<IButtonProps> = ({
  disabled,
  children,
  tag,
  onClick,
}) => (
  <button
    className={cx(styles.button, tag === 'secondary' && styles.secondary)}
    {...{ disabled, onClick }}
  >
    {children}
  </button>
);

export default Button;
