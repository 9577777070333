import * as React from 'react';

export interface IWindowSizeProps {
  windowWidth: number;
  windowHeight: number;
}

const withWindowSize = <P extends {}>(
  Component: React.ComponentType<P & IWindowSizeProps>
) =>
  class WithWindowSize extends React.Component<P> {
    readonly state = {
      windowWidth: 0,
      windowHeight: 0
    };

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
      return <Component {...this.state} {...this.props} />;
    }

    updateWindowDimensions = () => {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    };
  };

export default withWindowSize;
