import React from 'react';

export const Warning = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='80'
		height='80'
		viewBox='0 0 53.947 53.947'
		fill='#fff'
	>
		<g id='baseline-error_outline-24px' transform='translate(-2.324 -2.324)'>
			<path
				id='Path_34'
				d='M26.276 37.066h5.395v5.394h-5.395zm0-21.579h5.395v16.184h-5.395zM28.947 2a26.974 26.974 0 1 0 27 26.974A26.96 26.96 0 0 0 28.947 2zm0 50.984c-11.922 0-23.628-12.088-23.628-24.01S17.024 5.05 28.947 5.05s23.638 12 23.638 23.923-11.716 24.011-23.638 24.011z'
				data-name='Path 34'
				transform='translate(.324 .324)'
			/>
		</g>
	</svg>
);
export default Warning;
