import React from 'react';

export const DoubleSeats = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' enableBackground='new 0 0 50 50' xmlSpace='preserve'>
    <style>{'.st6_icon{fill:#da7d6d}'}</style>
    <g id='Layer_1'>
      <path
        d='M46.6 48.5H3.4c-1 0-1.9-.8-1.9-1.9V3.4c0-1 .8-1.9 1.9-1.9h43.3c1 0 1.9.8 1.9 1.9v43.3c-.1 1-.9 1.8-2 1.8z'
        style={{
          fill: 'none',
          stroke: '#e58170',
          strokeWidth: 1.6626,
          strokeMiterlimit: 10,
        }}
      />
      <path
        className='st6_icon'
        d='M45.5 22.6h-2.8c-.2 0-.3.2-.3.3v.5c0 .2.2.3.3.3h.8v1.2c0 1.3-1 2.3-2.3 2.3V27c0-.5-.4-1-1-1H9.4c-.5 0-1 .4-1 1v.2c-1.3 0-2.3-1-2.3-2.3v-1.2H7c.2 0 .3-.2.3-.3v-.5c0-.2-.2-.3-.3-.3H4.2c-.2 0-.3.2-.3.3v.5c0 .2.2.3.3.3H5v1.2c0 1.8 1.5 3.4 3.4 3.4v.2c0 .5.4 1 1 1h31c.5 0 1-.4 1-1v-.2c1.8 0 3.4-1.5 3.4-3.4v-1.2h.8c.2 0 .3-.2.3-.3v-.5c-.1-.2-.2-.3-.4-.3zM41.3 37.9h-.8l-.3-7.3h-2.3l.1 1.2H11.7l.1-1.2H9.6l-.3 7.3h-.9c-.2 0-.3.2-.3.3v.5c0 .2.2.3.3.3h3.9c.2 0 .3-.2.3-.3v-.5c0-.2-.2-.3-.3-.3h-.9l.2-3.9H38l.2 3.9h-.8c-.2 0-.3.2-.3.3v.5c0 .2.2.3.3.3h3.9c.2 0 .3-.2.3-.3v-.5c0-.1-.2-.3-.3-.3z'
      />
      <path
        className='st6_icon'
        d='M28.1 13.7c0-.3.3-.4.5-.4h7.6c.3 0 .5.2.5.4l.5 11.3h2.3L39 13.7c-.1-1.5-1.3-2.7-2.8-2.7h-7.6c-1.5 0-2.7 1.2-2.8 2.7L25.3 25h2.3l.5-11.3zM12.9 13.7c0-.3.3-.4.5-.4H21c.3 0 .5.2.5.4l.5 11.2h2.3l-.5-11.3c-.1-1.5-1.3-2.7-2.8-2.7h-7.6c-1.5 0-2.7 1.2-2.8 2.7l-.5 11.3h2.3l.5-11.2z'
      />
    </g>
  </svg>
);

export default DoubleSeats;
