import React from 'react';

export const CheckCircle = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='54.673'
    height='54.672'
    viewBox='0 0 54.673 54.672'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2.5px'
  >
    <g id='OK' transform='translate(-890.841 -50.752)'>
      <g
        id='Group_46'
        data-name='Group 46'
        transform='translate(892.091 52.002)'
      >
        <ellipse
          id='Ellipse_138'
          cx='26.086'
          cy='26.086'
          stroke='#afafaf'
          data-name='Ellipse 138'
          rx='26.086'
          ry='26.086'
        />
        <g
          id='Group_45'
          data-name='Group 45'
          transform='translate(17.032 11.068)'
        >
          <path
            id='Path_47'
            d='M907.231 74.012l7.477 7.477a2.245 2.245 0 0 0 3.178 0l19.645-19.649'
            stroke='#2d8377'
            data-name='Path 47'
            transform='translate(-907.231 -61.84)'
          />
        </g>
      </g>
    </g>
  </svg>
);
export default CheckCircle;
