import React from 'react';
import { Link } from 'gatsby';
// TODO ATHUGA
interface IProps {
  langPath: string;
  to: string;
  children: React.ReactNode;
  className?: string;
  // TODO: Kolibri Fix typings
  style?: any;
  queryParams?: string;
}

const LangLink = ({
  langPath,
  to,
  children,
  className,
  style,
  queryParams
}: IProps) => {
  let path = to;
  if (path.length > 1 && path[path.length - 1] !== '/') {
    path += '/';
  }

  return (
    <Link
      to={'/' + langPath + path + (queryParams ? queryParams : '')}
      className={className}
      style={style}
    >
      {children}
    </Link>
  );
};

export default LangLink;
