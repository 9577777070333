import React from 'react';

export const Square = () => (
  <svg
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path fill='#FA7268' d='M0 0h8v8H0z' />
  </svg>
);

export default Square;
