import React from 'react';

// arrow right
export const ArrowRight = () => (
  <svg
    width='11'
    height='8'
    fill='none'
    viewBox='0 0 11 8'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.354 4.354a.5.5 0 000-.708L7.172.464a.5.5 0 10-.708.708L9.293 4 6.464 6.828a.5.5 0 10.708.708l3.182-3.182zM0 4.5h10v-1H0v1z'
      fill='#2C2C2C'
    />
  </svg>
);
export default ArrowRight;
