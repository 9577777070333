import React from 'react';
import { ISortedAvailability, ISelectValue } from '../../../interfaces';
import { Moment } from 'moment';
import { formatDate } from '../../../utils';

interface IProps {
  availability: ISortedAvailability | null;
  selectedDate: Moment | null;
  selectedTime: ISelectValue | null;
  getTotalPersonCount(): number;
  text: {
    singular: string;
    plural: string;
  };
}
// To do, athuga med formatDate  , þungt!
const AvailabilityCount: React.FunctionComponent<IProps> = props => {
  function getAvailabilityCount() {
    const {
      availability,
      selectedDate,
      selectedTime,
      getTotalPersonCount,
      text
    } = props;
    if (
      !!availability &&
      !!selectedDate &&
      !!selectedTime &&
      !!availability[formatDate(selectedDate)] &&
      !!availability[formatDate(selectedDate)][selectedTime.value] &&
      !!availability[formatDate(selectedDate)][selectedTime.value].Available
    ) {
      const totalCount = getTotalPersonCount();
      const availableLeft =
        availability[formatDate(selectedDate)][selectedTime.value].Available;
      if (totalCount > availableLeft - 6) {
        const remaining = availableLeft - totalCount;
        const singularOrPlural = remaining === 1 ? text.singular : text.plural;
        return (
          <p
            style={{
              display: 'flex',
              padding: 10,
              color: '#fa7268'
            }}
          >
            {remaining + ' ' + singularOrPlural}
          </p>
        );
      }
    }
    return null;
  }

  return getAvailabilityCount();
};

export default AvailabilityCount;
