import { combineReducers } from 'redux';

import currencyReducer from './currencyReducers';
import cartReducers from './cartReducers';
import translationReducers from './translationReducers';
import layoutReducer from './layoutReducer';

export default combineReducers({
  currencyReducer,
  cartReducers,
  translationReducers,
  layoutReducer
});
