// extracted by mini-css-extract-plugin
export var productCard = "index-module--productCard--nCrOj";
export var productCardLink = "index-module--productCardLink--1U7TR";
export var card = "index-module--card--3Sh7O";
export var imageContainer = "index-module--imageContainer--GXSZS";
export var image = "index-module--image--dNzGj";
export var specialOfferBanner = "index-module--specialOfferBanner--17DmU";
export var category = "index-module--category--1Nv3s";
export var title = "index-module--title--34OxJ";
export var priceContainer = "index-module--priceContainer---uBEj";
export var originalPrice = "index-module--originalPrice--3Zudu";
export var finalPrice = "index-module--finalPrice--2IMVn";
export var discountPrice = "index-module--discountPrice--2qkE5";