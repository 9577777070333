import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
} from '../../interfaces';
import * as styles from './Questions.module.scss';

interface IProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  changeVal(val: string): void;
}

const LongQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion } = props;
  const inputLabelId =
    bookingQuestion && bookingQuestion.Label
      ? bookingQuestion.Label.replace(/\s+/g, '-')
      : 'NeedLongQuestionId';

  if (bookingQuestion) {
    return (
      <div>
        {bookingQuestion.extra ? (
          <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
            {bookingQuestion.extra}
          </h5>
        ) : null}
        <label
          htmlFor={inputLabelId}
          className={cx(styles.label, {
            [styles.required]: bookingQuestion.Required,
          })}
        >
          {bookingQuestion.Label}
        </label>
        <textarea
          id={inputLabelId}
          rows={5}
          className={cx(
            styles.textInput,
            styles.area,
            !bookingQuestion.isValid ? styles.errorField : null
          )}
          value={bookingQuestion.value ? bookingQuestion.value : ''}
          placeholder={
            bookingQuestion.Placeholder ? bookingQuestion.Placeholder : ''
          }
          onChange={(e) => props.changeVal(e.target.value)}
        />
      </div>
    );
  } else if (contactQuestion) {
    const inputLabelId = contactQuestion.Label
      ? contactQuestion.Label.replace(/\s+/g, '-')
      : 'NeedLongcontactQuestionId';

    return (
      <div>
        <label
          htmlFor={inputLabelId}
          className={cx(styles.label, {
            [styles.required]: contactQuestion.Required,
          })}
        >
          {contactQuestion.Label}
        </label>
        <textarea
          id={inputLabelId}
          rows={5}
          className={cx(
            styles.textInput,
            styles.area,
            !contactQuestion.isValid ? styles.errorField : null
          )}
          value={contactQuestion.value ? contactQuestion.value : ''}
          placeholder={
            contactQuestion.Placeholder ? contactQuestion.Placeholder : ''
          }
          onChange={(e) => props.changeVal(e.target.value)}
        />
      </div>
    );
  }
  return null;
};

export default LongQuestion;
