import React from 'react';
import * as styles from './index.module.scss';
import TourBookableExtras from '../TourBookableExtras';
import Title from '../../../components/Title';
import {
  ISelectValue,
  IPersonCounter,
  IBookableExtraState,
  DataType,
} from '../../../interfaces';

type AddOrRemove = 'addOne' | 'removeOne';

interface IProps {
  personCounter: IPersonCounter | null;
  bookableExtraCounter: IBookableExtraState | null;
  title: string;
  changeBookableExtraCounter(
    addorRemove: AddOrRemove,
    extraType: string | null,
    priceCategory?: string
  ): void;
  changeAnswer(
    type: DataType,
    key: string,
    answerIndex: number,
    questionId: string,
    categoryId: number,
    value: string | null,
    selectValue: ISelectValue[] | null,
    time: string | null
  ): void;
  toggleSelectExtra(name: string): void;
}

const TourBookingExtras: React.FunctionComponent<IProps> = ({
  personCounter,
  bookableExtraCounter,
  changeBookableExtraCounter,
  changeAnswer,
  toggleSelectExtra,
  title,
}) => {
  return (
    <div className={styles.bookableExtraContainer}>
      <Title tag='h3' theme={['sizeDesktopSmall']}>
        {title}
      </Title>
      <TourBookableExtras
        personCounter={personCounter}
        bookableExtras={bookableExtraCounter}
        changeBookableExtraCounter={changeBookableExtraCounter}
        changeAnswer={changeAnswer}
        toggleSelectExtra={toggleSelectExtra}
      />
    </div>
  );
};

export default TourBookingExtras;
