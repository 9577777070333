import React from 'react';
import * as styles from './index.module.scss';
import { IPersonCounter, IReducers } from '../../../interfaces';
import { graphql, StaticQuery } from 'gatsby';
import { connect } from 'react-redux';

interface IExternalProps {
  personCounter: IPersonCounter;
  counterKey: string;
  locale: string;
}

interface IProps extends IExternalProps {
  localeData: {
    age: string;
  };
}

const CounterNames: React.FunctionComponent<IProps> = ({
  personCounter,
  counterKey,
  localeData,
}) => {
  return (
    <div key={counterKey} className={styles.textContainer}>
      <div>
        <p className={styles.mainText}>{counterKey}</p>
      </div>
      {personCounter[counterKey].minAge === 0 &&
      personCounter[counterKey].maxAge === 0 ? null : (
        <p className={styles.secondaryText}>
          {personCounter[counterKey].minAge +
            (personCounter[counterKey].maxAge > personCounter[counterKey].minAge
              ? ' - ' + personCounter[counterKey].maxAge
              : '+') +
            ' ' +
            localeData.age}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(
  ({ personCounter, counterKey, locale }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query CounterNames {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                age
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => (
        <CounterNames
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          personCounter={personCounter}
          counterKey={counterKey}
          locale={locale}
        />
      )}
    />
  )
);
