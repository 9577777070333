// dynamicIconImports.ts
import { lazy } from 'react';

const dynamicIconImports = (name: string) => {
  return lazy(() =>
    import(`@phosphor-icons/react`).then((module) => {
      const IconComponent = module[name as keyof typeof module];
      if (IconComponent) {
        return { default: IconComponent as React.ComponentType<any> };
      }
      throw new Error(`Icon ${name} does not exist in Phosphor icons`);
    })
  );
};

export default dynamicIconImports;
