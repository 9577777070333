// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var footerTitle = "Footer-module--footerTitle--aN0TY";
export var linkList = "Footer-module--linkList--3cOcp";
export var agentLoginArea = "Footer-module--agentLoginArea--1xm_K";
export var agentButton = "Footer-module--agentButton--31BgM";
export var social = "Footer-module--social--1qeaN";
export var mobileSocial = "Footer-module--mobileSocial--1clHB";
export var line = "Footer-module--line--XmWKJ";
export var lineMargin = "Footer-module--lineMargin--XqOBD";
export var infoContainer = "Footer-module--infoContainer--2blJX";
export var info = "Footer-module--info--2wr65";
export var mobileInfo = "Footer-module--mobileInfo--1Mftf";
export var icons = "Footer-module--icons--2dm8w";
export var item = "Footer-module--item--23lUu";
export var question = "Footer-module--question--nPyJf";
export var links = "Footer-module--links--1BKb1";
export var arrow = "Footer-module--arrow--2W1kG";