import React, { useRef, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import {
  TransportDatePicker,
  TransportSelect,
  TransportTime,
  TransportReturnToggle,
  TransportReturnTicket,
  TransportGuests,
  TransportPriceBreakdown,
} from '../components';
import { connect } from 'react-redux';
import * as styles from './TransportBooking.module.scss';
import {
  ICurrencyOption,
  ITransport,
  ITransportOption,
  ISortedTransport,
  ISelectPickupValue,
  ITransportList,
  ISelectTime,
  ITransportPersonCounter,
  AddOrRemove,
  ITranslationsBookingModal,
  IReducers,
  ITourDetails,
  ITranslationsCheckout,
} from '../../interfaces';
import { Moment } from 'moment';
import TourBookingHeader from '../components/TourBookingHeader';
import { icelandairlogo } from '../../../src/icons';
import IcelandairCover from '../components/IcelandairCover';

interface IExternalProps {
  tour: ITourDetails;
  currency: ICurrencyOption;
  productId: string;
  transport: ITransport | null;
  transportRoutes: ISortedTransport | null;
  startPlaces: ITransportOption[] | null;
  endPlaces: ITransportOption[] | null;
  selectedStartDate: Moment;
  selectedEndDate: Moment | null;
  selectedStartPlace: ISelectPickupValue | null;
  selectedEndPlace: ISelectPickupValue | null;
  selectedStartTime: ISelectTime | null;
  selectedEndTime: ISelectTime | null;
  starTimeOptions: ISelectTime[] | null;
  endTimeOptions: ISelectTime[] | null;
  personCounter: ITransportPersonCounter | null;
  addingToCart: boolean;
  returnTicket: boolean;
  locale: string;
  isLoadingStartTimes: boolean;
  isLoadingEndTimes: boolean;
  changeSelectedPlace(place: ISelectPickupValue, list: ITransportList): void;
  changeSelectedDate(date: Moment, transportReturn: boolean): void;
  changeSelectedTime(time: ISelectTime, transportReturn: boolean): void;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getTotalPersonCount(): number;
  getPersonCount(name: string): number;
  addToCart(): void;
  toggleReturnTicket(ticket: boolean): void;

  getTimeError(): boolean;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsBookingModal;
  localeData2: ITranslationsCheckout;
}

const TransportBookingMobile: React.FunctionComponent<IProps> = (props) => {
  const [isValid, triggerValid] = useState(true);
  const priceRef = useRef<HTMLDivElement | null>(null);

  const {
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    returnTicket,
    transport,
    localeData,
    localeData2,
    changeSelectedTime,
    toggleReturnTicket,
    selectedEndDate,
    changeSelectedDate,
    selectedEndTime,
    endTimeOptions,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
  } = props;
  const [dropdownOpen, triggerDropdown] = useState(false);
  function addToCart() {
    if (returnTicket) {
      if (
        !!props.selectedStartTime &&
        !!props.selectedStartTime &&
        !!props.selectedEndDate &&
        !!props.selectedEndTime &&
        props.getTotalPersonCount() > 0
      ) {
        triggerValid(true);
        props.addToCart();
      } else {
        triggerValid(false);
      }
    } else {
      if (!!props.selectedStartDate && !!props.selectedStartTime) {
        triggerValid(true);
        props.addToCart();
      } else {
        triggerValid(false);
      }
    }
  }
  if (tour.slug == 'icelandair') {
    localeData.flybusHeader = 'Book a ';
    return (
      <React.Fragment>
        <div style={{ marginTop: '-3rem' }}>
          <div>
            <IcelandairCover />
          </div>
          <div className='column'>
            <TourBookingHeader
              toggleModal={triggerDropdown}
              tourTitle={localeData.flybusHeader}
              tourSlug={tour.slug}
            />
          </div>
          <div className='column'>
            <TransportSelect
              startPlaces={startPlaces}
              selectedStartPlace={selectedStartPlace}
              isValid={isValid}
              changeSelectedPlace={changeSelectedPlace}
              list='Start'
              ariaLabel='from'
              disabled={false}
              toFromLabel={localeData.from}
              isMobile={true}
            />
          </div>
          <div className='column'>
            <TransportSelect
              startPlaces={endPlaces}
              selectedStartPlace={selectedEndPlace}
              isValid={isValid}
              changeSelectedPlace={changeSelectedPlace}
              list='End'
              ariaLabel='to'
              disabled={false}
              toFromLabel={localeData.to}
              isMobile={true}
            />
          </div>
          <div className='column'>
            <TransportDatePicker
              selectedDate={selectedStartDate}
              nextAvailableDate={transport ? transport.NextAvailableDate : null}
              isReturn={false}
              changeDate={props.changeSelectedDate}
              transportKey='transportBookingMobile'
              transport={transport}
              selectedStartPlace={selectedStartPlace}
              selectedEndPlace={selectedEndPlace}
            />
          </div>
          <div />
          <div className='column'>
            <TransportTime
              selectedStartTime={selectedStartTime}
              starTimeOptions={starTimeOptions}
              isValid={isValid}
              changeSelectedTime={changeSelectedTime}
              isReturn={false}
              ariaLabel='pick a time'
              tabIndex='0'
              timeSelectorHeader={localeData.timeSelectorHeader}
              isMobile={true}
              isLoadingTimes={isLoadingStartTimes}
            />
          </div>
          <div className='column'>
            <TransportReturnToggle
              returnTicket={returnTicket}
              toggleReturnTicket={toggleReturnTicket}
              returnButtonText={localeData.returnButtonText}
            />
          </div>
          <div className='column'>
            {returnTicket ? (
              <TransportReturnTicket
                label='From'
                selectedEndPlace={selectedEndPlace}
                selectedStartPlace={selectedStartPlace}
                isValid={isValid}
                changeSelectedPlace={changeSelectedPlace}
                transport={transport}
                selectedEndDate={selectedEndDate}
                selectedStartDate={selectedStartDate}
                changeSelectedDate={changeSelectedDate}
                selectedEndTime={selectedEndTime}
                starTimeOptions={starTimeOptions}
                endTimeOptions={endTimeOptions}
                changeSelectedTime={changeSelectedTime}
                localeData={localeData}
                isLoadingEndTimes={isLoadingEndTimes}
              />
            ) : null}
          </div>
          <div className='column'>
            <TransportGuests
              personCounter={personCounter}
              changePersonCounter={changePersonCounter}
              participantsSelectorHeader={localeData.participantsSelectorHeader}
            />
          </div>
          <div className='column'>
            <TransportPriceBreakdown
              personCounter={personCounter}
              getTotalPersonCount={getTotalPersonCount}
              getPersonCount={getPersonCount}
              withReturn={returnTicket}
              priceRef={priceRef}
              priceHeader={localeData.priceHeader}
              totalPriceString={localeData.totalPrice}
              noBookingFees={localeData.noBookingFees}
              freeCancellation={localeData.freeCancellation}
              smallPrint={localeData.smallPrint}
              addToCartButton={localeData.addToCartButton}
              addToCart={addToCart}
              addingToCart={addingToCart}
              tour={tour}
              isMobile={true}
              isIcelandair={true}
              sagaTitle={localeData2.sagaTitle}
              sagaText={localeData2.sagaText}
              sagaInput={localeData2.sagaInput}
            />
          </div>
          <div>
            <img
              src={icelandairlogo}
              className={styles.icelandairLogoMobile}
              alt='icelandair logo'
            />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className='column'>
          <TransportSelect
            startPlaces={startPlaces}
            selectedStartPlace={selectedStartPlace}
            isValid={isValid}
            changeSelectedPlace={changeSelectedPlace}
            list='Start'
            ariaLabel='from'
            disabled={false}
            toFromLabel={localeData.from}
            isMobile={true}
          />
        </div>
        <div className='column'>
          <TransportSelect
            startPlaces={endPlaces}
            selectedStartPlace={selectedEndPlace}
            isValid={isValid}
            changeSelectedPlace={changeSelectedPlace}
            list='End'
            ariaLabel='to'
            disabled={false}
            toFromLabel={localeData.to}
            isMobile={true}
          />
        </div>
        <div className='column'>
          <TransportDatePicker
            selectedDate={selectedStartDate}
            nextAvailableDate={transport ? transport.NextAvailableDate : null}
            isReturn={false}
            changeDate={props.changeSelectedDate}
            transportKey='transportBookingMobile'
            transport={transport}
            selectedStartPlace={selectedStartPlace}
            selectedEndPlace={selectedEndPlace}
          />
        </div>
        <div />
        <div className='column'>
          <TransportTime
            selectedStartTime={selectedStartTime}
            starTimeOptions={starTimeOptions}
            isValid={isValid}
            changeSelectedTime={changeSelectedTime}
            isReturn={false}
            ariaLabel='pick a time'
            tabIndex='0'
            timeSelectorHeader={localeData.timeSelectorHeader}
            isMobile={true}
            isLoadingTimes={isLoadingStartTimes}
          />
        </div>
        <div className='column'>
          <TransportReturnToggle
            returnTicket={returnTicket}
            toggleReturnTicket={toggleReturnTicket}
            returnButtonText={localeData.returnButtonText}
          />
        </div>
        <div className='column'>
          {returnTicket ? (
            <TransportReturnTicket
              label='From'
              selectedEndPlace={selectedEndPlace}
              selectedStartPlace={selectedStartPlace}
              isValid={isValid}
              changeSelectedPlace={changeSelectedPlace}
              transport={transport}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              changeSelectedDate={changeSelectedDate}
              selectedEndTime={selectedEndTime}
              starTimeOptions={starTimeOptions}
              endTimeOptions={endTimeOptions}
              changeSelectedTime={changeSelectedTime}
              localeData={localeData}
              isLoadingEndTimes={isLoadingEndTimes}
            />
          ) : null}
        </div>
        <div className='column'>
          <TransportGuests
            personCounter={personCounter}
            changePersonCounter={changePersonCounter}
            participantsSelectorHeader={localeData.participantsSelectorHeader}
          />
        </div>
        <div className='column'>
          <TransportPriceBreakdown
            personCounter={personCounter}
            getTotalPersonCount={getTotalPersonCount}
            getPersonCount={getPersonCount}
            withReturn={returnTicket}
            priceRef={priceRef}
            priceHeader={localeData.priceHeader}
            totalPriceString={localeData.totalPrice}
            noBookingFees={localeData.noBookingFees}
            freeCancellation={localeData.freeCancellation}
            smallPrint={localeData.smallPrint}
            addToCartButton={localeData.addToCartButton}
            addToCart={addToCart}
            addingToCart={addingToCart}
            tour={tour}
            isMobile={true}
            isIcelandair={false}
            sagaTitle={undefined}
            sagaText={undefined}
            sagaInput={undefined}
          />
        </div>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (reducers: IReducers) => {
  const { cartReducers, currencyReducer, translationReducers } = reducers;
  const { cartObject } = cartReducers;
  const { currency } = currencyReducer;
  const { locale } = translationReducers;
  return { cartObject, currency, locale };
};

export default connect(mapStateToProps)(
  ({
    startPlaces,
    endPlaces,
    selectedStartPlace,
    changeSelectedPlace,
    selectedEndPlace,
    selectedStartDate,
    starTimeOptions,
    selectedStartTime,
    personCounter,
    addingToCart,
    returnTicket,
    transport,
    selectedEndDate,
    selectedEndTime,
    currency,
    productId,
    transportRoutes,
    endTimeOptions,
    changeSelectedDate,
    changeSelectedTime,
    changePersonCounter,
    getTotalPersonCount,
    getPersonCount,
    addToCart,
    toggleReturnTicket,
    locale,
    getTimeError,
    tour,
    isLoadingStartTimes,
    isLoadingEndTimes,
  }: IExternalProps) => (
    <StaticQuery
      query={graphql`
        query TransportMobileBookingQuery {
          allContentfulTranslationsBookingModal {
            edges {
              node {
                node_locale
                ...BookingModalHardcodedStrings
              }
            }
          }
          allContentfulTranslationsCheckout {
            edges {
              node {
                ...TranslationsCheckout
                sagaTitle
                sagaText
                sagaInput
              }
            }
          }
        }
      `}
      render={(data) => (
        <TransportBookingMobile
          localeData={
            data.allContentfulTranslationsBookingModal.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          localeData2={
            data.allContentfulTranslationsCheckout.edges.filter(
              (node: { node: { node_locale: string } }) => {
                return node.node.node_locale === locale;
              }
            )[0].node
          }
          startPlaces={startPlaces}
          endPlaces={endPlaces}
          selectedStartPlace={selectedStartPlace}
          changeSelectedPlace={changeSelectedPlace}
          selectedEndPlace={selectedEndPlace}
          selectedStartDate={selectedStartDate}
          starTimeOptions={starTimeOptions}
          selectedStartTime={selectedStartTime}
          personCounter={personCounter}
          addingToCart={addingToCart}
          returnTicket={returnTicket}
          transport={transport}
          selectedEndDate={selectedEndDate}
          selectedEndTime={selectedEndTime}
          currency={currency}
          productId={productId}
          transportRoutes={transportRoutes}
          endTimeOptions={endTimeOptions}
          changeSelectedDate={changeSelectedDate}
          changeSelectedTime={changeSelectedTime}
          changePersonCounter={changePersonCounter}
          getTotalPersonCount={getTotalPersonCount}
          getPersonCount={getPersonCount}
          addToCart={addToCart}
          toggleReturnTicket={toggleReturnTicket}
          locale={locale}
          getTimeError={getTimeError}
          tour={tour}
          isLoadingStartTimes={isLoadingStartTimes}
          isLoadingEndTimes={isLoadingEndTimes}
        />
      )}
    />
  )
);
