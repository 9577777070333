import React from 'react';
import TransportCountPerson from '../TransportCountPerson';
import { ITransportPersonCounter } from '../../../interfaces';
import * as styles from './index.module.scss';

interface IProps {
  personCounter: ITransportPersonCounter | null;
  isLagoon?: boolean;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
  getTotalPersonCount?(): number;
  getAvailabilityLeft?(): number;
}
type AddOrRemove = 'addOne' | 'removeOne';

const TransportCounter: React.FunctionComponent<IProps> = (props) => {
  const { personCounter, changePersonCounter } = props;
  function getNotAvailableText() {
    const { getAvailabilityLeft, getTotalPersonCount, isLagoon } = props;

    if (isLagoon && !!getTotalPersonCount && getTotalPersonCount() === 10) {
      return (
        <div style={{ height: 30 }}>
          <p className={styles.maxText}>Max 10 per booking!</p>
        </div>
      );
    } else if (!!getAvailabilityLeft && !!getTotalPersonCount) {
      if (getAvailabilityLeft() === getTotalPersonCount()) {
        return (
          <div style={{ height: 30 }}>
            <p className={styles.maxText}>No more availability left!</p>
          </div>
        );
      }
    }
    return null;
  }

  function getMaxPerMasterText(personType: string) {
    if (personCounter) {
      const newPersonCounter = personCounter;
      /*     let counter = 0;
       */ const realCounter: Array<{
        master: string;
        maxPersons: number;
        personType: string;
      }> = [];
      Object.keys(personCounter).map((key) => {
        const newPersonCounterItem = newPersonCounter[personType];
        if (
          personCounter[key].Id === newPersonCounterItem.MasterCategoryId &&
          !!newPersonCounterItem &&
          !!newPersonCounterItem.MaxPerMaster
        ) {
          /*  counter = personCounter[key].Count * newPersonCounterItem.MaxPerMaster;
              if (counter > 0) { */
          const maxPersons = personCounter[personType].MaxPerMaster;
          if (maxPersons) {
            realCounter.push({
              master: key,
              maxPersons,
              personType,
            });
            /* } */
          }
        }
      });
      if (realCounter.length > 0) {
        return (
          <div style={{ height: 30 }}>
            {realCounter.map((count) => {
              return (
                <p className={styles.maxText} key={personType}>
                  {'max ' +
                    count.maxPersons +
                    ' ' +
                    count.personType +
                    ' pr. ' +
                    count.master}
                </p>
              );
            })}
          </div>
        );
      }
    }
    return null;
  }
  if (personCounter) {
    return (
      <React.Fragment>
        {Object.keys(personCounter).map((key) => {
          return (
            <TransportCountPerson
              personCounter={personCounter}
              changePersonCounter={changePersonCounter}
              countKey={key}
              key={key}
            />
          );
        })}
        {Object.keys(personCounter).map((personType) => {
          return getMaxPerMasterText(personType);
        })}
        {getNotAvailableText()}
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default TransportCounter;
