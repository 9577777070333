import {
  IBookingQuestionsState,
  IQuestions,
  IReduceBookingQuestion,
  DataType,
  ISelectValue,
  IQuestionAnswers,
  IMainContactQuestions,
  ICountryOption,
} from '../../interfaces';
import * as styles from './CheckoutQuestions.module.scss';
import {
  TextQuestion,
  LongQuestion,
  IntQuestion,
  SelectQuestion,
} from '../../components';
import DoubleQuestion from '../Questions/DoubleQuestion';
import DateQuestion from '../Questions/DateQuestion';
import DateTimeQuestion from '../Questions/DateTimeQuestion';
import BooleanQuestion from '../Questions/BooleanQuestion';
import React from 'react';

interface IProps {
  questionState?: IBookingQuestionsState;
  checkoutQuestions?: IQuestions;
  mainContactQuestions?: IQuestionAnswers;
  mainContactHeader: string;
  selectedCountryCode: ICountryOption | null;
  changeAnswers?(
    key: string,
    type: string,
    index: number,
    questionType: DataType,
    value: string | null,
    time: string | null,
    selectValue: ISelectValue[] | null
  ): void;
  changeMainContactDetails?(
    key: string,
    value: string | null,
    time: string | null,
    selectValue: ISelectValue[] | null
  ): void;
  changeSelectedCountryCode(selectedCountry: ICountryOption): void;
}

const CheckoutQuestions: React.FunctionComponent<IProps> = (props) => {
  const {
    questionState,
    checkoutQuestions,
    mainContactQuestions,
    mainContactHeader,
    changeMainContactDetails,
    changeAnswers,
    changeSelectedCountryCode,
    selectedCountryCode,
  } = props;

  function getBookingTitle(checkQuestions: IQuestions, key: string) {
    const booking = checkQuestions.BookingLines
      ? checkQuestions.BookingLines.find(
          (item) => item.CartItemId === parseInt(key, 10)
        )
      : null;
    if (!!booking && !!booking.Product && !!booking.Product.Name) {
      return <h2>{booking.Product.Name}</h2>;
    }
    return null;
  }

  function renderMainQuestion(question: IMainContactQuestions) {
    if (!!question && !!changeMainContactDetails) {
      if (question.DataType === 'SHORT_TEXT' && question.SelectFromOptions) {
        return (
          <SelectQuestion
            contactQuestion={question}
            changeVal={(value) =>
              changeMainContactDetails(question.Id, null, null, value)
            }
            key={question.Id}
          />
        );
      }
      switch (question.DataType) {
        case 'SHORT_TEXT':
          if (question.DataFormat === 'PHONE_NUMBER') {
            return (
              <TextQuestion
                contactQuestion={question}
                changeVal={(value) =>
                  changeMainContactDetails(question.Id, value, null, null)
                }
                key={question.Id}
                selectedCountryCode={selectedCountryCode}
                changeSelectedCountryCode={changeSelectedCountryCode}
                showCountryCode={true}
              />
            );
          } else {
            return (
              <TextQuestion
                contactQuestion={question}
                changeVal={(value) =>
                  changeMainContactDetails(question.Id, value, null, null)
                }
                key={question.Id}
              />
            );
          }
        case 'LONG_TEXT':
          return (
            <LongQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, value, null, null)
              }
              key={question.Id}
            />
          );
        case 'DOUBLE':
          return (
            <DoubleQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, value, null, null)
              }
              key={question.Id}
            />
          );
        case 'INT':
          return (
            <IntQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, value, null, null)
              }
              key={question.Id}
            />
          );
        case 'OPTIONS':
          return (
            <SelectQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, null, null, value)
              }
              key={question.Id}
            />
          );
        case 'DATE_AND_TIME':
          return (
            <DateTimeQuestion
              contactQuestion={question}
              changeVal={(date, time) =>
                date
                  ? changeMainContactDetails(question.Id, date, null, null)
                  : time
                  ? changeMainContactDetails(question.Id, null, time, null)
                  : null
              }
              key={question.Id}
            />
          );
        case 'DATE':
          return (
            <DateQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, value, null, null)
              }
              key={question.Id}
            />
          );
        case 'BOOLEAN':
          return (
            <BooleanQuestion
              contactQuestion={question}
              changeVal={(value) =>
                changeMainContactDetails(question.Id, value, null, null)
              }
              key={question.Id}
            />
          );
      }
      return null;
    }
    return null;
  }

  function renderQuestion(
    question: IReduceBookingQuestion,
    key: string,
    type: string,
    index: number
  ) {
    if (!!question && !!changeAnswers) {
      switch (question.DataType) {
        case 'SHORT_TEXT':
          return (
            <TextQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
        case 'LONG_TEXT':
          return (
            <LongQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
        case 'DOUBLE':
          return (
            <DoubleQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
        case 'INT':
          return (
            <IntQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
        case 'OPTIONS':
          return (
            <SelectQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  null,
                  null,
                  value
                )
              }
            />
          );
        case 'DATE_AND_TIME':
          return (
            <DateTimeQuestion
              bookingQuestion={question}
              changeVal={(date, time) =>
                date
                  ? changeAnswers(
                      key,
                      type,
                      index,
                      question.DataType,
                      date,
                      null,
                      null
                    )
                  : time
                  ? changeAnswers(
                      key,
                      type,
                      index,
                      question.DataType,
                      null,
                      time,
                      null
                    )
                  : null
              }
            />
          );
        case 'DATE':
          return (
            <DateQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
        case 'BOOLEAN':
          return (
            <BooleanQuestion
              bookingQuestion={question}
              changeVal={(value) =>
                changeAnswers(
                  key,
                  type,
                  index,
                  question.DataType,
                  value,
                  null,
                  null
                )
              }
            />
          );
      }
    }
    return null;
  }
  if (!!mainContactQuestions && !!changeMainContactDetails) {
    return (
      <div className={styles.checkoutSection}>
        <h2 className={styles.subtitle}>{mainContactHeader}</h2>
        {Object.keys(mainContactQuestions).map((key) => {
          const question = mainContactQuestions[key];
          return renderMainQuestion(question);
        })}
      </div>
    );
  } else if (!!questionState && !!checkoutQuestions && !!changeAnswers) {
    return (
      <div>
        {Object.keys(questionState).map((key) => (
          <div className={styles.section} key={key}>
            <h2 className={styles.subtitle}>
              {getBookingTitle(checkoutQuestions, key)}
            </h2>
            {Object.keys(questionState[key]).map((type, typeIndex) => (
              <React.Fragment key={typeIndex}>
                <div className={styles.mainContactDetails}>
                  <h3>{type}</h3>
                  <div className='columns is-multiline'>
                    {questionState[key][type].map((q, index) => (
                      <div className='column is-full' key={index}>
                        {renderQuestion(q, key, type, index)}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default CheckoutQuestions;
