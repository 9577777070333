import React from 'react';
import cx from 'classnames';

import * as styles from './index.module.scss';

interface IProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'coral' | 'white';
}

const Loader: React.FunctionComponent<IProps> = ({ color, size }) => {
  const sizeClass = size || 'small';
  const colorClass = color || 'coral';
  return (
    <div
      className={cx(styles.container, styles[sizeClass], styles[colorClass])}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

export default Loader;
