import React from 'react';

import { ITranslations, ISortedTranslations, IGQQuery } from '../interfaces';

interface IProps {
  data: {
    allContentfulTranslations: IGQQuery<ITranslations>;
  };
  pageLocale: string;
}

interface IState {
  languageStrings: ISortedTranslations | null;
  setLanguageStrings: () => void;
  getTranslation: (word: string) => string | null;
  sortTranslations: (
    edges: Array<{ node: ITranslations }>
  ) => ISortedTranslations | null;
}

interface IContextValue {
  t: (word: string) => string | null;
}

export const Context = React.createContext<IContextValue>({
  t: (_WORD: string) => null
});
const { Provider, Consumer } = Context;

class TranslationContextProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      languageStrings: null,
      setLanguageStrings: this.setLanguageStrings,
      getTranslation: this.getTranslation,
      sortTranslations: this.sortTranslations
    };
  }

  componentDidMount() {
    this.setLanguageStrings();
  }

  render() {
    const value = {
      t: this.getTranslation
    };

    if (this.state.languageStrings) {
      return <Provider value={value}>{this.props.children}</Provider>;
    }
    return null;
  }

  setLanguageStrings = () => {
    const languages = this.sortTranslations(
      this.props.data.allContentfulTranslations.edges
    );
    this.setState({ languageStrings: languages });
  };

  getTranslation = (word: string) => {
    const { pageLocale } = this.props;
    const { languageStrings } = this.state;
    if (languageStrings) {
      if (languageStrings[pageLocale][word]) {
        return languageStrings[pageLocale][word];
      } else {
        return languageStrings['en-US'][word];
      }
    }
    return null;
  };

  sortTranslations(edges: Array<{ node: ITranslations }>) {
    return edges.reduce((acc, val) => {
      const locale = val.node.node_locale;
      const stringKey = val.node.title;
      const value = val.node.value;

      if (!acc[locale]) {
        acc[locale] = {};
      }
      acc[locale][stringKey] = value;
      return acc;
    }, {} as ISortedTranslations);
  }
}

export { Consumer as default, TranslationContextProvider };
