import React from 'react';

import * as styles from './styles//BookingOverview.module.scss';

interface IProps {
  children: React.ReactNode;
  open: boolean;
  isMobile?: boolean;
}

interface IState {
  optionsHeight: number;
}

class BookingOverview extends React.Component<IProps, IState> {
  readonly state = { open: false, optionsHeight: 0 };

  options: HTMLDivElement | null = null;

  componentDidMount() {
    this.updateOptionsHeight();
  }

  componentDidUpdate() {
    this.updateOptionsHeight();
  }

  render() {
    const { children, open, isMobile } = this.props;

    return (
      <div className={styles.container}>
        <div
          className={styles.options}
          style={{
            height: open ? 'auto' : 0,
            boxShadow:
              open && !isMobile ? '0px 18px 17px -3px rgba(0, 0, 0, 0.2)' : '',
            overflow: open ? 'visible' : 'hidden',
          }}
        >
          <div ref={(elem) => (this.options = elem)}>{children}</div>
        </div>
      </div>
    );
  }

  updateOptionsHeight = () => {
    if (this.options) {
      const height = this.options.clientHeight;
      if (this.state.optionsHeight !== height) {
        this.setState({ optionsHeight: height });
      }
    }
  };
}

export default BookingOverview;
