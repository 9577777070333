import React from 'react';
import cx from 'classnames';

import {
  IReduceBookingQuestion,
  IMainContactQuestions,
} from '../../interfaces';
import * as styles from './Questions.module.scss';

interface IProps {
  bookingQuestion?: IReduceBookingQuestion;
  contactQuestion?: IMainContactQuestions;
  changeVal(val: string): void;
}

const IntQuestion: React.FunctionComponent<IProps> = (props) => {
  const { bookingQuestion, contactQuestion } = props;
  const re = new RegExp(/^[0-9]*$/);
  if (bookingQuestion) {
    return (
      <div className={styles.paddingFlex}>
        <div style={{ flex: 1 }}>
          {bookingQuestion.extra ? (
            <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>
              {bookingQuestion.extra}
            </h5>
          ) : null}

          <p
            className={cx(styles.label, {
              [styles.required]: bookingQuestion.Required,
            })}
          >
            {bookingQuestion.Label}
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <input
            type='text'
            className={cx(
              styles.textInput,
              !bookingQuestion.isValid ? styles.errorField : null
            )}
            value={bookingQuestion.value ? bookingQuestion.value : ''}
            placeholder={
              bookingQuestion.Placeholder ? bookingQuestion.Placeholder : ''
            }
            onChange={(e) =>
              re.test(e.target.value)
                ? props.changeVal(e.target.value)
                : undefined
            }
          />
        </div>
      </div>
    );
  } else if (contactQuestion) {
    return (
      <div className={styles.paddingFlex}>
        <div style={{ flex: 1 }}>
          <p
            className={cx(styles.label, {
              [styles.required]: contactQuestion.Required,
            })}
          >
            {contactQuestion.Label}
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <input
            type='text'
            className={cx(
              styles.textInput,
              !contactQuestion.isValid ? styles.errorField : null
            )}
            value={contactQuestion.value ? contactQuestion.value : ''}
            placeholder={
              contactQuestion.Placeholder ? contactQuestion.Placeholder : ''
            }
            onChange={(e) =>
              re.test(e.target.value)
                ? props.changeVal(e.target.value)
                : undefined
            }
          />
        </div>
      </div>
    );
  }
  return null;
};

export default IntQuestion;
