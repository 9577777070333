import React from 'react';

import * as styles from './FilterSearch.module.scss';
import Icon from '../Icon';

interface IProps {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IState {
  active: boolean;
}

class FilterSearch extends React.Component<IProps, IState> {
  readonly state = { value: '', active: false };

  render() {
    const { onChange, value, placeholder } = this.props;
    const { active } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <input
            value={value}
            onChange={onChange}
            onFocus={this.focus}
            onBlur={this.blur}
            aria-label='Search'
            id='Search'
            placeholder={placeholder}
          />
          <div className={styles.iconContainer}>
            <Icon name='FILTERSEARCH' />
          </div>
        </div>
        <div
          className={styles.line}
          style={{ width: active || value !== '' ? '100%' : '0%' }}
        />
      </div>
    );
  }

  focus = () => {
    this.setState({ active: true });
  };

  blur = () => {
    this.setState({ active: false });
  };
}

export default FilterSearch;
