import React from 'react';
import TransportCounterNames from '../TransportCounterNames';
import TransportNumberInput from '../TransportNumberInput';
import { ITransportPersonCounter } from '../../../interfaces';
import * as styles from './index.module.scss';

interface IProps {
  personCounter: ITransportPersonCounter | null;
  countKey: string;
  changePersonCounter(addOrRemove: AddOrRemove, personType: string): void;
}
type AddOrRemove = 'addOne' | 'removeOne';

const TransportCountPerson: React.FunctionComponent<IProps> = ({
  personCounter,
  changePersonCounter,
  countKey,
}) => {
  if (personCounter && personCounter[countKey]) {
    const personCounterItem = personCounter[countKey];
    return (
      <div key={personCounterItem.Id} className={styles.container}>
        <TransportCounterNames
          personCounterItem={personCounterItem}
          countKey={countKey}
          personCounter={personCounter}
        />
        <TransportNumberInput
          personCounter={personCounter}
          changePersonCounter={changePersonCounter}
          countKey={countKey}
        />
      </div>
    );
  }
  return null;
};

export default TransportCountPerson;
