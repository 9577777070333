import axios, { AxiosInstance } from 'axios';
import { IPickupDropoff } from '../interfaces/index';
import { handleAxiosError } from '../utils';

interface IPickupApi {
  getPickupAddresses(
    productId: string,
    lang: string
  ): Promise<IPickupDropoff | null>;
}

class PickupApi implements IPickupApi {
  httpClient: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: process.env.GATSBY_PROXY_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    });
    this.httpClient = instance;
  }
  async getPickupAddresses(
    productId: string,
    lang: string
  ): Promise<IPickupDropoff | null> {
    try {
      const result = await this.httpClient.get('/api/Pickup/GetPickupPlaces', {
        params: {
          productId,
          lang
        }
      });
      return result.data;
    } catch (e) {
      handleAxiosError(e);
      return null;
    }
  }
}

export default PickupApi;
