import React from 'react';

export const ArrowDown = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <path
      d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'
      data-name='Path 272'
    />
    <path d='M-30-14h24v24h-24z' data-name='Path 273' />
  </svg>
);

export default ArrowDown;
